<template>
  <div v-if="$store.state.isUserLoggedIn" row wrap pa-0>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
      <BackButton></BackButton>
      <v-card-text v-if="academyName" @click="goToAcademy()" class="text-center text-h5">
        {{academyName}}
        </v-card-text>
        <v-card-text class="text-center pt-0" v-if="rating && rating >= 0">
          <AcademyStars :rating="rating" />
        </v-card-text>
    </v-sheet>
    <v-expansion-panels flat class="mt-2">
      <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
        <v-expansion-panel-header>
          <div>
            <PencilIcon class="hero-icon"></PencilIcon>
            {{this.alreadyReviewed ? $t('message.edit') + ' ' + $t('message.your_review') : $t('message.add_review')}}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card elevation="0">
            <v-card-title class="error" v-if="formError">{{ $t('message.fill_all_params') }}</v-card-title>
            <v-card-title>
              <StarIcon medium :color="ratingStar1" @click="selectStar(1)"></StarIcon>
              <StarIcon medium :color="ratingStar2" @click="selectStar(2)"></StarIcon>
              <StarIcon medium :color="ratingStar3" @click="selectStar(3)"></StarIcon>
              <StarIcon medium :color="ratingStar4" @click="selectStar(4)"></StarIcon>
              <StarIcon medium :color="ratingStar5" @click="selectStar(5)"></StarIcon>
            </v-card-title>
            <v-card-text>
              <v-textarea outlined v-model="selectedReview.contents" rows="2" :label="$t('message.review')">
              </v-textarea>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="buttonLoader" small color="primary" @click="addEditReview()">
                <v-progress-circular indeterminate color="white" v-if="buttonLoader"></v-progress-circular>
                <span v-else>{{alreadyReviewed ? $t('message.edit') : $t('message.add')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="loading">
      <v-skeleton-loader type="list-item-avatar, article"></v-skeleton-loader>
    </div>
    <div elevation="0" v-if="!loading && reviews.length > 0">
      <template v-for="(r, index) in reviews" class="pa-0">
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" :key="index+r._id+'_review'" class="mt-2 pa-2">
          <v-card-title class="pa-1">
            <user-name :user="r.user" :addHover="false"></user-name>
            <v-spacer></v-spacer>
            <span class="text-caption">{{ $d(new Date(r.time), 'month_day_year', $i18n.locale) }}</span>
          </v-card-title>
          <v-card-text class="pl-1">
            <AcademyStars :rating="r.rating" :show-reviews="false" />
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text v-html="r.contents"></v-card-text>
        </v-sheet>
      </template>
      <div v-if="viewMore" class="text-center">
        <v-btn color="primary" small @click="getReviews(true)">{{ $t('message.view_more') }}</v-btn>
      </div>
    </div>
    <v-card v-else-if="!loading" class="mt-2">
      <v-card-text>
        <h2>{{$t('message.no_reviews')}}</h2>
      </v-card-text>
    </v-card>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        small
        class="elevation-0"
        @click="goBack()"
      >
        {{ $t('message.back') }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </div>
</template>
<script>
import AcademyStars from "@/components/displays/AcademyStarsDisplay.vue";
import ReviewService from "@/services/ReviewService";
import AcademyService from "@/services/AcademyService";
import UserName from "@/components/displays/UserNameDisplay.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

import { 
  StarIcon
} from '@vue-hero-icons/solid'

import { 
  PencilIcon
} from '@vue-hero-icons/outline'


export default {
  data() {
    return {
      academyId: this.$route.params.academyId,
      formError: null,
      academyName: null,
      reviews: [],
      alreadyReviewed: false,
      loading: true,
      starColor1: null,
      starColor2: null,
      starColor3: null,
      starColor4: null,
      starColor5: null,
      reviewsCount: 0,
      reviewsText: "",
      selectedReview: { academyId: this.$route.params.academyId },
      rating: null,
      ratingStar1: null,
      ratingStar2: null,
      ratingStar3: null,
      ratingStar4: null,
      ratingStar5: null,
      viewMore: null,
      searching: false,
      buttonLoader: false,
    };
  },
  components: {
    AcademyStars,
    UserName,
    StarIcon,
    PencilIcon,
    BackButton
  },
  async created() {
    this.calculateRating();
    this.getReviews(false);
    AcademyService.academy(this.academyId)
      .then((data) => {
        if (data) this.academyName = data.academy.name;
      });
    const data = await ReviewService.getUserReview(this.academyId);
    if (data) {
      this.alreadyReviewed = true;
      this.selectedReview = data;
      this.selectStar(data.rating);
    }
  },
  methods: {
    async calculateRating() {
      const data = await ReviewService.getRating(this.academyId);
      var rating = data.rating;
      this.rating = data.rating;
      this.reviewsCount = data.totalReviews;
      //Get rating from Back end

      if (this.reviewsCount != 1) this.reviewsText = this.$t("message.reviews");
      else this.reviewsText = this.$t("message.review");
      for (var i = 1; i <= 5; i++) {
        if (i <= rating) this["starColor" + i] = "#FFBE33";
        else this["starColor" + i] = "";
      }
    },
    async addReview() {
      this.index = 0;
      this.buttonLoader = true;
      if (!this.validateForm()) {
        this.formError = true;
        this.buttonLoader = false;
        return false;
      } else {
        const data = await ReviewService.addReview(this.selectedReview);
        if (data) {
          this.getReviews(false);
          this.selectedReview = data;
          this.alreadyReviewed = true;
          this.calculateRating();
          this.buttonLoader = false;
        } else {
          this.buttonLoader = false;
          this.showAlert("error", this.$t("message.oops_not_working"));
        }
        this.formError = false;
      }
    },
    async editReview() {
      this.index = 0;
      if (!this.validateForm()) {
        this.formError = true;
        return false;
      } else {
        this.buttonLoader = true;
        const data = await ReviewService.updateReview(this.selectedReview);
        if (data) {
          this.getReviews(false);
          this.calculateRating();
          this.buttonLoader = false;
        } else {
          this.showAlert("error", this.$t("message.oops_not_working"));
          this.buttonLoader = false;
        }
        this.formError = false;
      }
    },
    async getReviews(append) {
      try {
        if (!this.searching) {
          this.searching = true;
          this.loading = true;
          this.viewMore = false;
          if (!append) {
            this.index = 0;
            this.reviews = [];
          }
          var data = {
            academyId: this.academyId,
          };
          ReviewService.reviews(this.index, data)
            .then((data) => {
              if (data) {
                this.reviews = this.reviews.concat(data.reviews);
                this.loading = false;
                this.index = this.index + data.count;
                if (data.count >= this.searchesLimit) this.viewMore = true;
                this.searching = false;
              }
            })
            .catch((err) => {
              if(err)
                this.loading = false;
            });
        }
      } catch (err) {
        console.log('Error.')
      }
    },
    validateForm() {
      const r = this.selectedReview;
      if (!r.rating || r.rating == 0) return false;
      if (!r.contents || r.contents == "") return false;
      return true;
    },
    async addEditReview() {
      this.selectedReview.academy = this.academyId;
      if (this.selectedReview._id) {
        this.editReview();
      } else {
        this.addReview();
      }
    },
    hoverStar(star) {
      if (star > 0) {
        for (let i = 1; i <= star; i++) {
          this["ratingStar" + i] = "#FFBE33";
        }
      } else if (!this.selectedReview.rating) {
        for (let i = 1; i <= 5; i++) {
          this["ratingStar" + i] = "";
        }
      }
    },
    selectStar(star) {
      this.selectedReview.rating = star;
      for (var i = 1; i <= 5; i++) {
        if (i <= star) this["ratingStar" + i] = "#FFBE33";
        else this["ratingStar" + i] = "";
      }
    },
  },
};
</script>

<style scoped>
</style>