<template>
    <v-avatar
        :size="calculateSize()">
        <div :style="getStyle()">
          <img v-if="src" :src="userFile(src)">
          <img v-else-if="country && object" class="bg-image" :src="assets('flags/'+country+'.png')">
          <img v-else class="bg-image" :src="assets('default-profile-avatar.png')">
          <icon-academy 
            :style="getIconSize()"
            v-if="!src && object && object =='academy'" 
            class="icon-overlay" color="black"/>
          <icon-belt 
            :style="getIconSize()"
            v-if="!src && object && object =='user'" 
            class="icon-overlay" :color="beltColor"/>
        </div>
    </v-avatar>
</template>

<script>
import IconAcademy from "@/components/displays/icons/IconAcademy.vue";
import IconBelt from "@/components/displays/icons/IconBelt.vue";

export default {
  props: [
    'size',
    'mode',
    'src',
    'country',
    'object',
    'belt'
  ],
  data() {
    return {
      beltColor: null
    };
  },
  created() {
    this.beltColor = this.getBeltColor(this.belt);
  },
  components:{
    IconBelt,
    IconAcademy
  },
  methods: {
    calculateSize() {
      if (this.mode){
        if(this.mode == 'small')
          return '50px';
        else if(this.mode == 'medium')
          return '150px';
        else if(this.mode == 'large')
          return '250px';
      } else if(this.size){
        return this.size;
      } else{
        return "100px";
      }
    },
    getStyle(){
      var dim = this.size;
      if(this.mode && !this.size){
        if(this.mode == 'small')
          dim = '50px';
        else if(this.mode == 'medium')
          dim = '150px';
        else if(this.mode == 'large')
          dim = '250px';
      }
      if(this.mode)
        return 'overflow:hidden;width:'+dim+';height:'+dim;
      else if (this.size)
        return 'overflow:hidden;width:'+dim+';height:'+dim;
      else 
        return 'width:100px;height:100px;overflow: hidden;';
    },
    getIconSize(){
      if(this.object == 'user'){//this.object == 'user'
        if(this.mode && this.mode == 'small')
          return 'width:40px;top:7;left:15%';
        else
          return 'width:70%;top:20%;left:15%';
      }else{
        return 'width:50%;top:25%;left:25%';
      }
    }
  },
  computed: {
  }
};
</script>

<style scoped>
.bg-image {
  display: block;
  opacity: 0.3;
}
.icon-overlay {
  position: absolute;
  height: auto;
  text-align: center;
}
</style>
