<template>
  <video
    :ref="'video_'+videoId"
    v-if="fileUrl"
    :src="fileUrl"
    @play="videoPlayed(video)"
    width="100%"
    playsinline
    muted
    :autoplay="autoplayVideo"
    controls
    controlsList="nodownload noremoteplayback"
    :poster="
      video && video.thumbnail
        ? userFile(video.thumbnail)
        : assets('play_background.png')
    "
  >
    Invalid video
  </video>
</template>

<script>
import Api from "@/services/Api";
import AWSUrlApi from "@/services/AWSUrlApi";
import VideoService from "@/services/VideoService";
export default {
  props: ["video", "skipAutoplay","onCompletion","src"],
  data() {
    return {
      fileUrl: null,
      autoplayVideo: false
    };
  },
  created() {
    const size = this.$vuetify.breakpoint.name;
    const os = this.getOS();
    if (this.skipAutoplay) {
      this.autoplayVideo = false;
    } else if (os == 'Mac OS' && size != 'lg' && size != 'xl') {//This should be an ipad
      this.autoplayVideo = false;
    } else {
      this.autoplayVideo = os == 'iOS' ? false : true;
    }
    this.getFileUrl();
  },
  methods: {
    bindOnCompletionEvent() {
      const vm = this;
      if (vm && vm.video) {
        setTimeout(function () {
          const videoElement = vm.$refs['video_' + vm.video._id];
          videoElement.onended = function () {
            /*Do things here!*/
            vm.onCompletion(vm.video);
          };
        }, 2000);
      }
    },
    videoPlayed(v) {
      if(v && v._id)
        VideoService.viewVideo(v._id);
    },
    getFileUrl() {
      if (this.video && this.video.fileUrl) {
        this.videoId = this.video._id;
        const fileUrl = this.video.fileUrl;
        if (fileUrl.indexOf('media/') >= 0) {
          AWSUrlApi()
            .get(this.video.fileUrl)
            .then((res) => {
              this.fileUrl = res.data;
              if (this.onCompletion) {
                //This ref is not available for some reason
                this.bindOnCompletionEvent();
              }
            });
        } else if (fileUrl.indexOf('AWSAccessKeyId') < 0) {
          const encodedUrl = encodeURIComponent(fileUrl)
          Api()
            .get("content/url/signed/" + encodedUrl)
            .then(res => {
              this.fileUrl = res.data;
              this.bindOnCompletionEvent();
            })
        } else {
          this.fileUrl = fileUrl;
          this.bindOnCompletionEvent();
        }
      } else if (this.src) {
        if (this.src.indexOf('AWSAccessKeyId') < 0) {
          const d = new Date();
          this.videoId = d.getMilliseconds();
          const encodedUrl = encodeURIComponent(this.src)
          Api()
            .get("content/url/signed/" + encodedUrl)
            .then(res => {
              this.fileUrl = res.data;
              this.bindOnCompletionEvent();
            })
        } else {
          this.fileUrl = this.src;
          this.bindOnCompletionEvent();
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
