<template>
  <v-flex xs6 offset-xs3>
    <div>
      <img style="width: 200px;" :src="assets('logo-circle.png')" />
    </div>
    <div>
      <img
        @click="
          goToLink(
            'https://play.google.com/store/apps/details?id=com.bjjlink.android'
          )
        "
        style="max-width: 200px; height: auto; cursor: pointer;"
        :src="assets('googleplay_badge.png')"
      />
    </div>
    <div>
      <img
        @click="goToLink('https://apps.apple.com/us/app/bjjlink/id1458988543')"
        style="max-width: 200px; height: auto; cursor: pointer;"
        :src="assets('appstore_badge.png')"
      />
    </div>
  </v-flex>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.splash {
  font-size: 2em;
  font-family: "Reckoner", "Avenir", Helvetica, Arial, sans-serif;
}
img {
  width: 100%;
  height: auto;
}
</style>
