<template>
  <div v-if="$store.state.isUserLoggedIn">
    <ObjectsAutocomplete :disabled="$store.state.hidden">
    </ObjectsAutocomplete>
    <div class="text-center" v-if="$store.state.hidden">
      {{ $t('message.social_disabled') }}
    </div>
  </div>
</template>

<script>
  import ObjectsAutocomplete from "@/components/inputs/ObjectsAutoComplete";

  export default {
    name: 'Home',
    data() {
      return {
        loading: true,
      };
    },
    components: {
      ObjectsAutocomplete
    },
    async created() {

    },
    methods:{
    }
  }
</script>
<style scoped>

</style>
