<template>
  <div v-if="post">
    <v-btn
      elevation="0"
      small
      :color="reactionColor()"
      :text="!reacted ? true : false"
      :outlined="!reacted ? true : false"
      @click="updateEmotions(reactions.oss)"
    >
      <IconOss v-if="!reacted" size="25" class="hero-button" :color="themeColor('gray')"></IconOss>
      <IconOss v-if="reacted == reactions.oss" size="25" class="hero-button" color="gray"></IconOss>
    </v-btn>
  </div>
</template>

<script>
import PostService from "@/services/PostService";
import IconOss from "@/components/displays/icons/IconOss.vue";

export default {
  props: ["post"],
  data() {
    return {
      alreadyLiked: false,
      showButtons: false,
      isInInfo: false,
      reacted: null,
      reactions: {
        oss: 'oss',
      }
    };
  },
  created() {
    this.loadInteraction();
  },
  components: {
    IconOss,
  },
  methods: {
    reactionColor(){
      if(!this.reacted)
        return 'gray';
      else if(this.reacted == this.reactions.oss)
        return 'error';
    },
    loadInteraction(){
      if(this.post){
        const emotions = this.post.emotions;
        if(emotions){
          //Oss
          if(emotions['oss'] && emotions['oss'].indexOf(this.$store.state.user._id) >= 0){
            this.reacted = this.reactions.oss;
          }
        }
      }
    },
    async updateEmotions(emotion) {
      const data = await PostService.updateEmotion(this.post._id, emotion);
      if (data && data.reacted) {
        if (emotion == this.reactions.oss) {
          this.reacted = this.reactions.oss;
        }
      } else {
        this.reacted = null;
      }
    },
  },
  beforeMount() {},
  computed: {}
};
</script>

<style scoped>
.emotion{
  margin-top:-4px;
  margin-left: 15px
}
.reactions-dialog{
  z-index: 10;
  position:absolute;
  margin-top: -55px
}
</style>
