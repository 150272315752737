<template>
  <v-combobox
    v-model="chips"
    :items="items"
    small-chips
    :label="labelText"
    multiple
    item-text="text"
    item-value="value"
    outlined
    dense
    @input="event => { $emit('input', event) }"
    :clearable="true"
  ></v-combobox>
</template>

<script>

export default {
  data() {
    return {
      chips: null,
      specialties: [],
      loading: false,
      labelText: null,
      items: [
        {text: this.$t('message.monday') + ' - ' + this.$t('message.morning') + '', value:'Monday - Morning'},
        {text: this.$t('message.monday') + ' - ' + this.$t('message.afternoon') + '', value:'Monday - Afternoon'},
        {text: this.$t('message.monday') + ' - ' + this.$t('message.evening') + '', value:'Monday - Evening'},
        {text: this.$t('message.tuesday') + ' - ' + this.$t('message.morning') + '', value:'Tuesday - Morning'},
        {text: this.$t('message.tuesday') + ' - ' + this.$t('message.afternoon') + '', value:'Tuesday - Afternoon'},
        {text: this.$t('message.tuesday') + ' - ' + this.$t('message.evening') + '', value:'Tuesday - Evening'},
        {text: this.$t('message.wednesday') + ' - ' + this.$t('message.morning') + '', value:'Wednesday - Morning'},
        {text: this.$t('message.wednesday') + ' - ' + this.$t('message.afternoon') + '', value:'Wednesday - Afternoon'},
        {text: this.$t('message.wednesday') + ' - ' + this.$t('message.evening') + '', value:'Wednesday - Evening'},
        {text: this.$t('message.thursday') + ' - ' + this.$t('message.morning') + '', value:'Thursday - Morning'},
        {text: this.$t('message.thursday') + ' - ' + this.$t('message.afternoon') + '', value:'Thursday - Afternoon'},
        {text: this.$t('message.thursday') + ' - ' + this.$t('message.evening') + '', value:'Thursday - Evening'},
        {text: this.$t('message.friday') + ' - ' + this.$t('message.morning') + '', value:'Friday - Morning'},
        {text: this.$t('message.friday') + ' - ' + this.$t('message.afternoon') + '', value:'Friday - Afternoon'},
        {text: this.$t('message.friday') + ' - ' + this.$t('message.evening') + '', value:'Friday - Evening'},
        {text: this.$t('message.saturday') + ' - ' + this.$t('message.morning') + '', value:'Saturday - Morning'},
        {text: this.$t('message.saturday') + ' - ' + this.$t('message.afternoon') + '', value:'Saturday - Afternoon'},
        {text: this.$t('message.saturday') + ' - ' + this.$t('message.evening') + '', value:'Saturday - Evening'},
        {text: this.$t('message.sunday') + ' - ' + this.$t('message.morning') + '', value:'Sunday - Morning'},
        {text: this.$t('message.sunday') + ' - ' + this.$t('message.afternoon') + '', value:'Sunday - Afternoon'},
        {text: this.$t('message.sunday') + ' - ' + this.$t('message.evening') + '', value:'Sunday - Evening'},
      ]
    };
  },
  created() {
    if(this.label)
      this.labelText = this.label;
  },
  mounted(){
    this.chips = this.value
  },
  props: {
    value: null,
    showIcon: {type:Boolean, default:false},
    label: null
  },
  watch: {
    value: {
      handler: function(newValue) {
        this.chips = newValue;
      }
    },
    chips: {
      handler: function() {
        this.$emit("input", this.chips);
      }
    }
  },
};
</script>

<style scoped>
</style>
