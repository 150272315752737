<template>
  <v-flex>
    <v-btn v-if="alreadyLiked" @click="likeFunction()" icon :color="icon ? '#4b4b4b' : ''">
      <icon-like style="margin-top:-10px" size="20px" color="blue" />
    </v-btn>
    <v-btn v-else @click="likeFunction()" icon>
      <icon-like style="margin-top:-10px" size="20px" :color="'grey'" />
    </v-btn>
    <span class="clickable text-caption ml-2">{{badgeNumber}}</span>
    <span class="clickable text-caption ml-1" @click="viewLikes()">{{badgeNumber == 1 ? $t('message.like') : $t('message.likes')}}</span>
    <v-dialog v-model="likesDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="secondary">
          <v-spacer></v-spacer>
          <v-btn icon dark @click="likesDialog = false">
            <XCircleIcon class="hero-button"></XCircleIcon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div v-if="loadingUsers">
            <v-skeleton-loader
              type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
            ></v-skeleton-loader>
          </div>
          <UsersList v-else :users="likeUsers"></UsersList>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-flex>
</template>

<script>
import AcademyService from "@/services/AcademyService";
import IconLike from "@/components/displays/icons/IconLike.vue";
import UsersList from "@/components/displays/UsersListDisplay.vue";

import { 
  XCircleIcon
} from '@vue-hero-icons/outline'

export default {
  props: ["academy", "icon"],
  data() {
    return {
      alreadyLiked: false,
      badgeNumber: 0,
      likeFunction: null,
      likesDialog: false,
      timer: null,
      isInInfo: false,
      loadingUsers: true,
      likeUsers: [],
      height: window.innerHeight - 100
    };
  },
  created() {
    var liked = false;
    const likes = this.academy.likes;
    this.likeFunction = this.likeAcademy;
    if (likes && likes.length > 0)
      liked = likes.indexOf(this.$store.state.user._id) >= 0 ? true : false;
    this.alreadyLiked = liked;
    if (liked) {
      this.likeFunction = this.unlikeAcademy;
    }
    this.badgeNumber = this.academy.likes.length;
  },
  components: {
    IconLike,
    UsersList,
    XCircleIcon
  },
  methods: {
    async likeAcademy() {
      const data = await AcademyService.like(this.academy._id);
      if (data) {
        this.alreadyLiked = true;
        this.badgeNumber++;
        this.likeFunction = this.unlikeAcademy;
      } else {
        this.showAlert(
          "error",
          "Opps... Something went wrong in the system, give us a bit."
        );
      }
    },
    async unlikeAcademy() {
      const data = await AcademyService.unlike(this.academy._id);
      if (data) {
        this.alreadyLiked = false;
        this.badgeNumber--;
        this.likeFunction = this.likeAcademy;
      } else {
        this.showAlert(
          "error",
          "Opps... Something went wrong in the system, give us a bit."
        );
      }
    },
    viewLikes() {
      if (this.badgeNumber > 0) {
        if (this.likesDialog) {
          this.likesDialog = false;
        } else {
          this.likesDialog = true;
          AcademyService.viewLikes(this.academy._id)
            .then(data => {
              if (data) this.likeUsers = data;
              this.loadingUsers = false;
            })
            .catch(err => {
              if(err)
                this.loadingUsers = false;
            });
        }
      }
    }
  },
  beforeMount() {},
  computed: {}
};
</script>

<style scoped>
</style>
