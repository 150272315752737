<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" >
    <div v-if="isAppAdmin(this.$store.state.user._id)">
      <v-card style="margin-top: 10px;">
        <v-card-title class="text-h6">Add New Relase</v-card-title>
        <v-card-text>
          <v-select
            @change="getLatestVersion"
            v-model="release.project"
            :items="projects"
            label="Project"
          ></v-select>
        </v-card-text>
        <v-card-text>
          <v-text-field
            outlined
            v-model="release.version"
            :label="
              'Version' +
              (latestVersion ? ' (Latest Version: ' + latestVersion + ')' : '')
            "
            dense
          ></v-text-field>
          <v-switch v-model="release.visible" label="Visible"></v-switch>
        </v-card-text>
        <v-card-text>
          <VueEditor :style="'background-color:white;color:black'" v-model="release.contents" :editor-toolbar="editorToolbar"/>
        </v-card-text>
        <v-card-text>
          <div class="supporting-text ml-2" v-if="uploadingMessage">
            {{uploadingMessage}}
          </div>
          <v-btn block color="primary" outlined class="elevation-0" @click="filePicker()" small
            slot="activator">
            <RefreshIcon v-if="uploading" size="20" class="rotate"></RefreshIcon>
            <PhotographIcon class="hero-button"></PhotographIcon>
            Upload Image
          </v-btn>
          <v-img v-if="release.image" :src="userFile(release.image)" width="100%"></v-img>
          <input style="display: none" type="file" accept="image/*" @change="onFileSelect" ref="fileUploadInput" />
        </v-card-text>
        <v-card-actions>
          <v-btn block :disabled="uploading ? true :false" v-if="editing" small color="primary" @click="updateRelease">Update</v-btn>
          <v-btn block :disabled="uploading ? true :false" v-else small color="primary" @click="addRelease">Add</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn v-if="editing" small color="secondary" @click="emptyRelease">Clear</v-btn>
        </v-card-actions>
      </v-card>
      <v-card>
        <v-card-text>
          <v-select
            outlined
            dense
            v-model="selectedProject"
            :items="projects"
            label="Selec a project to see releases"
          ></v-select>
        </v-card-text>
        <v-card-text v-if="releases">
          <v-expansion-panels flat>
            <template v-for="(i) in releases">
              <v-expansion-panel :key="i._id" style="margin-bottom: 5px;">
                <v-expansion-panel-header>
                  <div>
                    {{i.version}}
                    <span
                      style="margin-left:10px;color:gray"
                    >{{ $d(new Date(i.time), 'short', $i18n.locale) }}</span>
                    <EyeIcon size="20" class="hero-icon" color="green" v-if="i.visible"></EyeIcon>
                    <EyeOffIcon size="20" class="hero-icon" v-else></EyeOffIcon>
                    <v-badge
                      color="terciary"
                      overlap
                      right
                    >
                      <span slot="badge">{{i.seenBy ? i.seenBy.length : 0}}</span>
                      <v-btn @click.stop="checkSeenBy(i)" icon small>
                        <EmojiHappyIcon size="20" class="hero-button"></EmojiHappyIcon>
                      </v-btn>
                    </v-badge>
                    <PencilAltIcon size="20" class="hero-button" @click.stop="showToEdit(i)"></PencilAltIcon>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-img v-if="i.image" :src="userFile(i.image)" width="100%"></v-img>
                  <div v-html="i.contents"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <h2>You are not welcome here.</h2>
      <div>
        <icon-master color="error" :size="200" />
      </div>
    </div>
    <v-dialog v-model="seenByDialog" max-width="70%">
      <v-card v-if="seenBy">
        <v-card-text>
          <v-list-item  v-for="(u, index) in seenBy" :key="u._id + index" @click="goToUserNewTab(u._id)">
            <v-list-item-avatar>
              <img v-if="u.userImageSmall"
                  :src="userImg(u.userImageSmall)"
              />
              <img v-else :src="assets('blank-profile-picture.png')"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <span class="clickable">{{u.displayName}}</span>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeSeenBy()" color="secondary">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import FileService2 from "@/services/FileService2";
import BjjlinkAdminService from "@/services/BjjlinkAdminService";
import IconMaster from "@/components/displays/icons/IconMaster.vue";
import { VueEditor } from "vue2-editor";

import { 
  EyeOffIcon,
  EyeIcon,
  EmojiHappyIcon,
  PencilAltIcon,
  RefreshIcon,
  PhotographIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      release: {},
      editing: false,
      releases: null,
      editorToolbar: this.getEditorToolbar(),
      latestVersion: null,
      seenByDialog: false,
      selectedProject: null,
      uploadingMessage: null,
      selectedFile: null,
      uploadedFile: null,
      uploading: false,
      seenBy: null,
      projects: [
        "bjjlink-admin",
        "bjjlink-web",
        "bjjlink-elevate",
        "bjjlink-core",
      ],
      onUploadProgress: function () { },
    };
  },
  components: {
    IconMaster,
    VueEditor,
    EyeOffIcon,
    EyeIcon,
    PhotographIcon,
    PencilAltIcon,
    RefreshIcon,
    EmojiHappyIcon
  },
  created() {
    this.getLatestVersion("bjjlink-admin");
    this.emptyRelease();
  },
  watch: {
    selectedProject: {
      handler: function (newValue) {
        if (newValue) {
          this.getReleases();
        }
      },
    },
  },
  methods: {
    emptyRelease() {
      this.release = {
        project: "bjjlink-admin",
        contents:
          '',
      };
      this.uploadingMessage = null;
      this.editing = false;
    },
    showToEdit(release) {
      this.editing = true;
      this.release = release;
    },
    checkSeenBy(r){
      if(r.seenBy && r.seenBy.length > 0){
        this.seenBy = r.seenBy;
        this.seenByDialog = true;
      }
    },
    closeSeenBy(){
      this.seenBy = null;
      this.seenByDialog = false;
    },
    getLatestVersion(p) {
      BjjlinkAdminService.latestVersion(p).then((data) => {
        this.latestVersion = data.version;
      });
    },
    getReleases(){
      BjjlinkAdminService.getReleases(this.selectedProject)
        .then((data) => {
          this.releases = data.releases;
        });
    },
    goToUserNewTab(id){
      window.open("/secure/profile/"+id);
    },
    updateRelease() {
      BjjlinkAdminService.updateRelease(this.release)
        .then(() => {
          this.showAlert("success", "Yoo! ", "nice brou!");
          this.emptyRelease();
        })
        .catch(() => {
          this.showAlert("error", "Yoo! ", "What da??? Talk to Santi :/");
        });
    },
    addRelease() {
      BjjlinkAdminService.addRelease(this.release)
        .then(() => {
          this.release = {
            project: "bjjlink-admin",
            contents:
              '',
          };
          this.showAlert("success", "Yoo! ", "nice brou!");
          this.getLatestVersion("bjjlink-admin");
        })
        .catch(() => {
          this.showAlert("error", "Yoo! ", "What da??? Talk to Santi :/");
        });
    },
    uploadPhoto() {
      const formData = new FormData();
      this.uploadingMessage = this.$t("message.image_upload_progress");
      formData.append("image", this.selectedFile, this.selectedFile.name);
      FileService2.uploadImage(formData, this.onUploadProgress)
        .then((res) => {
          this.uploadedFile = res.file;
          this.uploading = false;
          this.uploadingMessage = this.selectedFile.name;
          this.release.image = res.file;
        })
        .catch(() => {
          this.clearParameters();
        });
    },
    filePicker() {
      this.uploading = false;
      this.$refs.fileUploadInput.click();
    },
    onFileSelect(event) {
      this.uploading = true;
      if (event.target.files[0]) {
        this.uploadedFile = null;
        this.selectedFile = event.target.files[0];
        const size = event.target.files[0].size;
        const sizeMb = Math.floor(size / 1000000);
        if (sizeMb <= 15) {
          this.uploadPhoto();
        } else {
          this.uploading = false;
          this.showAlert(
            "error",
            "Sorry! Your image is too big (" +
            sizeMb +
            "mb), try a smaller one."
          );
        }
      } else {
        this.uploading = false;
      }
    },
  },
};
</script>

<style scoped></style>
