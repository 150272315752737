<template>
  <v-container v-if="images && color" class="text-center">
    <v-row>
      <v-col
        class="gallery-panel pt-0"
        v-for="i in images"
        :key="i._id"
        xs="3"
        align-self="center"
      >
        <img 
          @click="goToImage(i._id)"
          :style="'cursor:pointer;background-color: '+color" 
          :src="userFile(i.image)">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  props: ["images", "color"],
  data() {
    return {
    };
  },
  methods: {
    goToImage(id){
      this.navigateTo({ name: "viewpost", params: { postId: id } });
    }
  },
  created() {
  },
  computed: {
  }
};
</script>

<style scoped>
.gallery-panel img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 0.75rem;
}
</style>
