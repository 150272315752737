/* eslint-disable indent */
import Api from "@/services/Api";

export default {
  async internal() {
    const response = await Api().get("aggregates/users");
    return response.data;
  },
  async counts() {
    const response = await Api().get("aggregates/counts");
    return response.data;
  },
  async countries() {
    const response = await Api().get("aggregates/bycountry?all=all");
    return response.data;
  },
  async usersCSV(claimedacademies) {
    const response = await Api().get(
      "aggregates/useremails?" + (claimedacademies ? "ca=true" : "")
    );
    return response.data;
  },
  async activityLast3Months() {
    const response = await Api().get("aggregates/acitivity/last/3");
    return response.data;
  },
};
