<template>
  <li style="width:100%">
    <div :class="{ bold: isFolder }">
      <v-progress-circular indeterminate v-if="loading" color="primary" class="mr-1" :size="24"
        :width="3"></v-progress-circular>
      <v-icon v-else-if="item.root && item.master" color="success" class="mr-1">mdi-tree-outline</v-icon>
      <v-icon class="hero-icon mr-1" v-else-if="item.root && item.children.length == 0"
        color="success">mdi-leaf</v-icon>
      <v-icon v-else-if="isFolder && item.children == null" class="mr-1 rotate-90">mdi-source-branch</v-icon>
      <v-icon v-else-if="isFolder" color="brown" class="mr-1 rotate-90">mdi-source-branch</v-icon>
      <v-icon v-else color="success" class="mr-1 hero-icon">mdi-leaf</v-icon>
      <v-avatar v-if="item.user && item.user.userImageSmall" size="20">
        <img :src="userImg(item.user.userImageSmall)" class="node-image" />
      </v-avatar>
      <v-avatar v-else-if="item.user && item.user.userImage" size="20">
        <img :src="userImg(item.user.userImage)" class="node-image" />
      </v-avatar>
      <span @click="toggle" class="clickable">{{ item.name }}</span>
      <span style="margin-left:3px;color:gray" v-if="item.nickname">"{{item.nickname}}"</span>
    </div>
    <ul v-show="isOpen" v-if="isFolder" style="list-style: none;padding-left: 14px;">
      <tree-item class="item" v-for="(child, index) in item.children" :key="index" :item="child"
        @make-folder="$emit('make-folder', $event)" @add-item="$emit('add-item', $event)"></tree-item>
    </ul>
  </li>
</template>

<script>
import LineageService from "@/services/LineageService";

export default {
  name: "tree-item",
  props: {
    item: Object,
  },
  data: function () {
    return {
      isOpen: false,
      loading: false,
    };
  },
  computed: {
    //this.item.children == null ||
    isFolder: function () {
      return (
        this.item.children == null ||
        (this.item.children && this.item.children.length)
      );
    },
  },
  methods: {
    toggle: function () {
      if (this.loading)
        return;
      this.loading = true;
      if (this.item.children == null) {
        LineageService.children(this.item._id).then((data) => {
          if (data) {
            this.item.children = data.children;
            if (this.isFolder) {
              this.isOpen = !this.isOpen;
            }
          }
          this.loading = false;
        });
      } else {
        if (this.isFolder) {
          this.isOpen = !this.isOpen;
        }
        this.loading = false;
      }
    },
    makeFolder: function () {
      if (!this.isFolder) {
        this.$emit("make-folder", this.item);
        this.isOpen = true;
      }
    },
  },
};
</script>

<style scoped>
.node-image {
  margin-bottom: 2px;
}
</style>
