<template>
  <div v-if="inputId">
    <div 
      :style="'height:'+editorHeight"
      :class="'pa-2 editor' + (outlined ? ' outlined-editor' : '')"
      contenteditable 
      @paste="handleOnPaste"
      :ref="inputId" 
      @input="onLiveEdit"
      @focus="onFocus"
      :placeholder="label"></div>
    <v-card
      class="available-tags-popover pa-2 outlined"
      v-if="false"
      transition="fade" 
    >
      <v-card-title class="pa-1 text-subtitle-1">
        <span class="mr-2">{{ $t("message.searching") }}: </span><span class="primary--text">{{term}}</span>
        <v-spacer></v-spacer>
        <v-btn small icon @click="clearTagging()"><XCircleIcon  size="20" class="hero-button"></XCircleIcon></v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-list v-if="results && results.length > 0">
          <template v-for="(i, index) in results">
            <v-list-item :key="'tag_'+index+'_'+i.id" @click="selectTag(i)" class="pa-0">
              <v-list-item-avatar>
                <img :src="userImg(i.image)" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="i.text"></v-list-item-title>
                <v-list-item-subtitle>
                  <span v-if="i.type == 'user'">Jitser</span>
                  <span v-if="i.type == 'event'">{{
                    $t("message.event")
                  }}</span>
                  <span v-if="i.type == 'partner'">{{
                    $t("message.partner")
                  }}</span>
                  <span v-if="i.type == 'academy'">{{
                    $t("message.academy")
                  }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <div v-else-if="loading">loading</div>
        <div v-else class="pa-2"><h3 style="color:gray">{{$t("message.no_results")}}</h3></div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import UtilService from "@/services/UtilService";

import { 
  XCircleIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      target: null,
      inputId: null,
      term: null,
      showPopup: false,
      loading: false,
      results: [],
      lastTagId: null,
      tags:[],
      ranges: [],
      currentRange: null,
      loaded: false,
      editorHeight: "70px"
    };
  },
  components: {
    XCircleIcon
  },
  props: ["value","label","edit", "height","outlined","managedAcademy"],
  created() {
    const d = new Date();
    this.inputId = 'dynamic_input_'+d.getTime();
    if(this.height)
      this.editorHeight = this.height;
  },
  mounted() {
    if (this.value) {
      this.target = this.$refs[this.inputId];
      if (this.target) {
        this.target.innerHTML = this.value;
        //Set position to the end
        this.placeCaretAtEnd(this.target);
      }
    }
  },
  watch: {
    value: {
      handler: function (newValue) {
        if (!newValue) {
          if (this.target) {
            this.target.innerHTML = '';
            this.loaded = false;
          }
        }else if(!this.loaded && this.edit){
          //Special case for loading text on edit mode
          this.target = this.$refs[this.inputId];
          if(this.target){
            this.target.innerHTML = newValue;
            this.loaded = true;
          }
        }
      },
    },
  },
  methods: {
    handleOnPaste(e){
      e.preventDefault();
      let pastedText = '';
      if (e.clipboardData || e.originalEvent.clipboardData) {
        pastedText = (e.originalEvent || e).clipboardData.getData('text/plain');
      } else if (window.clipboardData) {
        pastedText = window.clipboardData.getData('Text');
      }
      this.target = this.$refs[this.inputId];
      if(this.target){
        const existingContent = this.replaceNbsps(this.target.innerHTML);
        let position = this.getPos();
        const len = existingContent.length;
        if (existingContent.indexOf('dyn-lin') >= 0) {//we have entered tags, just put at the end
          position = len + 1;
        } else {
          pastedText = ' ' + pastedText;
        }
        const pre = existingContent.substring(0, position);
        let post = '';
        if(position != len)
          post = existingContent.substring(position, len);
        const updatedContent = pre + pastedText + post;
        this.target.innerHTML = updatedContent;
        this.loaded = true;
        //TODO: Fix me later
        //this.setPos(position + pastedText.length);
        //console.log('Updated content: ', updatedContent);
        this.$emit('input', updatedContent);
      }
    },
    replaceNbsps(str) {
      const re = new RegExp(String.fromCharCode(160), "g");
      return str.replace(re, " ").replaceAll('&nbsp;',' ');
    },
    getPos() {
      // for contentedit field
      if (this.target) {
        this.target.focus()
        let _range = document.getSelection().getRangeAt(0)
        let range = _range.cloneRange()
        range.selectNodeContents(this.target)
        range.setEnd(_range.endContainer, _range.endOffset)
        return range.toString().length;
      }
    },
    setPos(pos) {
        // for contentedit field
        if (this.isContentEditable) {
            this.target.focus()
            document.getSelection().collapse(this.target, pos)
            return
        }
        this.target.setSelectionRange(pos, pos)
    },
    selectTag(i){
      try{
        //replace text matching term + @
        this.showPopup = false;
        let src = this.target.innerHTML;
        let link = '/secure/';
        this.lastTagId = i.type+'_'+i.id;
        if(i.type == 'user')
          link += 'profile/'+i.id;
        else if(i.type == 'academy')
          link += 'academy/'+i.id;  
        else if(i.type == 'event')
          link += 'events/'+i.id;  
        else if(i.type == 'partner')
          link += 'partners/'+i.id;
        if(i.text && i.text != ""){
          const tagHtml = '<span dyn-link="'+link+'" class="dynamic-tag primary--text" obj-type="'+i.type+'" obj-id="'+this.lastTagId+'">'+i.text+'</span>&nbsp;';
          src = src.replace('@'+this.term, tagHtml);
          this.target.innerHTML = src;
          this.target.focus();
          this.placeCaretAtEnd(this.target);
          this.currentRange.end = this.currentRange.start + i.text.length+1;
          this.currentRange.id = i.id;
          this.currentRange.len = i.text.length;
          this.currentRange.html = tagHtml;
          this.ranges.push(this.currentRange);
          this.currentRange = null;
          this.clearTagging();
          this.$emit('input', src);
        }
      }catch(err){
        console.log(err);
      }
    },
    onBlur(){
      //let src = evt.target.innerHTML;
      this.showPopup = false;
    },
    onFocus(event){
      this.target = event.target;
    },
    clearTagging(){
      this.term = null;
      this.results = [];
      this.showPopup = false;
    },
    deletePressed(event){
      //When deleting an existing tag, you should know last used name, match that against the text and the pan containing it and completely delete it
      if(event.keyCode == 8 && this.term != null){//Backspace and we are adding a tag
        if(this.term == ''){
          this.clearTagging();
        }else if(this.term.length == 1){
          this.term = '';
        } else{
          this.term = this.term.substring(0, this.term.length -1);
          this.getItems(this.term);
        }
      } else if(event.keyCode == 8){ //Backspace and we are just typing
        this.inRange();
      }
    },
    keyPressed(event){
      if(event.keyCode == 64){//@
        this.term = '';
        this.currentRange = {
          start: this.getPos()
        };
      } else if(event.keyCode == 32 && this.term != null){//Space
        this.term += event.key;
        this.getItems();
      } else if(this.term != null) {
        this.term += event.key;
        this.getItems();
      }
    },
    inRange(){
      if(this.ranges.length > 0){
        const pos = this.getPos();
        //console.log('Curr Pos: ', pos);
        let index = 0;
        let found = false;
        for(const r of this.ranges){
          if (pos <= r.end && pos >= r.start) {
            if (this.target) {
              //console.log('We are in range! Curr pos: ', pos);
              //console.log('Replacing: ', r.html);
              //console.log('Html: ', this.target.innerHTML);
              this.target.innerHTML = this.target.innerHTML.replace(r.html.trim(), '') + '&nbsp;';
              this.placeCaretAtEnd(this.target);
              //We remove the current range 
              /*
              If we need to get fancy and reload the popup
              const currLen = pos - r.start;
              if(currLen < r.len){
                console.log('DISPLAY EDITOR')
              }
              */
              found = true;
              break;
            }
          }
          index++;
        }
        if(found){
          this.ranges.splice(index, 1);
          //console.log('Ranges after removing: ', this.ranges.length);
        }
      }
    },
    enterClicked(){
      //this.$refs.dynamicInput.blur();
      //this.$el.querySelector('.editme').blur();
      //console.log('End Edit')
    },
    onLiveEdit(evt) {
      if (evt.target) {
        let src = evt.target.innerHTML;
        this.$emit('input', src);
      }
    },
    getItems(){
      this.showPopup = true;
      this.loading = true;
      UtilService.searchPostTags(this.term, this.managedAcademy)
        .then((data) => {
          if (data) {
            const tmp = data.results;
            this.results = tmp.filter((e) => {
              return (
                (e.text || "")
                  .toLowerCase()
                  .indexOf((this.term || "").toLowerCase()) > -1
              );
            });
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err)
        });
    },
    placeCaretAtEnd(el) {
      el.focus();
      if (typeof window.getSelection != "undefined"
              && typeof document.createRange != "undefined") {
          var range = document.createRange();
          range.selectNodeContents(el);
          range.collapse(false);
          var sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
      } else if (typeof document.body.createTextRange != "undefined") {
          var textRange = document.body.createTextRange();
          textRange.moveToElementText(el);
          textRange.collapse(false);
          textRange.select();
      }
    }
  },
  computed: {
  },
};
</script>
<style scoped>
.editor {
  border-bottom: solid 1px #454444;
  width: 99%;
  padding: 6px;
  overflow: scroll;
  text-align: left;
  font-size: 16px;
}
.outlined-editor {
  border: solid 1px #454444;
  border-radius: 5px;
}
[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: gray;
  background-color: transparent;
}
.available-tags-popover{
  position: absolute;
  box-shadow: 0 6px 6px rgba(16, 16, 16, 0.04), 0 6px 6px rgba(0, 0, 0, 0.05);
  z-index: 999;
  min-width: 350px;
}
</style>