import Api from "@/services/Api";

export default {
  async cities(country) {
    const response = await Api().get(
      "cities" + (country ? "?country=" + country : "")
    );
    return response.data;
  },
  async filter(country) {
    const response = await Api().get("cities/country/" + country);
    return response.data;
  },
  async geocode(location) {
    const response = await Api().post("cities/geocode/location", location);
    return response.data;
  },
  async timeZone(data) {
    const response = await Api().post("cities/timezone", data);
    return response.data;
  }
};
