import Api from "@/services/Api";

export default {
  async posts(pointer) {
    const response = await Api().get("posts?pointer=" + pointer);
    return response.data;
  },
  async newsFeedPosts(data) {
    const response = await Api().post("posts/newsfeed", data);
    return response.data;
  },
  async hashtagPosts(hashtag, pointer) {
    const response = await Api().get(
      "posts/hashtag/" + hashtag + "?pointer=" + pointer
    );
    return response.data;
  },
  async getPost(postId) {
    const response = await Api().get("posts/post/" + postId);
    return response.data;
  },
  async academyPosts(aid, pointer) {
    const response = await Api().get(
      "posts/academy/" + aid + "?pointer=" + pointer
    );
    return response.data;
  },
  async eventPosts(eid, pointer) {
    const response = await Api().get(
      "posts/event/" + eid + "?pointer=" + pointer
    );
    return response.data;
  },
  async partnerPosts(id, pointer) {
    const response = await Api().get(
      "posts/partner/" + id + "?pointer=" + pointer
    );
    return response.data;
  },
  async userPosts(uid, pointer) {
    try {
      const response = await Api().get("posts/" + uid + "?pointer=" + pointer);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async submitPost(formData) {
    try {
      const response = await Api().post("posts/v2", formData);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async updatePost(id, contents) {
    try {
      const response = await Api().patch("posts/" + id, { contents: contents });
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async deletePost(pid) {
    const response = await Api().delete("posts/" + pid);
    return response.data;
  },
  async addComment(pid, formData) {
    try {
      const response = await Api().post("posts/comments/" + pid, formData);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async replyComment(pid, cid, formData) {
    try {
      const response = await Api().post(
        "posts/comments/" + pid + "/reply/" + cid,
        formData
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async getComments(pid) {
    try {
      const response = await Api().get("posts/comments/" + pid);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async getReplies(cid, index) {
    try {
      const response = await Api().get(
        "posts/comments/" + cid + "/replies?index=" + index
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async getReply(cid, rid) {
    try {
      const response = await Api().get(
        "posts/comments/" + cid + "/replies/" + rid
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async deleteRepliedMessage(cid) {
    try {
      const response = await Api().delete("posts/comments/replies/" + cid);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async updateComment(id, contents) {
    try {
      const response = await Api().patch("posts/comments/" + id, {
        contents: contents,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async deleteComment(cid) {
    try {
      const response = await Api().delete("posts/comments/" + cid);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async likePost(id) {
    try {
      const response = await Api().patch("posts/like/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async unlikePost(id) {
    try {
      const response = await Api().patch("posts/unlike/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async viewLikes(id) {
    try {
      const response = await Api().get("posts/likes/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async updateEmotion(id, emotion) {
    try {
      const response = await Api().patch(
        "posts/v2/" + id + "/emotions/" + emotion
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async viewEmotions(id, emotion) {
    try {
      const response = await Api().get(
        "posts/v2/" + id + "/emotions/" + emotion
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async fetchUrlData(data) {
    const response = await Api().post("posts/url/fetch", data);
    return response.data;
  },
  async likeComment(id) {
    try {
      const response = await Api().patch("posts/like/comment/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async unlikeComment(id) {
    try {
      const response = await Api().patch("posts/unlike/comment/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async viewCommentLikes(id) {
    try {
      const response = await Api().get("posts/likes/comment/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async answerPoll(id, data) {
    try {
      const response = await Api().post("posts/polls/" + id + "/answer", data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async answerDetails(id, index) {
    try {
      const response = await Api().get(
        "posts/polls/" + id + "/answer/" + index
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
};
