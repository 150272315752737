<template>
  <div v-if="!users">
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
    ></v-skeleton-loader>
  </div>
  <div v-else>
    <div v-if="!users || users.length == 0">
      {{$t("message.no_results")}}
    </div>
    <div v-else>
      <template v-for="(u, index) in users">
        <v-list-item
          two-line
          v-if="!u.hidden"
          :key="u._id"
          @click="goToUser(u._id)"
          ripple
        >
          <v-list-item-avatar :tile="true">
            <UserAvatar 
              :user="u" 
              size="28" 
              :small-badge="true"
              :has-progress="false" >
            </UserAvatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <user-name
                :user="u"
                :link="false"
                :add-hover="false"
                :hide-flag="true"
                :avatar="false"
              >
              </user-name>
            </v-list-item-title>
            <v-list-item-subtitle>
              <BeltDisplay 
                size="15" class="mr-1" 
                :hide-icon="false" :show-text="u.academyName ? false : true" :belt="u.belt"></BeltDisplay>
              <span class="ml-1" v-if="u.academyName">{{u.academyName}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <CountryFlag size="35" :avatar="true" :country-code="u.nationality"></CountryFlag>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="index"></v-divider>
      </template>
    </div>
  </div>
</template>

<script>
import UserName from "@/components/displays/UserNameDisplay.vue";
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";
import UserAvatar from "@/components/displays/UserAvatarDisplay";
import BeltDisplay from "@/components/displays/BeltDisplay";

export default {
  props: ["users"],
  data() {
    return {};
  },
  components: {
    UserName,
    CountryFlag,
    UserAvatar,
    BeltDisplay,
  },
  methods: {
    doNothing() {},
    goToUser(id) {
      this.navigateTo({ name: "viewprofile", params: { userId: id } });
    },
  },
  computed: {},
};
</script>

<style scoped>
.users {
  height: 300px;
  overflow-y: auto;
}
</style>
