import Api from "@/services/Api";

export default {
  async academies() {
    const response = await Api().get("admins/academies");
    return response.data;
  },
  async requestDemo(email) {
    const response = await Api().post("admins/request/demo", { email: email });
    return response.data;
  },
};
