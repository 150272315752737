<template>
  <div v-if="!$store.state.hidden">
    <BackButton></BackButton>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <v-row v-if="aggregates">
        <v-col>
          <v-card class="mt-2" elevation="0" outlined>
            <v-card-title>
              {{$t("message.last_month")}}
            </v-card-title>
            <v-card-text text-xs-center class="text-h4">
              {{ aggregates.lastMonth }}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mt-2" elevation="0" outlined>
            <v-card-title>
              {{$t("message.all_time")}}
            </v-card-title>
            <v-card-text text-xs-center class="text-h4">
              {{ aggregates.allTime }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
    <div class="mt-2">
      <v-subheader>
        {{ $t("message.jjp") }}
      </v-subheader>
      <template v-for="(i, index) in points">
        <v-list-item dense three-line :key="i._id">
          <v-list-item-action>
            <LinkIcon v-if="i.pointType == 'new_link'" class="hero-icon"></LinkIcon>
            <FireIcon v-else-if="i.pointType == 'new_competition'" ></FireIcon>
            <IconCompMedal v-else-if="i.pointType == 'new_competition_gold'" :color="themeColor('gold')"></IconCompMedal>
            <IconCompMedal v-else-if="i.pointType == 'new_competition_silver'" :color="themeColor('silver')"></IconCompMedal>
            <IconCompMedal v-else-if="i.pointType == 'new_competition_bronze'" :color="themeColor('bronze')"></IconCompMedal>
            <IconHero v-else-if="i.pointType == 'verified_hero'" color="grey"/>
            <LocationMarkerIcon v-else-if="i.pointType == 'checked_in'" class="hero-icon"/>
            <BadgeCheckIcon v-else-if="i.pointType.indexOf('belt_verified') >= 0" class="hero-icon" ></BadgeCheckIcon>
            <LoginIcon v-else-if="i.pointType == 'joined_bjjlink'" class="hero-icon"></LoginIcon>
            <CalendarIcon v-else-if="i.pointType == 'checked_in_event'" class="hero-icon"></CalendarIcon>
            <ChevronDoubleUpIcon v-else class="hero-icon"></ChevronDoubleUpIcon>
          </v-list-item-action>
          <v-list-item-content two-line>
            <v-list-item-title>
              <span>{{ $t("message.points_"+i.pointType) }}</span>
            </v-list-item-title>
            <v-list-item-subtitle v-if="i.sourceUser" @click="goToUser(i.sourceUser._id)">
              <v-avatar size="20" v-if="i.sourceUser.userImageSmall">
                <img :src="userImg(i.sourceUser.userImageSmall)"/>
              </v-avatar>
              <span v-if="i.sourceUser.displayName">
                {{i.sourceUser.displayName}}
              </span>
            </v-list-item-subtitle>
            <v-list-item-title>
              <span>{{
                $d(new Date(i.dateGiven), "short", $i18n.locale)}}
              </span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            +{{i.points}}
          </v-list-item-action>
        </v-list-item>
        <v-divider
          v-if="index + 1 < points.length"
         :key="index"
        ></v-divider>
      </template>
    </div>
    <div v-if="viewMore" class="text-center mt-2 mb-2">
      <v-btn color="primary" small class="elevation-0" @click="getPoints(true)">
        {{$t("message.view_more")}}
      </v-btn>
    </div>
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar, list-item-avatar, list-item-avatar"
    ></v-skeleton-loader>
    </v-sheet>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import IconCompMedal from "@/components/displays/icons/IconCompMedal";
import IconHero from "@/components/displays/icons/IconHero.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

import { 
  LocationMarkerIcon,
  LinkIcon,
  BadgeCheckIcon,
  LoginIcon,
  CalendarIcon,
  ChevronDoubleUpIcon,
  FireIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      points: [],
      index: 0,
      loading: true,
      viewMore: false,
      aggregates: null
    };
  },
  components: {
    LocationMarkerIcon,
    LinkIcon,
    IconCompMedal,
    IconHero,
    BadgeCheckIcon,
    LoginIcon,
    CalendarIcon,
    ChevronDoubleUpIcon,
    FireIcon,
    BackButton
  },
  created() {
    this.getPoints();
  },
  methods: {
    getPoints(append) {
      try {
        this.loading = true;
        this.viewMore = false;
        if (!append) {
          this.index = 0;
          this.points = [];
        }
        UserService.pointsHistory(this.index)
          .then((data) => {
            if (data) {
              this.points = this.points.concat(data.points);
              this.loading = false;
              this.index = this.index + data.count;
              if (data.hasMore) this.viewMore = true;
              this.aggregates = data.aggregates;
            }
          })
          .catch((err) => {
            if(err)
              this.loading = false;
          });
      } catch (err) {
        console.log(err)
      }
    },
  },
  watch: {
  },
};
</script>

<style scoped>
.aggregate-value {
  font-size: 3em;
  margin-top: -30px;
}
</style>
