<template>
  <v-select 
    @input="event => { emitChange(event) }"
    v-model="selectedLang" 
    :items="languages" 
    label="Select Language"
    item-text="text"
    item-value="value"
    dense
    outlined
  >
  </v-select>
</template>

<script>
export default {
  data () {
    return {
      selectedLang: '',
      languages: [
        {
          text: 'English',
          value: 'en'
        },
        {
          text: 'Español',
          value: 'es'
        },
        {
          text: 'Français',
          value: 'fr'
        },
        {
          text: 'Português',
          value: 'pt'
        }
      ]
    }
  },
  created(){
    if(this.value == 'es' || this.value == 'fr' || this.value == 'pt')
      this.selectedLang = this.value
    else
      this.selectedLang = 'en';
  },
  props: {
    value: null,
  },
  methods: {
    langImg () {
      if(this.value == 'es')
        return this.assets('flags/ES.png');
      else 
        return this.assets('flags/US.png');
    },
    emitChange (event) {
      this.$emit('input', event)
    }
  },
  watch: {
    value: {
      handler: function (newValue) {
        this.selectedLang = newValue
      }
    }
  }
}
</script>

<style scoped>
</style>
