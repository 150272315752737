<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>FIRE</title>
    <g id="App-Design-2022" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="BJJLINK---Menu-icons" transform="translate(-43.000000, -674.000000)" :stroke="iconColor" stroke-width="2">
            <g id="Flame-outlined" transform="translate(43.000000, 674.000000)">
                <path d="M17.6569001,18.6568001 C14.5327001,21.7810001 9.46734004,21.7810001 6.34315003,18.6568001 C4.78105002,17.0947001 4.00000002,15.0474001 4.00000002,13 C4.00000002,10.9526 4.78105002,8.90523004 6.34315003,7.34313003 C6.34315003,7.34313003 7.00004003,8.99995004 9.00004004,9.99995004 C9.00004004,7.99995003 9.50004004,4.99996002 11.9859,3.00000001 C14,5.00000001 16.0912001,5.77745002 17.6569001,7.34313003 C19.2190001,8.90523004 20,10.9526 20,13 C20,15.0474001 19.2189001,17.0947001 17.6569001,18.6568001 Z" id="Path"></path>
                <path d="M9.87868004,16.1213001 C11.0503,17.2928001 12.9497001,17.2928001 14.1213001,16.1213001 C14.7071001,15.5355001 15,14.7677001 15,14 C15,13.2322001 14.7071001,12.4644001 14.1213001,11.8786 C13.5392001,11.2965 12.7775001,11.0037 12.0146001,11 L10.9999,13.9999001 L9.00000004,14 C9.00000004,14.7677001 9.29290004,15.5355001 9.87868004,16.1213001 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
//:fill="iconColor"
export default {
  data() {
    return {
      iconColor: "gray",
      iconSize: "24px"
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
    if(this.size)
      this.iconSize = this.size;
  },
  props: ['color','size'],
  watch: {
    color: {
      handler: function (newValue) {
        this.iconColor = this.getColor(newValue);
      },
    },
  },
};
</script>
<style>
</style>