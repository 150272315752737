<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" >
    <div v-if="isAppAdmin(this.$store.state.user._id)">
      <v-card style="margin-top: 10px;">
        <v-card-title class="text-h6">Add New Banner</v-card-title>
        <v-card-text>
          <v-select
            dense outlined
            v-model="banner.platform"
            :items="projects"
            label="Project"
          ></v-select>
          <v-text-field outlined v-model="banner.name" label="Name" dense></v-text-field>
          <v-text-field outlined v-model="banner.weight" label="Weight (1,2,3,...)" dense></v-text-field>
          <v-text-field outlined v-model="banner.text" label="Text (Optional)" dense></v-text-field>
          <v-select
            dense outlined
            v-model="banner.display"
            :items="displays"
            label="Display"
          ></v-select>
          <v-switch v-model="banner.active" label="Active"></v-switch>
          <div class="supporting-text ml-2" v-if="uploadingMessage">
            {{uploadingMessage}}
          </div>
          <v-btn block color="primary" outlined class="elevation-0" @click="filePicker(true)" small
            slot="activator">
            <v-icon v-if="uploading" size="20" class="rotate">mdi-refresh</v-icon>
            Upload Mobile Image
          </v-btn>
          <v-btn block color="primary" outlined class="elevation-0 mt-1" @click="filePicker()" small
            slot="activator">
            <v-icon v-if="uploading" size="20" class="rotate">mdi-refresh</v-icon>
            Upload Regular Image
          </v-btn>
          <div v-if="banner.imageUrlMobile" class="text-subtitle-1">Mobile Banner</div>
          <v-img v-if="banner.imageUrlMobile" :src="userFile(banner.imageUrlMobile)" width="100%"></v-img>
          <div v-if="banner.imageUrl" class="text-subtitle-1 mt-2">Regular Banner</div>
          <v-img v-if="banner.imageUrl" :src="userFile(banner.imageUrl)" width="100%"></v-img>
          <input style="display: none" type="file" accept="image/*" @change="onFileSelect" ref="fileUploadInput" />
        </v-card-text>
        <v-card-actions>
          <v-btn block :disabled="uploading ? true :false" v-if="editing" small color="primary" @click="updateBanner">Update</v-btn>
          <v-btn block :disabled="uploading ? true :false" v-else small color="primary" @click="addBanner">Add</v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn block v-if="editing" small color="secondary" @click="empty">Clear</v-btn>
        </v-card-actions>
      </v-card>
      <v-divider></v-divider>
      <v-card>
        <v-card-text>
          <v-select
            outlined
            dense
            v-model="selectedBanner"
            :items="projects"
            label="Selec a project to see banners"
          ></v-select>
        </v-card-text>
        <v-card-text v-if="banners">
          <v-expansion-panels flat>
            <template v-for="(i) in banners">
              <v-expansion-panel :key="i._id" style="margin-bottom: 5px;">
                <v-expansion-panel-header>
                  <div>
                    {{ i.name }}
                    <v-icon size="20" class="hero-button" @click.stop="showToEdit(i)">mdi-pencil-box-outline</v-icon>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="text-subtitle-1">Display: {{ i.display }}</div>
                  <div class="text-subtitle-1">Text: {{ i.text }}</div>
                  <div class="text-subtitle-1">Active: {{ i.active }}</div>
                  <div class="text-subtitle-1">Weight: {{ i.weight }}</div>
                  <div class="text-subtitle-1">Banner Mobile</div>
                  <v-img v-if="i.imageUrlMobile" :src="userFile(i.imageUrl)" width="100%"></v-img>
                  <div class="text-subtitle-1 mt-1">Banner Mobile</div>
                  <v-img v-if="i.imageUrl" :src="userFile(i.imageUrlMobile)" width="100%"></v-img>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <h2>You are not welcome here.</h2>
      <div>
        <icon-master color="error" :size="200" />
      </div>
    </div>
  </v-sheet>
</template>

<script>
import FileService2 from "@/services/FileService2";
import BjjlinkAdminService from "@/services/BjjlinkAdminService";

export default {
  data() {
    return {
      banner: {
        active: true,
        weight: "1",
        display: 'horizontal',
        platform: 'bjjlink-elevate'
      },
      editing: false,
      banners: null,
      editorToolbar: this.getEditorToolbar(),
      latestVersion: null,
      mobileImage: false,
      selectedBanner: null,
      uploadingMessage: null,
      selectedFile: null,
      uploadedFile: null,
      uploading: false,
      projects: [
        "bjjlink-admin",
        "bjjlink-web",
        "bjjlink-elevate",
        "bjjlink-core",
      ],
      displays: ["horizontal","vertical"],
      onUploadProgress: function () { },
    };
  },
  components: {
  },
  created() {
    this.empty();
  },
  watch: {
    selectedBanner: {
      handler: function (newValue) {
        if (newValue) {
          this.getBanners();
        }
      },
    },
  },
  methods: {
    empty() {
      this.banner = {
        platform: "bjjlink-elevate",
        display: 'horizontal',
        weight: '1',
        active: true,
        text:
          '',
      };
      this.uploadingMessage = null;
      this.editing = false;
    },
    showToEdit(banner) {
      this.editing = true;
      this.banner = banner;
    },
    getLatestVersion(p) {
      BjjlinkAdminService.latestVersion(p).then((data) => {
        this.latestVersion = data.version;
      });
    },
    getBanners(){
      BjjlinkAdminService.getBanners(this.selectedBanner)
        .then((data) => {
          this.banners = data.banners;
        });
    },
    goToUserNewTab(id){
      window.open("/secure/profile/"+id);
    },
    updateBanner() {
      BjjlinkAdminService.updateBanner(this.banner)
        .then(() => {
          this.showAlert("success", "Yoo! ", "nice brou!");
          this.empty();
        })
        .catch(() => {
          this.showAlert("error", "Yoo! ", "What da??? Talk to Santi :/");
        });
    },
    addBanner() {
      if (!this.banner.imageUrl || !this.banner.imageUrlMobile) {
        this.showAlert("error", "You need images for Mobile and Regular");
        return;
      }
      if (isNaN(this.banner.weight))
        this.banner.weight = 1;
      else
        this.banner.weight = new Number(this.banner.weight).valueOf();
      BjjlinkAdminService.addBanner(this.banner)
        .then(() => {
          this.banner = {
            project: "bjjlink-elevate",
            contents:
              '',
          };
          this.showAlert("success", "Yoo! ", "nice brou!");
          this.getLatestVersion("bjjlink-admin");
        })
        .catch(() => {
          this.showAlert("error", "Yoo! ", "What da??? Talk to Santi :/");
        });
    },
    uploadPhoto() {
      const formData = new FormData();
      this.uploadingMessage = this.$t("message.image_upload_progress");
      formData.append("image", this.selectedFile, this.selectedFile.name);
      FileService2.uploadImage(formData, this.onUploadProgress)
        .then((res) => {
          this.uploadedFile = res.file;
          this.uploading = false;
          this.uploadingMessage = this.selectedFile.name;
          if(this.mobileImage)
            this.banner.imageUrlMobile = res.file;
          else
            this.banner.imageUrl = res.file;
        })
        .catch(() => {
          this.clearParameters();
        });
    },
    filePicker(mobile) {
      this.mobileImage = mobile;
      this.uploading = false;
      this.$refs.fileUploadInput.click();
    },
    onFileSelect(event) {
      this.uploading = true;
      if (event.target.files[0]) {
        this.uploadedFile = null;
        this.selectedFile = event.target.files[0];
        const size = event.target.files[0].size;
        const sizeMb = Math.floor(size / 1000000);
        if (sizeMb <= 15) {
          this.uploadPhoto();
        } else {
          this.uploading = false;
          this.showAlert(
            "error",
            "Sorry! Your image is too big (" +
            sizeMb +
            "mb), try a smaller one."
          );
        }
      } else {
        this.uploading = false;
      }
    },
  },
};
</script>

<style scoped></style>
