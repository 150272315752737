<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" >
    <div v-if="isAppAdmin(this.$store.state.user._id)">
      <v-card style="margin-top: 10px;">
        <v-card-title class="text-h6">Create Internal Event</v-card-title>
        <v-card-text class="text-caption">Remember these costs us money!</v-card-text>
        <v-card-text>
          <v-text-field dense outlined v-model="channel1" label="Elevate User 1"></v-text-field>
          <v-text-field dense outlined v-model="channel2" label="Elevate User 2"></v-text-field>
          <div>Start Time</div>
          <date-picker v-if="startTime" v-model="startTime" :show-time="true"></date-picker>
          <div>End Time</div>
          <date-picker v-if="endTime" v-model="endTime" :show-time="true"></date-picker>
        </v-card-text>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div v-if="elevateUser1" class="text-center">
                  <div>
                    <v-avatar>
                      <img :src="userFile(userImage(elevateUser1.publicProfile.image))"/>
                    </v-avatar>
                  </div>
                  <div>
                    {{ elevateUser1.publicProfile.name }}
                  </div>
                </div>
              </v-col>
              <v-col cols="12">
                <div v-if="elevateUser2" class="text-center">
                    <div>
                      <v-avatar>
                        <img :src="userFile(userImage(elevateUser2.publicProfile.image))"/>
                      </v-avatar>
                    </div>
                    <div>
                      {{ elevateUser2.publicProfile.name }}
                    </div>
                  </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-btn block :disabled="!elevateUser1 || !elevateUser2 || channel1 == channel2" small color="primary" @click="addEvent">Add</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div v-else>
      <h2>You are not welcome here.</h2>
      <div>
        <icon-master color="error" :size="200" />
      </div>
    </div>
  </v-sheet>
</template>

<script>
import BjjlinkAdminService from "@/services/BjjlinkAdminService";
import DatePicker from "@/components/inputs/DateTimePicker2.vue";

export default {
  data() {
    return {
      channel1: null,
      channel2: null,
      startTime: null,
      endTime: null,
      formConfirmed: null,
      elevateUser1: null,
      elevateUser2: null,
    };
  },
  watch: {
    channel1: {
      handler: function (newValue) {
        if (newValue) {
          this.getElevateUsers(newValue, 'elevateUser1');
        }
      },
    },
    channel2: {
      handler: function (newValue) {
        if (newValue) {
          this.getElevateUsers(newValue, 'elevateUser2');
        }
      },
    },
  },
  components: {
    DatePicker
  },
  created() {
    this.empty();
    this.startTime = new Date();
    this.endTime = new Date();
    this.endTime.setHours(this.endTime.getHours() + 1);
  },
  methods: {
    userImage(img) {
      if (img) {
        const split = img.split('media');
        return 'media/'+split[1];
      }
    },
    empty() {
      this.channel1 = null;
      this.channel2 = null;
    },
    getElevateUsers(val, element) {
      BjjlinkAdminService.getElevateUser(val)
        .then((response) => {
          this[element] = response;
        })
        .catch(() => {
          this.showAlert("error", "Check the logs :/ ");
        });
    },
    timeToDecimal(date) {
      if (date) {
        const d = new Date(date);
        const hours = d.getHours();
        const minutes = d.getMinutes();
        return new Number(hours + "." + minutes).valueOf();
      }
    },
    addEvent() {
      const startTime = new Date(this.startTime);
      const endTime = new Date(this.endTime);
      const startHour = this.timeToDecimal(startTime);
      const endHour = this.timeToDecimal(endTime);
      const data = {
        startTime: startTime,
        endTime: endTime,
        startHour: startHour,
        userId: this.channel1,
        endHour: endHour,
        participantId: this.channel2,
        totalPrice: 0,
        available: false,
      };
      BjjlinkAdminService.createElevateEvent(data)
        .then(() => {
          this.showAlert("success", "Check elevate");

        })
        .catch(() => {
          this.showAlert("error", "Check the logs :/ ");
        });
    },
  },
};
</script>

<style scoped></style>
