<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2" v-if="$store.state.isUserLoggedIn">
    <BackButton></BackButton>
    <v-card flat>
      <v-card-text class="pa-1">
        <iframe frameBorder="0" height="100%" class="agreement_iframe" id="scroll-target" :src="url"></iframe>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>
<script>

import BackButton from "@/components/displays/BackButtonDisplay.vue";
export default {
  data() {
    return {
      url: "https://bjjlink.com/info/en/terms.html",
    };
  },
  components: {
    BackButton
  },
  async created() {
  },
  methods: {
  },
};
</script>

<style scoped>
.agreement_box{
  max-height: 500px;
}
.agreement_iframe{
  width: 100%;
  height:600px;
}
</style>
