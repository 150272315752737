<template>
  <v-autocomplete
        @input="
          (event) => {
            emitChange(event);
          }
        "
        :items="beltList"
        v-model="selectedBelt"
        :label="$t('message.select_belt')"
        item-text="text"
        item-value="value"
        dense
        outlined
        :clearable="clearable"
      >
        <template slot="item" slot-scope="data">
          <template v-if="!data.item.avatar">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-avatar>
              <img :src="data.item.avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.text"></v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
  </v-autocomplete>
</template>

<script>
export default {
  data() {
    return {
      selectedBelt: "",
      beltList: [
        {
          text: this.$t("message.gray"),
          value: "gray",
          avatar: this.assets("belts/gray_belt.png"),
        },
        {
          text: this.$t("message.yellow"),
          value: "yellow",
          avatar: this.assets("belts/yellow_belt.png"),
        },
        {
          text: this.$t("message.orange"),
          value: "orange",
          avatar: this.assets("belts/orange_belt.png"),
        },
        {
          text: this.$t("message.green"),
          value: "green",
          avatar: this.assets("belts/green_belt.png"),
        },
        {
          text: this.$t("message.white"),
          value: "white",
          avatar: this.assets("belts/white_belt.png"),
        },
        {
          text: this.$t("message.blue"),
          value: "blue",
          avatar: this.assets("belts/blue_belt.png"),
        },
        {
          text: this.$t("message.purple"),
          value: "purple",
          avatar: this.assets("belts/purple_belt.png"),
        },
        {
          text: this.$t("message.brown"),
          value: "brown",
          avatar: this.assets("belts/brown_belt.png"),
        },
        {
          text: this.$t("message.black"),
          value: "black",
          avatar: this.assets("belts/black_belt.png"),
        },
        {
          text: this.$t("message.coral"),
          value: "red_black",
          avatar: this.assets("belts/red_black_belt.png"),
        },
        {
          text: this.$t("message.red_white"),
          value: "red_white",
          avatar: this.assets("belts/red_white_belt.png"),
        },
        {
          text: this.$t("message.red"),
          value: "red",
          avatar: this.assets("belts/red_belt.png"),
        },
      ],
    };
  },
  props: {
    value: null,
    showIcon: null,
    noBjj: null,
    clearable: {type: Boolean, default: false},
  },
  created() {
    if (this.noBjj) {
      const noBjjOption = {
        text: this.$t("message.not_bjj_user"),
        value: "not_bjj_user",
        avatar: this.assets("belts/white_belt.png"),
      };
      this.beltList.unshift(noBjjOption);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        this.selectedBelt = newValue;
      },
    },
  },
  methods: {
    emitChange(event) {
      this.$emit("input", event);
    },
  },
};
</script>

<style scoped>
</style>
