import Api from "@/services/Api";

export default {
  async getAssociatedAffiliates() {
    const response = await Api().get("referrals/user/affiliates");
    return response.data;
  },
  async getAffiliateDetails(id) {
    const response = await Api().get("referrals/user/affiliate/" + id);
    return response.data;
  },

  async leaveAffiliate(id) {
    const response = await Api().delete("referrals/user/affiliate/" + id);
    return response.data;
  },
};
