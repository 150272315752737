<template>
  <v-autocomplete
    @input="event => { emitChange(event) }"
    v-model="selectedTag"
    :items="loadedTags"
    :label="labelText"
    clearable
    dense
    outlined
  ></v-autocomplete>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  data() {
    return {
      selectedTag: null,
      loadedTags: null,
      labelText: null
    }
  },
  created() {
    if(this.label)
      this.labelText = this.label;
    UserService.jiujitsuTags()
      .then(data => {
        if (data) this.loadedTags = data;
      });
  },
  props: {
    value: null,
    label: null
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        this.selectedTag = newValue;
      }
    }
  },
  methods: {
    emitChange (event) {
      this.$emit('input', event)
    }
  }
};
</script>

<style scoped>
</style>
