<template>
  <input v-if="showTime" ref="datePickerEl" class="date-picker" type="datetime-local" :min="dateMin" :max="dateMax"
    :disabled="disabled" :style="'color:' + textColor" @change="emitChange">
  <input v-else ref="datePickerEl" class="date-picker" type="date" :min="dateMin" :max="dateMax" :disabled="disabled"
    :style="'color:' + textColor" @change="emitChange">
</template>

<script>

export default {
  data() {
    return {
      ready: false,
      timeValue: null,
      dateMin: null,
      dateMax: null,
      stringValue: null,
      textColor: this.isDarkTheme() ? 'gray' : 'gray'
    }
  },
  mounted() {
    this.initValues();
  },
  watch: {
    value() {
      this.initValues();
    }
  },
  props: {
    showTime: { type: Boolean, default: false },
    value: { type: Date, default: null },
    pastEvent: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    initValues() {
      try {
        let v = new Date();
        if (this.value)
          v = new Date(this.value);
        if (v) {
          const now = new Date();
          now.setHours(23);
          now.setMinutes(59);
          now.setSeconds(59);
          now.setDate(now.getDate() + 1);
          let stringValue = "";
          let stringValueToday = "";
          if (this.showTime) {
            stringValue = this.toNiceDateCustomFormatAdjusted(v, 'YYYY-MM-DDTHH:mm');
            stringValueToday = this.toNiceDateCustomFormatAdjusted(now, 'YYYY-MM-DDTHH:mm');
          } else {
            v.setHours(0);
            stringValue = this.toNiceDateCustomFormatAdjusted(v, 'YYYY-MM-DD');
            stringValueToday = this.toNiceDateCustomFormatAdjusted(now, 'YYYY-MM-DD');
          }
          if (this.pastEvent) {
            this.dateMax = stringValueToday;
          } else {
            this.dateMin = stringValueToday;
          }
          //this.stringValue = stringValue;
          this.$refs.datePickerEl.value = stringValue;
        }
      } catch (err) {
        console.log('Cant init values... ', err)
      }
    },
    emitChange(event) {
      if (this.showTime) {
        const dateTimeValue = this.stringToDateTime(event.target.value);
        this.$emit('input', dateTimeValue);
      } else {
        const dateValue = this.stringToDate(event.target.value);
        this.$emit('input', dateValue);
      }
    },
    stringToDate(s) {
      if (s) {
        const d = new Date();
        const split = s.split('-');
        d.setHours(1);
        const day = new Number(split[2]).valueOf();
        d.setMonth(new Number(split[1]).valueOf() - 1);
        d.setDate(day);
        //d.setMonth(d.getMonth()-1);
        d.setFullYear(split[0]);
        return d;
      }
    },
    stringToDateTime(s) {
      if (s) {
        const d = new Date();
        const split = s.split('T');
        const dateSplit = split[0].split('-');
        const timeSplit = split[1].split(':');
        d.setDate(dateSplit[2]);
        d.setMonth(new Number(dateSplit[1]).valueOf() - 1);
        d.setFullYear(dateSplit[0]);
        d.setSeconds(0);
        d.setHours(timeSplit[0]);
        d.setMinutes(timeSplit[1]);
        return d;
      }
    }
  }
};
</script>

<style scoped>
.date-picker {
  font-size: large;
}
</style>