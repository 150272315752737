<template>
  <div>
    <audio ref="countdown_audio" style="display:none">
      <source :src="assets('sounds/countdown.wav')" type="audio/wav">
    </audio>
    <audio v-if="activeSound" ref="active_audio" style="display:none">
      <source :src="activeSound" type="audio/wav">
    </audio>
    <audio v-if="restSound" ref="rest_audio" style="display:none">
      <source :src="restSound" type="audio/wav">
    </audio>
    <audio v-if="cooldownSound" ref="cooldown_audio" style="display:none">
      <source :src="cooldownSound" type="audio/wav">
    </audio>
    <audio v-if="endSound" ref="end_audio" style="display:none">
      <source :src="endSound" type="audio/wav">
    </audio>
    <v-sheet :rounded="isSmallerDisplay() || fullscreen? '' : 'lg'" :class="fullscreen ? 'full-screen' : 'pa-2'">
      <v-card-actions>
        <v-btn @click="volumeOn=!volumeOn" fab small class="elevation-0">
          <VolumeOffIcon class="hero-button" v-if="volumeOn"></VolumeOffIcon>
          <VolumeUpIcon class="hero-button" v-else></VolumeUpIcon>
        </v-btn>
        <v-spacer></v-spacer>
        <img v-if="fullscreen" class="fullscreen-logo" :src="assets('logo.png')">
        <v-spacer></v-spacer>
        <v-btn @click="goFullScreen()" fab small class="elevation-0">
          <XCircleIcon class="hero-button" v-if="fullscreen"></XCircleIcon>
          <ArrowsExpandIcon v-else class="hero-button"></ArrowsExpandIcon>
        </v-btn>
      </v-card-actions>
      <v-card-text v-if="timerData" class="pa-0 text-center text-h5">
        {{timerData.name.toUpperCase()}}
      </v-card-text>
      <v-card-text class="text-center pa-0">
        <v-progress-circular :color="getIntervalColor()" width="30" :size="progressSize" rotate="90" :value="progress">
          <v-sheet class="text-center">
            <v-card-text class="text-h6 text-center pt-0 pb-0" v-if="currentInterval">
              {{truncate(currentInterval.title, 20)}}
            </v-card-text>
            <v-card-text v-if="currentInterval && currentInterval.subtitle" class="text-overline pt-0 pb-0">
              {{currentInterval.subtitle.toLowerCase()}}
            </v-card-text>
            <v-card-text class="text-h1 pt-1 pb-1">
              {{showIntervalRemainingSeconds}}
            </v-card-text>
            <v-card-text class="text-h5 pt-0 pb-0">
              <span v-if="currentInterval && currentInterval.set">{{currentInterval.set}}/{{this.timerData.sets}}</span>
            </v-card-text>
          </v-sheet>

        </v-progress-circular>
        <v-row>
          <v-col cols="12" sm="1" class="text-left">
            <ChevronLeftIcon class="clickable hero-icon" v-if="started && index != 0" @click="moveBack()" size="40">
            </ChevronLeftIcon>
          </v-col>
          <v-col cols="12" sm="5" class="pb-0">
            <div class="text-h3 pa-2 pb-0">
              <div>{{showElapsedTime}}</div>
              <div class="text-caption">{{ $t("message.elapsed").toUpperCase() }}</div>
            </div>
          </v-col>
          <v-col cols="'12" sm="5" class="pb-0">
            <div class="text-h3 pa-2 pb-0">
              <div>{{showTotalRemainingTime}}</div>
              <div class="text-caption">{{ $t("message.remaining").toUpperCase() }}</div>
            </div>
          </v-col>
          <v-col cols="12" sm="1" class="text-right">
            <ChevronRightIcon class="clickable hero-icon" v-if="started && currentInterval && !currentInterval.cooldown"
              @click="moveForward()" size="40"></ChevronRightIcon>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn x-large icon v-if="!started" @click="startTimer()" elevation="0" class="mr-2">
          <PlayIcon class="hero-button" :color="themeColor('success')" size="80"></PlayIcon>
        </v-btn>
        <v-btn x-large icon v-else-if="paused" @click="continueTimer()" elevation="0" class="mr-3">
          <PlayIcon class="hero-button" :color="themeColor('success')" size="80"></PlayIcon>
        </v-btn>
        <v-btn x-large icon v-else-if="started && !paused" @click="pauseTimer()" elevation="0" class="mr-3">
          <PauseIcon class="hero-button" :color="themeColor('gold')" size="80"></PauseIcon>
        </v-btn>
        <v-btn x-large icon v-if="started" @click="stopTimer()" elevation="0" class="ml-3">
          <StopIcon class="hero-button" :color="themeColor('error')" size="80"></StopIcon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-1 mt-2" v-if="false">
      Coming Soon<br>
      BJJLINK Timers App
    </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-1 mt-2" v-if="false">
        <v-list>
          <v-subheader>{{ $t("message.your_timers").toUpperCase() }}</v-subheader>
          <template v-for="(i, index) in timers">
            <v-list-item class="pa-2" :key="'timer'+index" @click.stop="selectTimer(i)">
              <v-list-item-action>
                <CheckCircleIcon :color="themeColor('primary')" class="hero-icon" v-if="i._id == timerId">
                </CheckCircleIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span style="font-weight:bold">{{i.name}}</span>
                </v-list-item-title>
                <v-list-item-title class="supporting-text" v-if="i.advanced">
                  <AdjustmentsIcon size="20"></AdjustmentsIcon>{{
                  $t("message.sets_with_rounds",[i.sets,i.rounds.length]) }}
                </v-list-item-title>
                <v-list-item-title class="supporting-text" v-else>
                  {{i.sets}} {{ $t("message.rounds") }}
                </v-list-item-title>
                <v-list-item-title class="supporting-text">
                  {{ $t("message.total_time") }}: {{seconds2Minutes(i.totalTime)}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="index + 1 < timers.length" :key="index"></v-divider>
          </template>
        </v-list>
      </v-sheet>
      <div class="overlay" v-if="showOverlay">
        <div class="overlay-content">
          <div class="countdown-number">{{initialCountdownInSeconds}}</div>
        </div>
      </div>
  </div>
</template>

<script>
import TimerService from "@/services/TimerService";
import UserService from "@/services/UserService";


//Icons
import { 
  AdjustmentsIcon,
  VolumeUpIcon,
  VolumeOffIcon,
  ArrowsExpandIcon,
  XCircleIcon,
  PlayIcon,
  PauseIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckCircleIcon,
  StopIcon,
} from '@vue-hero-icons/outline'

export default {
  components: {
    AdjustmentsIcon,
    VolumeUpIcon,
    VolumeOffIcon,
    ArrowsExpandIcon,
    XCircleIcon,
    PlayIcon,
    PauseIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CheckCircleIcon,
    StopIcon,
  },
  data() {
    return {
      ONE_SECOND: 1000,
      timerId: this.$route.params.timerId,
      intervalId: null,
      timerData: null,
      totalTime: null,
      volumeOn: true,
      timer: null,
      progressSize:'300',
      loading: true,
      started: false,
      paused: false,
      timers: null,
      fullscreen: false,
      currentInterval: {title:null, seconds:0},
      remainingSeconds: null,
      totalTimeInSeconds: 0,
      initialCountdownInSeconds: 3,
      initialCountdownText: ['Go!','Set?', 'Ready?'],
      elapsedTimeInSeconds: 0,
      showOverlay: false,
      intervalCountdown: 0,
      totalIntervals: 0,
      progress: 0,
      activeSound: null,
      restSound: null,
      cooldownSound: null,
      endSound: null,
      index: 0,
      rounds:[],
      windowDimensions: null,
      colors:{
        warmup: this.getColor('gold'),
        rest: 'green',
        active: 'orange',
        cooldown: 'primary'
      }
    };
  },
  created(){
    this.init();
  },
  beforeRouteUpdate(){
    this.init();
  },
  beforeDestroy(){
    if(this.started){
      let clonedTimerData = Object.assign(this.timerData, {});
      const currentIntervalCopy =  {
          seconds: this.currentInterval.seconds,
          title: this.currentInterval.title,
          totalSeconds: this.currentInterval.totalSeconds,
          warmup: this.currentInterval.warmup,
          cooldown: this.currentInterval.cooldown,
          rest: this.currentInterval.rest,
          set: this.currentInterval.set,
          subtitle: this.currentInterval.subtitle,
        };
      const sessionTimer = {
        timer: clonedTimerData,
        savedAt: new Date(),
        total: this.totalTimeInSeconds,
        elapsed: this.elapsedTimeInSeconds,
        intervalId: this.intervalId,
        timerId: this.timerId,
        index: this.index,
        paused: this.paused ? true : false,
        currentInterval: currentIntervalCopy
      };
      this.$store.dispatch("setSessionTimer", sessionTimer);
    }
  },
  methods: {
    init(){
      this.windowDimensions = {
        h: window.innerHeight,
        w: window.innerWidth,
        isVertical: window.innerHeight > window.innerWidth ? true : false
      };
      this.getTimers();
      if(this.$store.state.sessionTimer){
        //console.log('Loading timer from session!')
        const sessionTimer = this.$store.state.sessionTimer;
        this.timerId = sessionTimer.timerId;
        this.$store.dispatch("setSessionTimer", null);
        window.clearInterval(sessionTimer.intervalId);
        this.continueFromSession(sessionTimer);
        //Calculate time elapsed since we left and start timer at that point
      }else{
        //console.log('Loading timer from 0, no session.');
        this.getTimer();
      }
    },
    goFullScreen(){
      this.fullscreen=!this.fullscreen;
      if(this.fullscreen){
        if(this.windowDimensions.isVertical){
          //Size gets calculated with width
          const newProgressSize = parseInt(0.6 * this.windowDimensions.w);
          if(newProgressSize > 300){
            this.progressSize = newProgressSize;
            //console.log('get circle based of width['+this.windowDimensions.h+']: ', newProgressSize);
          }
        }else{
          //Size gets caldulated from height
          const newProgressSize = parseInt(0.5 * this.windowDimensions.h);
          if(newProgressSize > 300){
            this.progressSize = newProgressSize;
            //console.log('get circle based of heigth['+this.windowDimensions.h+']: ', newProgressSize);
          }
        }
      }else{
        this.progressSize = '300';
      }
    },
    continueFromSession(sessionTimer){
      if(sessionTimer){
        console.log('The session; ', sessionTimer);
        const now = new Date();
        const savedAt = new Date(sessionTimer.savedAt);
        const secondsOutside = parseInt(Math.abs((now.getTime() - savedAt.getTime())/ 1000));
        const elapsed = sessionTimer.elapsed;
        this.timerData = sessionTimer.timer;
        this.totalTimeInSeconds = sessionTimer.total;
        this.activeSound = this.timerData.activeSound;
        this.restSound = this.timerData.restSound;
        this.endSound = this.timerData.endSound;
        this.cooldownSound = this.timerData.cooldownSound;
        this.loadTimer();
        //Now you neeed to set the current step and index for the timer and restart.
        if(sessionTimer.paused){
          this.started = true;
          //Just picked up where we left and keep it as paused
          this.paused = true;
          this.index = sessionTimer.index;
          this.elapsedTimeInSeconds = elapsed;
          this.currentInterval = sessionTimer.currentInterval;
          this.remainingSeconds = this.totalTimeInSeconds - elapsed;
          this.updateProgress();
        }else{
          const totalElapsedTime = elapsed + secondsOutside;
          if(totalElapsedTime > this.totalTimeInSeconds){
            //We took too long outside, the timer has finished.
            return;
          }
          let elapsedTimeCount = 0;
          let i = 0;
          let currentRoundSeconds = 0;
          for(const r of this.rounds){
            elapsedTimeCount += r.totalSeconds;
            currentRoundSeconds = r.totalSeconds;
            if(elapsedTimeCount > totalElapsedTime)
              break;
            i++;
          }
          //Now that we have the index we can figure out where we ended up, 
          //set pointers and continue the timer
          this.index = i;
          //console.log('We are in this index: ', this.index);
          const roundSeconds = currentRoundSeconds - (elapsedTimeCount - totalElapsedTime);
          this.currentInterval = this.rounds[this.index];
          //console.log('Current interval: ', this.currentInterval);
          this.currentInterval.seconds = roundSeconds;
          this.elapsedTimeInSeconds = totalElapsedTime;
          //console.log('Time outside: ', secondsOutside);
          //console.log('Elapsed before we left: ', elapsed);
          //console.log('Total elapsed time: ', this.elapsedTimeInSeconds);
          this.remainingSeconds = this.totalTimeInSeconds - totalElapsedTime;
          this.started = true;
          this.continueTimer(true);
        }
      }
    },
    updateProgress(skipping){
      if(this.totalTimeInSeconds > 0){
        if(skipping){
          let timerIndex = this.index+1;
          let index = 0;
          let elapsedTimeInSeconds = 0;
          for(const r of this.rounds){
            if(index == timerIndex){
              break;
            }
            elapsedTimeInSeconds += r.totalSeconds;
            index++;
          }
          this.elapsedTimeInSeconds = elapsedTimeInSeconds;
          this.remainingSeconds = this.totalTimeInSeconds - elapsedTimeInSeconds;
        }
        this.progress = parseInt((this.elapsedTimeInSeconds * 100)/this.totalTimeInSeconds);
      }
    },
    selectTimer(i){
      const split = document.location.href.split('/');
      let urlId = split[6];
      if(this.started){
        this.showAlert(
          "info",
          this.$t('message.timer_already_running')
        );
      }else if(this.timerId != i._id){
        //this.getTimer();
        if(urlId == i._id){
          window.location.replace("/secure/timers/start/"+i._id);
        }else{
          this.$route.params.timerId = i._id;
          this.timerId = i._id;
          this.navigateTo({ name: 'viewtimer', params: {timerId: i._id}})
        }
      }
    },
    getTimers(){
      TimerService.getTimers()
        .then((data) => {
          if(data){
            this.timers = data.timers;
          }
        });
    },
    moveForward(){
      this.currentInterval.seconds = 0;
      console.log("moving forwards paused? ", this.paused)
      if(this.paused)
        this.processTimerInterval();
      this.updateProgress(true);
    },
    moveBack(){
      this.index = this.index-2;
      this.currentInterval.seconds = 0;
      if(this.paused)
        this.processTimerInterval();
      this.updateProgress(true);
    },
    processTimerInterval(){
      const vm = this;
      //console.log('Processing timer INTERVAL <'+vm.timerData.name+'> on index ['+this.index+'] second: ', this.elapsedTimeInSeconds);
      if(vm.currentInterval && vm.currentInterval.seconds > 0){
        vm.remainingSeconds = vm.remainingSeconds - 1;
        vm.currentInterval.seconds = vm.currentInterval.seconds - 1;
        vm.elapsedTimeInSeconds = vm.elapsedTimeInSeconds + 1;
      }else{
        vm.index++;
        if(vm.rounds[vm.index]){
          vm.currentInterval = vm.rounds[vm.index];
          vm.currentInterval.seconds = vm.currentInterval.totalSeconds;
          //Now proecss the second
          vm.remainingSeconds = vm.remainingSeconds - 1;
          vm.currentInterval.seconds = vm.currentInterval.seconds - 1;
          vm.elapsedTimeInSeconds = vm.elapsedTimeInSeconds + 1;
        }else{
          vm.currentInterval = null;
        }
        //console.log('Moving to index ['+vm.index+'] interval: ', vm.currentInterval ? vm.currentInterval.title : 'END')
        if(vm.currentInterval){
          if(vm.currentInterval.active)
            vm.playSound('active_audio');
          else if(vm.currentInterval.rest)
            vm.playSound('rest_audio');
          else if(vm.currentInterval.cooldown)
            vm.playSound('cooldown_audio');
        }else{
          vm.playSound('end_audio');
          vm.endTimer();
          UserService.logActivity({
            activityType: "timer_end",
            value: this.timerId,
          });
        }
      }
      this.updateProgress();
    },
    getTimerColor(){
      if(this.currentInterval && this.started){
        if(this.currentInterval.active)
          return this.getColor('orange');
        else if(this.currentInterval.rest)
          return this.getColor('green');
        else if(this.currentInterval.warmup)
          return this.getColor('yellow');
        else if(this.currentInterval.cooldown)
          return this.getColor('primary');
      }else{
        return 'white';
      }
    },
    getIntervalColor(){
      if(this.currentInterval && this.started){
        if(this.currentInterval.active)
          return this.colors.active;
        else if(this.currentInterval.rest)
          return this.colors.rest;
        else if(this.currentInterval.warmup)
          return this.colors.warmup;
        else if(this.currentInterval.cooldown)
          return this.colors.cooldown
      }else{
        return 'black';
      }
    },
    playSound(id){
      const sound = this.$refs[id];
      if(sound && this.volumeOn){
        sound.volume = 1;
        sound.play();
      }
    },
    processCountdown(){
      this.showOverlay = true;
      const vm = this;
      window.setTimeout(function(){
        if(vm.initialCountdownInSeconds == 3){
          //This audio is all the countdown
          vm.playSound('countdown_audio');
        }
        if(vm.initialCountdownInSeconds > 0){
          vm.initialCountdownInSeconds = vm.initialCountdownInSeconds - 1;
          vm.processCountdown();
        }else{
          //Start timer
          vm.showOverlay = false;
          vm.intervalId = setInterval(vm.processTimerInterval, vm.ONE_SECOND);
        }
      }, this.ONE_SECOND);
    },
    startTimer(){
      this.started = true;
      this.processCountdown();
      //this.processTimer();
      UserService.logActivity({
        activityType: "timer_start",
        value: this.timerId,
      });
    },
    stopTimer(){
      this.endTimer();
      UserService.logActivity({
        activityType: "timer_stop",
        value: this.timerId,
      });
    },
    continueTimer(skipLogging){
      this.paused = false;
      this.intervalId = setInterval(this.processTimerInterval, this.ONE_SECOND);
      if(!skipLogging){
        UserService.logActivity({
          activityType: "timer_continued",
          value: this.timerId,
        });
      }
    },
    pauseTimer(){
      this.paused = true;
      window.clearInterval(this.intervalId);
      UserService.logActivity({
        activityType: "timer_pause",
        value: this.timerId,
      });
    },
    endTimer(){
      if(this.intervalId)
        window.clearInterval(this.intervalId);
      this.$store.dispatch("setSessionTimer", null);
      this.started = false;
      this.progress = 0;
      this.initialCountdownInSeconds = 3;
      this.getTimer(true);
    },
    loadTimer(){
      try{
        this.rounds = [];
        this.rounds.push({title: this.$t("message.warm_up"), totalSeconds:this.timerData.warmup, seconds: this.timerData.warmup, warmup: true});
        for(let i = 0; i<this.timerData.sets; i++){
          for(const r of this.timerData.rounds){
            this.rounds.push({
              title: r.name, totalSeconds:r.active, seconds: r.active, subtitle: this.$t("message.active"), active: true, set: i+1
            })
            this.rounds.push({
              title: r.name, totalSeconds:r.rest, seconds: r.rest, subtitle: this.$t("message.rest"), rest: true, set: i+1
            })
          }
        }
        this.rounds.push({title: this.$t("message.cool_down"), totalSeconds:this.timerData.cooldown, seconds: this.timerData.cooldown, cooldown: true});
        this.totalIntervals = this.rounds.length;
        this.index = 0;
        this.currentInterval = this.rounds[0];
        this.remainingSeconds = this.totalTimeInSeconds;
        this.elapsedTimeInSeconds = 0;
        this.updateProgress();
      }catch(err){
        console.log(err);
      }
    },
    getTimer(fromCompletion){
      //Do we have one in the session? get it and continue counting
      if(!fromCompletion){
        this.activeSound = null;
        this.restSound = null;
        this.endSound = null;
        this.cooldownSound = null;
      }
      TimerService.getTimer(this.timerId)
        .then(data => {
          if (data) {
            this.timerData = data.timer;
            this.totalTimeInSeconds = data.totalTime;
            this.loading = false;
            this.loadTimer();
            if(this.timerData.activeSound)
              this.activeSound = this.timerData.activeSound;
            else
              this.activeSound = this.assets('sounds/timer_active.wav');
            if(this.timerData.restSound)
              this.restSound = this.timerData.restSound;
            else
              this.restSound = this.assets('sounds/timer_rest.wav');
            if(this.timerData.cooldownSound)
              this.cooldownSound = this.timerData.cooldownSound;
            else
              this.cooldownSound = this.assets('sounds/cooldown.wav');
            if(!fromCompletion){
              //Dont touch this d=sound if we just completed the timer, it might still be playing
              if(this.timerData.endSound)
                this.endSound = this.timerData.endSound;
              else
                this.endSound = this.assets('sounds/end.wav')
                ;
            }
          }
        })
        .catch(err => {
          console.log(err);
          this.showAlert(
              "error",
              this.$t('message.oops_not_working')
            );
        });
    },
  },
  mounted() {},
  computed: {
    showIntervalRemainingSeconds(){
      if(this.currentInterval)
        return this.seconds2Minutes(this.currentInterval.seconds);
      else 
        return 0;
    },
    showElapsedTime(){
      return this.seconds2Minutes(this.elapsedTimeInSeconds);
    },
    showTotalRemainingTime(){
      return this.seconds2Minutes(this.remainingSeconds);
    }
  },
  watch: {}
};
</script>

<style scoped>
.full-screen{
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
}
.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgb(0,0,0);
  overflow-x: hidden;
  transition: 0.5s;
}
.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.countdown-number{
  font-size:15em;
  color: white
}
.round-title{
  font-size: 1.5em;
  color:gray;
  height: 80px;
  margin-bottom:-40px
}
.round-subtitle{
  font-size: 1em;
  margin-top:-5px;
  font-style: italic;
}
.round-counter{
  font-size: 3em;
  margin-top:-30px;
  height: 30px
}
.fullscreen-logo{
  height: 50px;
}
</style>
