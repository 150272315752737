<template>
    <div v-if="academies && academies.length > 0">
        <v-skeleton-loader
            v-if="!mapCenter"
          type="image"
        ></v-skeleton-loader>
        <GmapMap
            v-if="mapCenter"
            :center="mapCenter"
            :zoom="mapZoom"
            map-type-id="roadmap"
            :options="mapOptions"
            ref="map"
            :style="sizeForScreenType()"
        >
            <GmapInfoWindow
                v-if="mapLoaded"
                :options="infoOptions" 
                :position="infoWindowPos" 
                :opened="infoWinOpen" 
                @closeclick="infoWinOpen=false"
            >
                <v-card light elevation="0" class="pa-0" v-if="selectedMarker">
                    <v-card-title class="text-subtitle-1 pt-0 pb-0">
                        <v-avatar 
                            v-if="selectedMarker.academy.image"
                            size="25px"
                        >
                            <img :src="userImg(selectedMarker.academy.image)" alt="avatar" />
                        </v-avatar>
                        <span class="ml-2 text-truncate">{{selectedMarker.academy.name}}</span>
                    </v-card-title>
                    <v-card-text class="text-center pt-0 pb-0">
                        <b>{{ $t("message.checkins") }}:</b> <span>{{selectedMarker.count}}</span>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small block text color="primary" @click="goToAcademy(selectedMarker.academy._id)">
                            {{ $t("message.view_academy_page") }}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </GmapInfoWindow>
            <GmapCircle
                :key="index"
                v-for="(m, index) in markers"
                :center="m.center"
                :radius="m.radius"
                :visible="true"
                :strokeColor="m.strokeColor"
                :options="{fillColor:'#9ACEF7',fillOpacity:0.8, fillOpacity: 0.35, strokeWeight: 2, strokeColor: '#1B75BC'}"
                @click="toggleInfoWindow(m,index)"
            >
            </GmapCircle>
        </GmapMap>
    </div>
</template>

<script>

export default {
    props: [
        'size',
        'academies',
        'center'
    ],
    data () {
        return {
            key: 'AIzaSyDS7qZboGXsPMwSUxk35eRX7pDu26mU-MI',
            url: 'https://maps.googleapis.com/maps/api/staticmap?',
            imgUrl: null,
            windowWidth: null,
            encodedBar: '%7C',
            colors: [],
            map: null,
            mapCenter: null,
            markers:[],
            mapLoaded: false,
            boundries: null,
            selectedMarker: null,
            infoWinOpen: false,
            infoWindowPos: null,
            mapZoom: 3,
            mapOptions: {
            },
            currentMidx: null,
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            darkThemeStyles: [{
                    "elementType": "geometry",
                    "stylers": [{
                    "color": "#212121"
                    }]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [{
                    "visibility": "off"
                    }]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [{
                    "color": "#757575"
                    }]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                    "color": "#212121"
                    }]
                },
                {
                    "featureType": "administrative",
                    "elementType": "geometry",
                    "stylers": [{
                    "color": "#757575"
                    }]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                    "color": "#9e9e9e"
                    }]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "stylers": [{
                    "visibility": "off"
                    }]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                    "color": "#bdbdbd"
                    }]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                    "color": "#757575"
                    }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "geometry",
                    "stylers": [{
                    "color": "#181818"
                    }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                    "color": "#616161"
                    }]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "labels.text.stroke",
                    "stylers": [{
                    "color": "#1b1b1b"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [{
                    "color": "#2c2c2c"
                    }]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                    "color": "#8a8a8a"
                    }]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "geometry",
                    "stylers": [{
                    "color": "#373737"
                    }]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [{
                    "color": "#3c3c3c"
                    }]
                },
                {
                    "featureType": "road.highway.controlled_access",
                    "elementType": "geometry",
                    "stylers": [{
                    "color": "#4e4e4e"
                    }]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                    "color": "#616161"
                    }]
                },
                {
                    "featureType": "transit",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                    "color": "#757575"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [{
                    "color": "#000000"
                    }]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [{
                    "color": "#3d3d3d"
                    }]
                }
                ]
        }
    },
    components:{
    },
    created(){
        this.windowWidth = window.innerWidth;
        this.mapOptions = {zoomControl: true,
            disableDefaultUI : true,
            maxZoom: 12,
            styles: this.isDarkTheme() ? this.darkThemeStyles : []}
        ;
        if(this.center)
            this.mapCenter = this.center;
        else
            this.getMapCenter();
    },
    mounted() {
        if(this.$refs.map){
            const vm = this;
            this.$refs.map.$mapPromise.then((map) => {
                vm.mapLoaded = true;
                vm.map = map;
                if(vm.academies && vm.academies.length > 0)
                    vm.fillMarkers(vm.mapZoom);
                // eslint-disable-next-line
                google.maps.event.trigger(map, 'resize');
                // eslint-disable-next-line
                google.maps.event.addDomListener(map, 'zoom_changed', function() {
                    const newZoom = map.getZoom();
                    vm.fillMarkers(newZoom);
                });
            });
        }
    },
    methods: {
        toggleInfoWindow(marker, idx) {
            this.selectedMarker = marker;
            this.infoWindowPos = marker.center;
            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            }
            else {
                this.infoWinOpen = true
                this.currentMidx = idx
            }
        },
        getMapCenter(){
            try{
                if(this.academies && this.academies[0]){
                    const a = this.academies[0].academy;
                    this.mapCenter = {lat: (a.latitude), lng: (a.longitude)};
                }
            }catch(err){
                console.log(err);
            }
        },
        getMapCenterOld(){
            if(!this.academies || this.academies.length == 0){
                this.mapCenter = {lat: 0, lng: 0};
                return;
            }
            let minLat = 0;
            let maxLat = 0;
            let minLong = 0;
            let maxLong = 0;
            for(const a of this.academies){
                const academy = a.academy;
                if(minLat > academy.latitude)
                    minLat = academy.latitude;
                else if(maxLat < academy.latitude) 
                    maxLat = academy.latitude;
                if(minLong > academy.longitude)
                    minLong = academy.longitude;
                else if(maxLong < academy.longitude) 
                    maxLong = academy.longitude;
            }
            minLat -= 0.1;
            maxLat += 0.1;
            minLong -= 0.1;
            maxLong += 0.1;
            this.mapCenter = {lat: (maxLat+minLat)/2, lng: (maxLong+minLong)/2};
        },
        fillMarkers(zoom){
            this.markers = [];
            const strokeColor = this.getColor('blue');
            /*
            const map = this.map;
            let radiousMultiplier = 50000;
            if(zoom > this.mapZoom){
                let divider = zoom - this.mapZoom + 1;
                if(divider == 5)
                    divider = divider*2;
                else if(divider == 6)
                    divider = divider*4;
                else if(divider == 7)
                    divider = divider*6;
                else if(divider == 8)
                    divider = divider*8;
                else if(divider == 9)
                    divider = divider*10;
                else if(divider == 10)
                    divider = divider*20;
                else if(divider == 11)
                    divider = divider*30;
                //console.log('For Zoom ['+zoom+'], Divider: ', divider)
                radiousMultiplier = radiousMultiplier/divider;
            }
            */
            try{
                let max = 0;
                if(this.academies && this.academies[0])
                    max = this.academies[0].count;
                for(const a of this.academies){
                    const academy = a.academy;
                    const count = a.count;
                    let radius = this.getRadius(count, zoom, max);
                    //console.log('Radius: ', radius);
                    // eslint-disable-next-line
                    const academyCircle = new google.maps.Circle({
                        options:{
                            strokeColor: strokeColor,
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: "#FF0000",
                            fillOpacity: 0.35,
                        },
                        academy: academy,
                        center: {lat: academy.latitude, lng: academy.longitude},
                        radius: radius,
                        count: count
                    });
                    this.markers.push(academyCircle);
                }
                //console.log('Markers: ', this.markers);
            }catch(err){
                console.log(err);
            }
        },
        getRadius(number, zoom, max){
            //let radius = Math.sqrt(count) * radiousMultiplier;
            const biggestRadius = 2000000;
            let radius = Math.round(number * biggestRadius/max);
            if(radius < 100000)
                radius = 100000;
            return radius/zoom;   
        },
        sizeForScreenType(){
            return 'width:'+this.windowWidth+'px; height: 300px';
        }
    },
    computed: {
       
    }
}
</script>

<style scoped>
    .map-image{
        max-width:100%;
        height:auto;
        max-height: 310px;
    }
    .vue-map-container{
        position: inherit;
    }
</style>
