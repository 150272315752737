<template>
    <div left v-if="partner != null">
        <v-avatar size="24px" v-if="showAvatar && partner.image" class="grey lighten-4">
            <img :src="userImg(partner.image)" alt="avatar">
        </v-avatar>
        <span v-if="showLink" :class="usernameLink" @click="goToPartner()">
            {{partner.name}}
        </span>
        <span v-else>
            {{partner.name}}
        </span>
    </div>
</template>

<script>

export default {
    props: [
        'partner',
        'color',
        'avatar',
        'link'
    ],
    data () {
        return {
            showAvatar: true,
            showLink: true,
            usernameLink: 'username_link_light',
        }
    },
    created () {
        if(this.partner){
            if(this.avatar != null)
                this.showAvatar = this.avatar
        }
    },
    components: {
    },
    computed: {
    },
    methods: {
        goToPartner(){
            this.navigateTo({ name: 'viewpartner', params: { partnerId: this.partner._id } });
        }
    }
}
</script>

<style scoped>
    .username_link_light{
        color: black;
        cursor: pointer;
    }
    .username_link_dark{
         color: white;
        cursor: pointer;
    }
</style>