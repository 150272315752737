import Api from "@/services/Api";

export default {
  async getActive() {
    const response = await Api().get("tournaments/ibjjf/active");
    return response.data;
  },
  async getActiveCount() {
    const response = await Api().get("tournaments/ibjjf/active/count");
    return response.data;
  },
  async getTournament(tournamentId) {
    const response = await Api().get(
      "tournaments/ibjjf/tournament/" + tournamentId
    );
    return response.data;
  },
  async getPastTournaments(from) {
    const response = await Api().post("tournaments/ibjjf/past", { from: from });
    return response.data;
  },
  async getFights(data) {
    const response = await Api().post("tournaments/ibjjf/fights", data);
    return response.data;
  },
  async getFilters(tournamentId) {
    const response = await Api().get(
      "tournaments/ibjjf/tournament/" + tournamentId + "/filters"
    );
    return response.data;
  },
  async updateSavedCompetitor(competitorId, action) {
    const response = await Api().patch(
      "tournaments/ibjjf/competitor/" + competitorId + "/action/" + action
    );
    return response.data;
  },
  async getTournamentDayMats(tournamentId, day) {
    const response = await Api().get(
      "tournaments/ibjjf/tournament/" + tournamentId + "/day/" + day
    );
    return response.data;
  },
  async getCompetitorMatches(tournamentId, competitorId) {
    const response = await Api().get(
      "tournaments/ibjjf/tournament/" +
        tournamentId +
        "/competitor/" +
        competitorId +
        "/fights"
    );
    return response.data;
  },
  async getCompetitorBracket(tournamentId, competitorId) {
    const response = await Api().get(
      "tournaments/ibjjf/tournament/" +
        tournamentId +
        "/competitor/" +
        competitorId +
        "/bracket"
    );
    return response.data;
  },
  async savedCompetitors() {
    const response = await Api().get("tournaments/ibjjf/saved");
    return response.data;
  },
  async filterCompetitors(term) {
    const response = await Api().post("tournaments/ibjjf/competitors/search", {
      term: term,
    });
    return response.data;
  },
  async savedCompetitorsMatches() {
    const response = await Api().get("tournaments/ibjjf/saved/matches");
    return response.data;
  },
};
