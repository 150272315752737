import { render, staticRenderFns } from "./SystemMessage.vue?vue&type=template&id=3a3a21c0&scoped=true&"
import script from "./SystemMessage.vue?vue&type=script&lang=js&"
export * from "./SystemMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a3a21c0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCard,VCardText,VCardTitle,VSheet})
