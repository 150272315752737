<template>
  <svg :width="width ? width+'px' : '21px'" :height="height ? height+'px' : '14px'" :viewBox="'0 0 21 14'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linejoin="round">
        <g id="icons/outline/icon-gi-belt-copy-2" transform="translate(-2.000000, -6.000000)" :stroke="iconColor" stroke-width="1.62">
            <path d="M8.86850138,7 L11.9850959,8.52907256 L12,15.085499 L9.87474516,17 L7.22866218,14.7931253 L11.5461607,11.4505661 L7.25564357,9.552 L6.37264357,11.405 L7.85312114,10.2038249 C9.71181844,10.9803655 10.7219334,11.4348459 10.8834659,11.567266 L10.9002333,11.583327 C10.9443455,11.6372421 10.3065385,12.2169277 8.98681203,13.3223839 L8.65698484,13.5977882 C7.342458,14.6922013 5.45075462,16.2316265 2.9818747,18.216064 L2.9818747,18.216064 L2.96464357,14.1711683 L6.14464357,11.589 L3,11.2686497 L3,8 C5.73354268,8.32642451 4.9882056,8.30297284 7.27170616,8.29795717 L7.85539627,8.29729462 L7.85539627,8.29729462 L7.82264357,8.365 L8.86850138,7 Z M16.4769421,10.6299703 L22.1132241,14.7964352 L22.1136072,16.0953047 C22.1136302,16.1730601 22.1136526,16.2490484 22.1136745,16.3232695 L22.1143641,18.6612344 L22.1143641,18.6612344 L16.0015543,13.9779103 L16.4769421,10.6299703 Z M12,7.3 L14.912468,8.51463225 C16.7427408,8.44797469 18.4648621,8.28304739 19.6078621,8.1577112 L19.9409986,8.12051846 C20.5686292,8.04923172 20.9582954,7.99832623 21,8 L21,8 L21,11.2686497 L18.4095345,11.4854097 L15.9996436,10.086 L16,14.193334 L12,16.3 L12,7.3 Z" id="BJJ-Belt"></path>
        </g>
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {
      iconColor: "white"
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
  },
  props: ['color', 'width', 'height'],
  watch: {
    color: {
      handler: function (newValue) {
        this.iconColor = this.getColor(newValue);
      },
    },
  },
};
</script>
<style>
</style>