<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="$store.state.isUserLoggedIn">
    <BackButton></BackButton>
    <div v-if="loading">
      <circles-loader></circles-loader>
    </div>
    <div v-if="!loading && academy">
      <v-card v-if="completed" elevation="0">
        <v-card-title>
          <v-avatar size="25">
            <img v-if="academy.image" :src="userImg(academy.image)">
            <img v-else :src="assets('default-empty-academy.png')">
          </v-avatar>
          {{academy.name}}
        </v-card-title>
        <v-card-text><span>{{academyDocumentTemplate.name}}</span></v-card-text>
        <div light>
          <v-card-text class="template-contents" v-html="documentContents"></v-card-text>
        </div>
        <v-card-text v-if="transaction && transaction.product" class="text-left">
          <v-card class="elevation-1">
            <v-card-title>{{$t('message.document_transaction')}}</v-card-title>
            <v-card-text>
              <span>{{transaction.product.name}}</span>
              <span v-if="transaction.product.interval" style="color:gray">{{$t('message.interval_'+transaction.product.interval)}}</span>
              <span style="color:gray">{{transaction.product.amountDecimal}} {{transaction.currency}}</span>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text v-if="extraFieldValues">
          <template v-for="(field, index) in extraFieldValues">
            <v-text-field 
              disabled
              :key="'extra_field_'+index"
              :label="field.fieldName + (field.required ? ' ('+$t('message.required').toLowerCase()+')' : '')"
              v-model="field.value"></v-text-field>
          </template>
        </v-card-text>
        <v-card-title>
          <v-text-field
            style="margin-right:20px" 
            disabled
            v-model="participantName"
          ></v-text-field>
        </v-card-title>
        <v-card-title>
          <v-text-field
            style="margin-right:20px" 
            disabled
            v-model="guardianName"
          ></v-text-field>
        </v-card-title>
        <v-card-title>
          <v-text-field 
            disabled
            style="margin-right:20px"
            v-model="signedDate"
          ></v-text-field>
        </v-card-title>
        <div class="text-center" v-if="signature" style="margin-bottom:15px;overflow-x:hidden">
          <img :src="signature" style="width:90%;border-bottom: 1px solid black"/>
          <div style="font-family: 'Avenir', Arial, sans-serif;width:350px;margin-right:auto; margin-left:auto">{{$t('message.signature')}}</div>
        </div>
      </v-card>
      <v-card v-else elevation="0">
        <v-card-title>
          <v-avatar size="25">
            <img v-if="academy.image" :src="userImg(academy.image)">
            <img v-else :src="assets('default-empty-academy.png')">
          </v-avatar>
          {{academy.name}}
        </v-card-title>
        <v-card-actions v-if="availableLanguages && availableLanguages.length > 1">
          <template v-for="l in availableLanguages">
            <v-btn 
              elevation="0"
              @click="switchLang(l)"
              :color="l == selectedLang ? 'primary' : 'secondary'"
              fab small dark :key="l">
              {{l}}
            </v-btn>
          </template>
        </v-card-actions>
        <v-card-text><span>{{academyDocumentTemplate.name}}</span></v-card-text>
        <div light>
          <v-card-text class="template-contents" v-html="documentContents"></v-card-text>
        </div>
        <v-card-text v-if="transaction && transaction.product" class="text-left">
          <v-card class="elevation-1">
            <v-card-title>{{$t('message.document_transaction')}}</v-card-title>
            <v-card-text>
              <span>{{transaction.product.name}}</span>
              <span v-if="transaction.product.interval" style="color:gray">{{$t('message.interval_'+transaction.product.interval)}}</span>
              <span style="color:gray">{{transaction.product.amountDecimal}} {{transaction.currency}}</span>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text v-if="academyDocumentTemplate.extraFields">
          <v-list>
            <template v-for="(field, index) in academyDocumentTemplate.extraFields">
              <v-text-field 
                :key="'extra_field_'+index"
                :label="field.fieldName + (field.required ? ' ('+$t('message.required').toLowerCase()+')' : '')"
                v-model="field.value"></v-text-field>
              <v-divider
                v-if="index + 1 < academyDocumentTemplate.extraFields.length"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card-text>
        <v-card-text class="text-center" v-if="messages" v-html="messages"></v-card-text>
        <v-card-text>
          <v-text-field
            style="margin-right:20px"
            class="mb-0"
            :rules="[validNameRule]"
            :label="$t('message.name_of_participant_or_guardian', selectedLang)"
            v-model="participantName"
          ></v-text-field>
          <div v-if="originalRecipient" class="text-caption">
            {{$t('message.on_behalf_of', selectedLang)}}: 
            <span class="ml-1">{{originalRecipient.displayName}}</span>
          </div>
        </v-card-text>
        <v-card-title v-if="!originalRecipient">
          <v-text-field
            style="margin-right:20px"
            :label="$t('message.guardian_name', selectedLang) + ' ('+$t('message.if_applicable', selectedLang)+')'"
            v-model="guardianName"
          ></v-text-field>
        </v-card-title>
        <v-card-title>
          <v-text-field
            :label="$t('message.complete_todays_date')"
            v-model="date"
          ></v-text-field>
        </v-card-title>
        <v-card-text class="text-center">
          <div class="text-center">
            <v-btn x-small outlined class="mb-1" @click="clearSignature()">
              {{$t('message.clear_signature')}}
            </v-btn>
          </div>
          <div>
            <Vue-Signature 
              style="border: 1px solid black; margin-left:auto; margin-right:auto;" 
              :w="'360px'" :h="'150px'"
              ref="waiverSignature" 
              :sigOption="signatureOptions"></Vue-Signature>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            small
            :disabled="!canSubmit || processing"
            color="primary"
            elevation="0"
            @click="submitDocument()"
          >
            <v-progress-circular size="20" v-if="processing" indeterminate color="white"></v-progress-circular>
            {{$t('message.accept', [selectedLang])}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-hide-embed="$route.query.embed"
            small
            elevation="0"
            @click="goBack()"
          >
            {{$t('message.cancel', selectedLang)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-sheet>
</template>
<script>
import NotificationService from "@/services/NotificationService";
import AcademyDocumentService from "@/services/AcademyDocumentService";
import CirclesLoader from "@/components/displays/CirclesLoader.vue";
import VueSignature from "vue-signature"
import BackButton from "@/components/displays/BackButtonDisplay.vue";

var moment = require("moment");

export default {
  data() {
    return {
      loading: false,
      academyDocumentId: this.$route.params.academyDocumentId,
      notificationId: this.$route.params.notificationId,
      documentOwnerId: this.$route.query.doid,
      documentOwnerName: this.$route.query.doname,
      academyDocumentTemplate: null,
      documentContents: null,
      completed: false,
      date: null,
      guardianName: null,
      signedDate: null,
      availableLanguages: [],
      selectedLang: null,
      originalRecipient: null,
      academy: null,
      processing: false,
      extraFieldValues: null,
      transaction: null, 
      messages: null,
      participantName: null,
      signatureOptions: {
        penColor:"rgb(0, 0, 0)",
        backgroundColor:"rgb(255,255,255)"
      },
      signature: null,
      validNameRule: value => {
        if (value) {
          const split = value.split(' ');
          if (split[0] && split[0].length >= 2 && split[1] && split[1].length >= 2)
            return true;
          return this.$t('message.needs_last_name');
        }
        return true;
      }
    };
  },
  components: {
    CirclesLoader,
    VueSignature,
    BackButton
  },
  created() {
    const d = new Date();
    this.date = this.toNiceDateCustomFormat(d, "YYYY-MM-")
    this.loadDocument();
    if(this.notificationId && this.notificationId != 'alert'){
      NotificationService.read(this.notificationId);
    }
  },
  methods: {
    loadDocument(){
      if (this.notificationId) {
        AcademyDocumentService.document(this.academyDocumentId, this.notificationId)
          .then(data => {
          this.academy = data.academy;
          this.transaction = data.transaction;
          this.academyDocumentTemplate = data.template;
          if(this.academyDocumentTemplate.survey){
            this.navigateTo({ name: 'viewacademysurvey', params: { academyDocumentId: this.academyDocumentId } });
            return;
          }
          if(data.template && !data.completed){
            if(data.documentOwner){
              this.originalRecipient = data.documentOwner;
            } else if (this.documentOwnerId) {
              this.originalRecipient = { _id: this.documentOwnerId, displayName: this.documentOwnerName };
            }
            this.availableLanguages.push(data.template.lang);
            for(const t in data.template.contentTransaltions){
              this.availableLanguages.push(t);
            }

            const currentLang = this.$i18n.locale;
            const waiverLang = data.template.lang;
            this.switchLang(data.template.lang);
            if (waiverLang != currentLang) {
              if(this.availableLanguages.indexOf(currentLang) >= 0)
                this.switchLang(currentLang);
            } 
          }else{
            var completedDocument = data.completed;
            this.documentContents = completedDocument.contents;
            this.participantName = completedDocument.userName;
            this.guardianName = completedDocument.guardianName;
            this.extraFieldValues = completedDocument.extraFieldValues;
            this.signedDate = completedDocument.userDate;
            this.signature = completedDocument.signature;
            this.completed = true;
          }
          this.loading = false;
        })
        .catch(err => {
          if(err)
            this.showAlert(
              "error",
              this.$t('message.invalid_document')
            );
        });
      }else{
        AcademyDocumentService.viewCompletedDocument(this.academyDocumentId)
        .then(data => {
          this.academy = data.academy;
          this.transaction = data.transaction;
          this.academyDocumentTemplate = data.template;
          if(this.academyDocumentTemplate.survey){
            if(this.notificationId){
              this.navigateTo({ name: 'viewacademysurveynotification', params: { 
                academyDocumentId: this.academyDocumentId,
                notificationId: this.notificationId,
              }});
            }else{
              this.navigateTo({ name: 'viewacademysurvey', params: { academyDocumentId: this.academyDocumentId } });
            }
            return;
          }
          if(data.template && !data.completed){
            this.availableLanguages.push(data.template.lang);
            for(const t in data.template.contentTransaltions){
              this.availableLanguages.push(t);
            }
            this.switchLang(data.template.lang);
          }else{
            var completedDocument = data.completed;
            this.documentContents = completedDocument.contents;
            this.participantName = completedDocument.userName;
            this.signedDate = completedDocument.userDate;
            this.signature = completedDocument.signature;
            this.completed = true;
          }
          this.loading = false;
        })
        .catch(err => {
          if(err)
            this.showAlert(
              "error",
              this.$t('message.invalid_document')
            );
        });
      }
    },
    submitDocument() {
      this.processing = true;
      this.messages = null;
      let _this = this;
      let pngImage = _this.$refs.waiverSignature.save();
      if(_this.$refs.waiverSignature.isEmpty()){
        this.messages = '<span style="color:red;font-size:1.5em">'+this.$t('message.please_sign')+'</span>';
        this.processing = false;
        return;
      }
      if(this.academyDocumentTemplate.extraFields){
        for(const field of this.academyDocumentTemplate.extraFields){
          if(field.required && !field.value){
            this.messages = '<span style="color:red;font-size:1.5em">' + this.$t('message.complete_required_parameters') + '</span>';
            this.processing = false;
            return;
          }
        }
      }
      if(this.isToday()){
        const postData = {
          userName: this.participantName,
          guardianName: this.guardianName,
          originalRecipient: this.originalRecipient ? this.originalRecipient._id : null,
          userDate: this.date,
          lang: this.selectedLang,
          contents: this.documentContents,
          transaction: this.transaction,
          extraFieldValues: this.academyDocumentTemplate.extraFields,
          signature: pngImage
        };
        AcademyDocumentService.submitDocument(this.academyDocumentId, postData)
          .then(data => {
            if(data.completed){
              var completedDocument = data.completed;
              this.documentContents = completedDocument.contents;
              this.participantName = completedDocument.userName;
              this.guardianName = completedDocument.guardianName;
              this.signedDate = postData.userDate;
              this.signature = completedDocument.signature;
              this.completed = true;
              this.showAlert(
                "success", this.$t('message.success')
              );
            }
            this.loading = false;
            this.processing = false;
          })
          .catch(err => {
            if(err)
              this.showAlert(
                "error",
                this.$t('message.error_generic')
              );
            this.processing = false;
          });
      }else{
        this.messages = '<span style="color:red;font-size:1.5em">'+this.$t('message.not_today')+'</span>';
      }
    },
    clearSignature(){
      var _this = this;
      _this.$refs.waiverSignature.clear();
    },
    switchLang(lang){
      this.selectedLang = lang;
      if(lang == this.academyDocumentTemplate.lang){
        this.documentContents = this.academyDocumentTemplate.contents;
      }else{
        this.documentContents = this.academyDocumentTemplate.contentTransaltions[lang];
      }
    },
    isToday(){
      try{
        const day = moment(this.date, "YYYY-MM-DD");
        const today = moment();
        if(day.isSame(today, 'day') && day.isSame(today, 'date'))
          return true;
        else
          return false;
      }catch(e){
        return false;
      }      
    }
  },
  mounted() {
  },
  computed: {
    canSubmit() {
      let canSubmit = this.participantName && this.date;
      if (true && this.participantName) {
        canSubmit = false;
        const split = this.participantName.split(' ');
        if (split[0] && split[0].length >= 2 && split[1] && split[1].length >= 2)
          canSubmit = true;
      }
      return canSubmit;
    }
  },
};
</script>

<style scoped>
.template-contents{
  background-color: white;
  color: black;
}
</style>