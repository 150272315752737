<template>
  <div v-if="$store.state.isUserLoggedIn">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-text v-if="newAcademy">
        <div class="text-h6">{{$t('message.add_academy')}}</div>
        <div class="text-subtitle-1">{{$t('message.add_academy_explained')}}</div>
        <div v-if="newAcademyError" class="error">
          <p v-if="geolocated" class="red--text">
            {{$t('message.invalid_address')}}
          </p>
        </div>
      </v-card-text>
      <v-card-text>
        <v-form>
          <div row wrap>
            <v-card-text class="pa-0">
              <locations-autocomplete v-model="location" :display="address"></locations-autocomplete>
            </v-card-text>
            <v-flex>
              <GmapMap style="height: 390px; width: 100%" :center="position" :zoom="13" map-type-id="roadmap">
                <GmapMarker v-if="newAcademyPosition" :position="newAcademyPosition" :icon="getIcon()" />
              </GmapMap>
            </v-flex>
          </div>
          <v-card-text class="pa-0 mt-2">
            <v-text-field v-model="name" :counter="100" :label="$t('message.name')" required dense
              outlined></v-text-field>
            <affiliations-combo v-model="affiliation" :show-icon="true"></affiliations-combo>
            <local-affiliations-combo v-model="localAffiliation" :show-icon="true"></local-affiliations-combo>
            <v-text-field outlined dense v-model="website" :label="$t('message.website')"></v-text-field>
          </v-card-text>
        </v-form>
        <div v-if="displayErrors" class="error--text">
          {{$t('message.invalid_parameters')}}
        </div>
      </v-card-text>
      <v-card-actions v-if="newAcademy">
        <v-btn small elevation="0" color="primary" :disabled="!geolocated || !name"
          @click.stop="addNewAcademy()">{{ $t('message.submit_for_approval')}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn small elevation="0" @click.stop="goBack()">{{$t('message.back')}}</v-btn>
      </v-card-actions>
    </v-sheet>
  </div>
</template>

<script>
import AcademyService from "@/services/AcademyService";
import AffiliationsCombo from "@/components/inputs/AffiliationsCombo.vue";
import LocalAffiliationsCombo from "@/components/inputs/LocalAffiliationsCombo.vue";
import LocationsAutocomplete from "@/components/inputs/LocationsAutocomplete.vue";

export default {
  data() {
    return {
      from: this.$route.query.from,
      academyId: this.$route.params.academyId,
      newAcademy: false,
      affiliation: null,
      localAffiliation: null,
      website: null,
      name: null,
      newAcademyError: false,
      geolocated: null,
      address: null,
      zip: null,
      position: { lat: 32.715738, lng: -117.16108380000003 },
      newAcademyPosition: null,
      location: null,
      displayErrors: false
    };
  },
  components: {
    AffiliationsCombo,
    LocationsAutocomplete,
    LocalAffiliationsCombo
  },
  created() {
    this.newAcademy = true;
  },
  mounted() {},
  watch: {
    location: {
      handler: function(newValue) {
        if(newValue){
          if(!newValue.city){
            this.showAlert(
              "error",
              'Can\'t figure out the city name! Contact us at info@bjjlink.com'
            );
          }else{
            this.geolocated = newValue;
            //console.log(this.geolocated);
            this.address = this.geolocated.formatted;
            this.position = { lat: this.geolocated.latitude, lng: this.geolocated.longitude };
            this.newAcademyPosition = { lat: this.geolocated.latitude, lng: this.geolocated.longitude };
          }
        }
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getIcon(){
      return this.assets('logo_map.png');
    },
    getAddressCityStateCountry(item) {
      if(!item)
        return null;
      if(item.route)
        return (
          (item.number ? item.number + ' ' : '') + item.route + 
          (item.subpremise ? ' ' + item.subpremise : '') + ', ' +
          item.city +
          (item.state ? ", " + item.state : "") +
          (item.zip ? ", " + item.zip : "") +
          ", " +
          item.country)
      else if(item.formatted && item.latitude)
        return item.formatted;
    },
    validateNewAcademySubmit() {
      this.newAcademyError = false;
      if (
        this.geolocated == null ||
        this.name == null ||
        this.address == null
      ) {
        this.newAcademyError = true;
        return false;
      }
      return true;
    },
    async addNewAcademy() {
      const proceed = this.validateNewAcademySubmit();
      if (proceed) {
        //Get the country
        const data = {
          name: this.name,
          address: this.address,
          affiliation: this.affiliation,
          localAffiliation: this.localAffiliation,
          website: this.website,
          geolocated: this.geolocated
        };
        try {
          const saved = await AcademyService.addAcademy(data);
          if (saved) {
            this.newAcademy = true;
            this.geolocated = null;
            this.affiliation = null;
            this.localAffiliation = null;
            this.name = null;
            this.address = null;
            this.showAlert(
              "success",
              this.$t('message.we_will_contact_you_soon')
            );
          }else{
            this.showAlert(
              "error",
              this.$t('message.oops_not_working')
            );
          }
        } catch (err) {
          if (err.response && err.response.status === 409) {
            this.showAlert(
              "error",
              this.$t('message.duplicate_academy')
            );
          }else{
            this.showAlert(
              "error",
              this.$t('message.oops_not_working')
            );
          }
        }
      }else{
        this.displayErrors = true;
      }
    }
  }
};
</script>

<style scoped>
</style>
