<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
    <v-card-title class="primary--text">{{$t('message.help_title')}}</v-card-title>
    <v-card-text>
      <v-text-field
        :label="$t('message.search_help')"
        v-model="term"
        autocomplete="false"
        dense
        outlined
      ></v-text-field>
    </v-card-text>
    <v-expansion-panels v-if="help" flat class="pa-2">
        <v-expansion-panel 
          :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'"
          v-for="(h, index) in filteredHelp" :key="'help'+index+h._id" style="margin-top:5px">
          <v-expansion-panel-header>
            <div class="text-subtitle-1">{{ h.question }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="pa-3"><v-chip label small>{{ h.category }}</v-chip></div>
             <v-row>
              <v-col v-if="h.image">
                <img style="max-width:200px" :src="userFile(h.image)" />
              </v-col>
              <v-col>
                <div v-html="h.contents" class="pa-3 page-description"></div>
              </v-col>
             </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
  </v-sheet>
</template>

<script>
import UtilService from "@/services/UtilService";
export default {
  data() {
    return {
      term: null,
      lang: null,
      initialList: true,
      help: [],
      filteredHelp: []
    };
  },
  components: {
  },
  created(){
    this.setLang();
    this.lang = this.$i18n.locale;
    UtilService.getAllHelp()
      .then(help => {
        this.buildHelp(help);
      });
  },
  methods: {
    buildHelp(items){
      if(items){
        const lang = this.lang;
        var tmp = [];
        var count = 0;
        for(const i of items){
          const contents = i.contents[lang] ? i.contents[lang] : i.contents['en'];
          const question = i.question[lang] ? i.question[lang] : i.question['en'];
          const category = i.category[lang] ? i.category[lang] : i.category['en'];
          const item = {
            category: category,
            contents: contents,
            question: question,
            icon: i.icon ? i.icon : 'help_outline',
            image: i.image ? i.image : null
          };
          count++;
          if(count<50)
            tmp.push(item);
          this.help.push(item);
        }
        this.filteredHelp = tmp;
      }
    },
    filterHelp(v) {
      if(v && !this.initialList && v.length <= 3){
        this.initialList = true;
        var count = 0;
        this.filteredHelp = [];
        for(var i of this.help){
          this.filteredHelp.push(i);
          count ++;
          if(count == 50)
            break;
        }
      }else if(v.length > 3){
        this.initialList = false;
        this.filteredHelp = this.help.filter(e => {
          return (
            (e.category || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1 || 
            (e.question || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
          );
        });
      }
    },
  },
  watch: {
    term: {
      handler: function(newValue) {
        if(newValue)
          this.filterHelp(newValue);
      }
    }
  }
}
</script>

<style scoped>
</style>
