<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="text-center pa-6">
    <div class="text-h4 mb-2">{{$t('message.page_not_found')}}</div>
    <div class="mb-2 mt-2">
      <IconLike size="200px" class="rotate-90" :color="themeColor('error')"></IconLike>
    </div>
  </v-sheet>
</template>

<script>
import IconLike from '@/components/displays/icons/IconLike.vue';
export default {
  components: {
    IconLike
  }
}
</script>

<style scoped>
</style>
