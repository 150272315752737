import Api from "@/services/Api";

export default {
  async suggestions() {
    const response = await Api().post("suggestions");
    return response.data;
  },
  async interact(objectId, objectType) {
    const response = await Api().post(
      "suggestions/action/click/" + objectType + "/" + objectId
    );
    return response.data;
  },
  async markUnwanted(objectId) {
    const response = await Api().patch("suggestions/mark/unwanted/" + objectId);
    return response.data;
  },
  async processAction(url, method) {
    let response;
    if (method == "post") response = await Api().post(url);
    else if (method == "patch") response = await Api().patch(url);
    else if (method == "get") response = await Api().get(url);
    return response.data;
  },
};
