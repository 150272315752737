<template>
  <v-chip
      v-if="user"
      class="chip--select-multi"
    >
      <v-avatar>
        <img v-if="user" :src="userImg(user.userImage, false)">
      </v-avatar>
    {{ user.fName }}
  </v-chip>
</template>
<script>

export default {
  data() {
    return {
    };
  },
  components: {},
  props: ["user"]
};
</script>
<style scoped>
</style>