<template>
  <div class="about">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <BackButton></BackButton>
      <v-card-actions class="pl-0 pr-0 pt-0">
        <v-btn 
          @click="navigateTo({ name: 'addacademy', query: { from: 'academies' } })"
          block 
          color="primary" 
          small 
          elevation="0">
          <LibraryIcon class="hero-button"></LibraryIcon>
          {{ $t("message.add_academy") }}
        </v-btn>
      </v-card-actions>
      <v-card-title class="text-subtitle-2 pa-0">
        {{ $t("message.filter_academies") }}
      </v-card-title>
      <v-container>
        <v-row class="mt-0 pt-0 pb-0 mb-0">
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="nameFilter"
              :label="$t('message.academy_name')"
              :clearable="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-expansion-panels flat>
          <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
            <v-expansion-panel-header outlined>
              <div>
                <FilterIcon size="20" class="hero-icon"></FilterIcon>
                {{ $t("message.more_filters") }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <CountriesSelect v-model="countryFilter">
                  </CountriesSelect>
                </v-col>
                <v-col>
                  <KnownCitiesSelect :country="countryFilter" v-model="cityFilter"></KnownCitiesSelect>
                </v-col>
              </v-row>
              <v-row class="mt-0 pt-0 pb-0 mb-0">
                <v-col class="mt-0 pt-0 pb-0 mb-0">
                  <ScheduleTimes
                    :label="$t('message.class_times')"
                    v-model="scheduleFilter"
                  /> 
                </v-col>
                <v-col class="mt-0 pt-0 pb-0 mb-0">
                  <AffiliationsSelect
                    v-model="affiliationFilter"
                    :label="$t('message.affiliation')"
                  ></AffiliationsSelect>
                </v-col>
              </v-row>
              <v-row class="mt-0 pt-0 pb-0 mb-0">
                <v-col class="mt-0 pt-0 pb-0 mb-0">
                  <v-switch v-model="verifiedFilter" :label="$t('message.verified_only')"></v-switch> 
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-sheet>
    <v-sheet class="mt-2 pa-2 pb-0" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-text class="pa-0" style="padding-bottom:-10px">
        <LocationsAutocomplete 
          v-model="nearByFilter" 
          :clearable="true"
          :label="$t('message.find_nearby')"></LocationsAutocomplete>
      </v-card-text>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
      <div v-if="loadingNearby">
        <v-skeleton-loader
          type="list-item-avatar-two-line"
        ></v-skeleton-loader>
      </div>
      <div v-if="!loadingNearby && nearbyAcademies && nearbyAcademies.length == 0">
        <div class="text-subtitle-1">{{ $t("message.no_results") }}</div>
      </div>
      <template 
        v-else
        v-for="(a, index) in nearbyAcademies"
      >
        <v-expansion-panels :key="'nearby_'+index" flat>
          <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
            <v-expansion-panel-header>
              <v-list-item two-lines>
                <v-list-item-avatar tile>
                  <v-badge
                    v-if="a.hasAlert"
                    avatar
                    overlap
                    right
                    bottom
                    offset-x="15"
                    color="none"
                  >
                    <template v-slot:badge>
                      <ExclamationCircleIcon :color="themeColor('primary')" size="15" class="hero-icon"></ExclamationCircleIcon>
                    </template>
                    <v-avatar size="35" @click.stop="goToAcademy(a._id)">
                      <img v-if="a.image" :src="userFile(a.image)">
                      <img v-else :src="assets('default-empty-academy.png')">
                    </v-avatar>
                  </v-badge>
                  <v-avatar v-else size="35" @click.stop="goToAcademy(a._id)">
                    <img v-if="a.image" :src="userFile(a.image)">
                    <img v-else :src="assets('default-empty-academy.png')">
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{a.name}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ $t("message.distance_away", 
                      [metric != 'km' ? a.distanceMiles : a.distanceKm, metric != 'km' ? $t('message.miles') : 'km']) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="">
                <div class="text-truncate clickable text-caption" @click="goToAcademy(a._id)">
                  <LocationMarkerIcon size="20" class="hero-icon"></LocationMarkerIcon>{{a.address}}
                </div>
                <div 
                  v-if="a.sameAffiliation" 
                  class="text-truncate">
                  <ExclamationCircleIcon size="20" class="hero-icon"></ExclamationCircleIcon>
                  <span class="text-caption" v-html="$t('message.same_affiliation', [a.sameAffiliation])"></span>
                </div>
                <div v-if="a.members && a.members.length > 0">
                  <div>
                    <ExclamationCircleIcon size="20" class="hero-icon"></ExclamationCircleIcon>{{$t('message.members_to_reach', [a.members.length])}}
                  </div>
                  <v-list two-line class="pa-0">
                    <template v-for="(u, index) in a.members">
                      <v-list-item 
                        class="pa-0"
                        :key="'academy_member_'+u._id + index" 
                        @click="goToUser(u._id)">
                        <v-list-item-avatar>
                          <user-avatar
                            :user="u"
                            :size="'30px'"
                          >
                          </user-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{u.displayName}}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="u.sharedTag" v-html="$t('message.'+u.suggestion,[u.sharedTag])">
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-else v-html="$t('message.'+u.suggestion)">
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider
                        v-if="index + 1 < a.members.length"
                        :key="index"
                      ></v-divider>
                    </template>
                  </v-list>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-sheet>
    <div>
      <div v-if="!loading && !loadingNearby && ((!academies || academies.length == 0) && (!nearbyAcademies || nearbyAcademies.length == 0))" class="text-center">
        <div class="text-h6">{{$t('message.no_academies_match')}}</div>
      </div>
      <template v-else v-for="(a, index) in academies">
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2 pa-0" v-if="a.city" :key="a._id" :position="index">
          <div v-if="a.altImage" @click="goToAcademy(a._id)" class="text-center">
            <v-responsive 
              class="academy-cover"
              :style="academyCoverRadious+academyCoverGradient+',url(\''+userFile(a.altImage)+'\');'"
            >
              <v-badge
                color="none"
                icon
                v-if="a.managers && a.managers.length > 0"
                overlap
                right
                large
              >
                <template v-slot:badge>
                  <BadgeCheckIcon :color="themeColor('primary')" class="hero-icon"></BadgeCheckIcon>
                </template>
                <page-avatar
                  :src="a.image"
                  size="150px"
                  object="academy"
                  :country="a.city.country"
                />
              </v-badge>
              <page-avatar
                v-else
                :src="a.image"
                size="150px"
                object="academy"
                :country="a.city.country"
              />
            </v-responsive>
          </div>
          <div v-else class="text-center">
            <v-card-text @click="goToAcademy(a._id)" class="clickable">
              <v-badge
                color="none"
                icon
                 v-if="a.managers && a.managers.length > 0"
                overlap
                right
                large
              >
                <template v-slot:badge>
                  <BadgeCheckIcon :color="themeColor('primary')" class="hero-icon"></BadgeCheckIcon>
                </template>
                <page-avatar
                  :src="a.image"
                  size="150px"
                  object="academy"
                  :country="a.city.country"
                />
              </v-badge>
              <page-avatar
                v-else
                :src="a.image"
                size="150px"
                object="academy"
                :country="a.city.country"
              />
            </v-card-text>
          </div>
          <v-card-text 
            class="text-center text-truncate"
            @click="goToAcademy(a._id)">
            <div class="text-h6">{{ a.name | capitalize }}</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <LocationMarkerIcon class="hero-icon"></LocationMarkerIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <country-flag v-if="a.city.country" :countryCode="a.city.country"></country-flag>
                  {{ a.address }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-if="a.phone">
              <v-list-item-action>
                <PhoneIcon class="hero-icon"></PhoneIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ a.phone }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="a.phone"></v-divider>
            <v-list-item v-if="a.affiliation">
              <v-list-item-action>
                <OfficeBuildingIcon class="hero-icon"></OfficeBuildingIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ a.affiliation }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="a.affiliation"></v-divider>
            <v-list-item v-if="a.membersCount">
              <v-list-item-action>
                <UserGroupIcon class="hero-icon"></UserGroupIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ a.membersCount }} {{$t("message.members")}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="a.membersCount"></v-divider>
          </v-list>
          <v-card-actions>
            <AcademyLikes :academy="a" />
            <v-spacer></v-spacer>
            <AcademyStars :rating="a.rating ? a.rating : 0" :academyId="a._id" />
          </v-card-actions>
        </v-sheet>
      </template>
      <div v-if="loading">
        <v-skeleton-loader
          type="card, article"
        ></v-skeleton-loader>
      </div>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="searchMoreAcademies" color="primary" small :disabled="!viewMore">
            {{ $t("message.view_more") }}
            {{ totalAcademies ? pointer + "/" + totalAcademies : "" }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import AcademyService from "@/services/AcademyService";
import KnownCitiesSelect from "@/components/inputs/KnownCitiesSelect.vue";
import ScheduleTimes from "@/components/inputs/ScheduleTimesCombo.vue";
import AffiliationsSelect from "@/components/inputs/AffiliationsSelect.vue";
import PageAvatar from "@/components/displays/CountryObjectAvatar.vue";
import AcademyLikes from "@/components/displays/AcademyLikesDisplay.vue";
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";
import AcademyStars from "@/components/displays/AcademyStarsDisplay.vue";
import UserAvatar from "@/components/displays/UserAvatarDisplay.vue";
import CountriesSelect from "@/components/inputs/CountriesSelect.vue";
import LocationsAutocomplete from "@/components/inputs/LocationsAutocomplete.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

import { 
  FilterIcon,
  LibraryIcon,
  LocationMarkerIcon,
  UserGroupIcon,
  PhoneIcon,
  OfficeBuildingIcon
} from '@vue-hero-icons/outline'

import { 
  ExclamationCircleIcon,
  BadgeCheckIcon,
} from '@vue-hero-icons/solid'

export default {
  data() {
    return {
      academies: null,
      nearbyAcademies: null,
      loading: false,
      loadingNearby: false,
      viewMore: false,
      cityFilter: null,
      nameFilter: null,
      metric: null,
      nearByFilter: null,
      countryFilter: null,
      scheduleFilter: null,
      verifiedFilter: null,
      affiliationFilter: null,
      pointer: 0,
      totalAcademies: null,
      sortOption: "rank",
      sortOptions: [
        { text: this.$t("message.rank"), value: "rank" },
        { text: this.$t("message.alphabetical"), value: "alphabetical" },
      ],
      academyCoverGradient: this.isDarkTheme() ? 'background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(30, 30, 30, 100))' : 
        'background-image:linear-gradient(to bottom, rgba(30, 38, 48, 0), rgba(30, 38, 48, 0), rgba(30, 38, 48, 0), rgba(255, 255, 255, 100))',
      academyCoverRadious: !this.isSmallerDisplay() ? 'border-radius:5px 5px 0 0px;' : ''
    };
  },
  components: {
    KnownCitiesSelect,
    AffiliationsSelect,
    PageAvatar,
    ScheduleTimes,
    AcademyLikes,
    CountryFlag,
    AcademyStars,
    CountriesSelect,
    LocationsAutocomplete,
    UserAvatar,
    FilterIcon,
    LibraryIcon,
    ExclamationCircleIcon,
    LocationMarkerIcon,
    BadgeCheckIcon,
    UserGroupIcon,
    PhoneIcon,
    OfficeBuildingIcon,
    BackButton
  },
  created() {
    this.filterAcademies();
  },
  methods: {
    getNearBy(geolocated){
      if(geolocated){
        this.academies = null;
        this.totalAcademies = null;
        this.viewMore = false;
        this.loadingNearby = true;
        const data  = {location: geolocated}
        AcademyService.findNearby(data)
          .then((data) => {
            if (data && data.academies) {
              this.metric = data.metric;
              this.nearbyAcademies = data.academies;
              this.loadingNearby = false;
            }
          })
          .catch((err) => {
            if(err)
              this.loadingNearby = false;
          });
      }else{
        this.loadingNearby = false;
        this.nearbyAcademies = null;
        this.filterAcademies();
      }
    },
    searchMoreAcademies() {
      this.filterAcademies(true);
    },
    filterAcademies(append) {
      this.nearbyAcademies = null;
      if (!this.loading) {
        this.loading = true;
        this.viewMore = false;
        if (!append) {
          this.academies = [];
          this.pointer = 0;
        }
        var params = {
          pointer: this.pointer,
          total: this.totalAcademies,
        };
        if (this.cityFilter && this.cityFilter != "")
          params["city"] = this.cityFilter;
        if (this.countryFilter && this.countryFilter != "")
          params["country"] = this.countryFilter;
        if (this.affiliationFilter && this.affiliationFilter != "")
          params["affiliation"] = this.affiliationFilter;
        if (this.verifiedFilter)
          params["verified"] = true;
        if (this.nameFilter && this.nameFilter != "")
          params["name"] = this.nameFilter;
        if (this.scheduleFilter && this.scheduleFilter.length > 0){
          let tmp = [];
          for(const s of this.scheduleFilter){
            tmp.push(s.value);
          }
          params["schedule"] = tmp;
        }
        var sortBy = this.sortOption;
        AcademyService.academies(params, "sortby=" + sortBy)
          .then((data) => {
            if (data) {
              this.academies = this.academies.concat(data.academies);
              this.loading = false;
              this.totalAcademies = data.total;
              this.pointer = this.pointer + data.count;
              if (data.count >= this.searchesLimit) this.viewMore = true;
            }
          });
      }
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      return value.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
      });
    },
  },
  watch: {
    nearByFilter: {
      handler: function (newValue) {
        this.getNearBy(newValue);
      },
    },
    nameFilter: {
      handler: function () {
        this.filterAcademies();
      },
    },
    countryFilter: {
      handler: function () {
        this.filterAcademies();
      },
    },
    cityFilter: {
      handler: function () {
        this.filterAcademies();
      },
    },
    affiliationFilter: {
      handler: function () {
        this.filterAcademies();
      },
    },
    scheduleFilter: {
      handler: function () {
        this.filterAcademies();
      },
    },
    verifiedFilter: {
      handler: function () {
        this.filterAcademies();
      },
    },
    sortOption: {
      handler: function () {
        this.filterAcademies();
      },
    },
  },
};
</script>

<style scoped>
.academy-cover{
  padding-top:15px;
  height:200px;
  background-position: center;
  background-size: cover;
}
</style>
