<template>
  <div left v-if="user != null">
    <v-avatar :size="imgSize" v-if="showAvatar" class="grey lighten-4" @click="goToPage()">
      <v-badge
        color="white"
        small
        avatar
        icon
        v-if="user.verifiedBelts"
        overlap
        right
      >
        <span slot="badge">
          <verified-belt :belts="user.verifiedBelts" size="18" :custom-style="'margin-left:2px;margin-bottom:-4px'"/>
        </span>
        <img
          :style="'width:'+imgSize"
          v-if="user.image"
          :src="userImg(user.image)"
          alt="avatar"
        />
        <img
          v-else-if="user.userImageSmall"
          :style="'width:'+imgSize"
          :src="userImg(user.userImageSmall)"
          alt="avatar"
        />
        <img
          v-else
          :style="'width:'+imgSize"
          :src="assets('default-profile-avatar.png')"
          alt="avatar"
        />
      </v-badge>
      <img v-else-if="user.image" :src="userImg(user.image)" alt="avatar" />
      <img v-else-if="user.userImageSmall" :src="userImg(user.userImageSmall)" alt="avatar" />
      <img
        v-else
        :src="assets('default-profile-avatar.png')"
        alt="avatar"
      />
    </v-avatar>
    <span class="text-subtitle-2 ml-1" v-if="user.displayName"> {{ user.displayName }} </span>
    <span class="text-subtitle-2 ml-1" v-else> {{ user.fName }} {{ user.lName }} </span>
    <country-flag
      class="ml-2"
      v-if="!hideFlag && user.nationality"
      :countryCode="user.nationality"
    ></country-flag>
  </div>
</template>

<script>
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";
import UserService from "@/services/UserService";
import VerifiedBelt from "@/components/displays/VerifiedBeltDisplay.vue";

export default {
  props: ["user", "color", "addHover", "avatar", "link","trunc", "size","hideFlag"],
  data() {
    return {
      showPopup: false,
      timer: null,
      isInInfo: false,
      activateHover: true,
      alreadyLinked: false,
      linkedBy: null,
      loadedLinkStatus: false,
      showAvatar: true,
      displayName: null,
      imgSize: "35px"
    };
  },
  created() {
    if(this.size)
      this.imgSize = this.size;
    if (this.user) {
      if (this.avatar != null) this.showAvatar = this.avatar;
      if (this.addHover == null) this.activateHover = true;
      else this.activateHover = this.addHover;
      this.loadedLinkStatus = true;
    }
    if(this.user){
      this.displayName = this.user.displayName ? this.user.displayName : this.user.fName + ' ' + this.user.lName;
    }
  },
  components: {
    CountryFlag,
    VerifiedBelt,
  },
  computed: {},
  methods: {
    goToPage() {
      if (this.user.object == 'user')
        this.goToUser(this.user._id);
      else if(this.user.object == 'academy')
        this.goToAcademy(this.user._id)
      else
        this.goToUser(this.user._id);
    },
    getCountryName(code) {
      const c = this.getCountry(code);
      if (c) return c.country;
    },
    hasMedals() {
      if (
        this.user.overview.goldMedals > 0 ||
        this.user.overview.silverMedals > 0 ||
        this.user.overview.bronceMedals > 0
      )
        return true;
      return false;
    },
    hover() {
      if (this.activateHover) {
        let vm = this;
        this.timer = setTimeout(function () {
          if (vm.$store.state.user._id == vm.user._id) {
            vm.alreadyLinked = true;
          } else {
            vm.linkedBy = vm.user.linkedBy;
            if (vm.linkedBy == null) {
              UserService.user(vm.user._id, true)
                .then((data) => {
                  vm.linkedBy = data.linkedBy;
                  var i = vm.linkedBy.indexOf(this.$store.state.user._id);
                  if (i >= 0) {
                    vm.alreadyLinked = true;
                  }
                  vm.loadedLinkStatus = true;
                });
            } else {
              var i = vm.linkedBy.indexOf(vm.$store.state.user._id);
              if (i >= 0) vm.alreadyLinked = true;
              vm.loadedLinkStatus = true;
            }
          }
          vm.showPopup = true;
        }, 600);
      }
    },
    hoverOut() {
      if (this.activateHover) {
        let vm = this;
        clearTimeout(vm.timer);
        this.timer = setTimeout(function () {
          if (!vm.isInInfo) vm.showPopup = false;
        }, 200);
      }
    },
    hoverInfo() {
      this.isInInfo = true;
    },
    hoverOutInfo() {
      this.isInInfo = false;
      this.hoverOut();
    },
  },
};
</script>

<style scoped>
.username_link_light {
  color: black;
  cursor: pointer;
}
.username_link_dark {
  color: white;
  cursor: pointer;
}
.username_popover {
  position: absolute;
  background: #fff;
  color: black;
  border: 1px solid black;
  padding: 10px 20px;
  box-shadow: 0 6px 6px rgba(16, 16, 16, 0.04), 0 6px 6px rgba(0, 0, 0, 0.05);
  z-index: 999;
  min-width: 350px;
}
.user_medals {
  margin-top: 7px;
  margin-left: 7px;
}
</style>
