<template>
  <div v-if="$store.state.isUserLoggedIn" row wrap>
    <div v-if="loading">
      <v-skeleton-loader
        type="list-item-avatar-line, article"
      ></v-skeleton-loader>
    </div>
    <div v-if="noPostMessage" color="error" class="text-center">
      <IconLike size="200px" class="rotate-90" color="error"></IconLike>
    </div>
    <div v-if="!loading">
      <posts-display 
        v-if="loadPost" 
        :posts="posts" 
        :addHovers="true"
        :single="true"
        :highlight="commentId"
        :reply="replyId"
        v-on:event_repost="openRepostDialog"
      ></posts-display>
    </div>
    <v-sheet class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small elevation="0" @click="goBack()">
            {{$t('message.back')}}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-sheet>
    <v-dialog v-model="repostDialog" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <h3>{{$t('message.repost_from')}} {{repostFrom}}</h3>
        </v-card-title>
        <v-flex class="pa-2">
          <v-textarea outline v-model="repostContents" rows="3"></v-textarea>
        </v-flex>
        <v-card-actions>
          <v-btn color="primary" @click.stop="submitRepost()">{{$t('message.repost')}}</v-btn>
          <v-btn @click.stop="closeRepostDialog()">{{$t('message.cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import PostService from "@/services/PostService";
import ConnectService from "@/services/ConnectService";
import PostsDisplay from "@/components/displays/PostsDisplay.vue";
import NotificationService from "@/services/NotificationService";
import IconLike from '@/components/displays/icons/IconLike.vue';

export default {
  data() {
    return {
      postId: this.$route.params.postId,
      loadPost: false,
      commentId: this.$route.query.c,
      replyId: this.$route.query.r,
      notificationId: this.$route.params.notificationId,
      loading: true,
      posts: null,
      noPostMessage: false,
      repostDialog: false,
      repost: null,
      repostContents: null,
      repostFrom: null,
    };
  },
  components: {
    PostsDisplay,
    IconLike
  },
  async created() {
    if(this.notificationId){
      NotificationService.notification(this.notificationId)
        .then(data => {
          if (data) {
            if(data.params){
              const params = this.stringToParams(data.params);
              if(params.c)
                this.commentId = params.c;
            }
            this.getPost();
          }
        });
      this.readNotification();
    }else{
      this.getPost();
    }
  },
  methods: {
    doNothing() {},
    openRepostDialog(post) {
      this.repostDialog = true;
      this.repost = post;
      this.repostFrom = post.user.fName +' '+post.user.lName;
    },
    closeRepostDialog() {
      this.repost = null;
      this.repostContents = null;
      this.repostDialog = false;
      this.repostFrom = null;
    },
    submitRepost() {
      if(this.repost && this.repostContents && this.repostContents != ''){
        var hashTags = this.extractHashTags(this.repostContents);
        var data = {
          contents: this.repostContents,
          time: this.getNowDate(),
          hashTags: hashTags,
          repost: this.repost._id
        };
        this.submitPost(data);
      }
    },
    async submitPost(postData) {
      if (postData.contents && postData.contents != "") {
        //Grab hashtags first
        const data = await PostService.submitPost(postData);
        if (data) {
          this.showAlert("success", this.$t("message.reposted"));
        } else {
          this.showAlert("error", this.$t("message.oops_not_working"));
        }
      } else {
        this.showAlert(
          "warning",
          this.$t("message.need_type_text")
        );
      }
      if(this.repost){
        this.closeRepostDialog();
      }
    },
    async getPost() {
      ConnectService.getPost(this.postId)
        .then(data => {
          if (data) {
            this.loading = false;
            var list = [];
            list.push(data);
            this.posts = list;
            this.loadPost = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.noPostMessage = true;
        });
    },
    readNotification() {
      NotificationService.read(this.notificationId);
    }
  },
  mounted() {},
  computed: {
  },
  watch: {}
};
</script>

<style scoped>
</style>