<template>
  <v-layout>
    <v-flex>
      <v-select
        @input="event => { emitChange(event) }"
        :items="items"
        v-model="selectedValue"
        :label="$t('message.gender')"
        item-text="name"
        item-value="value"
        dense
        outlined
        :clearable="true">
      </v-select>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      selectedValue: null,
      items: [
        { name: this.$t('message.prefer_not_to_say'), value: null },
        { name: this.$t('message.female'), value: "female" },
        { name: this.$t('message.male'), value: "male" }
      ]
    };
  },
  created() {
  },
  props: {
    value: null,
    icon: null
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        this.selectedValue = newValue;
      }
    }
  },
  methods: {
    emitChange(event) {
      this.$emit("input", event);
    }
  }
};
</script>

<style scoped>
</style>
