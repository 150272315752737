<template>
  <div class="landing" fluid fill-height>
    <v-card-text v-if="closeAndReload" class="text-center text-h5">
      {{ $t('message.close_and_reload') }}
    </v-card-text>
    <v-skeleton-loader v-else
      type="article, actions"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data () {
    return {
      token: this.$route.params.token,
      appVersion: this.$route.query.appv,
      redirect: this.$route.query.r,
      embed: this.$route.query.embed,
      lat: this.$route.query.lat,
      lon: this.$route.query.lon,
      closeAndReload: false,
    }
  },
  mounted(){
    this.login()
  },
  methods: {
    goToLogin(){
      window.location = '/login'
    },
    async login () {
      try {
        if(!this.token){
            this.goToLogin();
            return;
        }else{
          if(this.appVersion && this.appVersion != ""){
            if(this.$store.state.appVersion != this.appVersion){
              this.$store.dispatch("setAppVersion", this.appVersion);
            }
          }
          AuthenticationService.authenticateWithToken({
            token:this.token, 
            appVersion: this.appVersion ? this.appVersion :null
          })
            .then(response => {
              if (response.data.token) {
                //GO to home
                this.$store.dispatch("setToken", null);
                this.$store.dispatch("setUser", null);
                localStorage.removeItem("token");
                this.processLoggedIn(response);
              }else{
                //if you are logged in, go ahead and stay there
                console.log('No token for some reason')
              }
            })
            .catch(() => {
              console.log('We are going to login!')
              if (this.embed && this.embed == 'true') {
                this.closeAndReload = true;
              } else if (localStorage.token) {
                this.navigateTo({name: 'home', query: { lat: this.lat, lon: this.lon } })
              } else {
                this.goToLogin();
              }
            });
        }
      } catch (err) {
        if(err)
          this.goToLogin();
      }
    },
    processLoggedIn(response) {
      if (response.data.token) {
          this.$store.dispatch("setToken", response.data.token);
          this.$store.dispatch("setUser", response.data.user);
          this.$store.dispatch("setName", response.data.user.fName);
          this.$store.dispatch("setInApp", true);
          this.$store.dispatch("setUserImage", 
            (response.data.user.userImageSmall ? 
            response.data.user.userImageSmall : 
            response.data.user.userImage));
          this.$store.dispatch("setLanguage", response.data.user.lang);
          this.$i18n.locale = response.data.user.lang;
          localStorage.token = response.data.token;
          if (
            response.data.hasLatestAgreement &&
            response.data.hasLatestAgreement == true
          ) {
            localStorage.hasAgreements = true;
            this.$store.dispatch("setHasAgreements", true);
          } else {
            localStorage.hasAgreements = false;
            this.$store.dispatch("setHasAgreements", false);
          }
          if (this.lat && this.lon) {
            //store this for future use
            this.$store.dispatch("setLat", this.lat);
            this.$store.dispatch("setLon", this.lon);
          }
          const embed = this.embed && this.embed == "true" ? true : false;
          if (this.redirect && this.redirect != '')
            window.location = '/secure/'+ this.redirect + (embed ? "?embed=true" : "")
          else if (
            response.data.user.academyId
          )
            window.location = '/secure/home?login=true&lat='+this.lat+'&lon='+this.lon
          else 
            window.location = '/secure/history'
        } else {
          this.error = 'Unknown';
        }
    }
  }
}
</script>

<style scoped>
</style>