<template>
  <v-container class="text-center">
    <v-card-text class="text-center text-h2">
      {{ $t("message.success") }}
    </v-card-text>
    <v-card-text class="text-center">
      <v-icon size="150" color="success">mdi-check-circle-outline</v-icon>
    </v-card-text>
  </v-container>
</template>

<script>


export default {
  data() {
    return {
    };
  },
};
</script>

<style scoped>
</style>