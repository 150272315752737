<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 24 24" :fill="iconColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M22 11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3z"/></svg>
</template>
<script>
//:fill="iconColor"
export default {
  data() {
    return {
      iconColor: "gray",
      iconSize: "24px"
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
    if(this.size)
      this.iconSize = this.size;
  },
  props: ['color','size'],
  watch: {
    color: {
      handler: function (newValue) {
        this.iconColor = this.getColor(newValue);
      },
    },
  },
};
</script>
<style>
</style>