<template>
  <div class="landing" fill-height>
    <img 
      :src="assets('landing_screenshot_nologo.jpg')"/>
    <v-flex class="centered" style="min-width:95%;opacity:.8">
        <v-card elevation="0" outlined>
          <v-card-title>
            Reset Password
          </v-card-title>
              <v-card-text>
                <div class="pl-4 pr-4 pt-2 pb-2">
                  <v-form ref="form">
                    <v-text-field
                      dense
                      outlined
                      type="password"
                      label="New password"
                      v-model="password"
                      required
                    ></v-text-field>
                    <v-text-field
                      dense
                      outlined
                      type="password"
                      label="Re-type password"
                      v-model="password2"
                      required
                    ></v-text-field>
                    <div class="error" v-html="error"/>
                    <v-btn
                      color="primary"
                      @click="reset">
                      Reset Password
                    </v-btn>
                    <v-btn
                      color="secondary ml-2"
                      @click="goToLogin">
                      Go To Login
                    </v-btn>
                  </v-form>
                </div>
              </v-card-text>
            </v-card>
    </v-flex>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data () {
    return {
      token: this.$route.params.token,
      password: '',
      password2: '',
      error: ''
    }
  },
  methods: {
    goToLogin(){
      this.navigateTo({name: 'login'});
    },
    async reset () {
      try {
        if(!this.password || this.password == '' || 
          !this.password2 || this.password2 == ''){
            this.error = 'Please fill all the parameters';
            return;
        }else if(this.password != this.password2){
          this.error = 'Passwords don\'t match -_-';
          return;
        }else{
          const data = {
            token: this.token,
            password: this.password,
            password2: this.password2
          };
          AuthenticationService.resetPassword(data)
            .then(data => {
              if (data) {
                //GO t Login
                this.goToLogin();
              }
            })
            .catch(err => {
              console.log(err.response.data)
              this.error = err.response.data.message
            });
        }
      } catch (err) {
        console.log(err.response);
        this.error = 'Something went wrong :/'
      }
    }
  }
}
</script>

<style scoped>
  .centered{
    font-family: Helvetica, Arial, sans-serif;
    color: black;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .landing{
    padding: 0px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
  }
  .landing img{
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
  }
  .registration_error{
    color: red
  }
</style>
