<template>
  <div v-if="!$store.state.hidden">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <BackButton></BackButton>
      <v-skeleton-loader v-if="loading" class="mx-auto" type="card"></v-skeleton-loader>
      <div v-else-if="affiliate">
        <v-card-title v-if="affiliate.campaign" class="text-center">{{ affiliate.campaign }}</v-card-title>
        <v-list>
          <v-list-item v-if="affiliate.user" class="mb-2">
            <v-list-item-avatar>
              <img v-if="affiliate.managedAcademy.academy.image" :src="userImg(affiliate.managedAcademy.academy.image)" />
              <img v-else :src="assets('default-profile-avatar.png')" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ affiliate.managedAcademy.academy.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("message.partner_since") }}: {{ $d(new Date(affiliate.createdAt), 'month_day_year', $i18n.locale) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="affiliate.expirationDate">
                {{ $t("message.ends") }}: {{ $d(new Date(affiliate.expirationDate), 'month_day_year', $i18n.locale) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text class="text-center text-h5">
          {{ $t("message.paid") }}: 
          {{ affiliate.managedAcademy.currencySymbol }}{{ totalPaid }}
        </v-card-text>
        <div v-if="trendline && trendline.options">
          <ApexChart width="100%" type="area" height="200"
                :options="trendline.options" :series="trendline.data"></ApexChart>
        </div>
        <v-expansion-panels class="mt-2" flat v-model="panels">
          <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
            <v-expansion-panel-header>
              <div class="text-left">
                <v-icon>mdi-link</v-icon>
                {{ $t("message.links") }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pl-1 pr-1">
              <v-list-item
                v-for="(item, i) in links"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.source = 'schedule-visit-page'">mdi-calendar-check</v-icon>
                  <v-icon v-else-if="item.source = 'memberships-page'">mdi-wallet-membership</v-icon>
                  <v-icon v-else-if="item.source = 'contact-form'">mdi-list-box-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn x-small icon @click.stop="showQrcode(item)">
                    <v-icon>mdi-qrcode</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn x-small icon @click.stop="copyLink(item)">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel><v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
            <v-expansion-panel-header>
              <div class="text-left" v-if="referrals">
                <v-icon>mdi-account-star-outline</v-icon>
                {{ $t("message.leads") }} ({{ referrals.length }})
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pl-1 pr-1">
              <v-list-item
                v-for="(item, i) in referrals"
                :key="i+'_referral'"
                dense
                class="pr-0 pl-0"
              >
                <v-list-item-avatar size="20">
                  <img v-if="i.image" :src="userImg(i.image)" />
                  <img v-else :src="assets('default-profile-avatar.png')" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $d(new Date(item.createdAt), 'month_day_year', $i18n.locale)  }}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{ item.sourceTranslated}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip top v-if="item.completed">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip v-bind="attrs" v-on="on" small color="success">{{ $t("message.converted") }}</v-chip>
                    </template>
                    <span>{{ $t("message.paid") }}: {{affiliate.managedAcademy.currencySymbol}}{{ item.paidAmount }}</span>
                  </v-tooltip>
                  <v-chip v-else small color="error">{{ $t("message.not_converted") }}</v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
            <v-expansion-panel-header>
              <div class="text-left">
                <v-icon>mdi-cursor-default-click-outline</v-icon>
                {{ $t("message.reach") }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="pl-1 pr-1">
              <v-list-item
                v-for="(item, i) in interactions"
                :key="i+'_interaction'"
                class="pr-0 pl-0"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip small color="success">{{ item.count }}</v-chip>
                </v-list-item-action>
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" elevation="0" small @click.stop="leaveAffiliateDialog=true">
          {{ $t("message.leave") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-dialog v-model="qrcodeDialog" width="90%">
        <v-card outlined elevation="0" v-if="selectedLink" class="pa-2">
          <v-card-text class="text-center text-h5">
            {{ selectedLink.name }}
          </v-card-text>
          <v-card-text class="text-center">
            <vue-qr :text="selectedLink.url" :size="200"></vue-qr>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn elevation="0" small @click="selectedLink = null; qrcodeDialog = null;">
              {{ $t("message.close") }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="leaveAffiliateDialog" width="90%">
        <v-card outlined elevation="0" class="pa-2">
          <v-card-text class="text-center text-h5">
            {{ $t("message.are_you_sure") }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" elevation="0" small @click="leaveAffiliate()">
              {{ $t("message.leave") }}
            </v-btn>
            <v-btn elevation="0" small @click="selectedLink = null;">
              {{ $t("message.close") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import AffiliateService from "@/services/AffiliateService";
import BackButton from "@/components/displays/BackButtonDisplay.vue";
import VueQr from 'vue-qr'
import ApexChart from 'vue-apexcharts';

export default {
  data() {
    return {
      affiliateId: this.$route.params.id,
      affiliate: null,
      interactions: [],
      referrals: [],
      links: [],
      panels: null,
      loading: true,
      selectedLink: null,
      qrcodeDialog: null,
      trendline: null,
      leaveAffiliateDialog: null,
      totalPaid: 0,
    };
  },
  components: {
    VueQr,
    BackButton,
    ApexChart
  },
  created() {
    this.getAffiliateDetails()
  },
  methods: {
    leaveAffiliate() {
      AffiliateService.leaveAffiliate(this.affiliateId)
        .then(() => {
          this.navigateTo({ name: 'affiliates' });
      }).catch(() => {
        this.showAlert(
          "error",
          this.$t("message.error_generic")
        );
      });
    },
    showQrcode(i) {
      this.selectedLink = {
        url: i.link,
        name: i.text
      };
      this.qrcodeDialog = true;
    },
    copyLink(i) {
      this.copyToClipboard(i.link);
    },
    getAffiliateDetails() {
      AffiliateService.getAffiliateDetails(this.affiliateId)
        .then((data) => {
          if (data) {
            this.affiliate = data.affiliate;
            this.interactions = data.interactions;
            this.referrals = data.referrals;
            for (const r of this.referrals){
              if (Math.abs(r.paidAmount) > 0)
                this.totalPaid += Math.abs(r.paidAmount);
            }
            this.links = data.sources;
            if (data.trendline && data.trendline.dates && data.trendline.dates.length > 0)
              this.loadTrendlineData(data.trendline);
            this.loading = false;
          }
      }).catch(() => {
        this.loading = false;
      });
    },
    loadTrendlineData(data) {
      this.trendline = {};
      this.trendline.data = [
        {
          name: data.seriesLabels[0],
          data: data.seriesData[0]
        },
        {
          name: data.seriesLabels[1],
          data: data.seriesData[1]
        },
      ];
      //Set up the dates:
      const categories = [];
      for (const i of data.dates) {
        const d = new Date();
        d.setDate(i.day);
        d.setMonth(i.month);
        d.setFullYear(i.year);
        d.setHours(0);
        d.setMinutes(0);
        categories.push(d)
      }
      this.trendline.options = {
        colors: [this.themeColor('primary'), this.themeColor('orange')],
        xaxis: {
          categories: categories,
          labels: {
            show: false,
          }
        },
        yaxis: {
          labels: {
            show: false,
          }
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy'
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
                  curve: 'smooth'
        },
        theme: {
                  mode: this.isDarkTheme() ? 'dark' : 'light',
        },
        chart: {
                  toolbar: {
                    show: false
                  },
                  background: 'none'
        },
        legend:{
                  position: 'bottom'
        },
        grid: {
          borderColor: this.themeColor('gray'),
          yaxis: {
            lines: {
              show: false  //or just here to disable only x axis grids
            }
          },
        },
      };
    }
  },
};
</script>

<style scoped>
</style>
