<template>
  <v-sheet v-if="$store.state.isUserLoggedIn" rounded="lg">
    <BackButton></BackButton>
    <div v-if="loading">
      <v-skeleton-loader
        type="list-item"
      ></v-skeleton-loader>
    </div>
    <v-card v-else flat>
      <v-card-text v-if="!$store.state.userManagedAcademy">
        {{$t('message.no_managed_academy')}}
      </v-card-text>
      <v-card-title>
        <v-icon>mdi-school-outline</v-icon>
        {{$t('message.active_programs')}}
      </v-card-title>
      <v-card-text v-if="!loading && syllabusInstances.length == 0">
        {{$t('message.no_academy_programs')}}
      </v-card-text>
      <v-list v-else>
        <v-list-item 
          :key="i._id"
          v-for="i in syllabusInstances"
          @click="goToSyllabusInstance(i)">
          <v-list-item-action>
            <v-progress-circular v-if="i.useBelts" :size="50" :width="5" :value="i.progress" :color="getBeltColor(i.currentLevel)">
              {{ i.progress }}%
            </v-progress-circular>
            <v-progress-circular v-else :size="50" :width="5" :value="i.progress" :color="i.color ? i.color : 'primary'">
              {{ i.progress }}%
            </v-progress-circular>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ i.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('message.current_level') }}: 
              <BeltDisplay size="15" v-if="i.useBelts" :belt="i.currentLevel"></BeltDisplay>
              <span v-else>{{ i.currentLevel }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-sheet>
</template>
<script>
import SyllabusService from "@/services/SyllabusService";
import BeltDisplay from "@/components/displays/BeltDisplay.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

export default {
  data() {
    return {
      syllabusInstances: [],
      loading: true
    };
  },
  components: {
    BeltDisplay,
    BackButton
  },
  created() {
    this.loadDetails();
  },
  methods: {
    goToSyllabusInstance(i) {
      if (i.displayDetails)
        this.navigateTo({ name: "viewprogressdetails", params: { instanceId: i._id } });
    },
    loadDetails () {
      SyllabusService.getActiveInstances()
        .then((response) => {
          this.syllabusInstances = response.instances;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>