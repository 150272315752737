<template>
    <v-autocomplete
      :items="results"
      v-model="objectId"
      item-text="text"
      item-value="id"
      :clearable="true"
      :loading="loading"
      color="white"
      :search-input.sync="search"
      @change="search=''"
      solo
      :hide-no-data="true"
      :eager="true"
      rounded
      dense
      flat
      outlined
      :placeholder="$t('message.search_users')"
      :disable-lookup="true"
    >
      <template slot="no-data">
        <v-list-item>
          <span class="subheading">{{ $t("message.no_data_matching") }} </span>
        </v-list-item>
      </template>
      <template slot="item" slot-scope="data">
        <v-list-item class="clickable" @click="goToObject(data.item)">
          <v-list-item-avatar>
            <img v-if="data.item.image" :src="userImg(data.item.image)" />
            <img v-else-if="data.item.type == 'user'" :src="userImg(data.item.image)" />
            <img v-else-if="data.item.type == 'academy'" :src="assets('default-empty-academy.png')" />
            <img v-else-if="data.item.type == 'event'" :src="assets('default-empty-event.png')" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span v-html="data.item.text"></span>
              <span class="ml-2 gray--text text-overline" v-if="data.item.identifier" v-html="'('+data.item.identifier+')'"></span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="data.item.type == 'user'">Jitser</span>
              <span v-if="data.item.type == 'event'">{{
                $t("message.event")
              }}</span>
              <span v-if="data.item.type == 'partner'">{{
                $t("message.partner")
              }}</span>
              <span v-if="data.item.type == 'academy'">{{
                $t("message.academy")
              }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
</template>

<script>
import UtilService from "@/services/UtilService";
export default {
  data() {
    return {
      loading: false,
      objectId: null,
      results: [],
      search: null,
      departing: false,
    };
  },
  created() {},
  props: ['value'],
  watch: {
    search(val) {
      val && val !== this.objectId && this.querySelections(val);
    },
  },
  methods: {
    leavingInput(){
      this.$emit("close_panel", true);
    },
    goToObject(r) {
      if (this.results) {
        const objectId = r.id;
        this.departing = true;
        if (r.type == "academy") {
          this.navigateTo({
            name: "viewacademy",
            params: { academyId: objectId },
          });
        } else if (r.type == "event") {
          this.navigateTo({
            name: "viewevent",
            params: { eventId: objectId },
          });
        } else if (r.type == "partner") {
          this.navigateTo({
            name: "viewpartner",
            params: { partnerId: objectId },
          });
        } else if (r.type == "user") {
          this.navigateTo({
            name: "viewprofile",
            params: { userId: objectId },
          });
        }
        this.objectId = null;
        this.results = [];
        this.departing = false;
        this.$emit("close_panel", true);
      }
    },
    emitChange(event) {
      this.goToObject(event);
    },
    querySelections(v) {
      if (this.departing) {
        this.departing = false;
        this.results = [];
        this.objectId = null;
      } else {
        this.loading = true;
        UtilService.search(v)
          .then((data) => {
            if (data) {
              const tmp = data;
              this.results = tmp.filter((e) => {
                return (
                  e.text != null
                );
              });
              this.loading = false;
            }
          });
      }
    },
  },
};
</script>

<style scoped>
</style>
