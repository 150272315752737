<template>
  <div class="landing pa-2" fill-height>
    <v-card class="pa-2 ma-2" elevation="0" outlined>
      <v-card-text v-if="loading">
        <circles-loader />
      </v-card-text>
      <v-card-text v-if="error">
        <h2 class="error--text">
          {{ $t('message.error_generic')}}
        </h2>
      </v-card-text>
      <v-card-text v-else>
        <h2 class="success--text">
          {{$t('message.updated')}}
        </h2>
      </v-card-text>
      <v-btn elevation="0" color="primary" @click="goToLogin">
        Go To Login
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import CirclesLoader from "@/components/displays/CirclesLoader.vue";

export default {
  data() {
    return {
      token: this.$route.params.token,
      success: false,
      error: null,
      loading: true
    };
  },
  components: {CirclesLoader},
  mounted() {
    this.updateEmail();
  },
  methods: {
    goToLogin() {
      this.navigateTo({ name: "login" });
    },
    async updateEmail() {
      try {
        console.log('Updateing email')
        AuthenticationService.updateEmail({token: this.token})
          .then(data => {
            if (data) {
              this.success = true;
              this.loading = false;
            }
          })
          .catch(err => {
            this.error = err.response.data.message;
            this.loading = false;
          });
      } catch (err) {
        console.log(err);
        this.loading = false;
        this.error = "Something went wrong :/";
      }
    }
  }
};
</script>

<style scoped>
.landing {
  padding: 0px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
</style>
