import Api from "@/services/Api";

export default {
  async getTimers() {
    const response = await Api().get("timers");
    return response.data;
  },
  async getTimer(id) {
    const response = await Api().get("timers/" + id);
    return response.data;
  },
  async addTimer(data) {
    const response = await Api().post("timers", data);
    return response.data;
  },
  async updateTimer(id, data) {
    const response = await Api().patch("timers/" + id, data);
    return response.data;
  },
  async removeTimer(id) {
    const response = await Api().delete("timers/" + id);
    return response.data;
  }
};
