<template>
  <v-sheet class="pa-0">
    <div v-if="displayTitle" class="text-caption gray--text pa-1">{{ $t("message.suggestions") }}</div>
    <v-list dense class="suggestions-container" :style="'height:'+(height ? height : '225px')">
      <v-list-item 
        @click="goToSuggestionLink(s)"
        three-line  
        class="pr-0"
        v-for="(s, index) in loadedSuggestions" 
        :key="identifier+'_'+index+'_'+s.id">
        <v-list-item-avatar>
          <img v-if="s.image" :src="userFile(s.image)" alt="avatar" />
          <img v-else-if="s.obj == 'user'" :src="assets('blank-profile-picture.png')" alt="avatar" />
          <img v-else-if="s.obj == 'academy'" :src="assets('avatars/avatar-academy.png')" alt="avatar" />
          <img v-else-if="s.obj == 'event'" :src="assets('avatars/avatar-event.png')" alt="avatar" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{s.title}}
          </v-list-item-title>
          <v-list-item-subtitle v-html="getSuggestionText(s)">
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn 
            class="elevation-0 mt-1"
            color="primary"
            :disabled="s.clicked || s.removed"
            x-small 
            @click.stop="processAction(s)">
            <LoginIcon v-if="s.obj == 'event'" size="15" class="hero-button"></LoginIcon>
            <LikeIcon v-else-if="s.obj == 'academy'" size="15" color="gray" class="hero-button"></LikeIcon>
            <LikeIcon v-else-if="s.obj == 'partner'" size="15" color="gray" class="hero-button"></LikeIcon>
            <LinkIcon v-else-if="s.obj == 'user'" class="hero-button" size="15"></LinkIcon>
            <PlayIcon v-else-if="s.obj == 'video'" class="hero-button" size="15"></PlayIcon>
            <v-progress-circular
              v-else
              indeterminate
              :color="getObjectColorTheme(s.obj)"
              size="20"
              style="opacity:0.5"
            ></v-progress-circular>
          </v-btn>
        </v-list-item-action>
        <v-list-item-action class="pr-0 ml-0">
          <v-btn 
            v-if="!s.removed && !s.clicked"
            small icon 
            @click.stop="removeSuggestion(s)">
            <XCircleIcon class="hero-button" size="20"></XCircleIcon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <div>
        
      </div>
    </v-list>
  </v-sheet>
</template>
<script>
import SuggestionsService from "@/services/SuggestionsService";
import LikeIcon from "@/components/displays/icons/IconLike";

import { 
  XCircleIcon,
  LoginIcon,
  PlayIcon,
  LinkIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      loadedSuggestions: null,
      clicked:[],
      removed:[]
    };
  },
  components: {
    XCircleIcon,
    LikeIcon,
    LoginIcon,
    PlayIcon,
    LinkIcon
  },
  props: ["suggestions", "identifier","displayTitle","height"],
  created(){
    if(this.suggestions && this.suggestions.length > 0){
      let tmp = [];
      for(const s of this.suggestions){
        tmp.push({
          id: s.id,
          actionUrl: s.actionUrl,
          actionMethod: s.actionMethod,
          actionText: s.actionText,
          viewText: s.viewText,
          viewUrl: s.viewUrl,
          obj: s.obj,
          image: s.image,
          title: s.title,
          belt: s.belt,
          nationality: s.nationality,
          text: s.text,
          params: s.params,
          performingAction: false,
          clicked: false,
          removed: false
        });
      }
      this.loadedSuggestions = tmp;
    }
  },
  methods:{
    removeSuggestion(suggestion){
      suggestion.removed = true;
      SuggestionsService.markUnwanted(suggestion.id);
    },
    processAction(s){
      if(s.obj == 'event'){
        this.goToEvent(s.id);
        return;
      }
      if(s.actionUrl && s.actionMethod){
        s.performingAction = true;
        s.clicked = true;
        SuggestionsService.processAction(s.actionUrl, s.actionMethod)
          .then((data) => {
            if (data)
              s.performingAction = false;
          })
          .catch(() => {
            s.performingAction = false;
          });
      }else {
        this.goToSuggestionLink(s);
      }
    },
    getObjectColor(c) {
      var color = "white";
      if (c == "user") color = "black";
      else if (c == "academy") color = "#1B75BC";
      else if (c == "partner") color = "#8D70B2";
      else if (c == "event") color = "#A97942";
      return color; //#4b4b4b; gray
    },
    getObjectColorTheme(c) {
      var color = "white";
      if (c == "user") color = "black";
      else if (c == "academy") color = "primary";
      else if (c == "partner") color = "terciary";
      else if (c == "event") color = "brown";
      return color; //#4b4b4b; gray
    },
    getSuggestionText(s){
      let text = "";
      if((s.text == "suggestion_academy_link_checkedin" ||
         s.text == "suggestion_academy_link_liked" || 
         s.text == "suggestion_partner_link_liked" ||
         s.text == "suggestion_event_link_going") && s.params)
        text = this.$t("message."+s.text, [s.params.user]);
      else if(s.params && s.params.user)
        text = this.$t("message."+s.text, [s.params.user]);
      else if(s.text == "suggestion_user_same_tags" && s.params && s.params.tags)
        text = this.$t("message."+s.text, [s.params.tags.join(', ')]);
      else
        text = this.$t("message."+s.text);
      return text;
    },
    goToSuggestionLink(s){
      //Log interaction with suggestion!
      if(s){
        SuggestionsService.interact(s.obj, s.id);
        if(s.obj == 'academy')
          this.goToAcademy(s.id);
        else if(s.obj == 'user')
          this.goToUser(s.id);
        else if(s.obj == 'event')
          this.goToEvent(s.id);
        else if(s.obj == 'partner')
          this.goToPartner(s.id);
        else if(s.obj == 'video')
          this.goToVideo(s.id);
      }
    }
  }
};
</script>
<style scoped>
.suggestions-container{
  text-align: left;
  overflow-x: auto;
}
</style>