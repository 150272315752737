<template>
  <div>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="isPartnerAdmin" text-center>
      <v-card-text class="text-center">
        <v-avatar
          size="200">
          <img :src="userFile(partnerImage)">
        </v-avatar>
        <ImageUpload
          class="mt-2"
          :objId="'new_partner_image'"
          :vm="this" 
          :operation="'return_image'"
          :success-function="successFunction" >
        </ImageUpload>
      </v-card-text>
      <v-card-text>
        <v-text-field outlined dense :label="$t('message.name')" v-model="partner.name"></v-text-field>
        <v-text-field outlined dense label="Url" v-model="partner.url"></v-text-field>
        <v-combobox
          v-model="partner.categories"
          :items="categories"
          :hide-no-data="!search"
          :search-input.sync="search"
          small-chips
          multiple
          outlined
          dense
          :label="$t('message.category')"
        >
          <template slot="no-data">
            <v-list-tile>
              <span class="subheading">{{ $t('message.click_enter_add_new') }}: </span>
              <v-chip
                label
                small
              >
                {{ search }}
              </v-chip>
            </v-list-tile>
          </template>
        </v-combobox>
        <v-text-field dense outlined :label="$t('message.contact')" v-model="partner.contact"></v-text-field>
        <v-textarea outlined dense :label="$t('message.description')" v-model="partner.description"></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="editPartner">
          {{ $t('message.edit') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="back">
          {{ $t('message.back') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-sheet>
    <v-flex v-else>
      You should not be here
    </v-flex>
  </div>
</template>

<script>
import ImageUpload from "@/components/inputs/ImageUploadCroppie.vue";
import PartnersService from "@/services/PartnersService";

export default {
  data() {
    return {
      partnerId: this.$route.params.partnerId,
      partner: {},
      partnerImage: null,
      isPartnerAdmin: false,
      categories: [],
      country: null,
      search: null,
      successFunction: function(uploadedFile, vm) {
        if (vm && uploadedFile) {
          vm.partnerImage = uploadedFile;
          vm.partner.image = uploadedFile;
        }
      }
    };
  },
  components: {
    ImageUpload
  },
  created(){
    this.getPartner();
    PartnersService.categories()
      .then(data => {
        if (data) this.categories = data;
      });
  },
  methods: {
    back(){
      this.goBack();
    },
    getPartner(){
      PartnersService.getPartner(this.partnerId)
        .then(data => {
          if (data) {
            if(data.isAdmin){
              this.partnerImage = data.partner.image;
              this.partner = data.partner;
              this.isPartnerAdmin = true;
            }
            else
              this.showAlert("error",'Not an Admin!');
          }
        });
    },
    editPartner(){
      if(this.partner.countries && this.partnerImage){
        if(this.country)
          this.partner.countries = [this.country.toUpperCase()];
        PartnersService.editPartner(this.partnerId, this.partner).then(data => {
          if (data) {
            this.showAlert(
              "success",
              this.$t('message.updated')
            );
          }
        })
        .catch(err => {
          this.showAlert(
            "error",
            err.response.data.message
          );
        });

      }else{
        console.log('FIll all parameters!')
      }
    }
  },
};
</script>

<style scoped>
</style>
