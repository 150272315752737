<template>
  <FireIcon :size="size ? size : '25'" :color="color">{{icon}}</FireIcon>
</template>

<script>
//Icons
import { 
  FireIcon,
} from '@vue-hero-icons/outline'

export default {
  props: ['type','size'],
  components:{
    FireIcon
  },
  data () {
    return {
      icon: 'fitness_center',
      color: this.themeColor('orange')
    }
  },
  created(){
    if(this.type){
      if(this.type == 'cardio'){
        this.icon = 'directions_run';
        this.color= this.themeColor('success');
      }else if(this.type == 'strength_and_conditioning'){
        this.icon = 'accessibility_new';
        this.color= this.themeColor('error');
      } else if(this.type == 'stretching') {
        this.icon = 'self_improvement';
        this.color= this.themeColor('brown');
      } else if(this.type == 'cross_straining') {
        this.color= this.themeColor('purple');
        this.icon = 'sports_mma';
      }
    }
  }
}
</script>
<style scoped>

</style>
