<template>
  <span>
    {{eventType()}}
  </span>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {};
  },
  methods: {
    eventType() {
      if (this.type) {
        if(this.type == 'type_openmat')
          return this.$t('message.open_mat');
        else if(this.type == 'type_tournament')
          return this.$t('message.tournament');
        else if(this.type == 'type_private')
          return this.$t('message.private');
        else if(this.type == 'type_charity')
          return this.$t('message.charity');
        else if(this.type == 'type_seminar')
          return this.$t('message.seminar');
        else if(this.type == 'type_camp')
          return this.$t('message.camp');
        else if(this.type == 'type_virtual')
          return this.$t('message.virtual');
        else
          return this.$t('message.regular');
      }
    }
  }
};
</script>

<style scoped>
</style>
