import Api from "@/services/Api";

export default {
  async event(id) {
    const response = await Api().get("events/v2/" + id);
    return response.data;
  },
  async events(data) {
    const response = await Api().post("events/filter", data);
    return response.data;
  },
  async saveUpdate(formData) {
    const response = await Api().post("events/", formData);
    return response.data;
  },
  async location(id) {
    const response = await Api().get("events/location/" + id);
    return response.data;
  },
  async participants(id) {
    const response = await Api().get("events/participants/" + id);
    return response.data;
  },
  async invite(eid, data) {
    const response = await Api().post(
      "events/participants/send/invite/" + eid,
      data
    );
    return response.data;
  },
  async join(id) {
    const response = await Api().post("events/participants/join/" + id);
    return response.data;
  },
  async leave(id) {
    const response = await Api().post("events/participants/leave/" + id);
    return response.data;
  },
  async addManager(eid, mid) {
    const response = await Api().patch(
      "events/managers/add/" + eid + "/" + mid
    );
    return response.data;
  },
  async removeManager(eid, mid) {
    const response = await Api().patch(
      "events/managers/remove/" + eid + "/" + mid
    );
    return response.data;
  },
  async getQrCode(eid) {
    const response = await Api().post("events/participants/generate/qr/" + eid);
    return response.data;
  },
  async cancel(eid) {
    const response = await Api().delete("events/" + eid);
    return response.data;
  },
  async acceptInvite(eid) {
    const response = await Api().patch(
      "events/participants/respond/invite/" + eid + "/accept"
    );
    return response.data;
  },
  async rejectInvite(eid) {
    const response = await Api().patch(
      "events/participants/respond/invite/" + eid + "/reject"
    );
    return response.data;
  },
  async getEventIcs(id) {
    const response = await Api().get("events/" + id + "/calendar/ics");
    return response.data;
  },
};
