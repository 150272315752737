<template>
  <v-container grid-list-md>
    <div v-if="loading">
      <v-skeleton-loader
        type="card, article"
      ></v-skeleton-loader>
    </div>
    <div v-else-if="!loading && (isManager)">
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
        <v-card-text class="text-center">
          <img :src="academyImage" style="max-width:300px;max-height:300px;" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <image-upload
            v-if="isManager || addingNew"
            :objId="academyId"
            :vm="this"
            :operation="'return_image'"
            :success-function="successFunction"
          ></image-upload>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
        <v-card>
          <v-card-text>
            <v-text-field outlined dense v-model="academy.name" :label="$t('message.name')" required></v-text-field>
            <v-text-field outlined dense v-model="academy.contact" :label="$t('message.contact')" required></v-text-field>
            <v-text-field outlined dense v-model="academy.phone" :label="$t('message.phone')" required></v-text-field>
            <v-text-field outlined dense v-model="academy.website" :label="$t('message.website')" required></v-text-field>
            <affiliations-select :label="$t('message.affiliation')" v-model="academy.affiliation" />
            <local-affiliations-combo
              :label="$t('message.local_affiliation')"
              v-model="academy.localAffiliation"
            />
            <locations-autocomplete v-model="location" :display="academy.address"></locations-autocomplete>
          </v-card-text>
        </v-card>
      </v-sheet>
      <v-flex class="mt-2">
        <GmapMap
          style="height: 390px; width: 100%"
          :center="position"
          :zoom="13"
          map-type-id="roadmap"
        >
          <GmapMarker v-if="position" :position="position" :icon="getIcon()" />
        </GmapMap>
      </v-flex>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
        <v-card-title>
            {{$t('message.description')}}
          </v-card-title>
          <v-card-text>
            <v-textarea
              outlined
              rows="10"
              name="input-7-4"
              :label="$t('message.description')"
              v-model="academy.description"
            ></v-textarea>
        </v-card-text>
      </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
        <v-card>
          <v-card-title>
            {{$t('message.schedule')}}
          </v-card-title>
          <v-card-text>
            <schedule-selector v-model="academy.schedule"></schedule-selector>
          </v-card-text>
        </v-card>
      </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
        <v-card-actions>
          <v-btn
            small
            color="primary"
            @click="saveUpdateAcademy"
            :disabled="!goodToProceed()"
          >{{$t('message.update')}}</v-btn>
          <v-btn color="secondary" small @click="goBack()">{{$t('message.cancel')}}</v-btn>
        </v-card-actions>
      </v-sheet>
    </div>
    <v-layout v-else>
      <h1>You should NOT be here... sorry, brou</h1>
    </v-layout>
  </v-container>
</template>

<script>
import AcademyService from "@/services/AcademyService";
import ImageUpload from "@/components/inputs/ImageUploadCroppie.vue";
import LocationsAutocomplete from "@/components/inputs/LocationsAutocomplete.vue";
import AffiliationsSelect from "@/components/inputs/AffiliationsSelect.vue";
import LocalAffiliationsCombo from "@/components/inputs/LocalAffiliationsCombo.vue";
import ScheduleSelector from "@/components/inputs/ScheduleSelector.vue";

export default {
  data() {
    return {
      academyId: this.$route.params.academyId,
      loading: true,
      location: null,
      addingNew: false,
      position: null,
      academy: null,
      dateFrom: null,
      academyImage: this.assets('default-empty-academy.png'),
      isManager: false,
      successFunction: function (uploadedFile, vm) {
        if (vm && uploadedFile) {
          vm.academyImage = this.userImg(uploadedFile);
          vm.academy.image = uploadedFile;
        }
      },
    };
  },
  created() {
    //get events
    this.loading = false;
    this.getAcademy();
  },
  components: {
    ImageUpload,
    LocationsAutocomplete,
    AffiliationsSelect,
    ScheduleSelector,
    LocalAffiliationsCombo,
  },
  watch: {
    location: {
      handler: function (newValue) {
        this.academy.geolocated = newValue;
        this.position = {
          lat: this.academy.geolocated.latitude,
          lng: this.academy.geolocated.longitude,
        };
      },
    },
  },
  methods: {
    getIcon() {
      return this.assets('logo_map.png');
    },
    goBack() {
      this.$router.go(-1);
    },
    canEdit() {
      return this.isAppAdmin(this.$store.state.user._id);
    },
    formatPhone(phone) {
      try {
        if (this.academy.country && this.academy.country == "US")
          return phone
            .replace(/[^0-9]/g, "")
            .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        else return phone;
      } catch (err) {
        if(err)
          console.log('...')
        return phone;
      }
    },
    goodToProceed() {
      if (this.academy) {
        return true;
      } else {
        return false;
      }
    },
    getAcademy() {
      AcademyService.academy(this.academyId)
        .then((data) => {
          if (data) {
            this.academy = data.academy;
            if (
              this.contains(
                this.academy.managers,
                this.$store.state.user._id,
                "_id"
              ) ||
              this.isAppAdmin(this.$store.state.user._id)
            ) {
              this.isManager = true;
              if (this.academy.image)
                this.academyImage = this.userImg(this.academy.image);
              this.position = {
                lat: this.academy.latitude,
                lng: this.academy.longitude,
              };
            }
          }
        });
    },
    saveUpdateAcademy() {
      if (this.goodToProceed()) {
        const data = [
          {
            propName: "name",
            value: this.academy.name,
          },
          {
            propName: "contact",
            value: this.academy.contact,
          },
          {
            propName: "phone",
            value: this.formatPhone(this.academy.phone),
          },
          {
            propName: "website",
            value: this.academy.website,
          },
          {
            propName: "affiliation",
            value: this.academy.affiliation,
          },
          {
            propName: "localAffiliation",
            value: this.academy.localAffiliation,
          },
          {
            propName: "geolocated",
            value: this.academy.geolocated,
          },
          {
            propName: "image",
            value: this.academy.image,
          },
          {
            propName: "schedule",
            value: this.academy.schedule,
          },
          {
            propName: "description",
            value: this.academy.description,
          },
        ];
        AcademyService.updateAcademy(this.academy._id, data)
          .then((data) => {
            if (data) {
              this.navigateTo({
                name: "viewacademy",
                params: { academyId: this.academy._id },
              });
            }
          })
          .catch((err) => {
            if(err)
              this.showAlert("error", this.$t("message.oops_not_working"));
          });
      }
    },
  },
};
</script>

<style scoped>
</style>
