var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{attrs:{"disabled":_vm.loadingLinks,"items":_vm.links,"chips":"","color":"blue-grey lighten-2","label":_vm.labelDisplay,"item-text":"displayName","item-value":"_id","multiple":"","search-input":_vm.search,"loading":_vm.loadingLinks,"dense":"","outlined":""},on:{"change":function($event){_vm.search=''},"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [(data.item && data.item.fName && !data.item.hidden)?_c('v-chip',_vm._b({attrs:{"close":"","input-value":data.selected},on:{"click":data.select,"click:close":function($event){return _vm.remove(data.item)},"input":function($event){return _vm.remove(data.item)}}},'v-chip',data.attrs,false),[_c('v-avatar',[(data.item)?_c('v-img',{attrs:{"src":_vm.userFile(
              data.item.userImageSmall
                ? data.item.userImageSmall
                : data.item.userImage,
              false
            )}}):_vm._e()],1),_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(data.item.fName))])],1):_vm._e()]}},{key:"item",fn:function(data){return [[_c('v-list-item-avatar',[(data.item)?_c('img',{attrs:{"src":_vm.userFile(
                data.item.userImageSmall
                  ? data.item.userImageSmall
                  : data.item.userImage,
                false
              )}}):_vm._e()]),_c('v-list-item-content',[(data.item)?_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.displayName)}}):_vm._e()],1)]]}}]),model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})}
var staticRenderFns = []

export { render, staticRenderFns }