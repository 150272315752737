<template>
  <li @click="clicked=true">
    <div :class="{bold: isFolder}">
      <TreeIcon v-if="item.root && item.master" :color="themeColor('success')" class="mr-1"></TreeIcon>
      <v-icon class="mr-1" v-else-if="item.root && item.children.length == 0" :color="themeColor('success')">mdi-check-circle-outline</v-icon>
      <TreeBranchIcon
        v-else-if="loading"
        :color="themeColor('gray')"
        class="mr-1"
      >
      </TreeBranchIcon>
      <TreeBranchIcon
        v-else-if="isFolder && item.children == null"
        :color="themeColor('gray')"
        class="mr-1"
      >
      </TreeBranchIcon>
      <TreeBranchIcon v-else-if="isFolder" :color="themeColor('brown')" class="mr-1"></TreeBranchIcon>
      <TreeBranchIcon v-else-if="!clicked" :color="themeColor('brown')" class="mr-1"></TreeBranchIcon>
      <v-icon v-else-if="!loading" :color="themeColor('success')" class="mr-1">mdi-check-circle-outline</v-icon>
      <v-icon v-else :color="themeColor('success')" class="mr-1">mdi-check-circle-outline</v-icon>

      <span @click="toggle">{{ item.name }}</span>
      <span style="margin-left:3px;color:gray" v-if="item.nickname">"{{item.nickname}}"</span>
      
      <v-btn
        x-small icon
        @click="clickNode(item._id)"
      >
        <v-icon size="15">mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn
        v-if="!hideEdit"
        x-small icon
        @click="toggleEdit(item)">
        <v-icon size="15">mdi-circle-edit-outline</v-icon>
      </v-btn>
      <v-btn
        x-small icon
        @click="copyNodeId(item)">
        <v-icon size="15">mdi-content-copy</v-icon>
      </v-btn>
      
    </div>
    <ul v-show="isOpen" v-if="isFolder" style="list-style: none;">
      <tree-item
        v-on:node_clicked="clickNode"
        class="item"
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        @make-folder="$emit('make-folder', $event)"
        @add-item="$emit('add-item', $event)"
        @edit-node="$emit('edit-node', $event)"
      ></tree-item>
    </ul>
  </li>
</template>

<script>
import LineageService from "@/services/LineageService";
import TreeBranchIcon from "@/components/displays/icons/TreeBranchIcon";
import TreeIcon from "@/components/displays/icons/TreeIcon";


export default {
  components: {
    TreeBranchIcon,
    TreeIcon
  },
  name: "tree-item",
  props: {
    item: Object,
    hideEdit: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      isOpen: false,
      clicked: false,
      loading: false
    };
  },
  computed: {
    isFolder: function() {
      return this.item.children && this.item.children.length;
    }
  },
  methods: {
    clickNode(nodeId) {
      this.$emit("node_clicked", nodeId);
    },
    copyNodeId(item) {
      this.copyToClipboard(item._id, 'Node ID Copied');
    },
    toggle: function () {
      if (this.item.children == null) {
        this.loading = true;
        LineageService.children(this.item._id).then((data) => {
          if (data) {
            this.loading = false;
            this.item.children = data.children;
            if (this.isFolder) {
              this.isOpen = !this.isOpen;
            }
          }
        });
      } else {
        if (this.isFolder) {
          this.isOpen = !this.isOpen;
        }
      }
    },
    makeFolder: function() {
      if (!this.isFolder) {
        this.$emit("make-folder", this.item);
        this.isOpen = true;
      }
    },
    toggleEdit: function() {
      this.$emit("edit-node", this.item);
    },
  }
};
</script>

<style scoped>
</style>
