<template>
  <!-- RegBeltSVG.vue -->
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 410 120" style="enable-background:new 0 0 410 120;" xml:space="preserve" width="70">
    <rect x="0" y="0" width="410" height="120" fill="black"/>
    <g>
      <g>
        <rect x="5" y="5.5" :fill="filledColors[0]" width="400" height="109"/>
      </g>
      <!-- if black belt make this red below in Code*-->
      <rect x="205" y="5.5" fill="black" width="199.6" height="109"/>

      <!-- if black belt make this red below in Code*-->
      <g>
        <rect x="227.9" y="5.5" :fill="filledColors[1]" width="20" height="109"/>
        <rect x="272" y="5.5" :fill="filledColors[2]" width="20" height="109"/>
        <rect x="316.2" y="5.5" :fill="filledColors[3]" width="20" height="109"/>
        <rect x="361.4" y="5.5" :fill="filledColors[4]" width="20" height="109"/>
      </g>
      <rect x="5" y="47.5" :fill="filledColors[5]" width="200" height="25"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
      default: () => [],
    },
    belt: {
      type: String,
      default: "",
    },
  },
  computed: {
    filledColors() {
      let defaultColors = ["#FFFFFF", "#000000", "#000000", "#000000", "#000000", "#FFFFFF"];
      return this.colors.concat(defaultColors).slice(0, 6);
    },
  },
};
</script>
