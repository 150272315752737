<template>
  <svg :fill="iconColor" :width="iconSize" :height="iconSize" id="icon_academy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>Icon academy</title><path d="M3.63,11v1.8h.7V11a1.17,1.17,0,0,0,.54-.31,1.24,1.24,0,0,0,1.79,0,1.24,1.24,0,0,0,.89.38,1.26,1.26,0,0,0,.89-.38,1.24,1.24,0,0,0,.89.38,1.26,1.26,0,0,0,.89-.38,1.24,1.24,0,0,0,.89.38,1.26,1.26,0,0,0,.89-.38,1.26,1.26,0,0,0,.89.38,1.24,1.24,0,0,0,.89-.38,1.26,1.26,0,0,0,.89.38,1.24,1.24,0,0,0,.89-.38,1.26,1.26,0,0,0,.89.38,1.24,1.24,0,0,0,.89-.38,1.24,1.24,0,0,0,1.79,0,1.17,1.17,0,0,0,.54.31v7.68H18.58V12.79H14.32v5.93H7.84v.7H20.37V11a1.22,1.22,0,0,0,.89-1.17V6.25H19.42V7h1.14V9.87a.55.55,0,0,1-1.09,0V8.39h-.69V9.87a.55.55,0,0,1-1.09,0V8.39H17V9.87a.54.54,0,0,1-.54.54.54.54,0,0,1-.54-.54V8.39h-.7V9.87a.54.54,0,1,1-1.08,0V8.39h-.7V9.87a.54.54,0,1,1-1.08,0V8.39h-.7V9.87a.54.54,0,1,1-1.08,0V8.39h-.7V9.87a.54.54,0,0,1-1.08,0V8.39h-.7V9.87a.54.54,0,0,1-.54.54A.54.54,0,0,1,7,9.87V8.39h-.7V9.87a.55.55,0,0,1-1.09,0V8.39H4.53V9.87a.55.55,0,0,1-1.09,0V7H4.58v-.7H2.74V9.87A1.22,1.22,0,0,0,3.63,11ZM15,16.45h.54v-.7H15V13.49h2.86v5.23H15Z"/><path d="M8.44,16.94v.7h4.21V12.79h-6v.7H9v3.45Zm1.24-3.45H12v3.45H9.68Z"/><path d="M6.06,7.84H17.94a.94.94,0,0,0,.94-.94V2.45a1,1,0,0,0-.94-1H6.06a1,1,0,0,0-.94,1V6.9A.94.94,0,0,0,6.06,7.84ZM5.82,2.45a.25.25,0,0,1,.24-.25H17.94a.25.25,0,0,1,.24.25V6.9a.25.25,0,0,1-.24.25H6.06a.25.25,0,0,1-.24-.25Z"/><path d="M5.52,19.91V19.4C7,19.3,7.9,18.55,7.9,17.29s-2.21-4.08-2.46-4.38a.36.36,0,0,0-.54,0c-.25.3-2.45,3-2.45,4.38s.86,2,2.37,2.11v.51H2.5v.7h19v-.7ZM3.15,17.29c0-.85,1.28-2.66,2-3.6.74.94,2,2.75,2,3.6s-.57,1.34-1.68,1.42v-2h-.7v2C3.71,18.63,3.15,18.15,3.15,17.29Z"/></svg>
</template>
<script>
export default {
  data() {
    return {
      iconColor: "white",
      iconSize: "32px"
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
    if(this.size)
      this.iconSize = this.size;
  },
  props: ['color','size'],
};
</script>
<style>
  svg {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -6px; 
  }
</style>