<template>
  <v-autocomplete
    v-model="tags"
    :disabled="loadingLinks"
    :items="links"
    chips
    color="blue-grey lighten-2"
    :label="labelDisplay"
    item-text="displayName"
    item-value="_id"
    @change="search=''"
    multiple
    :search-input.sync="search"
    :loading="loadingLinks"
    dense
    outlined
  >
    <template v-slot:selection="data">
      <v-chip
        v-bind="data.attrs"
        close
        @click="data.select"
        @click:close="remove(data.item)"
        v-if="data.item && data.item.fName && !data.item.hidden"
        :input-value="data.selected"
        @input="remove(data.item)"
      >
        <v-avatar>
          <v-img
            v-if="data.item"
            :src="
              userFile(
                data.item.userImageSmall
                  ? data.item.userImageSmall
                  : data.item.userImage,
                false
              )
            "
          ></v-img>
        </v-avatar>
        <span class="pl-1">{{ data.item.fName }}</span>
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template>
          <v-list-item-avatar>
            <img
              v-if="data.item"
              :src="
                userFile(
                  data.item.userImageSmall
                    ? data.item.userImageSmall
                    : data.item.userImage,
                  false
                )
              "
            />
          </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            v-if="data.item"
            v-html="data.item.displayName"
          ></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
import UserService from "@/services/UserService";

export default {
  data() {
    return {
      tags: null,
      search: null,
      links: null,
      linkIds: [],
      autoUpdate: true,
      labelDisplay: this.$t('message.tag_your_links'),
      loadingLinks: false,
    };
  },
  components: {},
  props: ["user", "value", "users", "label"],
  watch: {
    tags: {
      handler: function () {
        this.$emit("input", this.tags);
      },
    },
    search(val) {
      val && val !== this.objectId && this.querySelections(val);
    },
  },
  created() {
    if(this.label)
      this.labelDisplay = this.label;
    this.loadingLinks = true;
    UserService.filterLinks()
      .then((data) => {
        if (data) {
          this.links = data.users;
          this.loadingLinks = false;
          for(const l of this.links){
            if(l.displayName)
              this.linkIds.push(l._id)
          }
        }
      });
  },
  methods: {
    remove(item) {
      const index = this.tags.indexOf(item._id);
      if (index >= 0) this.tags.splice(index, 1);
    },
    clearAll() {
      this.tags = [];
    },
    querySelections(v) {
      this.filterLinks(v);
    },
    filterLinks(v){
      this.loadingLinks = false;
      UserService.filterLinks(v)
        .then((data) => {
          if (data) {
            for(const u of data.users){
              if(this.linkIds.indexOf(u._id) < 0 && u.displayName)
                this.links.push(u);
            }
          }
        }).catch(() => {
          this.loadingLinks = false;
        });
    }
  },
};
</script>
<style scoped>
</style>