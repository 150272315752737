<template>
  <v-skeleton-loader
    v-if="!fetched"
    type="article"
  ></v-skeleton-loader>
  <v-container v-else-if="title && !panel">
    <v-row class="pa-1" @click="goToLink(url)">
      <v-col 
        cols="12"
        sml="4"
        class="text-center">
        <div class="url-thumb" :style="'background-image:' + (img ? 'url('+img+')' : 'url('+assets('default-img-news.jpg')+')')">
        </div>
      </v-col>
      <v-col cols="12" sm="8">
        <div>
          <div class="primary--text pa-2 article-title-panel">
            {{ title }}
          </div>
          <div v-if="description" class="pa-2 article-desc-panel text-body-2">
            {{ description }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
  <div v-else-if="title && panel" class="pt-0 clickable" @click="goToLink(url)">
    <div v-if="img" class="url-thumb-panel">
      <v-img :src="img" class="url-image-panel" />
    </div>
    <div class="primary--text pa-2 article-title-panel">
      {{ title }}
    </div>
    <div v-if="description" class="pa-2 article-desc-panel text-body-2">
      {{ description }}
    </div>
  </div>
</template>

<script>
import PostService from "@/services/PostService";

export default {
  props: ["url", "value","alt","hideLink","panel","item"],
  data() {
    return {
      fetched: false,
      title: null,
      img: null,
      description: null,
      truncateNumber: 150,
      styles: {
        site_desc: 'font-size: small',
      }
    };
  },
  created() {
    if (this.item && this.item.image) {
      this.img = this.item.image;
      this.title = this.htmlDecimalDecode(this.item.title);
      this.description = this.htmlDecimalDecode(this.item.description);
      this.description = this.truncate(this.item.description, this.truncateNumber)
      this.fetched = true;
      this.$emit("input", true);
    } else {
      this.getUrlData();
    }
  },
  methods: {
    getImageStyle(){
      return 'background: url('+this.img+') 50% 50% no-repeat;';
    },
    htmlDecimalDecode(encodedText) {
      var txt = document.createElement("textarea");
      txt.innerHTML = encodedText;
      return txt.value;
    },
    getUrlData() {
      if (this.url) {
        const fixed = this.url.replaceAll('<br>','').replaceAll('</div>','');
        PostService.fetchUrlData({ url: fixed })
          .then((data) => {
            if (data && data.title) {
              this.img = data.img;
              this.title = this.htmlDecimalDecode(data.title);
              this.description = this.htmlDecimalDecode(data.description);
              this.description = this.truncate(this.description, this.truncateNumber)
              this.fetched = true;
              this.$emit("input", true);
            }else if(this.alt && this.alt.title){
              this.title = this.alt.title;
              this.fetched = true;
            }
          })
          .catch((err) => {
            console.log(err);
            //Do nothing
          });
      }
    },
  },
};
</script>

<style scoped>
  .url-thumb{
    display: inline-block;
    height: 144px;
    width:100%;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
  }
  .url-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height:100%;
  }
  .url-thumb-panel{
    display: inline-block;
    height: 130px;
    width:100%;
    background-position: top center;
    background-size: cover;
    overflow: hidden;
  }
  .url-image-panel{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height:100%;
  }
  .article-title-panel{
    overflow-y: hidden;
    max-height: 55px;
  }
  .article-desc-panel{
    overflow-y: hidden;
    max-height: 70px;
  }
</style>
