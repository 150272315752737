import Api from "@/services/Api";

export default {
  async document(id, notificationId) {
    let url = "documents/user/" + id;
    if (notificationId && notificationId != "alert")
      url = "documents/user/" + id + "/notification/" + notificationId;
    const response = await Api().get(url);
    return response.data;
  },
  async viewCompletedDocument(id) {
    const response = await Api().get("documents/user/completed/" + id);
    return response.data;
  },
  async completedDocuments(managedAcademyId, familyMemberId) {
    const response = await Api().get(
      "documents/user/completed/all" +
        (familyMemberId && managedAcademyId
          ? "?ma=" + managedAcademyId + "&fm=" + familyMemberId
          : "")
    );
    return response.data;
  },
  async visitorWaiver(id) {
    const response = await Api().get("documents/visitor/" + id);
    return response.data;
  },
  async submitDocument(id, data) {
    const response = await Api().post("documents/user/" + id, data);
    return response.data;
  },
  async submitVisitorWaiver(id, data) {
    const response = await Api().post(
      "documents/user/visitor/waiver/" + id,
      data
    );
    return response.data;
  },
};
