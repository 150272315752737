<template>
    <div v-if="showButton">
        <div v-if="alreadyLinked">
            <v-btn elevation="0" small :icon="small" color="gray" @click.stop="unlinkWithUser()" :class="small ? 'link-button-small' : 'link-button'" :disabled="displayLoader">
                <v-progress-circular indeterminate color="white" v-if="displayLoader"></v-progress-circular>
                <span v-else v-html="buttonText"></span>
            </v-btn>
        </div>
        <div v-else>
            <v-btn selevation="0" mall :icon="small" color="primary" @click.stop="linkWithUser()" :class="small ? 'link-button-small' : 'link-button'" :disabled="displayLoader">
                <LinkIcon class="hero-button" v-if="!displayLoader" size="24"/>
                <v-progress-circular size="20" indeterminate color="white" v-if="displayLoader"></v-progress-circular>
                <span v-else v-html="buttonText"></span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import UserService from '@/services/UserService'

//Icons
import { 
  LinkIcon,
} from '@vue-hero-icons/outline'

export default {
    props: [
        'linked',
        'userId',
        'small'
    ],
    data () {
        return {
            showButton: true,
            alreadyLinked: false,
            displayLoader: false,
            buttonText: '',
            disabled: false
        }
    },
    components:{
        LinkIcon
    },
    created(){
        if(this.linked){
            this.alreadyLinked = true
            if(this.small)
                this.showButton = false;
            if(!this.small)
                this.buttonText = 'unlink';    
        }else{
            if(!this.small)
                this.buttonText = 'link';
        }
    },
    methods: {
        async linkWithUser(){
            this.displayLoader = true;
            const data = await UserService.linkWithUser(this.userId)
            if(data){
                this.alreadyLinked = true;
                this.buttonText = 'unlink';
                if(this.small)
                    this.showButton = false;
            }
            this.displayLoader = false;
        },
        async unlinkWithUser(){
            this.displayLoader = true;
            const data = await UserService.unlinkWithUser(this.userId)
            if(data){
                this.alreadyLinked = false;
                this.buttonText = 'link';
            }
            this.displayLoader = false;
        }
    },
    computed: {
        
    }
}
</script>

<style scoped>
.link-button{
    min-width: 100px;
}
.link-button-small{
    padding-left: px;
    padding-bottom: 5px;
}
</style>