<template>
  <div v-if="$store.state.isUserLoggedIn">
    <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>
    <div v-if="event" row wrap>
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" elevation="0">
          <BackButton></BackButton>
          <div v-if="event.altImage" class="text-center">
            <v-responsive class="event-cover" :style="eventCoverGradient+',url(\''+userFile(event.altImage)+'\');'">
              <PageAvatar :src="event.image" class="clickable" style="border:2px solid white" size="150px"
                :alt="event.title" />
            </v-responsive>
          </div>
          <v-card-text v-else class="clickable text-center">
            <page-avatar :src="event.image" class="clickable" size="150px" :alt="event.title" />
          </v-card-text>
          <v-card-title>
            {{event.title}}
            <v-spacer></v-spacer>
            <v-menu offset-y v-if="isManager">
              <template v-slot:activator="{ on }">
                <v-btn elevation="0" v-on="on" icon small slot="activator">
                  <DotsVerticalIcon class="hero-button"></DotsVerticalIcon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click.stop="showInviteToEventDialog()">
                  <v-list-item-title>{{ $t('message.invite_friends') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click.stop="goToEdit()">
                  <v-list-item-title>
                    {{ $t('message.edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-show="false" @click.stop="showAddManagerDialog()">
                  <v-list-item-title>{{ $t('message.add_manager') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click.stop="cancelEvent()">
                  <v-list-item-title>{{ $t('message.cancel') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-actions v-if="canCheckin">
            <v-btn block small elevation="0" color="primary" @click="openCheckinQR()">
              {{ $t('message.show_entry') }}
            </v-btn>
          </v-card-actions>
          <v-card-text>
            <v-list>
              <v-list-item v-if="event.academyCreator">
                <v-list-item-action>
                  <v-avatar v-if="event.academyCreator.image" size="30px"><img
                      :src="userFile(event.academyCreator.image)" /></v-avatar>
                  <icon-academy v-else color="grey" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title><a @click="goToAcademy(event.academyCreator._id)">{{event.academyCreator.name}}</a>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{$t('message.academy_organizer')}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <CollectionIcon class="hero-icon"></CollectionIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <event-type :type="event.type"></event-type>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{$t('message.type')}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <LocationMarkerIcon class="hero-icon"></LocationMarkerIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{event.location}}</v-list-item-title>
                  <v-list-item-subtitle>{{$t('message.location')}}
                    <TruckIcon class="hero-icon clickable" @click="goToDirections(event.location)"></TruckIcon>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <ClockIcon class="hero-icon"></ClockIcon>
                </v-list-item-action>
                <v-list-item-content v-if="eventDate">
                  <div v-if="event.recurrence">
                    <RefreshIcon class="hero-icon"></RefreshIcon>{{$t('message.'+event.recurrence+'s')}} {{
                    $d(eventDate, 'time', $i18n.locale) }}
                  </div>
                  <div v-else>
                    <v-list-item-title>{{ $d(eventDate, 'long', $i18n.locale) }}</v-list-item-title>
                    <v-list-item-subtitle>{{$t('message.date_time')}}</v-list-item-subtitle>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="eventTimeZone">
                <v-list-item-action>
                  <GlobeIcon class="hero-icon"></GlobeIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-html="eventTimeZone"></v-list-item-title>
                  <v-list-item-subtitle>{{$t('message.time_zone')}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="event.phone">
                <v-list-item-action>
                  <PhoneIcon class="hero-icon"></PhoneIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-html="event.phone"></v-list-item-title>
                  <v-list-item-subtitle>{{$t('message.phone')}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="event.contact">
                <v-list-item-action>
                  <UserIcon class="hero-icon"></UserIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-html="event.contact"></v-list-item-title>
                  <v-list-item-subtitle>{{$t('message.contact')}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="event.url" @click="goToLink(event.url)">
                <v-list-item-action>
                  <ExternalLinkIcon class="hero-icon"></ExternalLinkIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title><span class="clickable">{{truncate(event.url, 30)}}</span></v-list-item-title>
                  <v-list-item-subtitle>{{$t('message.event_url')}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="event.free">
                <v-list-item-action>
                  <CashIcon class="hero-icon"></CashIcon>
                </v-list-item-action>
                <v-list-item-content>
                  {{$t('message.free_event')}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="event.videoConferenceUrl">
                <v-list-item-action>
                  <VideoCameraIcon class="hero-icon"></VideoCameraIcon>
                </v-list-item-action>
                <v-list-item-content @click="copyConferenceUrl()">
                  {{event.videoConferenceUrl}}
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="event.publicLink && !atMaxCapacity" @click="copyPublicUrl()">
                <v-list-item-action class="clickable">
                  <ClipboardCopyIcon class="hero-icon"></ClipboardCopyIcon>
                </v-list-item-action>
                <v-list-item-content class="clickable">
                  <v-list-item-title>
                    <span class="clickable">{{truncate(event.publicLink, 22)}}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{$t('message.shareable_link')}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item three-line v-if="managers">
                <v-list-item-action>
                  <UserGroupIcon class="hero-icon"></UserGroupIcon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <template v-for="(m) in managers">
                      <v-chip small @input="showRemoveManagerConfirm(m)" :close="managers.length > 1 && isManager"
                        :key="'manager'+m._id">
                        {{m.fName}}
                      </v-chip>
                    </template>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{$t('message.managers')}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions v-if="!event.isPastEvent">
            <v-btn elevation="0" fab small class="elevation-0" @click="getEventCalendarAttachment()"
              v-if="isParticipant && !event.recurrence && hasRequiredVersion('1.2.4')">
              <CalendarIcon class="hero-button"></CalendarIcon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="!isManager && !isParticipant && !hasTicket" @click="joinEvent(event._id)" small
              class="elevation-0" color="primary">{{$t('message.join')}}</v-btn>
            <v-btn v-if="isParticipant && !isManager && !hasTicket" @click="leaveEvent()" small color="error"
              class="elevation-0">
              <LogoutIcon class="hero-icon"></LogoutIcon>
            </v-btn>
          </v-card-actions>
        </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="atMaxCapacity" class="at-max-capacity pa-0">
        <v-card class="at-max-capacity">
          <v-card-text>
            {{$t("message.event_max_capacity")}}
          </v-card-text>
        </v-card>
      </v-sheet>
      <v-sheet class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'"
        v-if="!event.isPastEvent && canSellTicket && event.managedAcademy && (isParticipant || (!atMaxCapacity && hasTicket)) && !isRemovedParticipant">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="purchaseTicket()" class="elevation-0" small :disabled="!canSellTicket" color="primary"
            v-if="hasTicket && !isParticipant && !atMaxCapacity">
            <CashIcon class="hero-icon-button"></CashIcon>
            {{event.ticket.buttonLabel}} {{currency}}{{event.ticket.amountDecimal}}
          </v-btn>
          <v-btn elevation="0" v-if="event.managedAcademy && isParticipant" small color="primary"
            @click="triggerSignWaiver()">
            <PencilAltIcon class="hero-button"></PencilAltIcon>
            {{ $t("message.sign_waiver") }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-sheet>
      <v-sheet class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'">
        <v-card elevation="0">
          <v-card-text class="text-left page-description">
            {{event.description}}
          </v-card-text>
        </v-card>
      </v-sheet>
      <v-sheet class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="!event.hideParticipants">
        <v-card elevation="0">
          <v-card-title class="text-subtitle-1">
            <v-badge right v-if="participants">
              <span slot="badge">{{participants.length}}</span>
              <span>{{$t('message.participants')}}</span>
            </v-badge>
            <span v-if="externalParticipants > 0" class="supporting-text" style="margin-left: 35px">
              ({{$t('message.and_x_more', [externalParticipants])}})
            </span>
          </v-card-title>
          <v-card-text class="user-list-container">
            <div v-if="loadingParticipants">
              <v-skeleton-loader v-if="loading" type="list-item-avatar, list-item-avatar"></v-skeleton-loader>
            </div>
            <UsersList v-else :users="participants"></UsersList>
          </v-card-text>
        </v-card>
      </v-sheet>
      <v-sheet class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'">
        <v-card style="height:390px">
          <span v-if="!position">No map data available :/</span>
          <GmapMap v-if="position" style="height: 390px" :center="position" :zoom="13" map-type-id="roadmap">
            <GmapMarker v-if="position" :position="position" :icon="getIcon()" />
          </GmapMap>
        </v-card>
      </v-sheet>
    </div>
    <v-sheet class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="isParticipant">
      <v-card-title>Posts</v-card-title>
      <new-post class="mb-2" :clear="clearPost" :submit="submitPost" />
    </v-sheet>
    <div class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="isParticipant">
      <PostsDisplay class="mt-2" :posts="posts" :addHovers="true"></PostsDisplay>
      <div v-if="loadingPosts">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </div>
      <div v-if="viewMore" class="text-center">
        <v-btn elevation="0" color="primary" small @click="getPosts(true)">{{$t('message.view_more')}}</v-btn>
      </div>
    </div>
    <v-dialog v-model="addManagerDialog" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <h3>Whats up! Are you sure you want to add this new manager?</h3>
        </v-card-title>
        <v-card-actions>
          <v-btn small elevation="0" color="primary" @click.stop="addManager()">{{$t('message.add_manager')}}</v-btn>
          <v-btn small elevation="0" color="secondary" @click.stop="cancelAddManager()">{{$t('message.cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeManagerConfirm" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <h3>Are you sure you want to remove this manager?</h3>
        </v-card-title>
        <v-card-actions>
          <v-btn small elevation="0" color="primary"
            @click.stop="removeManager()">{{$t('message.remove_manager')}}</v-btn>
          <v-btn small elevation="0" color="secondary"
            @click.stop="cancelRemoveManager()">{{$t('message.cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelEventConfirm" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <h3>{{$t('message.cancel_event_1')}}</h3>
          <p>{{$t('message.cancel_event_2')}}</p>
        </v-card-title>
        <v-card-actions>
          <v-btn small elevation="0" color="primary" @click.stop="deleteEvent()">OK</v-btn>
          <v-btn small elevation="0" color="secondary"
            @click.stop="cancelDeleteEvent()">{{$t('message.cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="checkinDialog" width="90%">
      <v-card outlined>
        <v-card-text class="text-center text-h4">
          {{ $t('message.checkin_code') }}
        </v-card-text>
        <div class="text-center">
          <vue-qr v-if="eventEntryQr" :text="eventEntryQr" :size="200"></vue-qr>
          <div v-else>
            <v-progress-circular size="40" indeterminate color="primary"></v-progress-circular>
          </div>
        </div>
        <v-card-actions>
          <v-btn small elevation="0" block @click.stop="checkinDialog=false">{{$t('message.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="inviteToEventDialog" width="90%">
      <v-card outlined>
        <v-card-title class="text-subtitle-1">
          {{ $t('message.invite_links')}}
        </v-card-title>
        <v-card-text>
          <div v-if="loadingLinks">
            <v-skeleton-loader type="list-item-avatar, list-item-avatar"></v-skeleton-loader>
          </div>
          <div v-else-if="!links || links.length == 0">
            {{ $t('message.no_linked_by')}}
          </div>
          <div v-else>
            <v-text-field :disabled="loadingLinks ? true : false" dense outlined :clearable="true"
              :label="$t('message.filter_users')" v-model="linksFilter"></v-text-field>
            <v-card elevation="0" outlined style="height:200px;overflow-y: auto;">
              <v-list>
                <template v-for="(u, index) in filteredLinks">
                  <v-list-item :key="u._id" @click="doNothing()">
                    <v-list-item-avatar>
                      <img v-if="u.userImage" :src="userImg(u.userImage, false)">
                      <img v-else :src="assets('blank-profile-picture.png')">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <user-name :user="u" :link="false" :addHover="false" :avatar="false">
                      </user-name>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-checkbox v-model="u.selected"></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="index"></v-divider>
                </template>
              </v-list>
            </v-card>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="loadingLinks ? true : false" small elevation="0" color="primary"
            @click.stop="invite2Event()">{{$t('message.invite')}}</v-btn>
          <v-btn small elevation="0" color="secondary"
            @click.stop="cancelInvite2Event()">{{$t('message.cancel')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import PaymentsService from "@/services/PaymentsService";
import EventType from "@/components/displays/EventTypeDisplay.vue";
import PostService from "@/services/PostService";
import UserService from "@/services/UserService";
import UserName from "@/components/displays/UserNameDisplay.vue";
import PostsDisplay from "@/components/displays/PostsDisplay.vue";
import PageAvatar from "@/components/displays/PageAvatarDisplay.vue";
import IconAcademy from "@/components/displays/icons/IconAcademy.vue";
import NewPost from "@/components/inputs/NewPost.vue";
import UsersList from "@/components/displays/UsersListDisplay.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";
import VueQr from 'vue-qr'

//Icons
import { 
  TruckIcon,
  PencilAltIcon,
  LocationMarkerIcon,
  ClipboardCopyIcon,
  PhoneIcon,
  ClockIcon,
  RefreshIcon,
  LogoutIcon,
  GlobeIcon,
  UserIcon,
  ExternalLinkIcon,
  VideoCameraIcon,
  CashIcon,
  CalendarIcon,
  UserGroupIcon,
  CollectionIcon,
  DotsVerticalIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      eventId: this.$route.params.eventId,
      sessionId: this.$route.query.sessionId,
      sessionStatus: this.$route.query.status,
      checkinDialog: false,
      event: null,
      loading: true,
      isManager: false,
      isParticipant: false,
      isRemovedParticipant: false,
      posts: false,
      loadingQr: false,
      loadingParticipants: false,
      loadingPosts: false,
      loadingLinks: false,
      canCheckin: false,
      position: null,
      viewMore: false,
      currency: null,
      participants: [],
      links: [],
      filteredLinks: [],
      managers: [],
      addManagerDialog: false,
      eventDate: null,
      eventTimeZone: null,
      futureManager: null,
      removeManagerConfirm: false,
      cancelEventConfirm: false,
      inviteToEventDialog: false,
      linksFilter: null,
      clearPost: false,
      calendarFilename: null,
      eventEntryQr: null,
      atMaxCapacity: false,
      externalParticipants: 0,
      hasTicket: false,
      canSellTicket: false,
      maxSize: 30,
      eventCoverGradient: this.isDarkTheme() ? 'background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(30, 30, 30, 100))' : 
        'background-image:linear-gradient(to bottom, rgba(30, 38, 48, 0), rgba(30, 38, 48, 0), rgba(30, 38, 48, 0), rgba(255, 255, 255, 100))',
    };
  },
  components: {
    EventType,
    UserName,
    PostsDisplay,
    PageAvatar,
    IconAcademy,
    NewPost,
    TruckIcon,
    BackButton,
    PencilAltIcon,
    LocationMarkerIcon,
    ClipboardCopyIcon,
    PhoneIcon,
    ClockIcon,
    RefreshIcon,
    LogoutIcon,
    GlobeIcon,
    UserIcon,
    ExternalLinkIcon,
    VideoCameraIcon,
    CashIcon,
    CalendarIcon,
    UserGroupIcon,
    CollectionIcon,
    UsersList,
    DotsVerticalIcon,
    VueQr
  },
  beforeRouteUpdate(to, from, next) {
    this.eventId = to.params.eventId;
    this.getEvent();
    next();
  },
  async created() {
    this.getEvent();
  },
  methods: {
    openCheckinQR() {
      this.loadingQr = true;
      this.checkinDialog = true;
      EventService.getQrCode(this.eventId)
        .then(data => {
          if (data) {
            const eventId = this.eventId;
            const qrCodeId = data.id;
            this.eventEntryQr = this.event.managedAcademy + "|" + eventId + "|" + qrCodeId;
          }
        })
        .catch(err => {
          if (err)
            this.showAlert(
              "error",
              this.$t('message.oops_not_working')
            );
        });
    },
    purchaseTicket(){
      PaymentsService.initCheckoutEvent(this.eventId)
        .then((response) => {
          if(response && response.sessionId && response.stripeAccountId){
            this.checkout(response.sessionId, response.stripeAccountId);
          }else{
            this.showAlert("error", this.$t("message.oops_not_working"));
          }
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    checkout(sessionId, stripeAccountId){
      const stripePublishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
      if (stripeAccountId && stripePublishableKey) {
        // eslint-disable-next-line
        var stripe = Stripe(stripePublishableKey, {
          stripeAccount: stripeAccountId,
        });
        stripe
          .redirectToCheckout({
            sessionId: sessionId,
          })
          .then(function () {
            this.showAlert("error", "Oops!", this.$t("message.error_generic"));
          });
      }
    },
    copyPublicUrl() {
      let urlToCopy = this.event.publicLink;
      this.copyToClipboard(urlToCopy);
    },
    triggerSignWaiver(){
      this.navigateTo({
        name: "viewacademyvisitorwaiver",
        params: { academyId: this.event.academyCreator._id},
        query: { e: this.eventId }
      });
    },
    getEventCalendarAttachment(){
      this.goToLink(this.getApiBase()+'events/'+this.eventId+'/'+this.$store.state.user._id+'/calendar/ics');
    },
    copyConferenceUrl() {
      let urlToCopy = this.event.videoConferenceUrl;
      this.copyToClipboard(urlToCopy);
    },
    doNothing() {},
    getIcon(){
      return this.assets('logo_map.png');
    },
    loadLinks() {
      this.loadingLinks = true;
      UserService.getLinkedBy(this.$store.state.user._id)
        .then(data => {
          if (data) {
            const maxSize = this.maxSize;
            this.links = data;
            if (data.length > maxSize) {
              this.filteredLinks = data.slice(0, maxSize-1)
            } else {
              this.filteredLinks = data;
            }
            this.loadingLinks = false;
          }
        });
    },
    showInviteToEventDialog() {
      this.inviteToEventDialog = true;
      if (!this.links && this.links.length == 0 && (this.isParticipant && this.event.type != 'type_private') || this.isManager) {
        this.loadLinks();
      }
    },
    invite2Event() {
      var invites = [];
      for(var u of this.links){
        if(u.selected)
          invites.push(u._id);
      }
      const data = {participants: invites};
      EventService.invite(this.eventId, data)
        .then(data => {
          if (data) {
            this.cancelInvite2Event();
            this.showAlert(
              "success",
              "Invitations sent, lets hope they accept."
            );
          }
        })
        .catch(err => {
          if(err)
            this.showAlert(
              "error",
              this.$t('message.oops_not_working')
            );
        });
    },
    cancelInvite2Event() {
      this.inviteToEventDialog = false;
      for(var u of this.links){
        u.selected = false;
      }
    },
    cancelEvent() {
      this.cancelEventConfirm = true;
    },
    deleteEvent() {
      EventService.cancel(this.eventId)
        .then(data => {
          if (data) {
            this.navigateTo({
              name: "events"
            });
          }
        });
    },
    cancelDeleteEvent() {
      this.cancelEventConfirm = false;
    },
    joinEvent() {
      EventService.join(this.eventId)
        .then(data => {
          if (data) {
            this.isParticipant = true;
            this.getParticipants();
            this.showAlert(
              "success",
              "You are now part of the event and have access to the posts wall and more!"
            );
          }
        });
    },
    leaveEvent() {
      EventService.leave(this.eventId)
        .then(data => {
          if (data) {
            this.isParticipant = false;
            this.getParticipants();
          }
        });
    },
    goToEdit() {
      this.navigateTo({
        name: "manageevent",
        params: { eventId: this.eventId }
      });
    },
    userIsManager(id) {
      if (this.managers) {
        for (const m of this.managers) {
          if (m._id == id) return true;
        }
      }
      return false;
    },
    cancelAddManager() {
      this.addManagerDialog = false;
      this.futureManager = null;
    },
    cancelRemoveManager() {
      this.removeManagerConfirm = false;
    },
    async removeManager() {
      EventService.removeManager(this.eventId, this.futureManager._id)
        .then(data => {
          if (data) {
            var i;
            for (i = 0; i < this.managers.length; i++) {
              const m = this.managers[i];
              if (m._id == this.futureManager._id) {
                break;
              }
            }
            this.removeManagerConfirm = false;
            this.futureManager = null;
            this.managers.splice(i, 1);
          }
        })
        .catch(err => {
          if(err)
            this.showAlert(
              "error",
              this.$t('message.oops_not_working')
            );
        });
    },
    async addManager() {
      EventService.addManager(this.eventId, this.futureManager._id)
        .then(data => {
          if (data) {
            this.managers.push(this.futureManager);
            this.cancelAddManager();
          }
        })
        .catch(err => {
          if(err)
            this.showAlert(
              "error",
              this.$t('message.oops_not_working')
            );
        });

    },
    getEvent() {
      EventService.event(this.eventId)
        .then(data => {
          if (data && data.event) {
            if(data.calendarFilename)
              this.calendarFilename = data.calendarFilename;
            if (data.event.latitude) {
              this.position = {
                lat: data.event.latitude,
                lng: data.event.longitude
              };
            }
            this.getPosts();
            this.getParticipants();
            this.loading = false;
            this.event = data.event;
            this.canCheckin = data.canCheckin;

            this.hasTicket = this.event.ticket && this.event.ticket.amountDecimal > 0 ? true : false;
            this.currency = data.currency;
            this.atMaxCapacity = data.atMaxCapacity;
            this.externalParticipants = data.externalParticipants;
            this.canSellTicket = data.canSellTicket;
            if(this.event.managedAcademy)
              this.event.publicLink = 'https://bjjlink.com/pub/' + this.eventId;
            this.eventDate = this.adjustTimeToOffset(this.event.date, data.timeZone);
            let today = new Date();
            today.setHours(23);
            today.setDate(today.getDate()+1);
            today.setMinutes(59);
            today = this.adjustTimeToOffset(today, data.timeZone);
            if(this.eventDate < today){
              this.event.isPastEvent = true;
            }
            //this.eventDate = new Date(this.event.date);
            this.eventTimeZone = this.prettyTimeZone(data.timeZone, true);
            this.managers = data.event.managers;
            if (
              this.contains(
                this.event.managers,
                this.$store.state.user._id,
                "_id"
              )
            ) {
              this.isManager = true;
            }
            if (
              this.contains(this.event.participants, this.$store.state.user._id)
            ) {
              this.isParticipant = true;
            }
          }
        });
    },
    showRemoveManagerConfirm(m) {
      this.futureManager = m;
      this.removeManagerConfirm = true;
    },
    async getParticipants() {
      this.loadingParticipants = true;
      EventService.participants(this.eventId)
        .then(data => {
          if (data) {
            this.loadingParticipants = false;
            this.participants = data.participants;
            if (this.contains(this.participants, this.$store.state.user._id, "_id")) {
              this.isParticipant = true;
            }
            if(data.removedParticipants && data.removedParticipants.length > 0){
              if(this.contains(data.removedParticipants, this.$store.state.user._id)){
                this.isRemovedParticipant = true;
              }
            }
          }
        });
    },
    async getPosts(append) {
      this.loadingPosts = true;
      this.viewMore = false;
      if (!append) this.posts = [];
      PostService.eventPosts(this.eventId, this.pointer)
        .then(data => {
          if (data) {
            this.posts = this.posts.concat(data.posts);
            this.loadingPosts = false;
            this.pointer = this.pointer + data.count;
            if (data.count >= this.searchesLimit) this.viewMore = true;
          }
        })
        .catch(err => {
          if(err)
            this.loadingPosts = false;
        });
    },
    async submitPost(postData) {
      if (postData.contents && postData.contents != "") {
        postData.eventId = this.eventId;
        const data = await PostService.submitPost(postData);
        //Post added
        if (data) {
          data.post.user = this.$store.state.user;
          this.posts.unshift(data.post);
          this.clearPost = true;
        } else {
          this.showAlert(
            "error",
            this.$t('message.oops_not_working')
          );
        }
      } else {
        this.showAlert(
          "warning",
          this.$t("message.need_type_text")
        );
      }
    },
    showAddManagerDialog(m) {
      this.addManagerDialog = true;
      this.futureManager = m;
    },
    filterLinks(newValue) {
      let tmp = [];
      if (newValue && newValue.length > 0) {
        newValue = newValue.toLowerCase();
        if (this.links) {
          let count = 0;
          console.log('Searching against: ', this.links.length)
          for (var u of this.links) {
            if (count > this.maxSize)
              break;
            if (!newValue || newValue == '') {
              tmp.push(u);
              count++;
            } else {
              if (u.fName.toLowerCase().indexOf(newValue) >= 0 ||
                u.lName.toLowerCase().indexOf(newValue) >= 0 ||
                (u.fName + ' ' + u.lName).toLowerCase().indexOf(newValue) >= 0) {
                tmp.push(u);
                count++;
              }
            }
          }
          this.filteredLinks = tmp;
        }
      }
    }
  },
  mounted() {},
  computed: {},
  watch: {
    linksFilter: {
      handler: function (newValue) {
        this.filterLinks(newValue);
      }
    }
  }
};
</script>

<style scoped>
.event-cover{
  padding-top:15px;
  height:200px;
  background-position: center;
  background-size: cover;
}
.at-max-capacity{
  background-color: #EC7063;
  color:white;
  font-weight: bold;
}
</style>