<template>
  <svg :width="iconSize" :height="iconSize"  viewBox="0 0 24 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="App-Design-2022" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round">
        <g id="BJJLINK---Menu-icons" transform="translate(-69.000000, -310.000000)" :stroke="iconColor" stroke-width="2">
            <g id="Partner" transform="translate(70.000000, 311.000000)">
                <path d="M7.96849622,0 L5.38939878,0 L3.13931865,2.26636339 L1,2.26636339 C0.44771525,2.26636339 1.17007287e-14,2.71407864 0,3.26636339 L0,9.11335039 C6.76353751e-17,9.66563514 0.44771525,10.1133504 1,10.1133504 L2.99588661,10.1133504 L2.99588661,10.1133504 L7.37622032,14.34202 C8.23731425,14.5781856 8.97043103,14.6432128 9.57557064,14.5371016 C10.1807102,14.4309904 10.8598739,14.00937 11.6130616,13.2722404 C13.2447772,13.2304997 14.545773,12.8499187 15.5160488,12.1304973 C16.4863247,11.4110759 16.8855639,10.6289319 16.7137666,9.78406548 L13.3397686,6.21541743" id="Path-9"></path>
                <path d="M18.7475112,10.1823503 L21,10.1823503 C21.5522847,10.1823503 22,9.73463505 22,9.1823503 L22,3.38546417 C22,2.83317942 21.5522847,2.38546417 21,2.38546417 L18.7475112,2.38546417 L18.7475112,2.38546417 L16.362047,0 L10.8836689,0 L6.82487823,4.05879059 C6.71236369,4.89242131 6.91662556,5.5196282 7.43766387,5.94041124 C7.95870217,6.36119429 8.55036157,6.52990868 9.21264208,6.44655442 L12.6373932,3.02180336" id="Path-10"></path>
            </g>
        </g>
    </g>
  </svg>
</template>
<script>
//:fill="iconColor"
export default {
  data() {
    return {
      iconColor: "gray",
      iconSize: "24px"
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
    if(this.size)
      this.iconSize = this.size;
  },
  props: ['color','size'],
  watch: {
    color: {
      handler: function (newValue) {
        this.iconColor = this.getColor(newValue);
      },
    },
  },
};
</script>
<style>
</style>