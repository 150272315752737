import Api from "@/services/Api";

export default {
  async academies(data, sortBy) {
    const response = await Api().post(
      "academies" + (sortBy ? "?" + sortBy : ""),
      data
    );
    return response.data;
  },
  async academiesSimplified() {
    const response = await Api().get("academies/simplified/all");
    return response.data;
  },
  async mapLocations(data) {
    const response = await Api().post("academies/map/locations", data);
    return response.data;
  },
  async calendar(id) {
    const response = await Api().get("academies/calendar/" + id);
    return response.data;
  },
  async academyClasses(id) {
    const today = new Date();
    const response = await Api().get(
      "academies/academyclasses/" + id + "?today=" + today
    );
    return response.data;
  },
  async academy(id, professors) {
    try {
      const response = await Api().get(
        "academies/" + id + (professors ? "?professors=yes" : "")
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async publicId(id) {
    try {
      const response = await Api().get("academies/" + id + "/publicid");
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async rating(id) {
    try {
      const response = await Api().get("academies/" + id + "/rating");
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async addAcademy(data) {
    const response = await Api().post("academies/add", data);
    return response.data;
  },
  async members(id) {
    try {
      const response = await Api().get("academies/members/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async affiliations() {
    try {
      const response = await Api().get("academies/distinct/affiliations");
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async localAffiliations() {
    try {
      const response = await Api().get("academies/distinct/local_affiliations");
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async uploadAcademyImage(id, formData) {
    try {
      const response = await Api().post("academies/image/" + id, formData);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async claim(id, data) {
    try {
      const response = await Api().post("academies/claim/" + id, data);
      return response.data;
    } catch (err) {
      console.log("Logging error");
      console.log(err);
    }
    return null;
  },
  async updateAcademy(id, data) {
    try {
      const response = await Api().patch("academies/" + id, data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async updateAcademyManagers(id, data) {
    try {
      const response = await Api().patch("academies/managers/" + id, data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async like(id) {
    const response = await Api().patch("academies/like/" + id);
    return response.data;
  },
  async unlike(id) {
    const response = await Api().patch("academies/unlike/" + id);
    return response.data;
  },
  async validateAddress(address) {
    const response = await Api().get(
      "academies/validate/address/" + encodeURIComponent(address)
    );
    return response.data;
  },
  async viewLikes(id) {
    const response = await Api().get("academies/likes/" + id);
    return response.data;
  },
  async canPost(id) {
    const response = await Api().get("academies/" + id + "/users/can_post");
    return response.data;
  },
  async canSignWaiver(id) {
    const response = await Api().get("academies/" + id + "/users/can_sign");
    return response.data;
  },
  async academyFamily(managedAcademyId) {
    const response = await Api().get(
      "academies/managedacademy/" + managedAcademyId + "/family"
    );
    return response.data;
  },
  async academyMembers(managedAcademyId) {
    const response = await Api().get(
      "academies/managedacademy/" + managedAcademyId + "/members"
    );
    return response.data;
  },
  async findNearby(formData) {
    try {
      const response = await Api().post("academies/find/nearby/", formData);
      return response.data;
    } catch (err) {
      console.log(err);
      return null;
    }
  },
  async getMemberData(managedAcademyId, memberId) {
    const response = await Api().get(
      "academies/managedacademy/" +
        managedAcademyId +
        "/members/" +
        memberId +
        "/data"
    );
    return response.data;
  },
};
