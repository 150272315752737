<template>
  <v-container class="text-center">
    <div v-if="loading" class="text-center pa-3">
      <v-progress-circular indeterminate :size="150" :width="15" color="primary">
        <v-icon size="60">mdi-credit-card-fast-outline</v-icon>
      </v-progress-circular>
    </div>
    <div v-if="error">
      <icon-like size="400px" class="rotate-90" color="error"></icon-like>
    </div>
    <div v-if="expired" class="mt-2">
      <h1>
        <ClockIcon class="hero-icon" color="error" size="40"></ClockIcon> {{$t('message.link_expired')}}
      </h1>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="goBack()">{{$t('message.back')}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </div>
  </v-container>
</template>

<script>
import PaymentsService from "@/services/PaymentsService";
import IconLike from '@/components/displays/icons/IconLike.vue';
import NotificationService from "@/services/NotificationService";

export default {
  data() {
    return {
      transactionId: this.$route.params.transactionId,
      memberId: this.$route.params.transactionId,
      sessionId: this.$route.query.s,
      loading: true,
      error: false,
      expired: false,
      stripePublishableKey: null,
      stripeAccountId: this.$route.query.a,
    };
  },
  props: ["transactionData"],
  components: {
    IconLike,
  },
  created() {
    //Get transaction, if not cancelled, proceed
    if (this.transactionId) {
      PaymentsService.getUpdateSession(this.transactionId)
        .then((response) => {
          if(response.updateSession){
            this.stripeAccountId = response.stripeAccountId;
            const sessionId = response.updateSession.sessionId;
            this.expired = response.expired;
            if(!this.expired)
              this.checkout(sessionId);
            this.loading = false;
          }else{
            this.loading = false;
            this.error = true;
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.error = true;
        });
    } else if (this.sessionId) {
      this.checkout(this.sessionId);
    }
    if(this.notificationId){
      NotificationService.read(this.notificationId);
    }
  },
  methods: {
    checkout(sessionId) {
      this.stripePublishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
      console.log(this.transactionData)
      if (this.stripeAccountId) {
        // eslint-disable-next-line
        var stripe = Stripe(this.stripePublishableKey, {
          stripeAccount: this.stripeAccountId,
        });
        stripe
          .redirectToCheckout({
            sessionId: sessionId,
          })
          .then(function () {
            this.error = this.$t("message.error_generic");
            this.loading = false;
            this.showAlert("error", "Oops!", this.$t("message.error_generic"));
          });
      } else {
        this.loading = false;
        this.error = true;
      }
    }
  },
};
</script>

<style scoped>
</style>