<template>
  <div v-if="users && users.length > 0" class="user-avatars">
    <template v-for="u in users">
      <user-avatar-display
        style="display: inline-block"
        v-on:click.native="goToUser(u._id)"
        :key="u._id"
        size="25"
        :title="u.displayName ? u.displayName : u.fName + ' ' + u.lName"
        :user="u"
      />
    </template>
  </div>
</template>
<script>
import UserAvatarDisplay from "@/components/displays/UserAvatarDisplay";

export default {
  data() {
    return {};
  },
  components: {
    UserAvatarDisplay,
  },
  props: ["users"],
  created() {},
  mounted() {},
};
</script>

<style scoped>
</style>