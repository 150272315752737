<template>
  <div class="belt-display-wrapper" :title="beltDisplay+' belt'">
    <span v-if="!hideImage">
      <BeltSVG v-if="!isBlackBelt" :colors="beltColors" :belt="belt" class="belt-display mb-1 mr-2" :style="imageSize" />
      <BlackBeltSVG v-else :colors="beltColors" :belt="belt" class="belt-display mb-1 mr-2" :style="imageSize" />
    </span>
    <span v-if="addText != false">{{beltDisplay}}</span>
  </div>
</template>


<script>
import BeltSVG from '@/components/displays/BeltSVG.vue';
import BlackBeltSVG from '@/components/displays/BlackBeltSVG.vue';

export default {
  components: {
    BeltSVG,
    BlackBeltSVG,
  },

  props: ["belt", "showText", "size", "hideImage"],
  data() {
    return {
      addText: false,
      beltString: null,
      imageSize: "height: 15px;",
      beltList: [
        // Monthly System
        // White Belt Monthly
{
  text: this.$t("message.white_m_1"),
  value: "white_m_1",
  colors: ["#FFFFFF", "#000000", "#000000", "#000000", "#000000", "#FFFFFF"],
},
{
  text: this.$t("message.white_m_2"),
  value: "white_m_2",
  colors: ["#FFFFFF", "#000000", "#000000", "#000000", "#FFFFFF", "#FFFFFF"],
},
{
  text: this.$t("message.white_m_3"),
  value: "white_m_3",
  colors: ["#FFFFFF", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
},
{
  text: this.$t("message.white_m_4"),
  value: "white_m_4",
  colors: ["#FFFFFF", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
},
{
  text: this.$t("message.white_m_5"),
  value: "white_m_5",
  colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
},
{
  text: this.$t("message.white_m_6"),
  value: "white_m_6",
  colors: ["#FFFFFF", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
},

// Gray/White Belt Monthly
{
  text: this.$t("message.gray_white_m_7"),
  value: "gray_white_m_7",
  colors: ["#808080", "#000000", "#000000", "#000000", "#000000", "#FFFFFF"],
},
{
  text: this.$t("message.gray_white_m_8"),
  value: "gray_white_m_8",
  colors: ["#808080", "#000000", "#000000", "#000000", "#FFFFFF", "#FFFFFF"],
},
{
  text: this.$t("message.gray_white_m_9"),
  value: "gray_white_m_9",
  colors: ["#808080", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
},
{
  text: this.$t("message.gray_white_m_10"),
  value: "gray_white_m_10",
  colors: ["#808080", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
},
{
  text: this.$t("message.gray_white_m_11"),
  value: "gray_white_m_11",
  colors: ["#808080", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
},
{
  text: this.$t("message.gray_white_m_12"),
  value: "gray_white_m_12",
  colors: ["#808080", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
},

// Gray Belt Monthly
{
  text: this.$t("message.gray_m_1"),
  value: "gray_m_1",
  colors: ["#808080", "#000000", "#000000", "#000000", "#000000", "#808080"],
},
{
  text: this.$t("message.gray_m_2"),
  value: "gray_m_2",
  colors: ["#808080", "#000000", "#000000", "#000000", "#FFFFFF", "#808080"],
},
{
  text: this.$t("message.gray_m_3"),
  value: "gray_m_3",
  colors: ["#808080", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#808080"],
},
{
  text: this.$t("message.gray_m_4"),
  value: "gray_m_4",
  colors: ["#808080", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#808080"],
},
{
  text: this.$t("message.gray_m_5"),
  value: "gray_m_5",
  colors: ["#808080", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#808080"],
},
{
  text: this.$t("message.gray_m_6"),
  value: "gray_m_6",
  colors: ["#808080", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#808080"],
},
{
  text: this.$t("message.gray_m_7"),
  value: "gray_m_7",
  colors: ["#808080", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#808080"],
},
{
  text: this.$t("message.gray_m_8"),
  value: "gray_m_8",
  colors: ["#808080", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#808080"],
},
{
  text: this.$t("message.gray_m_9"),
  value: "gray_m_9",
  colors: ["#808080", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#808080"],
},
{
  text: this.$t("message.gray_m_10"),
  value: "gray_m_10",
  colors: ["#808080", "#E2D600", "#FF0000", "#FF0000", "#FF0000", "#808080"],
},
{
  text: this.$t("message.gray_m_11"),
  value: "gray_m_11",
  colors: ["#808080", "#E2D600", "#E2D600", "#FF0000", "#FF0000", "#808080"],
},
{
  text: this.$t("message.gray_m_12"),
  value: "gray_m_12",
  colors: ["#808080", "#E2D600", "#E2D600", "#E2D600", "#FF0000", "#808080"],
},

// Gray/Black Belt Monthly
{
  text: this.$t("message.gray_black_m_1"),
  value: "gray_black_m_1",
  colors: ["#808080", "#000000", "#000000", "#000000", "#000000", "#000000"],
},
{
  text: this.$t("message.gray_black_m_2"),
  value: "gray_black_m_2",
  colors: ["#808080", "#000000", "#000000", "#000000", "#FFFFFF", "#000000"],
},
{
  text: this.$t("message.gray_black_m_3"),
  value: "gray_black_m_3",
  colors: ["#808080", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#000000"],
},
{
  text: this.$t("message.gray_black_m_4"),
  value: "gray_black_m_4",
  colors: ["#808080", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
},
{
  text: this.$t("message.gray_black_m_5"),
  value: "gray_black_m_5",
  colors: ["#808080", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
},
{
  text: this.$t("message.gray_black_m_6"),
  value: "gray_black_m_6",
  colors: ["#808080", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
},
{
  text: this.$t("message.gray_black_m_7"),
  value: "gray_black_m_7",
  colors: ["#808080", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#000000"],
},
{
  text: this.$t("message.gray_black_m_8"),
  value: "gray_black_m_8",
  colors: ["#808080", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#000000"],
},
{
  text: this.$t("message.gray_black_m_9"),
  value: "gray_black_m_9",
  colors: ["#808080", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#000000"],
},
{
  text: this.$t("message.gray_black_m_10"),
  value: "gray_black_m_10",
  colors: ["#808080", "#E2D600", "#FF0000", "#FF0000", "#FF0000", "#000000"],
},
{
  text: this.$t("message.gray_black_m_11"),
  value: "gray_black_m_11",
  colors: ["#808080", "#E2D600", "#E2D600", "#FF0000", "#FF0000", "#000000"],
},
{
  text: this.$t("message.gray_black_m_12"),
  value: "gray_black_m_12",
  colors: ["#808080", "#E2D600", "#E2D600", "#E2D600", "#FF0000", "#000000"],
},



        // Yellow White Belt Monthly
        {
          text: this.$t("message.yellow_white_m_1"),
          value: "yellow_white_m_1",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#000000", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_2"),
          value: "yellow_white_m_2",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_3"),
          value: "yellow_white_m_3",
          colors: ["#E2D600", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_4"),
          value: "yellow_white_m_4",
          colors: ["#E2D600", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_5"),
          value: "yellow_white_m_5",
          colors: ["#E2D600", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_6"),
          value: "yellow_white_m_6",
          colors: ["#E2D600", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_7"),
          value: "yellow_white_m_7",
          colors: ["#E2D600", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_8"),
          value: "yellow_white_m_8",
          colors: ["#E2D600", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_9"),
          value: "yellow_white_m_9",
          colors: ["#E2D600", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_10"),
          value: "yellow_white_m_10",
          colors: ["#E2D600", "#FFA500", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_11"),
          value: "yellow_white_m_11",
          colors: ["#E2D600", "#FFA500", "#FFA500", "#FF0000", "#FF0000", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white_m_12"),
          value: "yellow_white_m_12",
          colors: ["#E2D600", "#FFA500", "#FFA500", "#FFA500", "#FF0000", "#FFFFFF"],
        },
        //Yellow Belt Monthly
        {
          text: this.$t("message.yellow_m_1"),
          value: "yellow_m_1",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#000000", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_2"),
          value: "yellow_m_2",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#FFFFFF", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_3"),
          value: "yellow_m_3",
          colors: ["#E2D600", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_4"),
          value: "yellow_m_4",
          colors: ["#E2D600", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_5"),
          value: "yellow_m_5",
          colors: ["#E2D600", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_6"),
          value: "yellow_m_6",
          colors: ["#E2D600", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_7"),
          value: "yellow_m_7",
          colors: ["#E2D600", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_8"),
          value: "yellow_m_8",
          colors: ["#E2D600", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_9"),
          value: "yellow_m_9",
          colors: ["#E2D600", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_10"),
          value: "yellow_m_10,",
          colors: ["#E2D600", "#FFA500", "#FF0000", "#FF0000", "#FF0000", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_11"),
          value: "yellow_m_11",
          colors: ["#E2D600", "#FFA500", "#FFA500", "#FF0000", "#FF0000", "#E2D600"],
        },
        {
          text: this.$t("message.yellow_m_12"),
          value: "yellow_m_12",
          colors: ["#E2D600", "#FFA500", "#FFA500", "#FFA500", "#FF0000", "#E2D600"],
        },
        // Yellow black Belt Montly
        {
          text: this.$t("message.yellow_black_m_1"),
          value: "yellow_black_m_1",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#000000", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_2"),
          value: "yellow_black_m_2",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_3"),
          value: "yellow_black_m_3",
          colors: ["#E2D600", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_4"),
          value: "yellow_black_m_4",
          colors: ["#E2D600", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_5"),
          value: "yellow_black_m_5",
          colors: ["#E2D600", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_6"),
          value: "yellow_black_m_6",
          colors: ["#E2D600", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_7"),
          value: "yellow_black_m_7",
          colors: ["#E2D600", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_8"),
          value: "yellow_black_m_8",
          colors: ["#E2D600", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_9"),
          value: "yellow_black_m_9",
          colors: ["#E2D600", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_10"),
          value: "yellow_black_m_10",
          colors: ["#E2D600", "#FFA500", "#FF0000", "#FF0000", "#FF0000", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_11"),
          value: "yellow_black_m_11",
          colors: ["#E2D600", "#FFA500", "#FFA500", "#FF0000", "#FF0000", "#000000"],
        },
        {
          text: this.$t("message.yellow_black_m_12"),
          value: "yellow_black_m_12",
          colors: ["#E2D600", "#FFA500", "#FFA500", "#FFA500", "#FF0000", "#000000"],
        },
        // Orange/White Belt Monthly
        {
          text: this.$t("message.orange_white_m_1"),
          value: "orange_white_m_1",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#000000", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_2"),
          value: "orange_white_m_2",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_3"),
          value: "orange_white_m_3",
          colors: ["#FFA500", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_4"),
          value: "orange_white_m_4",
          colors: ["#FFA500", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_5"),
          value: "orange_white_m_5",
          colors: ["#FFA500", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_6"),
          value: "orange_white_m_6",
          colors: ["#FFA500", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_7"),
          value: "orange_white_m_7",
          colors: ["#FFA500", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_8"),
          value: "orange_white_m_8",
          colors: ["#FFA500", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_9"),
          value: "orange_white_m_9",
          colors: ["#FFA500", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_10"),
          value: "orange_white_m_10",
          colors: ["#FFA500", "#008000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_11"),
          value: "orange_white_m_11",
          colors: ["#FFA500", "#008000", "#008000", "#FF0000", "#FF0000", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white_m_12"),
          value: "orange_white_m_12",
          colors: ["#FFA500", "#008000", "#008000", "#008000", "#FF0000", "#FFFFFF"],
        },
        // Orange Belt Monthly
        {
          text: this.$t("message.orange_m_1"),
          value: "orange_m_1",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#000000", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_2"),
          value: "orange_m_2",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#FFFFFF", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_3"),
          value: "orange_m_3",
          colors: ["#FFA500", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_4"),
          value: "orange_m_4",
          colors: ["#FFA500", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_5"),
          value: "orange_m_5",
          colors: ["#FFA500", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_6"),
          value: "orange_m_6",
          colors: ["#FFA500", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_7"),
          value: "orange_m_7",
          colors: ["#FFA500", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_8"),
          value: "orange_m_8",
          colors: ["#FFA500", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_9"),
          value: "orange_m_9",
          colors: ["#FFA500", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_10"),
          value: "orange_m_10",
          colors: ["#FFA500", "#008000", "#FF0000", "#FF0000", "#FF0000", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_11"),
          value: "orange_m_11",
          colors: ["#FFA500", "#008000", "#008000", "#FF0000", "#FF0000", "#FFA500"],
        },
        {
          text: this.$t("message.orange_m_12"),
          value: "orange_m_12",
          colors: ["#FFA500", "#008000", "#008000", "#008000", "#FF0000", "#FFA500"],
        },
        // Orange/Black Belt Monthly
        {
          text: this.$t("message.orange_black_m_1"),
          value: "orange_black_m_1",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#000000", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_2"),
          value: "orange_black_m_2",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_3"),
          value: "orange_black_m_3",
          colors: ["#FFA500", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_4"),
          value: "orange_black_m_4",
          colors: ["#FFA500", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_5"),
          value: "orange_black_m_5",
          colors: ["#FFA500", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_6"),
          value: "orange_black_m_6",
          colors: ["#FFA500", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_7"),
          value: "orange_black_m_7",
          colors: ["#FFA500", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_8"),
          value: "orange_black_m_8",
          colors: ["#FFA500", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_9"),
          value: "orange_black_m_9",
          colors: ["#FFA500", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_10"),
          value: "orange_black_m_10",
          colors: ["#FFA500", "#008000", "#FF0000", "#FF0000", "#FF0000", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_11"),
          value: "orange_black_m_11",
          colors: ["#FFA500", "#008000", "#008000", "#FF0000", "#FF0000", "#000000"],
        },
        {
          text: this.$t("message.orange_black_m_12"),
          value: "orange_black_m_12",
          colors: ["#FFA500", "#008000", "#008000", "#008000", "#FF0000", "#000000"],
        },
        // Green / white Belt Monthly
        // Green/White Belt Monthly
        {
          text: this.$t("message.green_white_m_1"),
          value: "green_white_m_1",
          colors: ["#008000", "#000000", "#000000", "#000000", "#000000", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_2"),
          value: "green_white_m_2",
          colors: ["#008000", "#000000", "#000000", "#000000", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_3"),
          value: "green_white_m_3",
          colors: ["#008000", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_4"),
          value: "green_white_m_4",
          colors: ["#008000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_5"),
          value: "green_white_m_5",
          colors: ["#008000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_6"),
          value: "green_white_m_6",
          colors: ["#008000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_7"),
          value: "green_white_m_7",
          colors: ["#008000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_8"),
          value: "green_white_m_8",
          colors: ["#008000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_9"),
          value: "green_white_m_9",
          colors: ["#008000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_10"),
          value: "green_white_m_10",
          colors: ["#008000", "#0000FF", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_11"),
          value: "green_white_m_11",
          colors: ["#008000", "#0000FF", "#0000FF", "#FF0000", "#FF0000", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white_m_12"),
          value: "green_white_m_12",
          colors: ["#008000", "#0000FF", "#0000FF", "#0000FF", "#FF0000", "#FFFFFF"],
        },
        // Green Belt Monthly

        {
          text: this.$t("message.green_m_1"),
          value: "green_m_1",
          colors: ["#008000", "#000000", "#000000", "#000000", "#000000", "#008000"],
        },
        {
          text: this.$t("message.green_m_2"),
          value: "green_m_2",
          colors: ["#008000", "#000000", "#000000", "#000000", "#FFFFFF", "#008000"],
        },
        {
          text: this.$t("message.green_m_3"),
          value: "green_m_3",
          colors: ["#008000", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#008000"],
        },
        {
          text: this.$t("message.green_m_4"),
          value: "green_m_4",
          colors: ["#008000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#008000"],
        },
        {
          text: this.$t("message.green_m_5"),
          value: "green_m_5",
          colors: ["#008000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#008000"],
        },
        {
          text: this.$t("message.green_m_6"),
          value: "green_m_6",
          colors: ["#008000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#008000"],
        },
        {
          text: this.$t("message.green_m_7"),
          value: "green_m_7",
          colors: ["#008000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#008000"],
        },
        {
          text: this.$t("message.green_m_8"),
          value: "green_m_8",
          colors: ["#008000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#008000"],
        },
        {
          text: this.$t("message.green_m_9"),
          value: "green_m_9",
          colors: ["#008000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#008000"],
        },
        {
          text: this.$t("message.green_m_10"),
          value: "green_m_10",
          colors: ["#008000", "#0000FF", "#FF0000", "#FF0000", "#FF0000", "#008000"],
        },
        {
          text: this.$t("message.green_m_11"),
          value: "green_m_11",
          colors: ["#008000", "#0000FF", "#0000FF", "#FF0000", "#FF0000", "#008000"],
        },
        {
          text: this.$t("message.green_m_12"),
          value: "green_m_12",
          colors: ["#008000", "#0000FF", "#0000FF", "#0000FF", "#FF0000", "#008000"],
        },
        // Green/Black Belt Weekly
        // Green/Black Belt Monthly
        {
          text: this.$t("message.green_black_m_1"),
          value: "green_black_m_1",
          colors: ["#008000", "#000000", "#000000", "#000000", "#000000", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_2"),
          value: "green_black_m_2",
          colors: ["#008000", "#000000", "#000000", "#000000", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_3"),
          value: "green_black_m_3",
          colors: ["#008000", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_4"),
          value: "green_black_m_4",
          colors: ["#008000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_5"),
          value: "green_black_m_5",
          colors: ["#008000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_6"),
          value: "green_black_m_6",
          colors: ["#008000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_7"),
          value: "green_black_m_7",
          colors: ["#008000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_8"),
          value: "green_black_m_8",
          colors: ["#008000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_9"),
          value: "green_black_m_9",
          colors: ["#008000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_10"),
          value: "green_black_m_10",
          colors: ["#008000", "#0000FF", "#FF0000", "#FF0000", "#FF0000", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_11"),
          value: "green_black_m_11",
          colors: ["#008000", "#0000FF", "#0000FF", "#FF0000", "#FF0000", "#000000"],
        },
        {
          text: this.$t("message.green_black_m_12"),
          value: "green_black_m_12",
          colors: ["#008000", "#0000FF", "#0000FF", "#0000FF", "#FF0000", "#000000"],
        },
        // White Belt 
        {
          text: this.$t("message.white"),
          value: "white",
          colors: ["#FFFFFF", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#FFFFFF"],
        },
        {
          text: this.$t("message.white1"),
          value: "white1",
          colors: ["#FFFFFF", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.white2"),
          value: "white2",
          colors: ["#FFFFFF", "#OOOOOO", "#OOOOOO", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.white3"),
          value: "white3",
          colors: ["#FFFFFF", "#OOOOOO", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.white4"),
          value: "white4",
          colors: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        // Gray/White Belt
        {
          text: this.$t("message.gray_white"),
          value: "gray_white",
          colors: ["#929497", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#FFFFFF"],
        },
        {
          text: this.$t("message.gray_white1"),
          value: "gray_white1",
          colors: ["#929497", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.gray_white2"),
          value: "gray_white2",
          colors: ["#929497", "#OOOOOO", "#OOOOOO", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.gray_white3"),
          value: "gray_white3",
          colors: ["#929497", "#OOOOOO", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.gray_white4"),
          value: "gray_white4",
          colors: ["#929497", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        
        // Gray Belt
        {
          text: this.$t("message.gray"),
          value: "gray",
          colors: ["#929497", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#929497"],
        },
        {
          text: this.$t("message.gray1"),
          value: "gray1",
          colors: ["#929497", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#FFFFFF", "#929497"],
        },
        {
          text: this.$t("message.gray2"),
          value: "gray2",
          colors: ["#929497", "#OOOOOO", "#OOOOOO", "#FFFFFF", "#FFFFFF", "#929497"],
        },
        {
          text: this.$t("message.gray3"),
          value: "gray3",
          colors: ["#929497", "#OOOOOO", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#929497"],
        },
        {
          text: this.$t("message.gray4"),
          value: "gray4",
          colors: ["#929497", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#929497"],
        },
        // Gray/Black Belt
        {
          text: this.$t("message.gray_black"),
          value: "gray_black",
          colors: ["#929497", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#000000"],
        },
        {
          text: this.$t("message.gray_black1"),
          value: "gray_black1",
          colors: ["#929497", "#OOOOOO", "#OOOOOO", "#OOOOOO", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.gray_black2"),
          value: "gray_black2",
          colors: ["#929497", "#OOOOOO", "#OOOOOO", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.gray_black3"),
          value: "gray_black3",
          colors: ["#929497", "#OOOOOO", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.gray_black4"),
          value: "gray_black4",
          colors: ["#929497", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        // Yellow/White Belt
        {
          text: this.$t("message.yellow_white"),
          value: "yellow_white",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#000000", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white1"),
          value: "yellow_white1",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white2"),
          value: "yellow_white2",
          colors: ["#E2D600", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white3"),
          value: "yellow_white3",
          colors: ["#E2D600", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.yellow_white4"),
          value: "yellow_white4",
          colors: ["#E2D600", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        // Yellow Belt
        {
          text: this.$t("message.yellow"),
          value: "yellow",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#000000", "#E2D600"],
        },
        {
          text: this.$t("message.yellow1"),
          value: "yellow1",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#FFFFFF", "#E2D600"],
        },
        {
          text: this.$t("message.yellow2"),
          value: "yellow2",
          colors: ["#E2D600", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#E2D600"],
        },
        {
          text: this.$t("message.yellow3"),
          value: "yellow3",
          colors: ["#E2D600", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#E2D600"],
        },
        {
          text: this.$t("message.yellow4"),
          value: "yellow4",
          colors: ["#E2D600", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#E2D600"],
        },
        // Yellow/Black Belt
        {
          text: this.$t("message.yellow_black"),
          value: "yellow_black",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#000000", "#000000"],
        },
        {
          text: this.$t("message.yellow_black1"),
          value: "yellow_black1",
          colors: ["#E2D600", "#000000", "#000000", "#000000", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.yellow_black2"),
          value: "yellow_black2",
          colors: ["#E2D600", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.yellow_black3"),
          value: "yellow_black3",
          colors: ["#E2D600", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.yellow_black4"),
          value: "yellow_black4",
          colors: ["#E2D600", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        // Orange/White Belt
        {
          text: this.$t("message.orange_white"),
          value: "orange_white",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#000000", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white1"),
          value: "orange_white1",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white2"),
          value: "orange_white2",
          colors: ["#FFA500", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white3"),
          value: "orange_white3",
          colors: ["#FFA500", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.orange_white4"),
          value: "orange_white4",
          colors: ["#FFA500", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        // Orange Belt
        {
          text: this.$t("message.orange"),
          value: "orange",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#000000", "#FFA500"],
        },
        {
          text: this.$t("message.orange1"),
          value: "orange1",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#FFFFFF", "#FFA500"],
        },
        {
          text: this.$t("message.orange2"),
          value: "orange2",
          colors: ["#FFA500", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFA500"],
        },
        {
          text: this.$t("message.orange3"),
          value: "orange3",
          colors: ["#FFA500", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFA500"],
        },
        {
          text: this.$t("message.orange4"),
          value: "orange4",
          colors: ["#FFA500", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFA500"],
        },
        // Orange/Black Belt
        {
          text: this.$t("message.orange_black"),
          value: "orange_black",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#000000", "#000000"],
        },
        {
          text: this.$t("message.orange_black1"),
          value: "orange_black1",
          colors: ["#FFA500", "#000000", "#000000", "#000000", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.orange_black2"),
          value: "orange_black2",
          colors: ["#FFA500", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.orange_black3"),
          value: "orange_black3",
          colors: ["#FFA500", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.orange_black4"),
          value: "orange_black4",
          colors: ["#FFA500", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        // Green/White Belt
        {
          text: this.$t("message.green_white"),
          value: "green_white",
          colors: ["#008000", "#000000", "#000000", "#000000", "#000000", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white1"),
          value: "green_white1",
          colors: ["#008000", "#000000", "#000000", "#000000", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white2"),
          value: "green_white2",
          colors: ["#008000", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white3"),
          value: "green_white3",
          colors: ["#008000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.green_white4"),
          value: "green_white4",
          colors: ["#008000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        // Green Belt
        {
          text: this.$t("message.green"),
          value: "green",
          colors: ["#008000", "#000000", "#000000", "#000000", "#000000", "#008000"],
        },
        {
          text: this.$t("message.green1"),
          value: "green1",
          colors: ["#008000", "#000000", "#000000", "#000000", "#FFFFFF", "#008000"],
        },
        {
          text: this.$t("message.green2"),
          value: "green2",
          colors: ["#008000", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#008000"],
        },
        {
          text: this.$t("message.green3"),
          value: "green3",
          colors: ["#008000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#008000"],
        },
        {
          text: this.$t("message.green4"),
          value: "green4",
          colors: ["#008000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#008000"],
        },
        // Green/Black Belt
        {
          text: this.$t("message.green_black"),
          value: "green_black",
          colors: ["#008000", "#000000", "#000000", "#000000", "#000000", "#000000"],
        },
        {
          text: this.$t("message.green_black1"),
          value: "green_black1",
          colors: ["#008000", "#000000", "#000000", "#000000", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.green_black2"),
          value: "green_black2",
          colors: ["#008000", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.green_black3"),
          value: "green_black3",
          colors: ["#008000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        {
          text: this.$t("message.green_black4"),
          value: "green_black4",
          colors: ["#008000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#000000"],
        },
        // Blue Belt
        {
          text: this.$t("message.blue"),
          value: "blue",
          colors: ["#0000FF", "#000000", "#000000", "#000000", "#000000", "#0000FF"],
        },
        {
          text: this.$t("message.blue1"),
          value: "blue1",
          colors: ["#0000FF", "#000000", "#000000", "#000000", "#FFFFFF", "#0000FF"],
        },
        {
          text: this.$t("message.blue2"),
          value: "blue2",
          colors: ["#0000FF", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#0000FF"],
        },
        {
          text: this.$t("message.blue3"),
          value: "blue3",
          colors: ["#0000FF", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#0000FF"],
        },
        {
          text: this.$t("message.blue4"),
          value: "blue4",
          colors: ["#0000FF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#0000FF"],
        },
        // Purple Belt
        {
          text: this.$t("message.purple"),
          value: "purple",
          colors: ["#800080", "#000000", "#000000", "#000000", "#000000", "#800080"],
        },
        {
          text: this.$t("message.purple1"),
          value: "purple1",
          colors: ["#800080", "#000000", "#000000", "#000000", "#FFFFFF", "#800080"],
        },
        {
          text: this.$t("message.purple2"),
          value: "purple2",
          colors: ["#800080", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#800080"],
        },
        {
          text: this.$t("message.purple3"),
          value: "purple3",
          colors: ["#800080", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#800080"],
        },
        {
          text: this.$t("message.purple4"),
          value: "purple4",
          colors: ["#800080", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#800080"],
        },
        // Brown Belt
        {
          text: this.$t("message.brown"),
          value: "brown",
          colors: ["#724100", "#000000", "#000000", "#000000", "#000000", "#724100"],
        },
        {
          text: this.$t("message.brown1"),
          value: "brown1",
          colors: ["#724100", "#000000", "#000000", "#000000", "#FFFFFF", "#724100"],
        },
        {
          text: this.$t("message.brown2"),
          value: "brown2",
          colors: ["#724100", "#000000", "#000000", "#FFFFFF", "#FFFFFF", "#724100"],
        },
        {
          text: this.$t("message.brown3"),
          value: "brown3",
          colors: ["#724100", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#724100"],
        },
        {
          text: this.$t("message.brown4"),
          value: "brown4",
          colors: ["#724100", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#724100"],
        },
        // Black Belt
        {
          text: this.$t("message.black"),
          value: "black",
          colors: ["#000000", "#000000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000"],
        },
        {
          text: this.$t("message.black1"),
          value: "black1",
          colors: ["#000000", "#000000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF"],
        },
        {
          text: this.$t("message.black2"),
          value: "black2",
          colors: ["#000000", "#000000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.black3"),
          value: "black3",
          colors: ["#000000", "#000000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.black4"),
          value: "black4",
          colors: ["#000000", "#000000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.black5"),
          value: "black5",
          colors: ["#000000", "#000000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.black6"),
          value: "black6",
          colors: ["#000000", "#000000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        // Red Belt
        {
          text: this.$t("message.black7"),
          value: "black-red-black7",
          colors: ["#000000", "#FF0000", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.black8"),
          value: "black-red-white8",
          colors: ["#FFFFFF", "#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
        {
          text: this.$t("message.black9"),
          value: "black-red9",
          colors: ["#FF0000", "#FF0000", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        },
      ]
    };
  },
  created() {
    if (this.size) this.imageSize = "height: " + this.size + "px;";
    if (this.showText == null || this.showText == undefined)
      this.addText = true;
    else this.addText = new Boolean(this.showText);
    if (this.belt) this.beltString = this.belt.toLowerCase();
    else this.beltString = "white";
  },
  computed: {
    isBlackBelt() {
      if (!this.belt)
        return false;
      else
        return this.belt.startsWith('black');
    },
    beltDisplay() {
      const beltData = this.beltList.find(b => b.value == this.belt);
      return beltData ? beltData.text : this.$t("message.white");
    },
    beltColors() {
      const beltData = this.beltList.find(b => b.value == this.belt);
      return beltData ? beltData.colors : ["#FFFFFF"];
    }
  }
};
</script>

<style scoped>
.belt-display-wrapper {
  display: inline-block;
}
.belt-display {
  vertical-align: middle;
}
</style>
