import Api from "@/services/Api";

export default {
  async filter(data) {
    const response = await Api().post("news/filter", data);
    return response.data;
  },
  async sources() {
    const response = await Api().get("news/sources");
    return response.data;
  },
  async share(data) {
    const response = await Api().post("news/share", data);
    return response.data;
  },
  async recordClick(id, data) {
    const response = await Api().patch("news/sources/" + id + "/click", data);
    return response.data;
  },
  async random(data) {
    const response = await Api().post("news/random", data);
    return response.data;
  }
};
