<template>
  <div>
    <v-progress-circular indeterminate color="white"></v-progress-circular>
    <v-progress-circular indeterminate color="blue"></v-progress-circular>
    <v-progress-circular indeterminate color="purple"></v-progress-circular>
    <v-progress-circular indeterminate color="brown"></v-progress-circular>  
    <v-progress-circular indeterminate color="black"></v-progress-circular>
  </div>
</template>

<script>
export default {
    data () {
        return {
        }
    }
}
</script>

<style scoped>

</style>
