import Api from "@/services/Api";

export default {
  async getLineage(masterName) {
    const response = await Api().post("lineages/lineage", {
      master: masterName,
    });
    return response.data;
  },
  async getLineageById(id, masterName) {
    const response = await Api().post("lineages/lineage/" + id, {
      master: masterName,
    });
    return response.data;
  },
  async getNode(id) {
    const response = await Api().get("lineages/node/" + id);
    return response.data;
  },
  async masters(filter) {
    const response = await Api().post("lineages/masters", filter);
    return response.data;
  },
  async updateUserMaster(userId) {
    const response = await Api().patch("lineages/user/" + userId);
    return response.data;
  },
  async changeUserMaster(masterName) {
    const response = await Api().patch("lineages/user", {
      masterName: masterName,
    });
    return response.data;
  },
  async updateUserMasterId(masterId) {
    const response = await Api().patch("lineages/user", {
      masterId: masterId,
    });
    return response.data;
  },
  async treeNodes() {
    const response = await Api().get("lineages/tree/nodes");
    return response.data;
  },
  async lineageTree(root) {
    const response = await Api().get(
      "lineages/tree?" + (root ? "root=" + root : "")
    );
    return response.data;
  },
  async children(nodeId, root) {
    const response = await Api().get(
      "lineages/tree/children/" + nodeId + "?" + (root ? "root=" + root : "")
    );
    return response.data;
  },
  async sendQuestions(data) {
    const response = await Api().post("lineages/questions/send", data);
    return response.data;
  },
};
