<template>
  <div class="about">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <v-card-text class="text-center pb-0 mb-0">
        <img :src="eventImage" style="max-width:300px;max-height:300px;">
      </v-card-text>
      <v-card-actions class="pa-0 mt-0">
        <v-spacer></v-spacer>
        <image-upload 
          :objId="'new_user_event'"
          :vm="this" 
          :operation="'return_image'"
          :success-function="successFunction">
        </image-upload>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2 pa-2">
      <v-text-field
        outlined
        dense
        v-model="event.title"
        :label="$t('message.title')"
        required
      ></v-text-field>
      <EventTypes v-model="event.type"></EventTypes>
      <LocationsAutocomplete v-model="location" :display="event.location"></LocationsAutocomplete>
      <span v-if="missingCityError" :style="'color:'+getColor('red')">{{$t('message.city_missing')}}</span>
      <v-text-field
        outlined
        dense
        v-model="event.url"
        :label="$t('message.website')"
      ></v-text-field>
      <v-text-field
        outlined
        dense
        v-if="event.type == 'type_virtual'"
        v-model="event.videoConferenceUrl"
        :label="$t('message.video_conference_url')"
      ></v-text-field>
      <v-switch v-model="freeEvent" :label="$t('message.free_event')"></v-switch>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
      <v-card-title class="text-subtitle-1">
        {{$t('message.date_time')}}
      </v-card-title>
      <v-card-text v-if="event.type == 'type_openmat'">
        <v-select
          :items="days"
          item-text="display"
          item-value="value"
          :label="$t('message.repeat_day')"
          v-model="event.recurrence" 
          @click="updateRecurrence()"
          clearable
          dense
          outlined
        ></v-select>
        <TimePicker
          :show-time="true"
          :hide-date="true"
          v-model="event.eventDate" 
          :past-event="false">
        </TimePicker>
      </v-card-text>
      <v-card-text v-if="event.type == 'type_openmat' && !event.recurrence">
        <span>{{$t('message.or_select_date')}}</span>
      </v-card-text>
      <v-card-text v-if="!event.recurrence">
        <date-picker 
          :show-time="true"
          v-model="event.eventDate" 
          :past-event="false">
        </date-picker>
      </v-card-text>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
      <v-card-text>
        <v-textarea
          outlined
          rows="3"
          name="input-7-4"
          :label="$t('message.description')"
          v-model="event.description"
        ></v-textarea>
      </v-card-text>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
      <v-card-actions>
        <v-btn color="primary" @click="createEvent" :disabled="!goodToProceed() || disbaleCreate">
          {{$t('message.create_event')}}
        </v-btn>
        <v-btn color="secondary" @click="goBack()">
          {{$t('message.cancel')}}
        </v-btn>
      </v-card-actions>
    </v-sheet>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import CityService from "@/services/CityService";
import DatePicker from "@/components/inputs/DateTimePicker2.vue";
import TimePicker from "@/components/inputs/DateTimePicker.vue";
import ImageUpload from "@/components/inputs/ImageUploadCroppie.vue";
import EventTypes from "@/components/inputs/EventTypesSelect.vue";
import LocationsAutocomplete from "@/components/inputs/LocationsAutocomplete.vue";

export default {
  data() {
    return {
      loading: false,
      freeEvent: false,
      disbaleCreate: false,
      missingCityError: null,
      hideDateOnPicker: false,
      event: null,
      location: null,
      dateFrom: null,
      eventImage: this.assets('logo-circle.png'),
      days: this.getWeekDaysTranslated(),
      successFunction: function(uploadedFile, vm) {
        if (vm && uploadedFile) {
          vm.eventImage = this.userImg(uploadedFile);
          vm.event.image = uploadedFile;
        }
      }
    };
  },
  created() {
    this.event = {
      userCreator: this.$store.state.user._id,
    };
  },
  components: {
    ImageUpload,
    LocationsAutocomplete,
    DatePicker,
    TimePicker,
    EventTypes
  },
  watch: {
    location: {
      handler: function(newValue) {
        if(newValue && !newValue.city){
          this.missingCityError = true;
        }else{
          this.event.geolocated = newValue;
          this.updateTimeZone(newValue);
          this.missingCityError = false;
        }
      }
    }
  },
  methods: {
    updateTimeZone(values){
      if(values && values.longitude && values.latitude){
      CityService.timeZone({
        longitude: values.longitude,
        latitude: values.latitude
      })
        .then(data => {
          if(data && data.timeZone)
            this.event.timeZone = data.timeZone;
        });
      }
    },
    getNextDay(day){
      let num = 0;
      if (day == "monday") num = 1;
      else if (day == "tuesday") num =  2;
      else if (day == "wednesday") num =  3;
      else if (day == "thursday") num =  4;
      else if (day == "friday") num =  5;
      else if (day == "saturday") num =  6;
      const now = new Date();
      now.setDate(now.getDate() + ((num + (7 - now.getDay())) % 7));
      return now;
    },
    updateRecurrence(){
      if(this.event.recurrence)
        this.hideDateOnPicker = true;
      else
        this.hideDateOnPicker = false;
    },
    goodToProceed() {
      if (
        this.event &&
        this.event.title &&
        this.event.type &&
        (this.event.eventDate || this.event.recurrence) &&
        this.event.description
      ) {
        if (!this.eventId && (!this.event.geolocated || !this.event.geolocated.city ))
          return false;
        else return true;
      } else {
        return false;
      }
    },
    createEvent() {
      if (this.goodToProceed()) {
        //this.disbaleCreate = true;
        if(this.event.recurrence){
          let d = new Date(this.event.eventDate);
          d.setFullYear(d.getFullYear() + 200);
          this.event.eventDate = d;
        }
        this.event.academyCreator = this.academyId;
        if(this.freeEvent)
          this.event.free = true;
        //We have to do some adjustments if the timezone of the event is not the same one of your browser
        this.event.date = this.adjustTimeToPost(this.event.eventDate, this.event.timeZone);
        this.event.eventDate = this.event.date;
        this.event.staticDate = this.event.eventDate.toString();
        /*
        if(this.event.timeZone){
          let browserOffset = this.getBrowserOffsetMinutes(new Date());
          let eventOffset = this.getTimeZoneOffsetMinutes(this.event.date, this.event.timeZone);
          //console.log('Handling evet for ['+eventOffset/60+'] from a browser in ['+browserOffset/60+'].');
          if(browserOffset != eventOffset){
            const minDifference = browserOffset-eventOffset;
            //console.log('Date before diffig['+minDifference+']: ', this.event.date);
            this.event.date.setMinutes(this.event.date.getMinutes()+minDifference);
            this.event.eventDate = this.event.date;
            //console.log('Date after diffig['+minDifference+']: ', this.event.date);
          }
        }
        */
        EventService.saveUpdate(this.event)
          .then(data => {
            if(data && data.event)
              this.goToEvent(data.event._id);
          })
          .catch(err => {
            if(err)
              this.disbaleCreate = false;
            this.showAlert(
              "error",
              this.$t('message.oops_not_working')
            );
          });
      }
    },
  }
};
</script>

<style scoped>
</style>
