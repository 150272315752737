<template>
  <div>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-0" v-if="timerData">
      <v-alert type="warning" elevation="0" color="primary">
        Timers are not available for use in this App at the moment. <br>
        We are working on a new experience, stay Tuned!
      </v-alert>
      <v-card-actions>
        <v-text-field outlined dense v-model="timerData.name" :label="$t('message.name')"></v-text-field>
      </v-card-actions>
      <v-card-title style="margin-top: -25px">
        <span><b style="margin-right: 10px">{{$t('message.total_time')}}: </b>
          {{ totalTime }}</span>
        <v-spacer></v-spacer>
        <span class="clickable text-subtitle-1" v-if="!timerData.advanced" @click="showAdvancedOptions()">
          <v-icon class="mr-1">mdi-chevron-up</v-icon>
          {{$t('message.advanced_options')}}
        </span>
        <span class="clickable text-subtitle-1" v-else @click="clearAdvanceOptions()">
          <v-icon color="primary" class="mr-1">mdi-chevron-down</v-icon>
          {{$t('message.advanced_options')}}
        </span>
      </v-card-title>
      <v-card class="pa-2" v-if="!timerData.advanced" elevation="0">
        <v-row>
          <v-col>
            <v-text-field @change="calculateTotal" outlined dense v-model="timerData.intervals[0].rounds"
              :label="$t('message.rounds')" :rules="numberRules"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field @change="calculateTotal" outlined dense v-mask="timeMask" class="active-interval"
              color="orange" v-model="timerData.intervals[0].active" :label="$t('message.active')"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field @change="calculateTotal" outlined dense v-mask="timeMask" class="active-interval"
              color="green" v-model="timerData.intervals[0].rest" :label="$t('message.rest')"></v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <div v-else>
        <v-card-text outlined>
          <v-card elevation="0" outlined class="pa-2">
            <template v-for="(i, index) in timerData.intervals">
              <div :key="'set'+index">
                <v-card-actions>
                  <span class="supporting-text">{{$t('message.interval')}}: {{index+1}}</span>
                  <v-spacer></v-spacer>
                  <v-btn @click="removeInterval(index)" small icon>
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-card-actions>
                <v-text-field outlined dense v-model="i.name" :label="$t('message.name')"></v-text-field>
                <v-text-field @change="calculateTotal" outlined dense v-model="i.rounds"
                  :label="$t('message.rounds')"></v-text-field>
                <v-text-field @change="calculateTotal" outlined dense v-mask="timeMask" class="active-interval"
                  color="orange" v-model="i.active" :label="$t('message.active')"></v-text-field>
                <v-text-field @change="calculateTotal" outlined dense v-mask="timeMask" color="green" v-model="i.rest"
                  :label="$t('message.rest')"></v-text-field>
              </div>
            </template>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small @click="addNewInterval()" class="elevation-0" color="primary">
                <v-icon class="mr-1">mdi-plus-circle-outline</v-icon>{{$t('message.interval')}}
              </v-btn>
            </v-card-actions>
            <v-divider></v-divider>
            <div class="text-center">
              <span class="text-subtitle-1">{{ $t('message.total_time') }}: {{ totalTime }}</span>
            </div>
          </v-card>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-btn v-if="timerId=='new'" small @click="createTimer()" class="elevation-0" color="primary">
          {{$t('message.add_timer')}}
        </v-btn>
        <v-btn v-else small @click="updateTimer()" class="elevation-0" color="primary">{{$t('message.update')}}</v-btn>
        <v-btn small @click="goBack()" class="elevation-0">{{$t('message.back')}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn small v-if="timerId!= 'new'" @click="removeTimerDialog=true" class="elevation-0"
          color="error">{{$t('message.delete')}}</v-btn>
      </v-card-actions>
    </v-sheet>
    <v-dialog v-model="removeTimerDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <h3>{{ $t("message.are_you_sure") }}</h3>
        </v-card-title>
        <v-card-actions>
          <v-btn elevation="0" color="error" @click.stop="removeTimer()">{{
            $t("message.delete")
            }}</v-btn>
          <v-btn elevation="0" @click.stop="removeTimerDialog=false">{{
            $t("message.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TimerService from "@/services/TimerService";
import { mask } from "vue-the-mask";

export default {
  data() {
    return {
      timerId: this.$route.params.timerId,
      timerData: null,
      totalTime: null,
      removeTimerDialog: false,
      timeMask:'##:##',
      numberRules: [
        (v) =>
          /^[\d]+$/.test(
            v
          ) || this.$t("message.invalid_number"),
      ],
    };
  },
  directives: {
    mask,
  },
  created(){
    if(this.timerId != 'new'){
      this.getTimer();
    }else{
      this.timerData = {
        name: null,
        intervals: [{
          name: null,
          rest: '00:20',
          active: '00:40',
          rounds: 1
        }],
        advanced: false,
        activeSound: this.assets('sounds/timer_active.wav'),
        restSound: this.assets('sounds/timer_rest.wav'),
        endSound: this.assets('sounds/end.wav'),
      };
      this.calculateTotal();
    }
  },
  methods: {
    startTimer(){
      this.navigateTo({ name: 'viewtimer', params: {timerId: this.timerId}})
    },
    showAdvancedOptions(){
      this.timerData.advanced = true;
    },
    clearAdvanceOptions(){
      this.timerData.advanced=false;
      this.timerData.intervals = [{
        name: null,
        rest: '00:20',
        active: '00:40',
        rounds: 1
      }];
    },
    removeInterval(i){
      this.timerData.intervals.splice(i, 1);
    },
    removeTimer(){
      TimerService.removeTimer(this.timerId)
        .then(data => {
          if (data) {
            this.navigateTo({
              name: "viewmytrainingtab",
              params: { tabId: 4 },
            });
          }
        })
        .catch(err => {
          if(err)
            this.showAlert(
                "error",
                this.$t('message.oops_not_working')
              );
        });
    },
    updateTimer() {
      const data = this.prepareTimer();
      if (data == null){
        this.showAlert(
          "error",
          this.$t('message.fill_all_parameters')
        );
        return;
      }
      TimerService.updateTimer(this.timerId, data)
        .then(data => {
          if (data) {
            this.showAlert(
              "success",
              this.$t('message.updated')
            );
          }
        })
        .catch(err => {
          if(err)
            this.showAlert(
                "error",
                this.$t('message.oops_not_working')
              );
        });
    },
    createTimer(){
      const data = this.prepareTimer();
      if (data == null) {
        this.showAlert(
          "error",
          this.$t('message.fill_all_parameters')
        );
        return;
      }
      TimerService.addTimer(data)
        .then(data => {
          if (data) {
            this.navigateTo({
              name: "viewmytrainingtab",
              params: { tabId: 4 },
            });
          }
        })
        .catch(err => {
          if(err)
            this.showAlert(
                "error",
                this.$t('message.oops_not_working')
              );
        });
    },
    prepareTimer(){
      const timer = this.timerData;
      if (!timer || !timer.name || timer.name == "")
        return null;
      let data  = {
        name: timer.name,
        advanced: timer.advanced
      };
      if (timer.intervals && timer.intervals.length > 0){
        let intervals = [];
        for (const r of timer.intervals) {
          let intervalData = {
            rest: 0,
            active: 0,
            name: null,
            rounds: 1
          };
          if(!isNaN(r.rest))
            intervalData.rest = new Number(r.rest).valueOf();
          else
            intervalData.rest = new Number(this.timeToNumber(r.rest)).valueOf();
          if(!isNaN(r.active))
            intervalData.active = new Number(r.active).valueOf();
          else
            intervalData.active = new Number(this.timeToNumber(r.active)).valueOf();
          if (!isNaN(r.rounds))
            intervalData.rounds = new Number(r.rounds).valueOf();
          else
            intervalData.rounds = 1;
          intervalData.name = r.name;
          intervals.push(intervalData);
        }
        data.intervals = intervals;
      } else {
        return null;
      }
      //sounds
      if(timer.activeSound)
        data.activeSound = timer.activeSound;
      else
        data.activeSound = this.assets('sounds/timer_active.wav');
      if(timer.restSound)
        data.restSound = timer.restSound;
      else
        data.restSound = this.assets('sounds/timer_rest.wav');
      if(timer.endSound)
        data.endSound = timer.endSound;
      else
        data.endSound = this.assets('sounds/end.wav');
      return data;
    },
    addNewInterval(){
      this.timerData.intervals.push({
        name: null,
        rest: '00:20',
        active: '00:40',
        rounds: 1
      });
    },
    getTimer(){
      TimerService.getTimer(this.timerId)
        .then(data => {
          if (data) {
            let timer = data.timer;
            if(timer){
              for(let r of timer.intervals){
                r.rest = this.numberToTime(r.rest);
                r.active = this.numberToTime(r.active);
              }
              this.timerData = timer;
            }
            this.totalTime = this.seconds2Minutes(data.totalTime);
            if(!timer.activeSound)
              this.timerData.activeSound = this.assets('sounds/timer_active.wav');
            if(!timer.restSound)
              this.timerData.restSound = this.assets('sounds/timer_rest.wav');
            if(!timer.endSound)
              this.timerData.endSound = this.assets('sounds/end.wav');
            this.loading = false;
          }
        })
        .catch(err => {
          if(err)
            this.showAlert(
                "error",
                this.$t('message.oops_not_working')
              );
        });
    },
    numberToTime(time){
      if(time && time > 0){
        let minutes = (Math.floor(time / 60))+"";
        if(minutes.length == 1)
          minutes = "0"+minutes;
        let seconds = (time - minutes * 60)+"";
        if(seconds.length == 1)
          seconds = "0"+seconds;
        return minutes+":"+seconds;
      }
    },
    timeToNumber(text){
      if(text)
        text = text+"";
      if(text && text.includes(":")){
        const split = text.split(":");
        if(split.length == 2){
          const minutes = new Number(split[0]).valueOf();
          const seconds = new Number(split[1]).valueOf();
          return (minutes * 60 ) + seconds;
        }
      }
      return 0;
    },
    calculateTotal() {
      let totalTime = 0;
      const timer = this.timerData;
      if (timer) {
        for (const r of timer.intervals) {
          let intervalTime = 0;
          intervalTime += new Number(this.timeToNumber(r.rest)).valueOf();
          intervalTime += new Number(this.timeToNumber(r.active)).valueOf();
          totalTime += intervalTime * r.rounds;
        }
      }
      this.totalTime = this.seconds2Minutes(totalTime);
    }
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped>
</style>