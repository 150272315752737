<template>
  <v-combobox
    v-model="selectedValue"
    :items="affiliations"
    :hide-no-data="!search"
    :search-input.sync="search"
    outlined
    dense
    small-chips
    :label="$t('message.affiliation')"
    @input="event => { $emit('input', event) }"
  >
    <template slot="no-data">
      <v-list-item>
        <span class="subheading">{{ $t('message.click_enter_add_new') }}: </span>
          <v-chip
            label
            small
          >
            {{ search }}
          </v-chip>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import AcademyService from "@/services/AcademyService";

export default {
  data() {
    return {
      selectedValue: null,
      affiliations: [],
      loading: false,
      items: [],
      search: null
    };
  },
  created() {
    AcademyService.affiliations()
      .then(data => {
        if (data) {
          this.affiliations = data.sort();
        }
      });
  },
  mounted(){
    this.selectedValue = this.value
  },
  props: {
    value: null,
    showIcon: {type: Boolean, default: false}
  },
  watch: {
    value: {
      handler: function(newValue) {
        this.selectedValue = newValue;
      }
    }
  },
  methods: {
    async querySelections(v) {
      this.loading = true;
      if (this.affiliations.length == 0) {
        var data = await AcademyService.affiliations();
        this.affiliations = data.sort();
      }
      this.items = this.affiliations.filter(e => {
        return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
      });
      this.loading = false;
    }
  }
};
</script>

<style scoped>
</style>
