<template>
  <div>
    <v-text-field
      dense
      outlined
      v-if="showIcon"
      :label="labelDisplay"
      v-model="selectedValue"
      :loading="loading"
      v-on:keyup="findLocation"
      :clearable="clearable"
    ></v-text-field>
    <v-text-field
      v-else
      dense
      outlined
      :label="labelDisplay"
      v-model="selectedValue"
      :loading="loading"
      v-on:keyup="findLocation"
      :clearable="clearable"
    ></v-text-field>
    <v-container v-if="showPopup">
      <v-card outlined class="locations_popover pa-0" transition="fade">
        <v-card-text v-if="!loading && items.length == 0"
          ><h2>{{ $t("message.no_data_matching") }}</h2></v-card-text
        >
        <v-card-text v-else-if="loading"><h2>... ... ...</h2></v-card-text>
        <v-card-text v-else class="pa-0">
          <v-list>
            <template v-for="(item, index) in items">
              <v-list-item
                :key="index + '_loc'"
                @click="selectLocation(item)"
              >
                <v-list-item-avatar>
                  <img
                    v-if="item.country"
                    :src="flagImg(item.country)"
                    @error="defaultFlagImg(item.country)"
                    :ref="item.country"
                  />
                </v-list-item-avatar>
                <v-list-item-content v-if="item.route">
                  <v-list-item-title
                    v-html="getNumberRoute(item)"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="getCityStateCountry(item)"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else-if="item.formatted">
                  <v-list-item-title>{{ item.formatted }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content
                  v-else-if="item.latitude"
                >
                  <v-list-item-title
                    >{{ $t("message.keep_typing") }}...
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import CityService from "@/services/CityService";

export default {
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      selectedValue: null,
      selectedLocation: null,
      showPopup: false,
      labelDisplay: this.$t('message.type_location')
    };
  },
  components: {},
  props: {
    value: null,
    display: null,
    label: null,
    showIcon: null,
    clearable: null
  },
  created() {
    if (this.display) this.selectedValue = this.display;
    if(this.label)
      this.labelDisplay = this.label
  },
  mounted() {},
  watch: {
    selectedValue: {
      handler: function (newValue) {
        if(!newValue){
          this.$emit("input", null);
        }
      },
    },
  },
  methods: {
    getAddressCityStateCountry(item) {
      if (!item) return "";
      if (item.formatted && item.latitude) return item.formatted;
      else if (item.route && item.city)
        return (
          (item.number ? item.number + " " : "") +
          (item.route ? item.route : "") +
          (item.subpremise ? " " + item.subpremise : "") +
          ", " +
          item.city +
          (item.state ? ", " + item.state : "") +
          (item.zip ? ", " + item.zip : "") +
          ", " +
          item.country
        );
    },
    getNumberRoute(item) {
      if(item.formatted)
        return this.truncate(item.formatted, 30);
      else
        return (
          (item.number ? item.number + " " : " ") +
          (item.route ? "" + item.route : "") +
          (item.subpremise ? " " + item.subpremise : "")
        );
    },
    getCityStateCountry(item) {
      return (
        (item.city ? item.city + " " : " ") +
        (item.state ? (item.city ? ", " + item.state : item.state) : "") +
        (item.zip ? ", " + item.zip : "") +
        ", " +
        item.country
      );
    },
    selectLocation(loc) {
      this.showPopup = false;
      this.$emit("input", loc);
      this.selectedLocation = loc;
      this.selectedValue = this.getAddressCityStateCountry(loc);
    },
    defaultFlagImg(country) {
      if (this.$refs[country]) this.$refs[country].src = this.assets("logo.png");
    },
    flagImg(country) {
      return this.assets("flags/" + country + ".png");
    },
    findLocation() {
      if (this.selectedValue && this.selectedValue.length > 5) {
        this.showPopup = true;
        this.fetchLocations(this.selectedValue);
      } else {
        this.showPopup = false;
      }
    },
    fetchLocations(v) {
      if (!this.loading) {
        this.loading = true;
        CityService.geocode({ address: v })
          .then((data) => {
            if (data) {
              this.items = data;
              this.loading = false;
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.locations_popover {
  position: absolute;
  box-shadow: 0 6px 6px rgba(16, 16, 16, 0.04), 0 6px 6px rgba(0, 0, 0, 0.05);
  z-index: 999;
  margin-top: -50px;
  margin-left: 10px;
  min-width: 50%;
}
</style>