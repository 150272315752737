<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 20 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Lineage Tree@2x</title>
    <g id="App-Design-2022" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id="BJJLINK---Menu-icons" transform="translate(-71.000000, -231.000000)" :stroke="iconColor" stroke-width="2">
            <g id="Lineage-Tree" transform="translate(72.000000, 232.000000)">
                <path d="M1.98330554,8.50656896 L15.8664442,8.50656896 M8.92487487,4.86089652 L8.92487485,14.2282492 M1.98330553,8.91164362 L1.98330553,13.8738088 M4.11686132,14.5826896 L4.11686132,18.5 L0,18.5 L0,14.5826896 L4.11686132,14.5826896 Z M11.0584307,14.5826896 L11.0584307,18.5 L6.94156934,18.5 L6.94156934,14.5826896 L11.0584307,14.5826896 Z M18,14.5826896 L18,18.5 L13.8831387,18.5 L13.8831387,14.5826896 L18,14.5826896 Z M11.1335563,0 L11.1335563,4.46058749 L6.44574296,4.46058749 L6.44574296,0 L11.1335563,0 Z" id="Combined-Shape"></path>
                <line x1="15.8664442" y1="8.91164362" x2="15.8664442" y2="13.8738088" id="Path-Copy-3"></line>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
//:fill="iconColor"
export default {
  data() {
    return {
      iconColor: "gray",
      iconSize: "24px"
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
    if(this.size)
      this.iconSize = this.size;
  },
  props: ['color','size'],
  watch: {
    color: {
      handler: function (newValue) {
        this.iconColor = this.getColor(newValue);
      },
    },
  },
};
</script>
<style>
</style>