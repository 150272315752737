<template>
  <div v-if="$store.state.isUserLoggedIn && !$store.state.hidden">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
      <NewPost v-if="pageReady" :clear="clearPost" :managed-academy="managedAcademy" :submit="submitPost"
        :private="privateMode" :is-admin="isAdmin" :feed="feed" />
    </v-sheet>
    <v-skeleton-loader class="mt-2" v-if="loading"
      type="list-item-avatar-two-line, image, list-item-avatar-two-line, image, list-item-avatar-two-line, image"></v-skeleton-loader>
    <div>
      <v-sheet class="mt-2" v-if="managedAcademy && feed == 'academy' && topAcademyUsers"
        :rounded="isSmallerDisplay() ? '' : 'lg'">
        <PodiumDisplay height="190px" :title="$t('message.top_users')+': '+$t('message.academy_last_30')"
          :display-avatars="true" :podium-action="goToPodiumUser" :display-scores="true" score-text="JJP"
          :data="topAcademyUsers">
        </PodiumDisplay>
      </v-sheet>
      <PostsDisplay v-if="posts" :posts="posts" :add-hovers="true" :news-feed="true" v-on:load_more="loadMorePosts()">
      </PostsDisplay>
      <div v-if="!loading && posts && posts.length == 0" class="text-center">
        <span class="text-h6 grey--text">{{ $t("message.no_posts") }}</span>
      </div>
    </div>
    <v-dialog v-model="repostDialog" max-width="80%">
      <v-card outlined>
        <v-card-title>
          <h3>{{ $t("message.repost_from") }} {{ repostFrom }}</h3>
        </v-card-title>
        <div class="pa-2">
          <v-textarea outlined v-model="repostContents" rows="3"></v-textarea>
        </div>
        <v-card-actions>
          <v-btn color="primary" @click.stop="submitRepost()">{{
            $t("message.repost")
            }}</v-btn>
          <v-btn @click.stop="closeRepostDialog()">{{
            $t("message.cancel")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <v-sheet v-else :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
    <div class="text-h6 text-center pb-2">
      {{$t("message.your_user_hidden")}}
    </div>
    <div class="text-center mt-2">
      <EyeOffIcon class="hero-icon" :color="themeColor('error')" size="80"></EyeOffIcon>
    </div>
  </v-sheet>
</template>

<script>
  import PostService from "@/services/PostService";
  import ConnectService from "@/services/ConnectService";
  import UserService from "@/services/UserService";
  import PostsDisplay from "@/components/displays/PostsDisplay.vue";
  import NewPost from "@/components/inputs/NewPost.vue";
  import NotificationService from "@/services/NotificationService";
  import MessageService from "@/services/MessageService";
  import PodiumDisplay from "@/components/displays/PodiumDisplay.vue";


  export default {
    name: 'Home',
    data() {
      return {
        feed: this.$route.params.feed,
        loading: true,
        lastReload: null,
        login: this.$route.query.login,
        contents: null,
        repost: null,
        repostDialog: false,
        repostContents: null,
        repostFrom: null,
        posts: null,
        privateAcademyFeed: false,
        excludeCheckinAcademies: [],
        viewMore: false,
        topAcademyUsers: null,
        isAdmin: false,
        clearPost: false,
        managedAcademy: null,
        page: 1,
        privateMode: false,
        pageReady: false
      };
    },
    components: {
      PostsDisplay,
      NewPost,
      PodiumDisplay
    },
    created() {
      this.selectedTabStyle = 'border-radius: 0px; border-bottom: 2px solid ' + this.themeColor('primary')+';';
      if (!this.$store.state.isUserLoggedIn) {
        this.$store.dispatch("setToken", null);
        this.$store.dispatch("setUser", null);
        localStorage.removeItem("token");
        window.location.replace("/login");
      }
      const vm = this;
      document.addEventListener("visibilitychange", function () {
        if (!document.hidden) {
          //Feeds are not home page naymore so no need to reload automatically
          /*
          if (vm.$route.path == "/secure/home") {
            let now = new Date();
            now.setMinutes(now.getMinutes() - 2);
            if (now > vm.lastReload) {
              vm.lastReload = new Date();
              vm.loadFeedContent();
            }
          }
          */
          //Load message counters every time u come back
          vm.getMessagesCount(function (countM) {
            vm.getNotificationsCount(function (countS) {
              vm.$store.dispatch("setMessagesCount", countM);
              vm.$store.dispatch("setNotifications", countS);
              if (countM + countS == 0) {
                //NotificationService.clearMobile();
              }
            });
          });
        }
      });
      if (this.feed == 'academy')
        this.privateMode = true;
      UserService.user(this.$store.state.user._id)
        .then((data) => {
          if (data) {
            if (data.hidden != this.$store.state.hidden)
              this.$store.dispatch(
                "setHidden",
                data.hidden ? data.hidden : false
              );
            if (
              !this.$store.state.user.academyId &&
              this.$store.state.user.belt != "bjjlover"
            ) {
              if (data.academyId) {
                //Update academy Id in session
                const updatedUser = this.$store.state.user;
                updatedUser.academyId = data.academyId;
                this.$store.dispatch("setUser", updatedUser);
              }
            }
            if(data.managedAcademy)
              this.managedAcademy = data.managedAcademy;
            this.loadFeedContent();
          }
        });
      //Load message counters every time u come back
      vm.getMessagesCount(function (countM) {
        vm.getNotificationsCount(function (countS) {
          vm.$store.dispatch("setMessagesCount", countM);
          vm.$store.dispatch("setNotifications", countS);
          if (countM + countS == 0) {
            NotificationService.clearMobile();
          }
        });
      });
      if (this.login) {
        //User just logged in
        MessageService.updateCount();
        NotificationService.updateCount();
      }
    },
  methods: {
      getPodium(){
        this.topAcademyUsers = null;
        UserService.academyPodium()
          .then((data) => {
            if (data) {
              this.topAcademyUsers = this.getPodiumData(data.topUsers);
            }
          });
      },
      getPodiumData(data){
        if(data && data.length == 3){
          let podiumData = [];
          podiumData.push({
            name: data[1].displayName,
            id: data[1]._id,
            place: 2,
            avatar: data[1].userImageSmall,
            points: data[1].jjPoints,
            badges: data[1].verifiedBelts,
            belt: data[1].belt,
            verifiedBelts: data[1].verifiedBelts,
            userImageSmall: data[1].userImageSmall,
          });
          podiumData.push({
            name: data[0].displayName,
            id: data[0]._id,
            place: 1,
            avatar: data[0].userImageSmall,
            points: data[0].jjPoints,
            badges: data[0].verifiedBelts,
            belt: data[0].belt,
            verifiedBelts: data[0].verifiedBelts,
            userImageSmall: data[0].userImageSmall,
          });
          podiumData.push({
            name: data[2].displayName,
            id: data[2]._id,
            place: 3,
            avatar: data[2].userImageSmall,
            points: data[2].jjPoints,
            badges: data[2].verifiedBelts,
            belt: data[2].belt,
            verifiedBelts: data[2].verifiedBelts,
            userImageSmall: data[2].userImageSmall,
          });
          return podiumData;
        }
      },
      goToPodiumUser(p){
        UserService.logActivity({
          activityType: "clicked_acadeny_user_podium",
          value: p.id,
        });
        this.goToUser(p.id);
      },
    async loadFeedContent() {
        let source = this.feed;
        if(source == 'academy')
          this.getPodium();
        this.viewMore = true;
        this.loadMorePosts(true);
      },
      loadMorePosts(beginning) {
        if(this.viewMore){
          this.loading = true;
          this.viewMore = false;
          if(!beginning)
            this.page = this.page + 1;
          ConnectService.loadFeed(this.feed, this.page)
            .then((posts) => {
              if (posts) {
                this.renderPosts(posts, true)
              }
            });
        }
      },
      openRepostDialog(post) {
        this.repostDialog = true;
        this.repost = post;
        this.repostFrom = post.user.fName + " " + post.user.lName;
      },
      closeRepostDialog() {
        this.repost = null;
        this.repostContents = null;
        this.repostDialog = false;
        this.repostFrom = null;
      },
      submitRepost() {
        if (this.repost && this.repostContents && this.repostContents != "") {
          var hashTags = this.extractHashTags(this.repostContents);
          var data = {
            contents: this.repostContents,
            time: this.getNowDate(),
            hashTags: hashTags,
            repost: this.repost._id,
          };
          this.submitPost(data);
        }
      },
      async submitPost(postData, postFunction) {
        if (postData.contents && postData.contents != "") {
          //Grab hashtags first
          const data = await PostService.submitPost(postData);
          if (data) {
            if (this.repost) data.post.repost = this.repost;
            if (data.post.video) {
              this.showAlert(
                "info",
                this.$t("message.post_with_video_message"),
                null,
                8000
              );
            } else {
              //Append to whatever you have in front of you
              this.posts.unshift(data.post);
            }
            this.clearPost = true;
            if (postFunction) postFunction();
          } else {
            this.showAlert("error", this.$t("message.oops_not_working"));
          }
        } else {
          this.showAlert("warning", this.$t("message.need_type_text"));
        }
        if (this.repost) {
          this.closeRepostDialog();
        }
      },
      addNews(posts, news){
        if(news && news.entry){
          const d = new Date();
          const position = this.getRandomInt(5);
          const newsPost = {
            _id: 'newsentry_'+d.getTime(),
            newsEntry: news.entry
          };
          posts.splice(position, 0, newsPost);
        }
        return posts;
      },
      addSuggestions(posts, suggestions){
        if(suggestions && suggestions.suggestions && suggestions.suggestions.length > 0){
          const d = new Date();
          const position = this.getRandomInt(3);
          const suggestionsPost = {
            _id: 'suggestion_'+d.getTime(),
            suggestions: suggestions.suggestions
          };
          posts.splice(position, 0, suggestionsPost);
        }
        return posts;
      },
      renderPosts(returnedData, append){
        if (returnedData) {
          if (!append || !this.posts) {
            this.posts = [];
          }
          if (returnedData.isAdmin)
            this.isAdmin = true;
          if (returnedData.hasMore) this.viewMore = true;
          if (this.feed == 'academy') {
            this.privateAcademyFeed = true;
          } else {
            this.privateAcademyFeed = false;
          }
          this.posts = this.posts.concat(returnedData.posts);
          this.loading = false;
          this.pageReady = true;
        }
      },
    }
  }
</script>
<style scoped>
.legend-box{
  padding:5px;
  border: 1px solid #fbc42e;
  border-radius: 5px;
}
</style>
