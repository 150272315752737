<template>
  <v-autocomplete
    @input="event => { emitChange(event) }"
    v-model="selectedUser"
    :items="users"
    item-text="text"
    item-value="id"
    :label="labelText" 
    clearable
    dense
    outlined
  ></v-autocomplete>
</template>

<script>

export default {
  data() {
    return {
      selectedUser: null,
      labelText: null
    }
  },
  created() {
    if(this.label)
      this.labelText = this.label;
  },
  props: {
    value: null,
    label: null,
    users: null
  },
  watch: {
      value: {
      immediate: true,
      handler: function (newValue) {
        this.selectedUser = newValue
      }
    }
  },
  methods: {
    emitChange (event) {
      this.$emit('input', event)
    }
  }
};
</script>

<style scoped>
</style>