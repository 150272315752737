<template>
  <v-container v-if="$store.state.isUserLoggedIn" wrap pa-0>
    <v-card>
      <v-card-text v-if="userName">
        <h3>{{$t("message.user_photos", [userName])}}</h3>
      </v-card-text>
      <div v-if="loading">
        <circles-loader></circles-loader>
      </div>
      <image-gallery :images="images" :color="backGroundColor"></image-gallery>
      <v-card-actions v-if="viewMore">
        <v-spacer></v-spacer>
        <v-btn color="primary" elevation="0" small @click="getImages(true)">{{
          $t("message.view_more")}}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import UserService from "@/services/UserService";
import CirclesLoader from "@/components/displays/CirclesLoader.vue";
import ImageGallery from "@/components/displays/ImageGallery.vue";

export default {
  data() {
    return {
      userId: this.$route.params.userId,
      viewMore: false,
      loading: true,
      index: 0,
      images: null,
      userName:null,
      backGroundColor: null,
    }
  },
  components: {
    CirclesLoader,
    ImageGallery,
  },
  created() {
    this.getImages(false);
  },
  methods: {
    getImages(append){
      this.searching = true;
      if (!append) {
        this.index = 0;
        this.images = [];
      }
      UserService.userImages(this.userId, this.index)
        .then((data) => {
          if(data.images.length > 0){
            if(!this.userName)
              this.userName = data.user.fName;
            this.images = this.images.concat(data.images)
            this.index = this.index + data.count;
            if (data.hasMore) this.viewMore = true;
            else
              this.viewMore = false;
            this.loading = false;
            this.backGroundColor = this.lightenDarkenColor(this.getBeltColor(data.user.belt), 40);
          }
        });
    }
  },
  mounted() {},
  computed: {
  },
  watch: {
  },
};
</script>

<style scoped>
</style>
