<template>
  <div>
    <span v-if="displayText && displayText != 'Unknown'">{{displayText}}</span>
    <div v-if="lineage">
      <template v-for="(n, index) in lineage">
        <div :key="index+'_lineage_node'" style="display: inline-block">
          <span v-if="index == 0"></span>
          <span class="ml-1 mr-1" v-else>/</span>
          <UserCircleIcon size="20" :color="n.user ? themeColor('primary') : ''" class="hero-icon"></UserCircleIcon>
          <a @click="goToProfile(n.user)" v-if="n.user">
            {{n.name}}
            {{n.nickname ? ' ('+n.nickname+')' : ''}}
          </a>
          <span v-else>
            {{n.name}} 
            {{n.nickname ? ' ('+n.nickname+')' : ''}}
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import LineageService from "@/services/LineageService";

import { 
  UserCircleIcon
} from '@vue-hero-icons/outline'

export default {
  props: ["master", "user", "masterId","userId","icon"],
  data() {
    return {
      showPopup: true,
      timer: null,
      lineage: null,
      isInInfo: false,
      displayText: this.$t('message.calculating_lineage')
    };
  },
  components: {
    UserCircleIcon
  },
  mounted() {
    this.findLineage();
  },
  methods: {
    goToProfile(id){
      if(id)
        this.navigateTo({ name: 'viewprofile', params: { userId: id } });
    },
    processLineage(data){
      this.displayText = null;
      var reversed = data.reverse();
      reversed.push({name: this.user});
      this.lineage = reversed;
    },
    findLineage() {
      if (this.masterId) {
        LineageService.getLineageById(this.masterId, this.master)
          .then(data => {
            if (data)
              this.processLineage(data);
          })
          .catch(() => {
            this.displayText = this.$t("message.unknown");
          });
      } else {
        //this.displayText = "Unknown";
        //get it the old way
        LineageService.updateUserMaster(this.userId)
          .then(data => {
            if (data){
              if(data._id){
                LineageService.getLineageById(data._id, this.master)
                  .then(data => {
                    if (data)
                      this.processLineage(data);
                  })
                  .catch(() => {
                    this.displayText = this.$t("message.unknown");
                  });
              }else{
                this.displayText = this.$t("message.unknown");
              }
            }
          })
          .catch(() => {
            this.displayText = this.$t("message.unknown");
          });
      }
    }
  },
  watch: {
    master: {
      handler: function(newValue) {
        this.master = newValue;
        this.findLineage();
      }
    },
    masterId: {
      handler: function(newValue) {
        this.masterId = newValue;
        this.findLineage();
      }
    }
  }
};
</script>

<style scoped>
</style>