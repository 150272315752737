<template>
  <div v-if="$store.state.isUserLoggedIn" :rounded="isSmallerDisplay() ? '' : 'lg'">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pb-2">
      <v-list three-line>
        <v-subheader>
          <BellIcon :color="themeColor('primary')" class="hero-icon"></BellIcon>
          {{ $t("message.new") }}
          <v-spacer></v-spacer>
          <v-btn small elevation="0" @click="clearAll()" color="primary" outlined>
            <TrashIcon class="hero-button"></TrashIcon>
            {{ $t("message.clear_all") }}
          </v-btn>
        </v-subheader>
        <div class="text-center" v-if="!notifications || notifications.length == 0">
          <div class="text-subtitle-1">
            {{ $t("message.no_notifications") }}
          </div>
          <div class="text-subtitle-1">
            {{ $t("message.go_train") }}
          </div>
        </div>
        <template v-for="(n, index) in notifications">
          <v-list-item :key="n._id + index" @click="viewDetails(n)">
            <v-list-item-avatar style="margin-top:25px" class="mr-2">
              <v-img v-if="isSystemMessage(n.type)" :src="assets('system.png')" :title="$t('message.system')" />
              <v-img v-else-if="n.academy && n.academy.image" :src="userImg(n.academy.image)" :title="n.name"
                @click.stop="
                  navigateTo({
                    name: 'viewacademy',
                    params: { academyId: n.academy._id },
                  })" />
              <v-img v-else-if="n.sender.userImage" :src="userImg(n.sender.userImage)"
                :title="n.sender.fName + ' ' + n.sender.lName" @click.stop="
                  navigateTo({
                    name: 'userprofile',
                    params: { userId: n.sender._id },
                  })" />
              <v-img v-else :src="assets('blank-profile-picture.png')" :title="n.sender.fName + ' ' + n.sender.lName"
                @click.stop="
                  navigateTo({
                    name: 'userprofile',
                    params: { userId: n.sender._id },
                  })" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <NotificationIcon class="mr-1" :type="n.type"></NotificationIcon>
                <span v-html="n.titleTranslated ? n.titleTranslated : n.title"></span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text-subtitle-2 font-weight-bold">{{ $d(new Date(n.time), "short", $i18n.locale) }} •
                </span>
                {{ n.contentsTranslated ? n.contentsTranslated : n.contents }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar v-if="n.originalRecipient" style="margin-top:25px">
              <UserAvatar :user="n.originalRecipient"></UserAvatar>
            </v-list-item-avatar>
            <v-list-item-action v-if="requiresResponse(n)" fill-height class="ml-0">
              <v-btn v-if="requiresResponse(n)" icon small @click.stop="processResponse(n, 'accept')">
                <CheckCircleIcon class="hero-button" :color="themeColor('success')"></CheckCircleIcon>
              </v-btn>
              <v-btn v-if="requiresResponse(n)" icon small @click.stop="processResponse(n, 'reject')">
                <XCircleIcon class="hero-button" :color="themeColor('error')"></XCircleIcon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action v-else fill-height class="ml-0" style="margin-right: -10px; margin-top:5px">
              <v-btn icon small @click.stop="readNotification(n)">
                <XCircleIcon class="hero-button" color="grey lighten-1"></XCircleIcon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < notifications.length" :key="index"></v-divider>
        </template>
      </v-list>
      <v-skeleton-loader v-if="loadingNew"
        type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
      </v-skeleton-loader>
      <div v-if="viewMoreNew" class="text-center mb-2">
        <v-btn small elevation="0" color="primary" @click="getNewNotifications(true)">{{$t('message.view_more')}}</v-btn>
      </div>
    </v-sheet>
    <v-sheet class="mt-2 pb-2" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-list three-line>
        <v-subheader>
          <BellIcon class="hero-icon"></BellIcon>
          {{ $t("message.old") }}
          <v-spacer></v-spacer>
        </v-subheader>
        <template v-for="(n, index) in notificationsRead">
          <v-list-item :key="n._id + index" @click="viewDetails(n)">
            <v-list-item-avatar style="margin-top:25px" class="mr-2">
              <v-img v-if="isSystemMessage(n.type)" :src="assets('system.png')" :title="$t('message.system')" />
              <v-img v-else-if="n.academy && n.academy.image" :src="userImg(n.academy.image)" :title="n.name"
                @click.stop="
                  navigateTo({
                    name: 'viewacademy',
                    params: { academyId: n.academy._id },
                  })" />
              <v-img v-else-if="n.sender.userImage" :src="userImg(n.sender.userImage)"
                :title="n.sender.fName + ' ' + n.sender.lName" @click.stop="
                  navigateTo({
                    name: 'userprofile',
                    params: { userId: n.sender._id },
                  })" />
              <v-img v-else :src="assets('blank-profile-picture.png')" :title="n.sender.fName + ' ' + n.sender.lName"
                @click.stop="
                  navigateTo({
                    name: 'userprofile',
                    params: { userId: n.sender._id },
                  })" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <NotificationIcon class="mr-1" :type="n.type"></NotificationIcon>
                <span v-html="n.titleTranslated ? n.titleTranslated : n.title"></span>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span class="text-subtitle-2 font-weight-bold">{{ $d(new Date(n.time), "short", $i18n.locale) }} •
                </span>
                {{ n.contentsTranslated ? n.contentsTranslated : n.contents }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar v-if="n.originalRecipient" style="margin-top:25px">
              <UserAvatar :user=" n.originalRecipient">
              </UserAvatar>
            </v-list-item-avatar>
            <v-list-item-action v-if="requiresResponse(n)">
              <v-btn icon small @click.stop="processResponse(n, 'accept')">
                <CheckCircleIcon class="hero-button" :color="themeColor('success')"></CheckCircleIcon>
              </v-btn>
              <v-btn icon small @click.stop="processResponse(n, 'reject')">
                <XCircleIcon class="hero-button" :color="themeColor('error')"></XCircleIcon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < notifications.length" :key="index"></v-divider>
        </template>
      </v-list>
      <v-skeleton-loader v-if="loadingRead"
        type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line">
      </v-skeleton-loader>
      <div v-if="viewMoreRead" class="text-center">
        <v-btn small color="primary" @click="getReadNotifications(true)">{{$t('message.view_more')}}</v-btn>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import EventService from "@/services/EventService";
import NotificationService from "@/services/NotificationService";
import NotificationIcon from "@/components/displays/NotificationIconDisplay.vue";
import UserAvatar from "@/components/displays/UserAvatarDisplay.vue";

import { 
  BellIcon,
  TrashIcon,
  XCircleIcon,
  CheckCircleIcon,
} from '@vue-hero-icons/outline'

export default {
  components: {
    BellIcon,
    TrashIcon,
    XCircleIcon,
    NotificationIcon,
    CheckCircleIcon,
    UserAvatar,
  },
  data() {
    return {
      notifications: [],
      notificationsRead: [],
      pointerRead: 0,
      pointerNew: 0,
      loadingNew: true,
      loadingRead: true,
      viewMoreNew: false,
      viewMoreRead: false,
      types: {
        NOTI_NEWEVENTPARTICIPANT: "new_event_participant", //EVENT
        NOTI_NEWEVENTMANAGER: "new_event_managers", //EVENT
        NOTI_EVENTINVITE: "event_invite", //EVENT
        NOTI_EVENTINVITEACCEPTED: "event_invite_accepted", //EVENT
        NOTI_NEWLINK: "new_link", //LINK
        NOTI_NEWACADEMYMEMBER: "new_academy_member", //ACADEMY
        NOTI_NEWACADEMYMANAGER: "new_academy_manager", //ACADEMY
        NOTI_ACADEMYINVITE: "academy_invite", //ACADEMY
        NOTI_POSTLIKED: "post_liked", //LIKE
        NOTI_COMMENTLIKED: "comment_liked", //LIKE
        NOTI_VIDEOLIKED: "video_liked", //LIKE
        NOTI_ACADEMYLIKED: "academy_liked", //LIKE
        NOTI_ACADEMYREVIEWED: "academy_reviewed", //LIKE
        NOTI_POSTTAG: "post_tag", //TAG
        NOTI_COMMENTTAG: "comment_tag", //TAG
        NOTI_POSTCOMMENTED: "post_commented", //COMMENT
        NOTI_VIDEOCOMMENTED: "video_commented", //COMMENT
        NOTI_VIDEOREADY: "video_ready",
        NOTI_VIDEODELETED: "video_deleted",
        NOTI_REPOST: "post_reposted", //REPOST
        NOTI_ACADEMYMESSAGE: "academy_message", //REPOST
        NOTI_PAYMENTREQUEST: "payment_request", //Payment request
        NOTI_CC_UPDATE_REQUEST: "cc_update_request", //Credit card update
        NOTI_PAYMENTCONFIRMATION: "payment_confirmation", //Payment request
        NOTI_NEWACADEMYVIDEO: "new_academy_video", //New academy video
        NOTI_CLASSREQUESTACCEPTED: "class_request_accepted", //CLASS ACCEPTED
        NOTI_CLASSREQUESTREJECTED: "class_request_rejected", //REQUEST REJECTED
        NOTI_CLASSATENDEELEFT: "class_atendee_left", //ATENDEE LEFT
        NOTI_CLASSREQUEST: "class_request", //CLASS REQUEST
        NOTI_DOCUMENTREQUEST: "document_signature_request", //Signature request
        NOTI_DOCUMENTREQUESTSURVEY: "document_survey_request",
        NOTI_BELTVERIFIED: "belt_verified", //Signature request,
        NOTI_BELTREJECTED: "belt_rejected",
        NOTI_POSTOSS: "post_ossed",
        NOTI_POSTFIRE: "post_fire",
        NOTI_POSTRNC: "post_rnc",
        NOTI_ACADEMYPOST: "academy_post",
        NOTI_COMMENTREPLIED: "comment_replied", //COMMENT
        NOTI_PROGRAMASSIGNED: "program_assigned", //COMMENT
        NOTI_SAVECOMPETITORALERT:"saved_competitor_alert",//Tourney 
        NOTI_JOINEDCLASS: "joined_class"//Joined Class 
      },
    };
  },
  created() {
    NotificationService.updateCount();
    this.getNewNotifications();
    this.getReadNotifications();
    var vm = this;
    vm.getMessagesCount(function (countM) {
      vm.getNotificationsCount(function (countS) {
        vm.$store.dispatch("setMessagesCount", countM);
        vm.$store.dispatch("setNotifications", countS);
        if (countM + countS == 0) {
          NotificationService.clearMobile();
        }
      });
    });
  },
  methods: {
    doNothing() {},
    clearAll(){
      NotificationService.clearAllNotifications()
        .then((data) => {
          if (data) {
            let tmp = this.notifications;
            tmp = tmp.reverse();
            this.notifications = [];
            for (const n of tmp)
              this.notificationsRead.unshift(n);
            this.$store.dispatch("setNotifications", 0);
          }
        });
    },
    requiresResponse(n) {
      if (n.type == this.types.NOTI_EVENTINVITE) {
        return true;
      } else {
        return false;
      }
    },
    processResponse(n, action) {
      if (n.type == this.types.NOTI_EVENTINVITE) {
        if (action == "accept") {
          EventService.acceptInvite(n.objectId)
            .then((data) => {
              if (data) {
                this.readNotification(n);
              }
            });
        } else {
          EventService.rejectInvite(n.objectId)
            .then((data) => {
              if (data) {
                this.readNotification(n);
              }
            });
        }
      }
    },
    viewDetails(n, skipMarkRead) {
      if (!skipMarkRead && !this.requiresResponse(n)) {
        const newNotificationCount = this.$store.state.notificationsCount - 1;
        this.$store.dispatch("setNotifications", newNotificationCount);
        NotificationService.read(n._id);
      }
      if (
        n.type == this.types.NOTI_POSTLIKED || 
        n.type == this.types.NOTI_POSTOSS||
        n.type == this.types.NOTI_POSTFIRE ||
        n.type == this.types.NOTI_ACADEMYPOST || 
        n.type == this.types.NOTI_POSTRNC||
        n.type == this.types.NOTI_COMMENTLIKED ||
        n.type == this.types.NOTI_POSTTAG ||
        n.type == this.types.NOTI_COMMENTTAG || 
        n.type == this.types.NOTI_COMMENTREPLIED ||
        n.type == this.types.NOTI_POSTCOMMENTED ||
        n.type == this.types.NOTI_REPOST
      )
        this.navigateTo({ name: "viewpost", params: { postId: n.objectId } }, n.params);
      else if (
        n.type == this.types.NOTI_NEWACADEMYMEMBER ||
        n.type == this.types.NOTI_ACADEMYLIKED
      )
        this.navigateTo({
          name: "viewacademy",
          params: { academyId: n.objectId },
        });
      else if (n.type == this.types.NOTI_PAYMENTREQUEST && n.originalRecipient){
        this.readNotification(n);
        this.navigateTo({ name: 'transaction', params: { transactionId: n.objectId } });
      }else if (n.type == this.types.NOTI_PAYMENTREQUEST){
        this.readNotification(n);
        this.navigateTo({ name: 'transaction', params: { transactionId: n.objectId } });
      }else if (n.type == this.types.NOTI_CC_UPDATE_REQUEST && n.originalRecipient){
        this.readNotification(n);
        this.navigateTo({ name: 'transaction', params: { transactionId: n.objectId } });
      }else if (n.type == this.types.NOTI_CC_UPDATE_REQUEST){
        this.navigateTo({
          name: "ccupdate",
          params: { transactionId: n.objectId },
        });
      }else if (n.type == this.types.NOTI_PAYMENTCONFIRMATION)
        this.navigateTo({
          name: "transaction",
          params: { transactionId: n.objectId },
        });
      else if (n.type == this.types.NOTI_DOCUMENTREQUEST || n.type == this.types.NOTI_DOCUMENTREQUESTSURVEY)
        this.navigateTo({
          name: "viewacademydocumentnotification",
          params: { academyDocumentId: n.objectId, notificationId: n._id },
        });
      else if (
        n.type == this.types.NOTI_VIDEOLIKED ||
        n.type == this.types.NOTI_NEWACADEMYVIDEO ||
        n.type == this.types.NOTI_VIDEOCOMMENTED
      )
        this.navigateTo({ name: "viewvideo", params: { videoId: n.objectId } });
      else if (n.type == this.types.NOTI_ACADEMYREVIEWED)
        this.navigateTo({
          name: "academyreview",
          params: { reviewId: n.objectId },
        });
      else if (
        n.type == this.types.NOTI_CLASSREQUESTACCEPTED ||
        n.type == this.types.NOTI_CLASSREQUESTREJECTED ||
        n.type == this.types.NOTI_CLASSREQUEST ||
        n.type == this.types.NOTI_JOINEDCLASS ||
        n.type == this.types.NOTI_CLASSATENDEELEFT
      ) {
        this.navigateTo({
          name: "viewacademyclassnotification",
          params: { academyClassId: n.objectId, notificationId: n._id },
        });
      } else if (n.type == this.types.NOTI_ACADEMYMESSAGE)
        this.navigateTo({
          name: "academymessage",
          params: { messageId: n._id },
        });
      else if(n.type == this.types.NOTI_PROGRAMASSIGNED)
        this.navigateTo({ name: "viewprogressdetailsnotification", params: { instanceId: n.objectId, notificationId: n._id } });
      else if (n.type == this.types.NOTI_VIDEOREADY)
        this.navigateTo({
          name: "viewvideo",
          params: { videoId: n.objectId },
        });
      else if (n.type == this.types.NOTI_VIDEODELETED)
        this.navigateTo({
          name: "viewsystemmessage",
          params: { notificationId: n._id },
        });
      else if (
        n.type == this.types.NOTI_NEWEVENTPARTICIPANT ||
        n.type == this.types.NOTI_NEWEVENTMANAGER ||
        n.type == this.types.NOTI_EVENTINVITE
      )
        this.navigateTo({
          name: "viewevent",
          params: { eventId: n.objectId },
        });
      else if (
        n.type == this.types.NOTI_NEWLINK ||
        n.type == this.types.NOTI_EVENTINVITEACCEPTED
      )
        this.navigateTo({
          name: "userprofile",
          params: { userId: n.sender._id },
        });
      else if (n.type == this.types.NOTI_BELTVERIFIED)
        this.navigateTo({
          name: "userprofile",
          params: { userId: n.objectId },
        });
      else if (n.type == this.types.NOTI_BELTREJECTED)
        this.navigateTo({
          name: "edithistory",
        });
      else if (n.type == this.types.NOTI_SAVECOMPETITORALERT)
        this.navigateTo({
          name: "ibjjftournamentstourney",
          params: { tournamentId: n.objectId },
        });
      else
        this.navigateTo({
          name: "userprofile",
          params: { userId: n.sender._id },
        });
    },
    readNotification(n) {
      var data = NotificationService.read(n._id);
      //Change news counter for user on fron and back
      if (data) {
        var i;
        for (i = 0; i < this.notifications.length; i++) {
          const readNoti = this.notifications[i];
          if (readNoti._id == n._id) {
            break;
          }
        }
        this.notifications.splice(i, 1);
        const newNotificationCount = this.$store.state.notificationsCount - 1;
        this.$store.dispatch("setNotifications", newNotificationCount);
        //HACK: Clear push for mobile?
        if (this.$store.state.messagesCount + newNotificationCount == 0) {
          //NotificationService.clearMobile();
        }
        this.notificationsRead.unshift(n);
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    getReadNotifications(append) {
      this.loadingRead = true;
      this.viewMoreRead = false;
      if (!append) this.notificationsRead = [];
      NotificationService.notifications(this.pointerRead, true)
        .then((data) => {
          if (data) {
            this.notificationsRead = this.notificationsRead.concat(
              data.notifications
            );
            this.loadingRead = false;
            this.pointerRead = this.pointerRead + data.count;
            if (data.count >= this.searchesLimit) this.viewMoreRead = true;
          }
        });
    },
    isSystemMessage(type) {
      if (type == this.types.NOTI_VIDEOREADY) {
        return true;
      }
      return false;
    },
    getNewNotifications(append) {
      this.loadingNew = true;
      this.viewMoreNew = false;
      if (!append) this.notifications = [];
      NotificationService.notifications(this.pointerNew, false)
        .then((data) => {
          if (data) {
            this.notifications = this.notifications.concat(data.notifications);
            UserService.updateUser([
              { propName: "notifications", value: this.notifications.length },
            ]);
            this.loadingNew = false;
            this.pointerNew = this.pointerNew + data.count;
            if (data.count >= this.searchesLimit) this.viewMoreNew = true;
          }
        });
    },
  },
};
</script>