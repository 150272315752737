<template>
  <div class="about" v-if="!$store.state.hidden">
    <BackButton></BackButton>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <v-card-actions class="pl-0 pr-0 pt-0">
        <v-btn 
          @click="navigateTo({
            name: 'createevent'})"
          block 
          color="primary" 
          small 
          elevation="0">
          <CalendarIcon class="hero-button"></CalendarIcon>
          {{ $t("message.create_event") }}
        </v-btn>
      </v-card-actions>
      <v-card-title class="text-subtitle-1 pa-0">
        {{ $t("message.filter_events") }}
      </v-card-title>
      <v-container>
        <v-row class="mt-0 pt-0 pb-0 mb-0">
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <v-text-field dense outlined clearable v-model="myEventsTitleFilter" :label="$t('message.name')"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0 pb-0 mb-0">
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <v-switch v-model="pastEventsFilter" :label="$t('message.past_events')"></v-switch> 
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <div>
      <div v-if="!loading && (!myEvents || myEvents.length == 0)" class="text-center">
        <div class="text-h6">{{$t('message.no_events_match')}}</div>
      </div>
      <template v-else v-for="(e, index) in myEvents">
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2 pa-1" elevation="0" :key="e._id" :position="index">
          <v-card-actions class="pa-1 text-overline">
            <country-flag
              v-if="e.country"
              size="15"
              :countryCode="e.country"
            ></country-flag>
            <EventType class="ml-1" :type="e.type"></EventType>
            <v-spacer></v-spacer>
            <CheckCircleIcon
              v-if="e.isParticipant" 
              class="hero-icon" 
              :color="themeColor('success')" 
              size="15">
            </CheckCircleIcon>
          </v-card-actions>
          <v-card-text class="clickable text-center" @click="goToEvent(e._id)">
            <page-avatar 
              :src="e.image"
              size="medium"
              :alt="e.title"
            />
          </v-card-text>  
          <v-card-text 
            class="text-center text-truncate"
            @click="goToEvent(e._id)">
            <div class="text-h6">{{e.title}}</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-list>
            <v-list-item two-line v-if="e.academyCreator">
              <v-list-item-action>
                <v-avatar size="25px" v-if="e.academyCreator.image"><img :src="userFile(e.academyCreator.image)"/></v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{e.cityDisplay}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{$t('message.event_behalf_academy')}}<span class="ml-1 font-weight-bold">{{e.academyCreator.name}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="e.academyCreator"></v-divider>
            <v-list-item>
              <v-list-item-action>
                <LocationMarkerIcon class="hero-icon"></LocationMarkerIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{e.location}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-action>
                <FlagIcon class="hero-icon"></FlagIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{e.cityDisplay}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-action>
                <RefreshIcon v-if="e.recurrence" class="hero-icon"></RefreshIcon>
                <ClockIcon v-else class="hero-icon"></ClockIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="e.recurrence">
                  {{$t('message.'+e.recurrence+'s')}} {{ $d(new Date(e.date), 'time', $i18n.locale) }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  {{ $d(new Date(e.date), 'long', $i18n.locale) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="e.free"></v-divider>
            <v-list-item v-if="e.free">
              <v-list-item-action>
                <CashIcon class="hero-icon"></CashIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('message.free_event')}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </template>
      <div v-if="loading">
        <v-skeleton-loader
          type="card, article"
        ></v-skeleton-loader>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          v-if="viewMoreMyEvents" 
          small
          color="primary"
          class="elevation-0" 
          @click="getMyEvents(true)">
          {{$t('message.view_more')}}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import PageAvatar from "@/components/displays/PageAvatarDisplay.vue";
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";
import EventType from "@/components/displays/EventTypeDisplay.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

import { 
  CalendarIcon,
  CheckCircleIcon,
  LocationMarkerIcon, 
  ClockIcon,
  RefreshIcon,
  CashIcon,
  FlagIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      loading: false,
      viewMoreMyEvents: false,
      pointer: 0,
      myEventsTitleFilter: null,
      myEvents: [],
      freeEventFilter: false,
      pastEventsFilter: null,
      myEventsCount: 0,
      event: null,
    };
  },
  created() {
    if(this.$store.state.user.city && this.$store.state.user.city.country){
      this.countryFilter = this.$store.state.user.city.country;
    }
    this.getMyEvents();
    this.event = {
        userCreator: this.$store.state.user._id,
    };
  },
  components: {
    EventType,
    PageAvatar,
    CountryFlag,
    CalendarIcon,
    CheckCircleIcon,
    LocationMarkerIcon, 
    ClockIcon,
    RefreshIcon,
    CashIcon,
    FlagIcon,
    BackButton
  },
  watch: {
    myEventsTitleFilter: {
      handler: function() {
        this.getMyEvents();
      }
    },
    pastEventsFilter: {
      handler: function() {
        this.getMyEvents();
      }
    },
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      return value.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  },
  methods: {
    getMyEvents(append) {
      this.loading = true;
      if (!append) {
          this.myEvents = [];
          this.pointer = 0;
      }
      let params = { 
        currentuser: true, 
        pastEvents: this.pastEventsFilter,
        pointer: this.pointer,
        time: this.toUTCMilliseconds(new Date()) };
      if (this.myEventsTitleFilter && this.myEventsTitleFilter.length > 0)
          params["title"] = this.myEventsTitleFilter;
      EventService.events(params)
        .then(data => {
          if (data) {
            this.loading = false;
            const sortedEvents = this.getSortedEvents(data.events);
            this.myEvents = this.myEvents.concat(sortedEvents);
            this.pointer = this.pointer + data.count;
            if (data.count >= this.searchesLimit) this.viewMoreMyEvents = true;
            if(this.myEvents){
                this.myEventsCount = this.myEvents.length;
            }
          }
        });
    },
    filterEvents(append) {
      if (!this.loading) {
        this.loading = true;
        this.viewMore = false;
        if (!append) {
          this.events = [];
          this.pointer = 0;
        }
        let params = {
          pointer: this.pointer
        };
        if (this.cityFilter && this.cityFilter != "")
          params["city"] = this.cityFilter;
        if (this.typeFilter && this.typeFilter != "")
          params["type"] = this.typeFilter;
        if (this.titleFilter && this.titleFilter.length > 0)
          params["title"] = this.titleFilter;
        if (this.freeEventFilter)
          params["free"] = true;
        if (this.recurrentFilter)
          params["recurrent"] = true;
        if(this.countryFilter)
          params["country"] = this.countryFilter;
        params["from"] = new Date();
        EventService.events(params)
          .then(data => {
            if (data) {
              const sortedEvents = this.getSortedEvents(data.events);
              this.events = this.events.concat(sortedEvents);
              this.loading = false;
              this.pointer = this.pointer + data.count;
              if (data.count >= this.searchesLimit) this.viewMore = true;
            }
          })
          .catch(err => {
            console.log(err)
          });
      }
    },
    getSortedEvents(events){
      for(let e of events){
        e.date = this.adjustTimeToOffset(e.date, e.timeZone);
      }
      return events;
    }
  }
};
</script>

<style scoped>
.search-box {
  margin-bottom: 20px;
}
.event-card {
  margin-bottom: 5px;
}
.my-event-card {
  margin-right: 10px;
}
.event-flag{
  float: left;
}
.event-confirmation{
  float: right;
}
</style>