<template>
  <div class="fill-height">
    <BackButton></BackButton>
    <v-sheet 
      class="pa-2"
      v-if="!$store.state.hidden" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <affiliations-select v-if="markersLoaded" style="margin-bottom:-20px" :label="$t('message.filter_by_affiliation')" v-model="affiliation"/>
      <div v-else class="text-center">
        <MapIcon class="hero-icon rotate" size="40" :color="themeColor('primary')"></MapIcon>
      </div>
    </v-sheet>
    <v-card 
      class="mt-2 pa-2"
      :height="mapHeight"
      elevation="0" 
      v-if="!$store.state.hidden" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-container>
      <v-btn @click="navigateTo({ name: 'academies' })" v-if="mapLoaded" color="primary" style="z-index: 100" elevation="0" fab x-small class="mr-2"><v-icon>mdi-magnify</v-icon></v-btn>
      <GmapMap
        :center="mapCenter"
        :zoom="mapZoom"
        map-type-id="roadmap"
        :options="mapOptions"
        ref="map"
      >
        <GmapInfoWindow
          v-if="mapLoaded"
          :options="infoOptions" 
          :position="infoWindowPos" 
          :opened="infoWinOpen" 
          @closeclick="infoWinOpen=false"
        >
          <v-container class="info_window">
            <academy-dialog :hide-button="embed != 'true' ? false : true" v-if="selectedAcademy" :academy="selectedAcademy"></academy-dialog>
          </v-container>
        </GmapInfoWindow>
        <gmap-cluster v-if="mapLoaded" :zoom-on-click="true">
          <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            :icon="getIcon()"
            @click="toggleInfoWindow(m,index)"
          />
        </gmap-cluster>
      </GmapMap>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import AcademyService from '@/services/AcademyService'
import AcademyDialog from '@/components/displays/AcademyDialogDisplay.vue'
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import AffiliationsSelect from '@/components/inputs/AffiliationsSelect.vue'
import BackButton from "@/components/displays/BackButtonDisplay.vue";

import { 
  MapIcon,
} from '@vue-hero-icons/outline'

export default {
  data () {
    return {
      mapLoaded: false,
      markersLoaded: false,
      embed: this.$route.query.embed,
      mapCenter: {lat:21.7590005, lng:-39.9592219},
      markers: [],
      infoContent: '',
      selectedAcademy: null,
      mapHeight: window.innerHeight - 130,
      affiliation: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      mapZoom: 10,
      mapOptions: {
        disableDefaultUI : true
      },
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      legend_cluster: 'legend_cluster',
      showLegend: false
    }
  },
  components:{
    AcademyDialog,
    GmapCluster,
    AffiliationsSelect,
    MapIcon,
    BackButton
  },
  created(){
    this.getMapCenter();
    //If you need to get academies for a specific date
    //const data = await AcademyService.mapLocations({createdBefore: '2019-05-15 17:42:13.913'})
    if(this.$store.state.user.seenLegends && this.$store.state.user.seenLegends.legend_cluster)
      this.showLegend = false
    else
      this.showLegend = true
  },
  mounted() {
    //const vm = this;
    this.$refs.map.$mapPromise.then(() => {
      this.mapLoaded = true;
      /*
      const limits = {
        lat1: map.getCenter().lat()+0.5,
        lat2: map.getCenter().lat()-0.5,
        lng1: map.getCenter().lng()-0.5,
        lng2: map.getCenter().lng()+0.5
      };
      {limits: limits}
      */
      AcademyService.mapLocations()
        .then(data => {
          if (data) {
            this.fillMarkers(data);
          }
        });
      /*
      // eslint-disable-next-line
      google.maps.event.addListener(map, 'dragend', function() {
        if(!vm.affiliation){
          const bounds = map.getBounds();
          if(bounds.zb && bounds.Ua){
            const topLeftLat = bounds.zb.j;
            const bottomLeftLat = bounds.zb.h;
            const bottomLeftLong = bounds.Ua.h;
            const bottomRightLong = bounds.Ua.j;
            const limits = {
              lat1: topLeftLat,
              lat2: bottomLeftLat,
              lng1: bottomLeftLong,
              lng2: bottomRightLong
            };
            AcademyService.mapLocations({limits: limits})
              .then(data => {
                if (data) {
                  vm.fillMarkers(data);
                }
              });
          }else{
            console.log('Skipping')
          }
        }
      });
      

      // eslint-disable-next-line
      google.maps.event.addListener(map, 'zoom_changed', function() { 
        if(!vm.affiliation){
          const bounds = map.getBounds();
          if(bounds.zb && bounds.Ua){
            const topLeftLat = bounds.zb.j;
            const bottomLeftLat = bounds.zb.h;
            const bottomLeftLong = bounds.Ua.h;
            const bottomRightLong = bounds.Ua.j;
            const limits = {
              lat1: topLeftLat,
              lat2: bottomLeftLat,
              lng1: bottomLeftLong,
              lng2: bottomRightLong
            };
            AcademyService.mapLocations({limits: limits})
              .then(data => {
                if (data) {
                  vm.fillMarkers(data);
                }
              });
          }
        }
      });
    */
    });
  },
  methods: {
    updateSeenLegends(){
      this.markLegendSeen(this.$store, this.legend_cluster);
      this.showLegend = false;
    },
    fillMarkers(data){
      for(const a of data.academies){
        // eslint-disable-next-line
        var marker = new google.maps.Marker({
          position: {lat: a.latitude, lng: a.longitude},
          title: a.name,
          academy: a
        })
        this.markers.push(marker)
      }
      this.markersLoaded = true;
    },
    getIcon(){
      return this.assets('logo_map.png');
    },
    getMapCenter(){
      if(this.affiliation && this.affiliation != ''){
        this.mapZoom = 2;
        this.mapCenter = {lat:0, lng:0};
      }else if(this.$store.state.user.city){
        const center =  {
          lat:this.$store.state.user.city.latitude, 
          lng:this.$store.state.user.city.longitude
        }
        this.mapZoom = 2;
        if (center.lat)
          this.mapCenter = center;
        else
          this.mapCenter = { lat: 21.7590005, lng: -39.9592219 };
      }else{
        this.mapZoom = 2;
        this.mapCenter = {lat:21.7590005, lng:-39.9592219}
      }
    },
    toggleInfoWindow(marker, idx) {
      this.selectedAcademy = marker.academy
      this.infoWindowPos = marker.position
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    }
  },
  computed: {
  },
  watch: {
    affiliation: {
      handler: function(newValue) {
        if(newValue && newValue != ''){
          this.getMapCenter();
          this.markers = [];
          AcademyService.mapLocations({affiliation: newValue})
            .then(data => {
              if (data) {
                this.fillMarkers(data);
              }
            });
        }
      }
    }
  }
}
</script>

<style scoped>
.vue-map-container {
  position: inherit;
}
  .rotate {
    animation: rotation 2s infinite linear;
  }
      
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
      
          to {
            transform: rotate(359deg);
          }
        }
</style>