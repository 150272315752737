<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
    <v-flex v-if="isAppAdmin($store.state.user._id)">
      <v-card>
        <v-card-text v-if="partnerImage">
          <img :src="partnerImage" style="max-width:300px;max-height:300px;">
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <image-upload 
            :objId="'new_partner_image'"
            :vm="this" 
            :operation="'return_image'"
            :success-function="successFunction" >
          </image-upload>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-text>
          <v-text-field dense outlined label="Name*" v-model="newPartner.name"></v-text-field>
          <v-text-field dense outlined label="Url*" v-model="newPartner.url"></v-text-field>
          <v-text-field dense outlined label="Categories* (comma separated values)" v-model="categories"></v-text-field>
          <v-text-field dense outlined label="Country (2-letter code)" v-model="country"></v-text-field>
          <v-textarea outlined label="Description*" v-model="newPartner.description"></v-textarea>
          <v-text-field dense outlined label="Admins (comma separated IDs)" v-model="admins"></v-text-field>
          <v-switch label="For Social" v-model="newPartner.social"></v-switch>
          <v-switch label="For Admin" v-model="newPartner.admin"></v-switch>
          <v-switch label="For Elevate" v-model="newPartner.elevate"></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addPartner">
            Add
          </v-btn>
          <v-btn color="secondary" @click="goBack">
            Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex v-else>
      You should not be here
    </v-flex>
  </v-sheet>
</template>

<script>
import ImageUpload from "@/components/inputs/ImageUploadCroppie.vue";
import PartnersService from "@/services/PartnersService";
import countriesJson from "@/assets/data/countries.json";

export default {
  data() {
    return {
      newPartner: {
        url: 'https://'
      },
      partnerImage: null,
      country: null,
      admins: null,
      categories: null,
      successFunction: function(uploadedFile, vm) {
        if (vm && uploadedFile) {
          vm.partnerImage = this.userImg(uploadedFile);
          vm.newPartner.image = uploadedFile;
        }
      }
    };
  },
  components: {
    ImageUpload,
  },
  methods: {
    addPartner(){
      if(this.partnerImage){
        if(!this.country){
          this.showAlert("error", "Add Country!");
          return;
        }
        if(!this.isValidCountry()){
          this.showAlert("error", "Invalid country, use 2-letter codes!");
          return;
        }
        this.newPartner.countries = [this.country];
        this.newPartner.categories = this.stringToArray(this.categories);
        if(!this.newPartner.categories){
          this.showAlert("error", "Add Categories (comma separated)!");
          return;
        }
        this.newPartner.admins = this.stringToArray(this.admins);
        PartnersService.addPartnerAdmin(this.newPartner).then(data => {
          if (data) {
            this.showAlert(
              "success",
              'Added: ' + this.newPartner.name
            );
            this.newPartner = {
              url: 'https://'
            };
            this.partnerImage = null;
          }
        })
        .catch(err => {
          this.showAlert("error", err.response.data.message);
        });

      }else{
        this.showAlert("error", "Add Image, has to look good!");
      }
    },
    isValidCountry(){
      for(const c of countriesJson){
        if(c.countryCode == this.country)
        return true;
      }
      return false;
    },
    stringToArray(str){
      let array = [];
      if(str){
        str = str.toLowerCase();
        const tmp = str.split(',');
        if(tmp.length > 0){
          for(const s of tmp)
            array.push(s.trim());
          return array;
        }
      }
      return null;
    }
  }
};
</script>

<style scoped>
.charity_class {
  height: 100%;
}
.charity_image {
  overflow-y: hidden;
  height: 350px;
}
.charity_image img {
  width: 90%;
  margin-top: -50px;
}
.charity_text{
  overflow-y: hidden;
  height:100px;
}
</style>
