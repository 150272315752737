<template>
  <v-row no-gutters>
    <v-col v-if="belt" align="top" justify="end">
      <belt-display class="pl-2 pt-0" style="margin-top:-1px" v-if="belt" :belt="belt" :show-text="false" size="13"/>
    </v-col>
    <v-col :cols="belt ? '11' : '12'">
      <span class="mb-0 pb-0 text-body-2" v-html="transformed">
      </span>
      <a class="text-caption ml-2" v-if="text.length > maxLength" @click="showingFullText = !showingFullText">
        {{ showingFullText ? $t('message.see_less') : $t('message.see_more') }}
      </a>
    </v-col>
  </v-row>
</template>

<script>
import BeltDisplay from "@/components/displays/BeltDisplay.vue";

export default {
  props: ["text", "belt","length","displayBelt"],
  data() {
    return {
      showingFullText: false,
      hashtagsPointers: [],
      linksPointers: [],
      maxLength: 500,
      dynamicTagRegex: /<span dyn-link/g,
      hashtagRegex: /#([\wñÑ]+)/g,
      userTagRegex: /@([\w%\-&,/().µùàçéèçÇ']+\|[\w]+\|[\w]{24})/g,
      //last part ([^\n <]*)? excludes spaces or opening brackets at the end of the regexp
      linkRegex: this.getLinkRegex()
    };
  },
  created(){
    if(this.length && this.length > 0)
      this.maxLength = this.length;
  },
  components: {
    BeltDisplay
  },
  methods: {
    convertHashTags(str) {
      if (!str) str = "";
      return str.replace(
        this.hashtagRegex,
        '<a title="Check #hashtag!" href="/secure/hashtags/$1">#$1</a>'
      );
    },
    convertUserTags(str) {
      if (!str) str = "";
      let matches = [];
      let re = this.userTagRegex;
      let match = false;
      let index = 0;
      while ((match = re.exec(str))) {
        const split = match[0].split("|");
        const name = decodeURIComponent(split[0].substring(1));
        const url = split[1] + "/" + split[2];
        matches[index] = {
          link:
            '<a style="color:'+this.themeColor('primary')+'" title="Go to Page." href="/secure/' +
            url +
            '">' +
            name +
            "</a>",
          raw: match[0]
        };
        index++;
      }
      for (const m of matches) str = str.replace(m.raw, m.link);
      return str;
    },
    convertDynamicTags(str) {
      if (!str) str = "";
      str = str.replace(this.dynamicTagRegex, '<a href');
      str = str.replace(/<\/span>/g, '</a>');
      return str;
    },
    convertLinks(str) {
      if (!str) str = "";
      let matches = [];
      let match = false;
      let index = 0;
      while ((match = this.linkRegex.exec(str))) {
        matches[index] = {
          link:
            '<a title="Go to Page." target="_blank" href="' +
            match[0] +
            '">' +
            match[0] +
            "</a>",
          raw: match[0]
        };
        index++;
      }
      for (const m of matches) str = str.replace(m.raw, m.link);
      return str;
    }
  },
  computed: {
    transformed() {
      //console.log('BEFORE: ', this.text)
      let replaced = this.convertHashTags(this.text);
      //console.log('HASHTAGS: ', replaced);
      //No need to process links anymmore cuz they come ready from the dynamic input
      replaced = this.convertLinks(replaced);
      //console.log('LINKS: ', replaced);
      replaced =  this.convertDynamicTags(replaced);
      //console.log('DYNAMIC TAGS: ', replaced);
      replaced =  this.convertUserTags(replaced);
      if (this.showingFullText) {
        return replaced;
      }else{
        return replaced.slice(0, this.maxLength).trim();
      }
    }
  }
};
</script>

<style scoped>
</style>