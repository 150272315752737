<template>
    <div v-if="ready" style="cursor:pointer" @click="viewAcademyReviews()">
        <StarIcon :size="iconSize" :color="starColor1">star</StarIcon>
        <StarIcon :size="iconSize" :color="starColor2">star</StarIcon>
        <StarIcon :size="iconSize" :color="starColor3">star</StarIcon>
        <StarIcon :size="iconSize" :color="starColor4">star</StarIcon>
        <StarIcon :size="iconSize" :color="starColor5">star</StarIcon>
        <span v-if="showReviews" class="text-caption">{{count}} {{ reviewsText }}</span>
    </div>
</template>

<script>
import AcademyService from "@/services/AcademyService";

import { 
  StarIcon
} from '@vue-hero-icons/solid'

export default {
    components:{
        StarIcon
    },
    props: [
        'academyId',
        'showReviews',
        'count',
        'rating',
        'size'
    ],
    data () {
        return {
            starColor1: null,
            starColor2: null,
            starColor3: null,
            starColor4: null,
            starColor5: null,
            reviewsText: '',
            ready: false,
            iconSize: "25"
        }
    },
    created() {
        if(this.size && this.size == 'small')
            this.iconSize = '13';
        this.reviewsCount = this.count;
        if(this.rating != null){
            this.calculateRating(this.rating);
        }else if(this.academyId){
            AcademyService.rating(this.academyId).then((data) => {
                if (data) {
                    this.calculateRating(data.rating);
                }
            });
        }
    },
    methods: {
        calculateRating(rating){
            if(this.count != 1)
                this.reviewsText = this.$t('message.reviews')
            else
                this.reviewsText = this.$t('message.review')
            for(var i = 1; i <= rating; i++){
                this['starColor'+i] = '#FFBE33';
            }
            this.ready = true;
        },
        viewAcademyReviews(){
            if(this.academyId)
                this.navigateTo({ name: "academyreviews", params: { academyId: this.academyId } });
        }
    }
}
</script>

<style scoped>
.reviews-count{
    font-size: 1.35em
}
</style>