<template>
  <v-combobox
      @input="event => { emitChange(event) }"
      :items="loadedTags"
      v-model="selectedTag"
      :search-input.sync="search"
      small-chips
      outlined
      dense
      multiple
      :label="labelText"
    >
      <template slot="no-data">
        <template>
          <span class="subheading">{{ $t('message.click_enter_add_new') }}:</span>
          <v-chip small>{{ search }}</v-chip>
        </template>
      </template>
  </v-combobox>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  data() {
    return {
      selectedTag: null,
      loadedTags: null,
      labelText: null,
      search: null,
    }
  },
  created() {
    if(this.label)
      this.labelText = this.label;
    UserService.jiujitsuTags()
      .then(data => {
        if (data) this.loadedTags = data;
      });
  },
  props: {
    value: null,
    label: null
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        this.selectedTag = newValue;
      }
    }
  },
  methods: {
    emitChange (event) {
      this.$emit('input', event)
    }
  }
};
</script>

<style scoped>
</style>
