<template>
  <div v-if="!$store.state.hidden">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <v-card-title class="text-subtitle-2 pa-0">
        {{ $t("message.filter_partners") }}
      </v-card-title>
      <v-container>
        <v-row class="mt-0 pt-0 pb-0 mb-0">
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <v-text-field
              outlined
              dense
              v-model="nameFilter"
              :label="$t('message.name')"
              :clearable="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0 pb-0 mb-0">
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <category-select v-model="categoryFilter" :label="$t('message.category')"></category-select>
          </v-col>
          <v-col class="mt-0 pt-0 pb-0 mb-0">
            <CountriesSelect :list="countries" v-model="countryFilter"/>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <div class="mt-2 text-caption text-center">
      {{$t('message.partners_message')}}
    </div>
    <div>
      <div v-if="loading">
        <v-skeleton-loader
          type="card,article"
        ></v-skeleton-loader>
      </div>
      <template v-for="(p, index) in partners">
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2" :key="index+p._id" v-if="p.image">
          <v-card-actions>
            <template v-for="(c, index3) in p.countries">
              <CountryFlag
                :key="p._id+'country'+index3"
                class="mr-2"
                :countryCode="c.toUpperCase()"
              ></CountryFlag>
            </template>
          </v-card-actions>
          <v-card-text class="text-center">
            <v-avatar size="180">
              <v-img 
                class="clickable"
                @click="viewPartner(p._id)"
                :src="userImg(p.image)"
              ></v-img>
            </v-avatar>
          </v-card-text>
          <v-card-title class="text-h5">
            {{p.name}}
          </v-card-title>
          <v-card-text class="text-left text-body-2 pb-0" style="max-height: 100px; overflow-y: hidden" v-html="p.description"></v-card-text>
          <div class="text-xs-left pa-2 ma-0">
            <template v-for="(c, index2) in p.categories">
              <v-chip class="mr-2" small :key="p._id+'category'+index2">{{c}}</v-chip>
            </template>
          </div>
          <v-divider></v-divider>
          <v-card-actions class="pt-2">
            <PartnerLikes :partner="p"/>
            <v-spacer/>
            {{getHostName(p.url)}}
            <v-btn small @click="goToSite(p)" icon>
              <ExternalLinkIcon class="hero-button"></ExternalLinkIcon>
            </v-btn>
          </v-card-actions>
        </v-sheet>
      </template>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click="searchMorePartners" color="primary" :disabled="!viewMore">{{ $t('message.view_more') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";
import PartnersService from "@/services/PartnersService";
import CategorySelect from "@/components/inputs/PartnerCategorySelect";
import CountriesSelect from "@/components/inputs/CountriesSelect.vue";
import PartnerLikes from "@/components/displays/PartnerLikesDisplay.vue";

import { 
  ExternalLinkIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      partners: null,
      countryFilter: null,
      categoryFilter: null,
      nameFilter: null,
      loading: false,
      viewMore: false,
      countries: null
    };
  },
  components: {
    CountriesSelect,
    CategorySelect,
    PartnerLikes,
    CountryFlag,
    ExternalLinkIcon
  },
  async created() {
    this.filterPartners();
    this.countries = await PartnersService.countries();
  },
  methods: {
    goToSite(p) {
      PartnersService.click(p._id);
      if (p.url) 
        this.goToLink(p.url);
    },
    searchMorePartners() {
      this.filterPartners(true);
    },
    viewPartner(id) {
      this.navigateTo({ name: "viewpartner", params: { partnerId: id } });
    },
    filterPartners(append) {
      if (!this.loading) {
        this.loading = true;
        this.viewMore = false;
        if (!append) {
            this.partners = [];
            this.pointer = 0;
        }
        var params = {
            pointer: this.pointer
        };
        if (this.countryFilter && this.countryFilter != "")
          params["country"] = this.countryFilter;
        if (this.categoryFilter && this.categoryFilter != "")
          params["category"] = this.categoryFilter;
        if (this.nameFilter && this.nameFilter != "")
          params["name"] = this.nameFilter;

        PartnersService.filter(params)
          .then(data => {
            if (data) {
              this.partners = this.partners.concat(data.partners);
              this.loading = false;
              this.pointer = this.pointer + data.count;
              if (data.count >= this.searchesLimit) this.viewMore = true;
            }
          });
      }
    }
  },
  watch: {
    categoryFilter: {
      handler: function() {
        this.filterPartners();
      }
    },
    countryFilter: {
      handler: function() {
        this.filterPartners();
      }
    },
    nameFilter: {
      handler: function() {
        this.filterPartners();
      }
    }
  }
};
</script>

<style scoped>
</style>