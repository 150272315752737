<template>
  <v-container justify-center pa-0> </v-container>
</template>

<script>
export default {
  data() {
    return {
      route: this.$route.params.route,
      knownLanguages: ['en','es','pt']
    };
  },
  created() {
    this.setLang();
    let unknownLang = true;//Change me to false in the future
    const lang = this.$i18n.locale;
    if (!lang || this.knownLanguages.indexOf(lang) < 0)
      unknownLang = true;
    if (lang == 'en' || unknownLang) {
      if (this.route == "admin")
        window.location = "https://bjjlink.com/info/product/bjjlink-admin.html";
      else if (this.route == "social")
        window.location = "https://bjjlink.com/info/product/bjjlink-connect.html";
      else if (this.route == "connect")
        window.location = "https://bjjlink.com/info/product/bjjlink-connect.html";
      else if (this.route == "elevate")
        window.location = "https://bjjlink.com/info/product/bjjlink-elevate.html";
      else window.location = "https://bjjlink.com/info";
    } else {
      if (this.route == "admin")
        window.location = "https://bjjlink.com/info/" + lang + "/product/bjjlink-admin.html";
      else if (this.route == "social")
        window.location = "https://bjjlink.com/info/" + lang + "/product/bjjlink-connect.html";
      else if (this.route == "social")
        window.location = "https://bjjlink.com/info/" + lang + "/product/bjjlink-connect.html";
      else if (this.route == "elevate")
        window.location = "https://bjjlink.com/info/" + lang + "/product/bjjlink-elevate.html";
      else window.location = "https://bjjlink.com/info/" + lang + "/";
    }
  },
};
</script>

<style scoped></style>
