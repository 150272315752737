<template>
    <v-card-text v-if="isSmallerDisplay()">
        <v-btn x-small elevation="0" @click.stop="goBack()">
            <ChevronLeftIcon style="margin-bottom: 0px;"></ChevronLeftIcon> {{ $t('message.back') }}
        </v-btn>
    </v-card-text>
</template>

<script>
import {
    ChevronLeftIcon,
} from '@vue-hero-icons/outline'

export default {
    components: {
        ChevronLeftIcon,
    }
}
</script>

<style scoped>
</style>
