<template>
  <v-card rounded="lg" elevation="0">
    <dynamic-input v-model="contents" :managed-academy="managedAcademy"
      style="margin-left: 5px; margin-right: 5px; padding-top: 5px;"
      :label="$t('message.new_post_text')"></dynamic-input>
    <input style="display: none" type="file" accept="video/*" name="video" @change="onVideoFileSelect"
      ref="videoFileUploadInput" />
    <input style="display: none" type="file" accept="image/*" @change="onPhotoFileSelect" ref="fileUploadInput" />
    <v-card-text v-if="uploadedVideoFile">
      <v-flex xs12>
        <v-card class="pa-2" elevation="0">
          <div>
            <div class="text-caption">
              <v-icon color="green">mdi-cloud-upload-outline</v-icon>
              {{ $t('message.video_processing_post') }}
            </div>
          </div>
          <v-switch v-model="acknowledgement" :label="$t('message.video_acknowledgement')"></v-switch>
        </v-card>
      </v-flex>
    </v-card-text>
    <v-card-actions v-if="showTagLinks" transition="slide-x-reverse-transition">
      <academy-members ref="userTagsCombo" :user="$store.state.user._id" v-model="userTags"></academy-members>
    </v-card-actions>
    <v-card-actions v-if="showLocationPicker" transition="slide-x-reverse-transition"><post-locations
        v-model="postLocation" style="width: 100%" />
    </v-card-actions>
    <v-card-text v-if="createPoll" elevation="0">
      <v-flex xs12>
        <v-card class="pa-2" v-if="poll">
          <v-card-title style="margin-bottom:-25px">
            <ChartSquareBarIcon class="hero-icon"></ChartSquareBarIcon>{{$t('message.poll')}}
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="poll.question" :label="$t('message.question')" maxlength="60"
              counter="60"></v-text-field>
          </v-card-text>
          <v-card-text style="margin-top:-35px">
            <v-text-field :key="'poll_answer_'+index" v-for="(i, index) in poll.answers" v-model="poll.answers[index]"
              :label="$t('message.answer')+' '+(index+1)" maxlength="40" counter="40"></v-text-field>
          </v-card-text>
          <v-card-actions class="pa-1" v-if="poll.question && poll.answers.length < 5">
            <v-btn class="elevation-0" color="secondary" small @click="addPollAnswer()">
              <PlusCircleIcon class="hero-button"></PlusCircleIcon>
              {{$t('message.answer')}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-card-text>
    <v-card-text v-if="uploadedFile">
      <v-flex xs12>
        <v-card class="text-center" elevation="0">
          <div>
            <ImageDisplay style="width: 100%;" :src="uploadedFile"></ImageDisplay>
          </div>
          <div class="mt-1">
            <v-btn elevation="0" small color="error" block @click="removeImage()">
              <TrashIcon class="hero-button mr-1"></TrashIcon>
              {{ $t('message.remove') }}
            </v-btn>
          </div>
        </v-card>
      </v-flex>
    </v-card-text>
    <v-card-text v-if="videoFound && !uploadedFile">
      <v-flex xs12>
        <div slot="extension" v-html="videoContents"></div>
        <v-btn fab x-small color="error" top right @click="removeVideo()">
          <TrashIcon class="hero-button"></TrashIcon>
        </v-btn>
      </v-flex>
    </v-card-text>
    <v-card-text v-else-if="!videoFound && linkFound">
      <fetched-url v-model="imageFetched" :url="linkUrl" />
      <div class="mt-1">
        <v-btn class="elevation-0" v-if="imageFetched" block small color="error" @click="removeLink()">
          <TrashIcon class="hero-button mr-1"></TrashIcon>
          {{ $t('message.remove') }}
        </v-btn>
      </div>
    </v-card-text>
    <div v-if="uploadingFile" class="mt-2">
      <v-progress-linear buffer-value="5" color="success" height="15" stream :value="uploadPercentage">
      </v-progress-linear>
    </div>
    <v-card-actions class="pt-0 mt-1">
      <v-btn v-if="$store.state.userManagedAcademy && managedAcademy && feed == 'academy'" elevation="0"
        fab small outlined color="primary" @click="showTags()">
        <v-icon>mdi-tag-plus-outline</v-icon>
      </v-btn>
      <v-btn v-if="false" elevation="0" style="margin-left: 5px" color="blue" fab small
        @click="triggerLocationPicker()">
        <v-icon>mdi-map-marker-plus-outline</v-icon>
      </v-btn>
      <v-btn class="elevation-0" :disabled="uploadingFile" @click="filePicker()" color="primary" outlined fab small>
        <v-icon v-if="!uploadingFile">mdi-image-plus-outline</v-icon>
        <v-progress-circular v-else size="20" indeterminate color="white"></v-progress-circular>
      </v-btn>
      <v-btn elevation="0" @click="videoFilePicker()" color="primary" fab outlined small>
        <v-icon>mdi-video-plus-outline</v-icon>
      </v-btn>
      <v-btn v-if="false" elevation="0" @click="showOptions('poll')" fab small color="black">
        <v-icon color="white">mdi-chart-box-outline</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn small elevation="0"
        :disabled="uploadingFile || disableSubmitButton || (uploadedVideoFile && !acknowledgement)" color="primary"
        @click="submitPost">
        {{ $t("message.share") }}
      </v-btn>
    </v-card-actions>
    <v-card-actions v-if="displayNotify && !uploadedVideoFile" class="pb-0 pt-0">
      <v-switch v-model="notifyMembers" :label="$t('message.notify_members')"></v-switch>
    </v-card-actions>
  </v-card>
</template>

<script>
import FileService3 from "@/services/FileService3";
import AcademyMembers from "@/components/inputs/MyAcademyUsers.vue";
import FetchedUrl from "@/components/displays/FetchedUrlDisplay.vue";
import PostLocations from "@/components/inputs/PostLocations";
import DynamicInput from "@/components/inputs/DynamicInput";
import ImageDisplay from "@/components/displays/ImageDisplay.vue";

export default {
  data() {
    return {
      uploadedVideoFileId: null,
      uploadingFile: false,
      uploadPercentage: 0,
      uploadedVideoThumb: null,
      vm: this,
      contents: null,
      selectedFile: null,
      callWhenDone: null,
      uploading: false,
      disableSubmitButton: false,
      uploadedFile: null,
      uploadedVideoFile: null,
      videoUploaded: false,
      //uploadedVideoThumbnail: null,
      createPoll: false,
      poll: {
        answers: ['','']
      },
      privatePost: false,
      managedAcademyId: null,
      acknowledgement: false,
      videoTitle: null,
      videoTags: [],
      videoCategory: null,
      videoFound: false,
      uploadingMessage: null,
      postLocation: null,
      linkFound: false,
      videoContents: null,
      linkContents: null,
      displayNotify: false,
      notifyMembers: false,
      videoId: null,
      linkUrl: null,
      userTags: null,
      imageFetched: false,
      showTagLinks: false,
      showLocationPicker: false,
      canLongerVideo: false,
      postFunction: null,
      onUploadProgress: function () { },
      mediaRules: [
        (v) => !v || v.size < 9000000 || "Image should be less than 9MB",
      ],
      linkRegex: this.getLinkRegex(),
      videoRegex: /(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/,
      videoRegex2: /(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})\W/,
    };
  },
  components: {
    AcademyMembers,
    FetchedUrl,
    PostLocations,
    DynamicInput,
    ImageDisplay,
  },
  props: ["clear", "submit", "longerVideo","private","managedAcademy","isAdmin","feed"],
  watch: {
    imageFetched: {
      handler: function (newValue) {
        if (!newValue) this.imageFetched = false;
      },
    },
    private: {
      handler: function (newValue) {
        //console.log('Private is changing: ', newValue);
        this.privatePost = newValue;
      },
    },
    managedAcademy: {
      handler: function (newValue) {
        //console.log('ManagedAcademy is changing: ', newValue);  
        this.managedAcademyId = newValue;
      },
    },
    clear: {
      handler: function (newValue) {
        const b = new Boolean(newValue);
        if (b) this.clearParameters();
      },
    },
    /*
    contents: {
      handler: function (val) {
        //console.log('New value updated: ', val);
        if (val) {
          let newValue = val.replaceAll('<br>','').replaceAll('</div>','');
          if (!this.videoFound && !this.linkFound) {
            var res = newValue.match(this.videoRegex);
            if (res) {
              if (res[0].indexOf("?v=") >= 0) {
                const arr = res[0].split("?v=");
                const vid = arr[1].split("&");
                this.videoId = vid[0];
              } else {
                const arr = res[0].split("/");
                if (arr.length > 0) this.videoId = arr[arr.length - 1];
              }
            } else {
              res = newValue.match(this.videoRegex2);
              if (res) {
                const arr = res[0].split("?v=");
                const vid = arr[1].split("&");
                this.videoId = vid[0];
              }
            }
            if (this.videoId) {
              this.videoFound = true;
              this.videoContents = this.getVideoIFrame(this.videoId);
            } else {
              this.videoFound = false;
              this.videoContents = null;
              res = newValue.match(this.linkRegex);
              if (res) {
                this.linkUrl = res[0];
                this.linkFound = true;
              }
            }
          }
        }
      },
    },
    */
  },
  created() {
    if (this.private) {
      this.privatePost = this.private;
      if (this.isAdmin) {
        this.displayNotify = true;
      }
    }
    if(this.managedAcademy)
      this.managedAcademyId = this.managedAcademy;
    if (this.longerVideo) this.canLongerVideo = true;
    if (this.submit) this.postFunction = this.submit;
  },
  methods: {
    async uploadNewImage() {
      const vm = this;
      this.uploadingFile = true;
      const formData = new FormData();
      formData.append("image", this.selectedFile);
      const response = await FileService3.uploadImage(formData, function (progressEvent) {
        const percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        if (percent >= 97)
          vm.uploadPercentage = 97;//Stay on 97
        else
          vm.uploadPercentage = percent;
      });
      if (response && response.location) {
        const newImage = response.location;
        this.uploadedFile = newImage;
        this.uploadingFile = false;
      }
    },
    addPollAnswer(){
      if(this.poll && this.poll.answers && this.poll.answers.length < 5){
        this.poll.answers.push('');
      }
    },
    showOptions(button){
      if(button == 'poll'){
        this.createPoll = !this.createPoll;
      }else if(button == 'video'){
        this.createPoll = false;
      }
    },
    showTags() {
      if (this.showTagLinks) this.userTags = null;
      this.showTagLinks = !this.showTagLinks;
    },
    triggerLocationPicker() {
      this.showLocationPicker = !this.showLocationPicker;
      if (!this.showLocationPicker) this.postLocation = null;
    },
    submitPost() {
      this.submitButton = false;
      this.disableSubmitButton = true;
      if (this.submit) {
        let contents = this.contents;
        if(this.uploadedVideoFile && (!contents || contents == ''))
          contents = '#video';
        else if(this.uploadedFile && (!contents || contents == ''))
          contents = '#photo';
        if(!contents && !this.uploadedVideoFile && !this.uploadedFile){
          if(this.createPoll 
            && this.poll 
            && this.poll.answers.length > 1 
            && this.poll.answers[0] != ""){
            contents = '#poll';
          }
        }
        let hashTags = this.extractHashTags(this.contents);
        let data = {
          contents: contents,
          time: this.getNowDate(),
          image: this.uploadedFile,
          notifyMembers: !this.uploadedVideoFile && this.notifyMembers ? true : false,
          category: "social_post",
          video: this.uploadedVideoFile,
          fileId: this.uploadedVideoFileId,
          poll: this.poll, 
          videoId: this.videoId,
          link: this.linkUrl,
          managedAcademy: this.privatePost ? this.managedAcademyId : null,
          privateMode: this.privatePost ? true : false,
          userTags: this.userTags,
          hashTags: hashTags,
          location: this.postLocation,
        };
        var vm = this;
        this.postFunction(data, function () {
          vm.postLocation = null;
          vm.disableSubmitButton = false;
          vm.uploadingMessage = null;
          vm.clearParameters();
        });
        this.contents = null;
        this.disableSubmitButton = false;
        this.postLocation = null;
        this.showLocationPicker = false;
        this.showTagLinks = false;
      }
    },
    clearParameters() {
      this.acknowledgement = false;
      this.uploadingMessage = null;
      this.contents = null;
      this.uploadedFile = null;
      this.uploadingFile = false;
      this.postLocation = null;
      this.uploadedVideoFile = null;
      this.videoTags = [];
      this.videoUploaded = false;
      this.videoCategory = null;
      this.notifyMembers = false;
      this.videoTitle = null;
      this.poll = {
        answers: ['','']
      };
      this.createPoll = false;
      this.uploadedVideoThumb = null;
      this.videoId = null;
      if (this.$refs.userTagsCombo) this.$refs.userTagsCombo.clearAll();
      this.removeVideo();
      this.removeUploadedVideo();
      this.removeLink();
    },
    removeImage() {
      this.uploadedFile = null;
      this.$emit("input", null);
      this.$refs.fileUploadInput.value = null;
      this.clearParameters();
    },
    removeUploadedVideo() {
      this.uploadedVideoFile = null;
      this.uploadingFile = false;
      this.$refs.videoFileUploadInput.value = null;
      this.$emit("input", null);
    },
    filePicker() {
      this.uploadingFile = false;
      this.uploadedVideoFile = null;
      this.videoTags = [];
      this.videoCategory = null;
      this.videoTitle = null;
      this.uploadingFile = false;
      this.$refs.fileUploadInput.click();
    },
    videoFilePicker() {
      this.$refs.videoFileUploadInput.click();
    },
    onVideoFileSelect(event) {
      try {
        if (event.target.files[0]) {
          const size = event.target.files[0].size;
          const sizeMb = (size / 1000000).toFixed(1);
          if (sizeMb > 300.0) {
            this.showAlert(
              "error",
              this.$t("message.video_too_big") + " (< 300 MB)"
            );
            return;
          } else {
            this.videoUploaded = false;
            this.selectedFile = event.target.files[0];
            this.uploadedVideoFile = null;
            this.uploadedFile = null;
            this.uploadVideo();
          }
        } else {
          this.uploadingFile = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    onPhotoFileSelect(event) {
      this.uploadingFile = true;
      if (event.target.files[0]) {
        this.uploadedVideoFile = null;
        this.uploadedFile = null;
        this.selectedFile = event.target.files[0];
        const size = event.target.files[0].size;
        const sizeMb = Math.floor(size / 1000000);
        if (sizeMb <= 15) {
          this.uploadNewImage();
        } else {
          this.uploadingFile = false;
          this.showAlert(
            "error",
            "Sorry! Your image is too big (" +
              sizeMb +
              "mb), try a smaller one."
          );
        }
      } else {
        this.uploadingFile = false;
      }
    },
    async uploadVideo() {
      this.uploadingFile = true;
      const vm = this;
      const formData = new FormData();
      formData.append("video", this.selectedFile, this.selectedFile.name);
      try {
        const response = await FileService3.uploadVideo(formData, function (progressEvent) {
          const percent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
          if (percent >= 97)
            vm.uploadPercentage = 97;//Stay on 97
          else
            vm.uploadPercentage = percent;
        });
        if (response && response.location) {
          this.uploadedVideoThumb = response.thumbnail;
          this.uploadedVideoFile = response.location;
          this.uploadedVideoFileId = response.fileId;
          this.uploadingFile = false;
        }
      } catch (err) {
        this.uploadingFile = false;
        const errorMessage = this.$t("message.oops_not_working");
        this.clearParameters();
        //console.log('We are here uploading video error2!', err)
        this.showAlert("error", errorMessage);
      }
    },
    getVideoIFrame(videoId) {
      var iFrame =
        '<div class="yt-video-wrapper text-center"><iframe width="100%" height="315" ' +
        'src="https://www.youtube.com/embed/' +
        videoId +
        '" ' +
        'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe></div>';
      return iFrame;
    },
    removeVideo() {
      this.videoFound = false;
      this.videoContents = null;
    },
    removeLink() {
      this.linkFound = false;
      this.linkUrl = null;
      this.linkContents = null;
      this.imageFetched = false;
    },
  },
};
</script>

<style scoped>
.yt-video-wrapper {
  position: relative;
  position: relative;
  padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
  /* padding-bottom: 62.5%; - 16:10 aspect ratio */
  /* padding-bottom: 75%; - 4:3 aspect ratio */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.yt-video-wrapper iframe,
.yt-video-wrapper object,
.yt-video-wrapper embed {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
