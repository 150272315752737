<template>
  <v-autocomplete v-if="cities"
    @input="event => { emitChange(event) }"
    :items="cities"
     v-model="selectedCity"
    :label="inputLabel"
    item-text="name"
    item-value="_id"
    :clearable="true"
    :filter="search"
    :hide-details="true"
    :disabled="disabled"
    outlined
    dense
  >
    <template slot="item" slot-scope="data">
      <v-list-item-content>
        <v-list-item-content>
          <v-list-item-title>
            {{data.item.name}}<span v-if="data.item.state">, {{data.item.state}}</span><span v-if="data.item.countryName">, {{data.item.countryName}}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import CityService from "@/services/CityService";

export default {
  data() {
    return {
      selectedCity: "",
      cities: null,
      loading: true,
      inputLabel: this.$t('message.select_city')
    };
  },
  created() {
    if(this.label)
      this.inputLabel = this.label;
    this.getCities(this.country);
  },
  props: {
    value: null,
    label: null,
    disabled: {type: Boolean, default:false},
    country: null
  },
  watch: {
    value: {
      immediate: true,
      handler: function(newValue) {
        this.selectedCity = newValue;
      }
    },
    country:{
      immediate: false,
      handler: function(newValue) {
        this.getCities(newValue);
      }
    }
  },
  methods: {
    getCities(country){
      CityService.cities(country)
        .then(data => {
          if (data) {
            this.cities = data;
            this.loading = false;
          }
        });
    },
    emitChange(event) {
      this.$emit("input", event);
    },
    search: (item, queryText, itemText) => {
      const hasValue = val => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);
      const evaluation = 
        text
          .toString()
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 || 
        (item.countryName ? 
          item.countryName
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 : false) || 
        (item.stateName ? 
          item.stateName
          .toLowerCase()
          .indexOf(query.toString().toLowerCase()) > -1 : false);
      return (
        evaluation
      );
    }
  }
};
</script>

<style scoped>
</style>
