<template>
    <v-flex xs12>
        <v-card elevation="0" light class="academy_dialog">
            <div class="academy_image" v-if="academy.image">
                <a @click="goToAcademy"><img width="100%" :src="userImg(academy.image)" /></a>
            </div>
            <v-card-title primary-title>
                <div class="headline"><a style="color:black" @click="goToAcademy">{{ academy.name }}</a></div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <div class="text-subtitle-1">{{ academy.address }}</div>
                <div>{{ academy.cityDisplay }}</div>
            </v-card-text>
            <v-card-actions v-if="!hideButton">
                <v-spacer></v-spacer>
                <v-btn elevation="0" small color="primary" @click="goToAcademy">
                    {{ $t('message.see_page') }}
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-flex>
</template>

<script>

export default {
    props: [
        'academy',
        'hideButton'
    ],
    data() {
        return {
        }
    },
    components: {
    },
    methods: {
        goToAcademy() {
            this.navigateTo({ name: 'viewacademy', params: { academyId: this.academy._id } })
        }
    }
}
</script>

<style scoped>
.members_count {
    font-weight: bold
}

.academy_dialog {
    border: 0px;
    box-shadow: 0px 0px;
}

.academy_image {
    overflow-y: hidden;
    height: 100px
}

.academy_image img {
    width: 90%;
    margin-top: -20%;
}
</style>