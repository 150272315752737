<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="iconSize" :height="iconSize" viewBox="0 0 24 24" :fill="iconColor"><path d="M0 0h24v24H0z" fill="none"/><path d="M13 16.12c3.47-.41 6.17-3.36 6.17-6.95 0-3.87-3.13-7-7-7s-7 3.13-7 7c0 3.47 2.52 6.34 5.83 6.89V20H5v2h14v-2h-6v-3.88z"/>
  </svg>
</template>
<script>
//:fill="iconColor"
export default {
  data() {
    return {
      iconColor: "gray",
      iconSize: "24px"
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
    if(this.size)
      this.iconSize = this.size;
  },
  props: ['color','size'],
  watch: {
    color: {
      handler: function (newValue) {
        this.iconColor = this.getColor(newValue);
      },
    },
  },
};
</script>
<style>
</style>