import Api from "@/services/Api";

export default {
  login(credentials) {
    return Api().post("users/login", credentials);
  },
  fbLogin(credentials) {
    return Api().post("fbconnect/login", credentials);
  },
  signup(newuser) {
    return Api().post("users/signup", newuser);
  },
  fbSignup(newuser) {
    return Api().post("fbconnect/signup", newuser);
  },
  fbConnect(data) {
    return Api().patch("fbconnect/connect", data);
  },
  isFBConnected() {
    return Api().get("fbconnect/isconnected");
  },
  forgotPassword(data) {
    return Api().post("recovery/forgot-password", data);
  },
  resetPassword(data) {
    return Api().post("recovery/reset-password", data);
  },
  updateEmail(data) {
    return Api().post("recovery/update-email", data);
  },
  authenticateWithToken(credentials) {
    return Api().post("users/authapp", credentials);
  },
  logout() {
    return Api().get("users/logout");
  },
};
