<template>
  <div class="fill-height" style="overflow-y:hidden">
    <v-card 
      class="mt-2 pa-2"
      :height="mapHeight"
      elevation="0" 
      v-if="!$store.state.hidden" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-chip @click="loadListView()" medium v-if="mapLoaded" dark style="z-index: 100">
        <v-btn elevation="0" icon x-small color="primary" class="mr-2"><v-icon>mdi-bank</v-icon></v-btn> {{ $t("message.view_list_view") }}
      </v-chip>
      <v-container>
        <div v-if="!markersLoaded">
          <v-skeleton-loader
            class="mx-auto"
            type="image"
          ></v-skeleton-loader>
        </div>
      <GmapMap
        v-if="mapCenter"
        v-show="markersLoaded"
        :center="mapCenter"
        :zoom="mapZoom"
        map-type-id="roadmap"
        :options="mapOptions"
        ref="map"
      >
        <GmapInfoWindow
          v-if="mapLoaded"
          :options="infoOptions" 
          :position="infoWindowPos" 
          :opened="infoWinOpen" 
          @closeclick="infoWinOpen=false"
        >
          <v-container class="info_window" >
            <academy-dialog :hide-button="embed != 'true' ? false : true" v-if="selectedAcademy" :academy="selectedAcademy"></academy-dialog>
          </v-container>
        </GmapInfoWindow>
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          :icon="getIcon()"
          @click="toggleInfoWindow(m,index)"
        />
      </GmapMap>
      </v-container>
    </v-card>
    <v-dialog v-model="academiesListDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card elevation="0">
        <v-toolbar dense flat>
          <v-spacer></v-spacer>
          <v-btn icon @click="academiesListDialog = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="markers.length == 0" class="text-center text-h6">
          {{ $t("message.no_academies_match") }}
        </v-card-text>
        <v-list>
          <v-list-item dense :key="i._id" v-for="(i) in markers" @click="goToAcademy(i._id)">
            <v-list-item-avatar v-if="i.academy.image">
              <img :src="userFile(i.academy.image)" />
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <v-icon>mdi-bank</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{i.academy.name}}</v-list-item-title>
              <v-list-item-subtitle>
                <span class="mr-2" v-if="i.academy.internationalAffiliation">{{i.academy.internationalAffiliation}}</span>
                <span class="font-weight-bold" v-if="i.academy.metric == 'miles'">{{ i.academy.distanceMiles }} {{ $t("message.miles") }}</span>
                <span class="font-weight-bold" v-else>{{ i.academy.distanceKm }} km</span>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="mr-1" v-if="i.academy.yourAcademy">
              <v-icon color="error">mdi-heart-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-action class="mr-1" v-else-if="i.academy.sameAffiliation || i.academy.members.length">
              <v-icon color="success">mdi-check-circle</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ConnectService from '@/services/ConnectService'
import AcademyDialog from '@/components/displays/AcademyDialogDisplay.vue'


export default {
  data () {
    return {
      mapLoaded: false,
      dataLoaded: false,
      loadingMap: true,
      metric: 'miles',
      lat: this.$route.query.lat,
      lon: this.$route.query.lon,
      markersLoaded: false,
      embed: this.$route.query.embed,
      mapCenter: null,
      markers: [],
      academies: null,
      infoContent: '',
      selectedAcademy: null,
      mapHeight: window.innerHeight - 130,
      affiliation: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      mapZoom: 12,
      academiesListDialog: null,
      mapOptions: {
        disableDefaultUI : true
      },
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      legend_cluster: 'legend_cluster',
      showLegend: false
    }
  },
  components:{
    AcademyDialog,
  },
  created(){
    if (!this.lat) {
      this.lat = this.$store.state.lat;
      this.lon = this.$store.state.lon;
    }
    if (this.lat) {
      this.mapCenter = { lat: new Number(this.lat).valueOf(), lng: new Number(this.lon).valueOf() }
    }
  },
  mounted() {
    //const vm = this;
    let vm = this;
    ConnectService.findNearby(this.lat, this.lon)
      .then(data => {
        if (data) {
          if (!this.mapCenter) {
            this.mapCenter =  { lat: data.lat, lng: data.lon }
          }
          this.mapLoaded = true;
          this.metric = data.metric;
          this.academies = data.academies;
          if (vm.$refs.map) {
            this.loadMap(data)
          } else {
            setTimeout(function () { vm.loadMap(data)}, 1000)
          }
        }
      });
  },
  methods: {
    loadMap(data) {
      let vm = this;
      this.$refs.map.$mapPromise.then(() => {
        vm.metric = data.metric;
        if (!vm.lat) {
          vm.getMapCenter(data.lat, data.lon);
        }
        vm.fillMarkers(data);
      });
    },
    getIcon() {
      return 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png';
    },
    loadListView() {
      this.academiesListDialog = true;
    },
    fillMarkers(){
      for(const a of this.academies){
        // eslint-disable-next-line
        var marker = new google.maps.Marker({
          position: {lat: a.latitude, lng: a.longitude},
          title: a.name,
          _id: a._id,
          academy: a
        })
        this.markers.push(marker)
      }
      this.markersLoaded = true;
    },
    getMapCenter(lat, lon){
      this.mapZoom = 12;
      if (this.lat && this.lon) {
        this.mapCenter = { lat: new Number(this.lat).valueOf(), lng: new Number(this.lon).valueOf() }
      } else if(lat) {
        this.mapCenter = { lat: lat, lng: lon }
      } else if(this.$store.state.lat){
        this.mapCenter = { lat: this.$store.state.lat, lng: this.$store.state.lon }
      }
    },
    toggleInfoWindow(marker, idx) {
      this.selectedAcademy = marker.academy
      this.infoWindowPos = marker.position
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    }
  }
}
</script>

<style scoped>
.vue-map-container {
  position: inherit;
}
  .rotate {
    animation: rotation 2s infinite linear;
  }
      
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
      
          to {
            transform: rotate(359deg);
          }
        }
</style>