<template>
  <div>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-actions class="text-right pb-0">
        <user-select class="mt-1" style="margin-bottom:-14px" v-if="threadRecipients" :users="threadRecipients"
          :label="$t('message.filter_by_recipient')" v-model="filterMessagesUser"></user-select>
        <v-spacer></v-spacer>
        <v-btn small class="elevation-0 mb-1" @click="openDialog()" color="primary">{{
        $t("message.new")
        }}
        </v-btn>
      </v-card-actions>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="loading" class="mt-2">
      <v-skeleton-loader type="list-item-avatar, list-item-avatar, list-item-avatar"></v-skeleton-loader>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="academyThreads && academyThreads.length > 0" class="mt-2">
      <div v-if="academyThreads && academyThreads.length > 0" two-line>
        <v-subheader>
          {{$t('message.academy_group_chats')}}
        </v-subheader>
        <template v-for="(t, index) in academyThreads">
          <v-list-item :key="t._id" @click="viewMessages(t)">
            <v-list-item-avatar v-if="t.title">
              <img v-if="t.image" :src="userImg(t.image)" />
              <v-btn fab small v-else>
                <UserGroupIcon class="hero-button"></UserGroupIcon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-avatar v-else :title="'Last message sent by ' + t.lastMessageSender.fName">
              <img v-if="t.lastMessageSender._id != $store.state.user._id"
                :src="userImg(t.lastMessageSender.userImageSmall)" />
              <img v-else :src="getRecipientImage(t)" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-if="t.title">
                {{ t.title}}
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ getRecipientsDisplay(t.recipients) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="text-caption">
              <div>
                {{ $d(new Date(t.lastMessageOn), "month_day", $i18n.locale) }}
                <v-badge right overlap class="hero-icon ml-2" v-if="t.newMessages > 0"
                  :title="getLastMessageSentTime(t.lastMessageOn)" color="primary">
                  <span slot="badge">{{ t.newMessages }}</span>
                  <ChatAltIcon size="20"></ChatAltIcon>
                </v-badge>
                <span v-else>
                  &nbsp;
                </span>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < academyThreads.length" :key="index"></v-divider>
        </template>
      </div>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="threads && threads.length > 0" class="mt-2 mb-2">
      <div v-if="threads && threads.length > 0" two-line>
        <template v-for="(t, index) in threads">
          <v-list-item :key="t._id" @click="viewMessages(t)">
            <v-list-item-avatar v-if="t.title">
              <v-btn fab small>
                <UserGroupIcon class="hero-button">groups</UserGroupIcon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-avatar v-else :title="'Last message sent by ' + t.lastMessageSender.fName">
              <img v-if="t.lastMessageSender._id != $store.state.user._id"
                :src="userImg(t.lastMessageSender.userImageSmall)" />
              <img v-else :src="getRecipientImage(t)" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-if="t.title">
                {{ t.title}}
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ getRecipientsDisplay(t.recipients) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="text-caption">
              <div>
                {{ $d(new Date(t.lastMessageOn), "month_day", $i18n.locale) }}
                <v-badge right overlap class="hero-icon ml-2" v-if="t.newMessages > 0"
                  :title="getLastMessageSentTime(t.lastMessageOn)" color="primary">
                  <span slot="badge">{{ t.newMessages }}</span>
                  <ChatAltIcon size="20"></ChatAltIcon>
                </v-badge>
                <span v-else>
                  &nbsp;
                </span>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider v-if="index + 1 < threads.length" :key="index"></v-divider>
        </template>
      </div>
      <v-card-actions v-if="viewMore">
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="getThreads(true)">{{ $t("message.view_more") }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-else-if="!loading && (threads == null || threads.length == 0)"
      class="mt-2 mb-2">
      <div class="text-subtitle-2 pa-2 text-center">
        {{$t("message.no_messages")}}
      </div>
    </v-sheet>
    <v-dialog v-model="viewThreadDialog" fullscreen scrollable hide-overlay transition="dialog-bottom-transition">
      <v-card item style="overflow-y: hidden" v-if="selectedThread">
        <div>
          <v-toolbar dark flat class="grey darken-3">
            <v-btn small icon @click="closeThreadPanel()">
              <XCircleIcon class="hero-button"></XCircleIcon>
            </v-btn>
            <v-toolbar-title>
              <template v-for="(u, index) in selectedThread.recipients">
                <v-avatar v-if="index < 5" @click="goToUser(u._id)" style="margin-right: 5px"
                  :title="u.fName + ' ' + u.lName" :key="u._id" size="25px" class="grey lighten-4 clickable">
                  <img v-if="!u.userImageSmall" :src="assets('blank-profile-picture.png')" alt="avatar" />
                  <img v-else :src="userImg(u.userImageSmall)" alt="avatar" />
                </v-avatar>
              </template>
              <span v-if="selectedThread.recipients && selectedThread.recipients.length >= 5">...</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="displayConfiguration()" small icon elevation="0"
              :color="configurationPanel ? 'primary' : ''">
              <AdjustmentsIcon class="hero-button"></AdjustmentsIcon>
            </v-btn>
            <v-btn v-if="$store.state.user._id == selectedThread.sender._id" @click="showDeleteThreadConfirm()" small
              icon elevation="0">
              <TrashIcon class="hero-button" title="Delete Thread"></TrashIcon>
            </v-btn>
          </v-toolbar>
          <v-toolbar dark class="grey darken-3" flat v-if="selectedThread.title">
            <v-toolbar-title>
              {{selectedThread.title}}
            </v-toolbar-title>
          </v-toolbar>
        </div>
        <v-card-text v-if="configurationPanel" class="configuration-panel" transition="dialog-bottom-transition">
          <div>
            <v-switch v-model="selectedThread.skipNotifications" @change="skipNotifications"
              :label="$t('message.notifications')"></v-switch>
          </div>
          <div v-if="$store.state.user._id == selectedThread.sender._id">
            <v-text-field v-model="selectedThread.title" :label="$t('message.title')" maxlength="20" counter="20"
              outlined dense @blur="updateThreadTitle()"></v-text-field>
          </div>
          <div v-if="$store.state.user._id == selectedThread.sender._id">
            <div v-if="selectedThread.managedAcademy">
              <div class="supporting-text text-left">{{$t("message.add_academy_member")}}</div>
              <user-select v-if="academyMembers" :users="academyMembers" v-model="extraRecipient"></user-select>
              <div v-else class="text-left text-h4">
                {{$t("message.no_members_to_add")}}
              </div>
            </div>
            <div v-else>
              <div class="supporting-text text-left">{{$t("message.add_participant")}}</div>
              <user-select v-if="mutualLinks" :users="mutualLinks" v-model="extraRecipient"></user-select>
              <div v-else>
                <h4>{{$t("message.no_users_to_add")}}</h4>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div class="supporting-text text-left">{{$t("message.participants")}}</div>
            <div class="participants-box">
              <template v-for="(u, index) in selectedThread.recipients">
                <v-list-item :key="'recipient_'+index+u._id" @click="goToUser(u._id)">
                  <v-list-item-avatar size="35px">
                    <img v-if="!u.userImageSmall" :src="assets('blank-profile-picture.png')" alt="avatar" />
                    <img v-else :src="userImg(u.userImageSmall)" alt="avatar" />
                  </v-list-item-avatar>
                  <v-list-item-title>
                    {{u.displayName}}
                  </v-list-item-title>
                  <v-list-item-action v-if="$store.state.user._id == selectedThread.sender._id">
                    <v-btn @click="removeParticipant(u)" small icon>
                      <TrashIcon class="hero-button" color="secondary" size="20"></TrashIcon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="index + 1 < selectedThread.recipients.length" :key="index+'_recipient_divider'">
                </v-divider>
              </template>
            </div>
          </div>
        </v-card-text>
        <v-card-text ref="messagesDisplay" v-if="!configurationPanel">
          <div v-if="loadingMessages">
            <circles-loader></circles-loader>
          </div>
          <v-card-actions v-if="viewMoreMessages" class="mt-2">
            <v-spacer></v-spacer>
            <v-btn color="primary" small @click="getThreadMessages(selectedThread._id, true)">
              {{ $t("message.earlier_messages") }}
              <span v-if="earlierUnreadCount > 0" style="margin-left: 15px">{{
              earlierUnreadCount
              }}</span>
              <span v-if="earlierUnreadCount > 0">new</span>
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <div v-if="loadedMessages" two-line style="">
            <template v-for="(dayMessages, messageDay) in loadedMessages">
              <div :key="'message_date_'+messageDay" style="margin-bottom:15px">
                <div class="text-center supporting-text">{{messageDay}}</div>
                <template v-for="m in dayMessages">
                  <!-- SYSTEM MESSAGE -->
                  <div :key="selectedThread._id + m._id" v-if="m.source == 'system'"
                    class="system_message_area supporting-text">
                    <div>
                      <v-tooltip top v-if="m.participant">
                        <template v-slot:activator="{ on, attrs }">
                          <v-avatar v-if="m.participant" v-bind="attrs" v-on="on" size="25px">
                            <img v-if="!m.participant.userImageSmall" :src="assets('blank-profile-picture.png')"
                              alt="avatar" />
                            <img v-else :src="userImg(m.participant.userImageSmall)" alt="avatar" />
                          </v-avatar>
                        </template>
                        <span>{{m.participant.displayName}}</span>
                      </v-tooltip>
                      {{$t('message.' + m.contents)}}
                    </div>
                    <div class="text-center message-time">{{$d(new Date(m.sentOn), "time", $i18n.locale)}}</div>
                  </div>
                  <!-- OTHER USER -->
                  <div v-else-if="m.sender._id != $store.state.user._id" :key="selectedThread._id + m._id"
                    @click="doNothing()" style="margin-bottom: 5px">
                    <v-layout>
                      <v-list-item-avatar size="30" class="message-sender-avatar">
                        <img :src="userImg(m.sender.userImageSmall ? m.sender.userImageSmall : m.sender.userImage)" />
                      </v-list-item-avatar>
                      <div>
                        <v-card-title class="pa-0">
                          <span v-if="m.sender._id != $store.state.user._id" class="text-subtitle-2"
                            @click="goToUser(m.sender._id)">
                            {{ truncate(m.sender.displayName ? m.sender.displayName : m.sender.fName + ' ' +
                            m.sender.lName , 20, true)}}
                          </span>
                        </v-card-title>
                        <v-card-text class="text-xs-left message-area message-area-other-user" style="width: auto">
                          <div v-if="m.reply && m.reply.contents" class="reply-message"
                            @click="displayReplyMessage(m.reply)">
                            <div>
                              <span class="text-caption pl-2">{{m.reply.sender.displayName}}</span>
                            </div>
                            <v-card-text class="text-left text-body-2 pa-2 reply-message-contents"
                              v-html="truncate(m.reply.transformedContents, 100)">
                            </v-card-text>
                          </div>
                          <div class="text-body-2 " v-html="m.transformedContents"></div>
                          <div class="text-right text-caption">{{$d(new Date(m.sentOn), "time", $i18n.locale)}}</div>
                        </v-card-text>
                      </div>
                      <v-list-item-avatar size="35px" class="reply-button-wrapper">
                        <v-btn class="message-button elevation-0" x-small fab @click="replyMessage(m)">
                          <ReplyIcon size="15" class="hero-button" color="grey"></ReplyIcon>
                        </v-btn>
                      </v-list-item-avatar>
                    </v-layout>
                    <v-card-actions style="margin-top:0px;margin-left:55px">
                      <template v-for="u in m.readBy">
                        <v-avatar v-if="m.readBy && u._id != m.sender._id" style="margin-right: 2px"
                          :title="u.displayName" :key="u._id" size="15px" class="grey lighten-4">
                          <img v-if="!u.userImageSmall" :src="assets('blank-profile-picture.png')" alt="avatar" />
                          <img v-else :src="userImg(u.userImageSmall)" alt="avatar" />
                        </v-avatar>
                      </template>
                    </v-card-actions>
                  </div>
                  <!-- CURRENT USER -->
                  <div style="right:0" v-else @click="doNothing()" :key="selectedThread._id + m._id">
                    <v-layout>
                      <v-spacer></v-spacer>
                      <v-list-item-avatar size="30" class="">
                        <v-btn class="message-button elevation-0" x-small fab @click="replyMessage(m)">
                          <ReplyIcon class="hero-icon" size="15" color="grey"></ReplyIcon>
                        </v-btn>
                      </v-list-item-avatar>
                      <v-list-item-avatar size="30" class="mr-3">
                        <v-btn class="message-button elevation-0" x-small fab @click="showDeleteMessageConfirm(m._id)">
                          <TrashIcon size="15" class="hero-button" color="grey"></TrashIcon>
                        </v-btn>
                      </v-list-item-avatar>
                      <div>
                        <v-card-text class="text-xs-left message-area message-area-current-user"
                          :style="'width: auto; margin-left:-10px;background-color: '+themeColor('blue')">
                          <div v-if="m.reply && m.reply.contents" class="message-area-reply"
                            @click="displayReplyMessage(m.reply)">
                            <div>
                              <span class="text-caption pl-2">{{m.reply.sender.displayName}}</span>
                            </div>
                            <v-card-text class="text-left text-body-2 pa-2 reply-message-contents"
                              v-html="truncate(m.reply.transformedContents, 100)">
                            </v-card-text>
                          </div>
                          <div class="text-body-2 pa-2" v-html="m.transformedContents"></div>
                          <div class="text-right text-caption">{{$d(new Date(m.sentOn), "time", $i18n.locale)}}</div>
                        </v-card-text>
                      </div>
                    </v-layout>
                    <v-card-actions style="margin-top:0px;">
                      <v-spacer></v-spacer>
                      <template v-for="u in m.readBy">
                        <v-avatar v-if="m.readBy && u._id != m.sender._id" style="margin-right: 2px"
                          :title="u.displayName" :key="u._id" size="15px" class="grey lighten-4">
                          <img v-if="!u.userImageSmall" :src="assets('blank-profile-picture.png')" alt="avatar" />
                          <img v-else :src="userImg(u.userImageSmall)" alt="avatar" />
                        </v-avatar>
                      </template>
                    </v-card-actions>
                  </div>
                </template>
              </div>
            </template>
            <div style="height:85px"><br /><br /></div>
          </div>
        </v-card-text>
        <v-footer absolute padless height="auto" class="pa-0">
          <v-card flat tile width="100%" class="pa-0 pl-1">
            <div v-if="replyingMessage" class="grey darken-1 replying-message">
              <v-card-actions class="pa-0">
                <span class="text-caption">{{replyingMessage.sender.displayName}}</span>
                <v-spacer></v-spacer>
                <v-btn x-small icon @click="clearReplyMessage()">
                  <XCircleIcon class="hero-icon" size="15"></XCircleIcon>
                </v-btn>
              </v-card-actions>
              <v-card-text class="text-left pa-1 replying-message-contents"
                v-html="truncate(replyingMessage.contents, 50)">
              </v-card-text>
            </div>
            <v-card-actions color="lighten-1" class="pa-0 lighten-1">
              <v-textarea :label="$t('message.type_response')" :auto-grow="true" v-model="newMessage" outlined dense
                rows="1" style="margin-bottom:-20px"></v-textarea>
              <v-btn :disabled="displayLoader" color="primary" icon @click="sendMessage()">
                <v-progress-circular indeterminate color="white" v-if="displayLoader"></v-progress-circular>
                <PaperAirplaneIcon v-else class="hero-button send-icon"></PaperAirplaneIcon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-footer>
      </v-card>
    </v-dialog>
    <new-message v-model="newMessageDialog"></new-message>
    <v-dialog v-model="deleteMessageDialog" max-width="500px">
      <v-card outlined>
        <v-card-title class="text-h4">
          {{ $t("message.sure_delete_message") }}
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" @click.stop="deleteMessage()">{{
          $t("message.delete")
          }}</v-btn>
          <v-btn @click.stop="deleteMessageDialog = false">{{
          $t("message.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteThreadDialog" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <h2>{{ $t("message.sure_delete_thread") }}</h2>
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" @click.stop="deleteThread()">{{
          $t("message.delete")
          }}</v-btn>
          <v-btn @click.stop="deleteThreadDialog = false">{{
          $t("message.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="replyMessageDialog" max-width="80%">
      <v-card outlined v-if="replyMessageData">
        <div class="text-subtitle-2 grey--text pa-1">{{$d(new Date(replyMessageData.sentOn), "short", $i18n.locale)}}
        </div>
        <div class="pa-1 pt-0 text-subtitle-1">
          <v-avatar size="30px">
            <img
              :src="userImg(replyMessageData.sender.userImageSmall ? replyMessageData.sender.userImageSmall : replyMessageData.sender.userImage)" />
          </v-avatar>
          <span class="clickable ml-1" @click="goToUser(replyMessageData.sender._id)">{{
          truncate(replyMessageData.sender.displayName ? replyMessageData.sender.displayName :
          replyMessageData.sender.fName + ' ' + replyMessageData.sender.lName , 20, true)}}</span>
        </div>
        <v-divider></v-divider>
        <v-card-text class="message_area text-left pa-2 text-body-2" v-html="replyMessageData.transformedContents"
          style="max-height: 300px; overflow-y:auto">
        </v-card-text>
        <v-card-actions class="pr-0 pb-0">
          <v-spacer></v-spacer>
          <v-btn icon @click="replyMessageData = null;replyMessageDialog=false">
            <XCircleIcon class="hero-button"></XCircleIcon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import MessageService from "@/services/MessageService";
import CirclesLoader from "@/components/displays/CirclesLoader.vue";
import NewMessage from "@/components/displays/NewMessageDisplay.vue";
import UserSelect from "@/components/inputs/UserSelect.vue";

//Icons
import {
  UserGroupIcon,
  ChatAltIcon,
  AdjustmentsIcon,
  TrashIcon,
  ReplyIcon,
  XCircleIcon,
  PaperAirplaneIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      threadId: this.$route.params.threadId,
      loading: false,
      loadingMessages: false,
      viewMore: false,
      viewMoreMessages: false,
      threads: null,
      academyThreads: null,
      threadRecipients: null,
      viewThreadDialog: false,
      selectedThread: null,
      loadedMessages: {},
      sortedMessageDates: [],
      extraRecipient: null,
      newMessage: null,
      newMessageDialog: false,
      earlierUnreadCount: 0,
      filterMessagesUser: null,
      pointer: 0,
      academyUsers: null,
      academyMembers: null,
      mutualLinks: null,
      pointerMessages: 0,
      deleteMessageDialog: false,
      deleteThreadDialog: false,
      messageToDelete: null,
      displayLoader: false,
      configurationPanel: false,
      replyingMessage: null,
      replyMessageData: null,
      replyMessageDialog: false,
      linkRegex: this.getLinkRegex()
    };
  },
  components: {
    CirclesLoader,
    NewMessage,
    UserSelect,
    UserGroupIcon,
    ChatAltIcon,
    AdjustmentsIcon,
    TrashIcon,
    ReplyIcon,
    XCircleIcon,
    PaperAirplaneIcon,
  },
  created() {
    this.updateNumbers();
    if (this.threadId)
      this.getThread(this.threadId);
    this.getAvailableRecipients();
  },
  beforeRouteUpdate(to, from, next) {
    this.updateNumbers();
    next();
  },
  watch: {
    filterMessagesUser: {
      handler: function () {
        this.filterMessages();
      },
    },
    extraRecipient: {
      handler: function (newValue) {
        if (newValue)
          this.addParticipant(newValue);
      },
    },
    newMessageDialog: {
      handler: function (newValue) {
        if (newValue == false) {
          this.getThreads();
        }
      },
    },
  },
  
  methods: {
    convertLinks(str, skipLinkDecoration) {
      if (!str) str = "";
      let matches = [];
      let match = false;
      let index = 0;
      while ((match = this.linkRegex.exec(str))) {
        let linkText = match[0];
        if (linkText.length > 25)
          linkText = linkText.substring(0, 25) + '...'
        if (skipLinkDecoration) {
          matches[index] = {
            link: linkText,
            raw: match[0]
          };
        } else {
          matches[index] = {
            link:
              '<a style="color:white;font-weight:bold" title="Go to Page." target="_blank" href="' +
              match[0] +
              '">' +
              linkText +
              "</a>",
            raw: match[0]
          };
        }
        index++;
      }
      for (const m of matches) str = str.replace(m.raw, m.link);
      return str;
    },
    closeThreadPanel() {
      this.updateNumbers();
      this.viewThreadDialog = false;
      this.configurationPanel = false;
    },
    updateNumbers() {
      MessageService.updateCount();
      this.getThreads();
    },
    getThread(id) {
      MessageService.thread(id)
        .then((data) => {
          if (data && data.thread) {
            this.viewMessages(data.thread);
          }
        });
    },
    getMessageDate(sentOn) {
      if (sentOn) {
        return this.$d(new Date(sentOn), "month_day_year", this.$i18n.locale);
      }
    },
    updateThreadTitle() {
      if (this.selectedThread && this.selectedThread.title) {
        MessageService.updateThreadTitle(this.selectedThread._id, { title: this.selectedThread.title })
          .then(() => {
            this.getThreads();
            this.showAlert("success", this.$t("message.success"));
          })
          .catch(() => {
            this.showAlert("error", this.$t("message.oops_not_working"));
          });
      }
    },
    displayReplyMessage(m) {
      if (m) {
        this.replyMessageData = {
          sentOn: m.sentOn,
          sender: m.sender,
          transformedContents: this.convertLinks(m.contents)
        };
        this.replyMessageDialog = true;
      }
    },
    replyMessage(m) {
      this.replyingMessage = m;
    },
    clearReplyMessage() {
      this.replyingMessage = null;
    },
    skipNotifications() {
      MessageService.skipNotifications(this.selectedThread._id)
        .then(() => {
          this.showAlert("success", this.$t("message.success"));
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    addParticipant(participantId) {
      MessageService.addParticipant(this.selectedThread._id, participantId)
        .then((data) => {
          this.selectedThread.recipients = data.participants;
          this.extraRecipient = null;
          this.getThreads();
          this.showAlert("success", this.$t("message.success"));
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    getMutualLinks() {
      UserService.filterMutualLinks()
        .then((data) => {
          let currentRecipients = this.selectedThread.recipients;
          if (!currentRecipients)
            currentRecipients = [];
          let usersToDiscard = [];
          for (const r of currentRecipients)
            usersToDiscard.push(r._id);
          if (data && data.users.length > 0) {
            let tmp = [];
            for (const s of data.users) {
              if (s._id != this.$store.state.user._id && usersToDiscard.indexOf(s._id) < 0)
                tmp.push({
                  text: s.displayName,
                  id: s._id
                });
            }
            this.mutualLinks = tmp;
          }
        });
    },
    removeParticipant(u) {
      MessageService.removeParticipant(this.selectedThread._id, u._id)
        .then((data) => {
          this.selectedThread.recipients = data.participants;
          this.showAlert("success", this.$t("message.success"));
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    displayConfiguration() {
      this.configurationPanel = !this.configurationPanel;
      if (this.configurationPanel) {
        if (this.selectedThread.managedAcademy) {
          UserService.academyMembers(this.selectedThread.managedAcademy)
            .then((data) => {
              if (data && data.members && data.members.length > 0) {
                let currentRecipients = this.selectedThread.recipients;
                if (!currentRecipients)
                  currentRecipients = [];
                let usersToDiscard = [];
                for (const r of currentRecipients)
                  usersToDiscard.push(r._id);
                if (data.members) {
                  let tmp = [];
                  for (const s of data.members) {
                    if (s._id != this.$store.state.user._id && usersToDiscard.indexOf(s._id) < 0)
                      tmp.push({
                        text: s.displayName,
                        id: s._id
                      });
                  }
                  if (tmp.length > 0)
                    this.academyMembers = tmp;
                }
              }
            });
        } else {
          this.getMutualLinks();
        }
      }
    },
    getAvailableRecipients() {
      MessageService.availableRecipients()
        .then((data) => {
          if (data && data.recipients.length > 0) {
            let tmp = [];
            for (const s of data.recipients) {
              if (s._id != this.$store.state.user._id)
                tmp.push({
                  text: s.displayName,
                  id: s._id
                });
            }
            this.threadRecipients = tmp;
          }
        });
    },
    filterMessages() {
      this.getThreads(false);
    },
    getRecipientsDisplay(recipients) {
      var display = "";
      if (recipients) {
        var i = 0;
        for (const r of recipients) {
          var text = r.fName;
          if (r._id == this.$store.state.user._id) text = "You";
          if (i == 0) display += text;
          else if (i == 1) {
            if (recipients.length == 2) display += " & " + text;
            else display += ", " + text;
          } else if (i >= 2) {
            break;
          }
          i++;
        }
        if (recipients.length > 2)
          display += " " + this.$t("message.more", [recipients.length - 2]);
      }
      return display;
    },
    doNothing() { },
    goToUser(id) {
      this.navigateTo({ name: "viewprofile", params: { userId: id } });
    },
    getRecipientImage(t) {
      if (t.recipients) {
        for (const r of t.recipients) {
          if (r._id != this.$store.state.user._id && r.userImageSmall) {
            return this.userImg(r.userImageSmall);
          }
        }
      }
      return this.userImg(t.lastMessageSender.userImageSmall);
    },
    getRecipientDisplay(index, recipientsSize, u) {
      const isSessionUser = this.$store.state.user._id == u._id;
      var toReturn = "";
      if (index == 0) {
        //First
        if (isSessionUser) toReturn = "You";
        else toReturn = u.fName;
      } else if (index == recipientsSize - 1) {
        //Last
        if (isSessionUser) toReturn = " and You";
        else toReturn = " and " + u.fName;
      } else {
        if (isSessionUser) toReturn = ", You";
        else toReturn = ", " + u.fName;
      }
      return toReturn;
    },
    openDialog() {
      this.newMessageDialog = true;
    },
    showDeleteMessageConfirm(id) {
      this.deleteMessageDialog = true;
      this.messageToDelete = id;
    },
    showDeleteThreadConfirm() {
      this.deleteThreadDialog = true;
    },
    deleteMessage() {
      MessageService.deleteMessage(this.messageToDelete)
        .then((data) => {
          if (data) {
            try {
              this.deleteMessageDialog = false;
              for (const messageDay in this.loadedMessages) {
                for (var i = 0; i < this.loadedMessages[messageDay].length; i++) {
                  if (this.loadedMessages[messageDay][i]._id == this.messageToDelete) {
                    this.loadedMessages[messageDay].splice(i, 1);
                    break;
                  }
                }
              }
            } catch (err) {
              this.showAlert("error", this.$t("message.oops_not_working"));
            }
          }
        });
    },
    deleteThread() {
      MessageService.deleteThread(this.selectedThread._id)
        .then((data) => {
          if (data) {
            try {
              this.deleteThreadDialog = false;
              this.viewThreadDialog = false;
              this.getThreads();
            } catch (err) {
              this.showAlert("error", this.$t("message.oops_not_working"));
            }
          }
        });
    },
    scrollToBottom() {
      const vm = this;
      setTimeout(function () {
        vm.$refs.messagesDisplay.scrollTop =
          vm.$refs.messagesDisplay.scrollHeight;
      }, 500);
    },
    sendMessage() {
      if (this.newMessage && this.newMessage != "") {
        this.displayLoader = true;
        const data = { contents: this.newMessage, reply: this.replyingMessage ? this.replyingMessage._id : null };
        MessageService.sendMessage(this.selectedThread._id, data)
          .then((data) => {
            if (data) {
              try {
                //Add the message
                data.sender = {
                  _id: this.$store.state.user._id,
                  fName: this.$store.state.user.fName,
                  lName: this.$store.state.user.lName,
                  userImage: this.$store.state.user.userImage,
                };
                data.readBy = [data.sender];
                if (this.replyingMessage)
                  data.reply = this.replyingMessage;

                data.transformedContents = this.convertLinks(data.contents);
                const messageDay = this.getMessageDate(data.sentOn);
                if (!this.loadedMessages[messageDay])
                  this.loadedMessages[messageDay] = [];
                this.loadedMessages[messageDay].push(data);
                this.newMessage = null;
                this.scrollToBottom();
                this.displayLoader = false;
                this.replyingMessage = null;
              } catch (err) {
                console.log(err);
                this.displayLoader = false;
              }
            }
          })
          .catch(() => {
            this.displayLoader = false;
          });
      }
    },
    readByUser(message) {
      if (message.readBy) {
        for (const u of message.readBy) {
          if (u._id == this.$store.state.user._id) return true;
        }
      }
      return false;
    },
    getLastMessageSentTime(time) {
      return (
        "Last Message at " +
        this.toNiceDateCustomFormatAdjusted(time, "hh:mm A")
      );
    },
    getMessageTitle(u) {
      if (this.$store.state.user._id == u._id) return "You";
      else return u.displayName;
    },
    viewMessages(thread) {
      this.viewThreadDialog = true;
      this.configurationPanel = false;
      this.selectedThread = thread;
      this.selectedThread.newMessages = 0;
      this.replyingMessage = null;
      this.getThreadMessages(thread._id);
    },
    readMessages(messages) {
      MessageService.readMessages(this.selectedThread._id, {
        messages: messages,
      });
    },
    getThreadMessages(id, append) {
      var vm = this;
      this.loadingMessages = true;
      this.viewMoreMessages = false;
      if (!append) {
        this.loadedMessages = {};
        this.sortedMessageDates = [];
        this.pointerMessages = 0;
      }
      MessageService.threadMessages(id, this.pointerMessages)
        .then((data) => {
          if (data) {
            this.selectedThread.skipNotifications = !data.skipNotifications;
            //Load messages into dates 
            let tmp = {};
            if (append) {
              for (const groupDate in this.loadedMessages) {
                tmp[groupDate] = this.loadedMessages[groupDate];
              }
            }
            for (let m of data.messages) {
              const messageDate = this.getMessageDate(m.sentOn);
              if (messageDate) {
                this.sortedMessageDates.push(m.sentOn);
                if (!tmp[messageDate])
                  tmp[messageDate] = [];
                m.transformedContents = this.convertLinks(m.contents);
                if (m.reply)
                  m.reply.transformedContents = this.convertLinks(m.reply.contents, true);
                tmp[messageDate].push(m);
              }
            }
            if (append) {
              this.sortedMessageDates = this.sortedMessageDates.sort();
              //this.sortedMessageDates = this.sortedMessageDates.reverse();
              let datesSet = new Set();
              for (const d of this.sortedMessageDates) {
                const messageDate = this.getMessageDate(d);
                if (messageDate)
                  datesSet.add(messageDate)
              }
              const datesArray = Array.from(datesSet);
              let newLoadedMessages = {};
              for (const messageDate of datesArray) {
                newLoadedMessages[messageDate] = tmp[messageDate];
              }
              this.loadedMessages = newLoadedMessages;
            } else {
              this.loadedMessages = tmp;
            }
            this.loadingMessages = false;
            this.pointerMessages = this.pointerMessages + data.count;
            if (data.count >= this.searchesLimit) this.viewMoreMessages = true;
            if (!append) this.scrollToBottom();
            //Mark these messages as read
            var toRead = [];
            for (const m of data.messages) {
              if (m.readBy) {
                var found = false;
                for (const u of m.readBy) {
                  if (this.$store.state.user._id == u._id) found = true;
                }
                if (!found) toRead.push(m._id);
              }
            }
            //Send read messages call
            if (toRead.length > 0) {
              this.readMessages(toRead);
              //Done reading the messages, lets update your count.
              const newCount = this.$store.state.messagesCount - toRead.length;
              vm.$store.dispatch(
                "setMessagesCount",
                newCount >= 0 ? newCount : 0
              );
              if (newCount + this.$store.state.notificationsCount == 0) {
                //NotificationService.clearMobile();
              }
              //Also updated the selected thread
              const selectedId = this.selectedThread._id;
              for (const t of this.threads) {
                if (t._id == selectedId) {
                  t.newMessages = t.newMessages - toRead.length;
                  break;
                }
              }
              if (
                this.selectedThread &&
                this.selectedThread.newMessages > toRead.length
              ) {
                this.earlierUnreadCount =
                  this.selectedThread.newMessages - toRead.length;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getThreads(append) {
      this.loading = true;
      this.viewMore = false;
      if (!append) {
        this.pointer = 0;
        this.threads = [];
        this.academyThreads = [];
      }
      let data = {
        sender: this.filterMessagesUser ? this.filterMessagesUser : null
      };
      MessageService.filterThreads(data, this.pointer)
        .then((data) => {
          if (data) {
            this.threads = this.threads.concat(data.threads);
            this.academyThreads = this.academyThreads.concat(data.academyThreads);
            this.loading = false;
            this.pointer = this.pointer + data.count;
            if (data.count >= this.searchesLimit) this.viewMore = true;
            const vm = this;
            this.getMessagesCount(function (count) {
              vm.$store.dispatch("setMessagesCount", count);
            });
            this.getNotificationsCount(function (count) {
              vm.$store.dispatch("setNotifications", count);
            });
          }
        });
    },
  },
};
</script>
<style scoped>
.send-icon {
  transform: rotate(90deg);
}

.message-area {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
  border-radius: 10px 10px 10px 10px;
  font-size: 1.2em;
}

.message-area-current-user {
  color: white;
}

.message-area-other-user {
  background-color: #e9ecef;
  color: gray;
}

.replying-message {
  padding: 3px;
  margin-bottom: 5px;
}

.reply-message-contents {
  background-color: white;
  color: black;
  border-radius: 10px 10px 10px 10px;
}


.message-link{
  font-weight: bold;
  font-size: larger;
  text-decoration: underline;
  color: red;
}
</style>
