<template>
    <span>
        {{dobDisplay}}
    </span>
</template>

<script>
var moment = require('moment');

export default {
    props: [
        'dob',
        'hideAge'
    ],
    data () {
        return {

        }
    },
    computed: {
        dobDisplay: function () {
            if(this.dob){
                let date = this.dateFromString(this.dob);
                const split = this.dob.split('-');
                if (split.length == 3) {
                    const day = split[2].substring(0, 2);
                    date.setDate(day);
                }
                let age = date ? '(' + moment().diff(date, 'years') + ')': '';
                let bday = this.$d(date, "month_day_year", this.$i18n.locale);
                if(this.hideAge){
                    age = '';
                    bday = this.$d(date, "month_day", this.$i18n.locale);
                }
                return bday + ' ' + age 
            }
            return ''
        }
    }
}
</script>

<style scoped>
    .country-display {
        height: 20px;
        vertical-align: middle;
    }
</style>
