<template>
  <div v-if="$store.state.isUserLoggedIn">
    <div v-if="loading">
      <v-skeleton-loader
        type="card"
      ></v-skeleton-loader>
    </div>
    <v-sheet v-if="!loading && video && video.fileUrl" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-title class="pa-2">
        <v-avatar size="30" v-if="academy && academy.image">
          <img :src="userImg(academy.image)" />
        </v-avatar>
        <v-spacer></v-spacer>
        <span class="text-caption ml-1">
          {{ $d(new Date(video.uploaded), "month_day", $i18n.locale) }}
        </span>
      </v-card-title>
      <v-responsive v-if="video.ready" class="pa-0">
        <VideoDisplay :video="video"></VideoDisplay>
      </v-responsive>
      <div v-else class="text-center pa-3">
        <div class="pb-2">
          {{$t("message.video_processing")}}
        </div>
        <v-progress-circular
          class="mt-2"
          size="60"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="video.tags && video.tags.length > 0" class="pa-2">
        <template v-for="(t, index2) in video.tags">
          <v-chip small class="text-overline mr-1" :key="video._id + 'tag' + index2">{{ t }}</v-chip>
        </template>
      </div>
      <v-card-actions
        v-if="video.userTags && video.userTags.length > 0"
        class="pa-2 text-left"
      >
        <user-avatars :users="video.userTags" />
      </v-card-actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small elevation="0" @click="goBack()">
          {{$t('message.back')}}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-sheet>
    <v-dialog v-model="reportVideoDialog" max-width="80%">
      <v-card outlined>
        <v-card-title>
          <h3>{{ $t("message.report_abuse") }}</h3>
        </v-card-title>
        <v-card-text>
          <h4>{{ $t("message.report_abuse_text") }}</h4>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click.stop="reportVideo()">{{
            $t("message.report_abuse")
          }}</v-btn>
          <v-btn @click.stop="reportVideoDialog = false">{{
            $t("message.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import VideoService from "@/services/VideoService";
import NotificationService from "@/services/NotificationService";
import UserAvatars from "@/components/displays/UserAvatars.vue";
import VideoDisplay from "@/components/displays/VideoDisplay.vue";

export default {
  data() {
    return {
      videoId: this.$route.params.videoId,
      loadComments: this.$route.query.comments,
      notificationId: this.$route.params.notificationId,
      loading: true,
      video: null,
      likesDialog: false,
      academy: null,
      comments: [],
      likes:[],
      commentContents: null,
      addingComment: false,
      selectedComment: null,
      updatedContents: null,
      updateCommentDialog: false,
      deleteCommentDialog: false,
      reportVideoDialog: false,
      loadingComments: false,
      commentsDialog: false,
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src:
              "http://localhost:3001/media/vid/5ad5642fb5883f5b2fed7fac/0694d017-051a-4b1a-ae29-d90f362c9a2f.mp4",
            type: "video/mp4",
          },
        ],
      },
    };
  },
  components: {
    UserAvatars,
    VideoDisplay,
  },
  async created() {
    this.loadingComments = true;
    this.getVideo();
    if (this.notificationId) {
      this.readNotification();
    }
    if(this.loadComments){
      this.displayCommentsDialog();
    }
  },
  methods: {
    doNothing() {},
    displayCommentsDialog(){
      this.commentsDialog = true;
      VideoService.getComments(this.videoId)
        .then((data) => {
          if (data) {
            this.loadingComments = false;
            this.comments = data;
          }
        });
    },
    async addComment() {
      if (!this.addingComment) {
        this.addingComment = true;
        let contents = this.commentContents;
        if (!contents || contents == "") {
          this.addingComment = false;
          return;
        }
        let hashTags = this.extractHashTags(contents);
        contents = contents.replaceAll('<div><br></div>','');
        const data = await VideoService.addComment(this.videoId, {
          contents: contents,
          hashTags: hashTags
        });
        if (data) {
          this.addingComment = false;
          //Comment Added
          this.commentContents = null;
          if (this.comments.length == 0) this.comments.push(data.comment);
          else this.comments.unshift(data.comment);
        } else {
          this.addingComment = false;
          this.showAlert("error", this.$t("message.oops_not_working"));
        }
      }
    },
    async viewLikes(id) {
      const data = await VideoService.viewLikes(id);
      if (data) {
        this.likes = data;
        this.likesDialog = true;
      }
    },
    async likeVideo(id, whenDone) {
      const data = await VideoService.likeVideo(id);
      if (data) {
        whenDone();
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    async unlikeVideo(id, whenDone) {
      const data = await VideoService.unlikeVideo(id);
      if (data) {
        whenDone();
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    async getVideo() {
      VideoService.video(this.videoId)
        .then((data) => {
          if (data) {
            var tagsFixed = new Set();
            if (data.video && data.video.tags) {
              for (const t of data.video.tags) {
                var noHashTags = t.replace(/#/g, "");
                const split = noHashTags.split(" ");
                for (const t2 of split) {
                  if (t2 != "") tagsFixed.add(t2);
                }
              }

              this.video = data.video;
              this.video.tagsFixed = Array.from(tagsFixed);
            }
            this.academy = data.academy;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    readNotification() {
      NotificationService.read(this.notificationId);
    },
    displayReportVideoDialog() {
      this.reportVideoDialog = !this.reportVideoDialog;
    },
    goToEditVideo() {
      this.navigateTo({
        name: "managevideo",
        params: { videoId: this.videoId },
      });
    },
    goToAcademy() {
      if (this.academy)
        this.navigateTo({
          name: "viewacademy",
          params: { academyId: this.academy._id },
        });
    },
    displayUpdateCommentDialog(c) {
      this.selectedComment = c;
      this.updatedContents = c.contents;
      this.updateCommentDialog = !this.updateCommentDialog;
    },
    displayDeleteCommentConfirm(c) {
      this.selectedComment = c;
      this.deleteCommentDialog = !this.deleteCommentDialog;
    },
    async deleteComment() {
      this.deleteCommentDialog = false;
      const data = await VideoService.deleteComment(this.selectedComment._id);
      if (data) {
        //Deleted!
        var i;
        for (i = 0; i < this.comments.length; i++) {
          const c = this.comments[i];
          if (c._id == this.selectedComment._id) {
            break;
          }
        }
        this.comments.splice(i, 1);
      }
      this.selectedComment = null;
    },
    async updateComment() {
      const data = await VideoService.updateComment(
        this.selectedComment._id,
        this.updatedContents
      );
      this.updateCommentDialog = false;
      const comments = this.comments;
      if (data) {
        //updated!
        var i;
        for (i = 0; i < comments.length; i++) {
          const c = comments[i];
          if (c._id == this.selectedComment._id) {
            c.contents = this.updatedContents;
          }
        }
        this.updatedContents = null;
        this.selectedComment = null;
      }
    },
    async reportVideo() {
      const data = await VideoService.reportAbuse(this.videoId);
      if (data) {
        this.showAlert("success", this.$t("message.abuse_reported"));
      }
      this.reportVideoDialog = false;
    },
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.video-comments {
  margin-top: 10px;
}
.commentbox-margin-adjust {
  margin-top: -30px;
}
.comment-time {
  margin-left: 25px;
  color: gray;
  font-style: italic;
}
.video-tag {
  border-top: 1px solid #f8f8f8;
  border-bottom: 1px solid #f8f8f8;
}
</style>
