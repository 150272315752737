<template>
  <v-card outlined elevation="0" rounded="lg" class="pa-2 ma-2" v-if="paymentMethod">
    <div v-if="paymentMethod.type == types.card">
      <div class="text-left">
        <img v-if="paymentMethod.brand == 'visa'" :src="cardImage" :style="'height:60px;'" />
        <img v-else-if="paymentMethod.brand == 'amex'" :src="cardImage" :style="'height:30px'" />
        <img v-else-if="paymentMethod.brand == 'discover'" :src="cardImage"
          :style="'height:50px; margin-bottom:-20px'" />
        <img v-else-if="paymentMethod.brand == 'mastercard'" :src="cardImage"
          :style="'height:50px; margin-bottom:-20px'" />
        <CreditCardIcon v-else size="30" class="hero-icon" :color="themeColor('primary')"></CreditCardIcon>
      </div>
      <v-card-text class="text-center text-h5">
        <div
          v-if="paymentMethod.brand == 'visa' || paymentMethod.brand == 'mastercard' || paymentMethod.brand == 'discover'">
          <span>****</span>
          <span class="ml-2">****</span>
          <span class="ml-2">****</span>
          <span class="ml-2">{{ paymentMethod.lastFour }}</span>
        </div>
        <div v-else-if="paymentMethod.brand == 'amex'">
          <span>****</span>
          <span class="ml-2">****</span>
          <span class="ml-2">******</span>
          <span class="ml-2">*{{ paymentMethod.lastFour }}</span>
        </div>
        <div v-else-if="paymentMethod.lastFour">
          <span class="text-caption">{{ $t("message.last4") }}: {{ paymentMethod.lastFour }}</span>
        </div>
      </v-card-text>
      <v-card-text v-if="expired" class="text-right red--color">
        <ExclamationIcon size="20" class="hero-icon" :color="themeColor('error')"></ExclamationIcon>
        {{ $t("message.expires") }}:
        {{ $d(expDate, "month_year", $i18n.locale) }}
      </v-card-text>
      <v-card-text v-else-if="expiresSoon" class="text-right red--color">
        <ExclamationIcon size="20" class="hero-icon" :color="themeColor('accent')"></ExclamationIcon>
        {{ $t("message.expires") }}:
        {{ $d(expDate, "month_year", $i18n.locale) }}
      </v-card-text>
      <v-card-text v-else-if="expDate" class="text-right">
        {{ $t("message.expires") }}:
        {{ $d(expDate, "month_year", $i18n.locale) }}
      </v-card-text>
    </div>
    <div v-else-if="paymentMethod.type">
      <v-card-actions>
        <LibraryIcon class="hero-icon"></LibraryIcon>
        <v-spacer></v-spacer>
        {{ paymentMethod.bankName }}
      </v-card-actions>
      <v-card-text class="text-center text-h5">
        <div>
          <span>****</span>
          <span class="ml-2">****</span>
          <span class="ml-2">****</span>
          <span class="ml-2">{{ paymentMethod.lastFour }}</span>
        </div>
      </v-card-text>
      <v-card-actions>
        {{ paymentMethod.routing }}
        <v-spacer></v-spacer>
        <span class="text-overline">{{ paymentMethod.type.toUpperCase() }}</span>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import {
  CreditCardIcon,
  LibraryIcon,
  ExclamationIcon
} from '@vue-hero-icons/outline'

export default {
  props: ["paymentMethod"],
  data() {
    return {
      cardImage: null,
      expired: false,
      expDate: null,
      expiresSoon: false,
      name: null,
      types: {
        card: "card",
        us_bank_account: "us", //US
        acss_debit: "ca", //Canada
        bacs_debit: "gb", //UK
        au_becs_debit: "au", //Australia
        sepa_debit: "eu", // Europe
      }
    };
  },
  components: {
    CreditCardIcon,
    LibraryIcon,
    ExclamationIcon
  },
  created() {
    if (this.paymentMethod && this.paymentMethod.type == this.types.card) {
      const brand = this.paymentMethod.brand;
      if (brand == "visa")
        this.cardImage = this.assets() + "/cards/visa.png";
      else if (brand == "amex")
        this.cardImage = this.assets() + "/cards/amex.png";
      else if (brand == "discover")
        this.cardImage = this.assets() + "/cards/discover.png";
      else if (brand == "mastercard")
        this.cardImage = this.assets() + "/cards/mastercard.png";
      this.checkIfExpired();
    }
  },
  methods: {
    checkIfExpired() {
      if (this.paymentMethod && this.paymentMethod.expMonth) {
        let expDate = new Date();
        expDate.setFullYear(this.paymentMethod.expYear);
        expDate.setMonth(this.paymentMethod.expMonth - 1);
        expDate.setDate(28);
        this.expDate = expDate;
        let today = new Date();
        today.setMonth(today.getMonth());
        today.setDate(1);
        today.setHours(0);
        today.setMinutes(0);
        if (this.expDate > today) {
          const diff = this.monthsBetween(expDate, today);
          if (diff <= 2)
            this.expiresSoon = true;
        } else {
          this.expired = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.st0 {
  clip-path: url(#XMLID_27_);
  fill: url(#SVGID_1_);
}
</style>
