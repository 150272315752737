import Api from '@/services/Api'

export default {
    async filter(data){
        const response = await Api().post('partners/filter', data);
        return response.data
    },
    async addPartnerAdmin(data){
        const response = await Api().post('partners/admin/add', data)
        return response.data
    },
    async editPartner(id, data){
        const response = await Api().patch('partners/edit/'+id, data)
        return response.data
    },
    async getPartner(id){
        const response = await Api().get('partners/get/'+id)
        return response.data
    },
    async click(id){
        const response = await Api().post('partners/click/'+id)
        return response.data
    },
    async categories(){
        const response = await Api().get('partners/categories')
        return response.data
    },
    async countries(){
        const response = await Api().get('partners/countries')
        return response.data
    },
    async like(id){
        const response = await Api().post('partners/like/'+id)
        return response.data
    },
    async unlike(id){
        const response = await Api().post('partners/unlike/'+id)
        return response.data
    }
}
