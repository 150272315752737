<template>
  <div v-if="$store.state.isUserLoggedIn" >
    <BackButton></BackButton>
    <div v-if="!loading">
      <v-skeleton-loader
        v-if="!historyReady"
        type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
      ></v-skeleton-loader>
      <div v-if="historyReady">
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="!historyEmpty">
          <v-card-actions>
            <v-btn
              small
              @click="printResume()"
              dark
              block
              color="primary"
              class="hidden-sm-and-down"
            >
              <PrinterIcon class="hero-button"></PrinterIcon>
              {{$t("message.print_curriculum")}}
            </v-btn>
          </v-card-actions>
          <v-card-actions v-if="!isSmallerDisplay()">
            <v-btn
              small
              elevation="0"
              @click="displayAddDialog('new_academy')"
              color="blue"
            >
              <LibraryIcon size="25" class="hero-button"/>
              <span class="hidden-sm-and-down">{{
                $t("message.new_academy")
              }}</span>
            </v-btn>
            <v-spacer v-if="user && !user.notBjjUser"></v-spacer>
            <v-btn
              small
                elevation="0"
              color="success"
              v-if="user && !user.notBjjUser"
              @click="displayAddDialog('promotion')"
            >
              <ChevronDoubleUpIcon size="25" class="hero-button"/>
              <span class="hidden-sm-and-down">{{
                $t("message.promotion")
              }}</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              small
              @click="displayAddDialog('competition')"
              color="error"
            >
              <FireIcon size="25" class="hero-button"/>
              <span class="hidden-sm-and-down">{{
                $t("message.competition")
              }}</span>
            </v-btn>
          </v-card-actions>
          <div v-else class="pa-2">
            <v-btn
              small
              block
              elevation="0"
              @click="displayAddDialog('new_academy')"
              color="blue"
            >
              <LibraryIcon size="25" class="hero-button"/>
              {{
                $t("message.new_academy")
              }}
            </v-btn>
            <v-btn
              small
              block
              class="mt-2"
              elevation="0"
              color="success"
              v-if="user && !user.notBjjUser"
              @click="displayAddDialog('promotion')"
            >
              <ChevronDoubleUpIcon size="25" class="hero-button"/>
              {{$t("message.promotion")}}
            </v-btn>
            <v-btn
              small
              block
              elevation="0"
              class="mt-2"
              @click="displayAddDialog('competition')"
              color="error"
            >
              <FireIcon size="25" class="hero-button"/>
              {{
                $t("message.competition")
              }}
            </v-btn>
          </div>
        </v-sheet>
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-else>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="displayAddDialog('new_academy')"
              dark
              color="terciary"
            >
              <LibraryIcon size="25" class="hero-button"/>
              {{ $t("message.add") }} {{ $t("message.new_academy") }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="user && !user.notBjjUser"
              @click="displayAddDialog('promotion')"
              light
            >
              <ChevronDoubleUpIcon size="25" class="hero-button"/>
              {{ $t("message.add") }} {{ $t("message.promotion") }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
          <v-card-actions>
            <v-spacer />
            <v-btn
              @click="displayAddDialog('competition')"
              dark
              color="primary"
            >
              <FireIcon size="25" class="hero-button"/>
              {{ $t("message.add") }} {{ $t("message.competition") }}
            </v-btn>
            <v-spacer />
          </v-card-actions>
          <v-card-text class="empty_history_message empty_list_message">
            {{ $t("message.no_history1") }}
            <br />
            {{ $t("message.no_history2") }}
            <br />
            {{ $t("message.no_history3") }}
          </v-card-text>
        </v-sheet>
        <v-expansion-panels class="mt-2" flat>
          <v-expansion-panel
            v-if="!historyEmpty && user.belt != 'white' && verifications"
          >
            <v-expansion-panel-header>
              <div>
                <BadgeCheckIcon class="hero-icon"></BadgeCheckIcon>
                {{ $t("message.verifications") }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="pa-2">
                <v-list two-line>
                  <template v-for="(v, index) in verifications">
                    <v-list-item :key="v._id + index">
                      <v-list-item-avatar>
                        <belt-display
                          v-if="v.type == 'belt'"
                          :belt="v.value"
                          :show-text="false"
                        ></belt-display
                      ></v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          ><span v-if="v.type == 'belt'">{{
                            $t("message.verification_belt")
                          }}</span></v-list-item-title
                        >
                        <v-list-item-subtitle>
                          <span v-if="v.verifiedOn"
                            >{{ $t("message.verified_on") }}:
                            {{
                              $d(new Date(v.verifiedOn), "short", $i18n.locale)
                            }}</span
                          >
                          <span v-else>{{ $t("message.pending") }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <BadgeCheckIcon
                          class="hero-icon"
                          v-if="v.type == 'belt' && v.verifiedOn && v.verified"
                          :color="getColor(v.value)"
                          ></BadgeCheckIcon
                        >
                        <XCircleIcon
                          v-else-if="v.type == 'belt' && v.verifiedOn && !v.verified"
                          class="hero-icon"
                          :color="themeColor('error')"
                          ></XCircleIcon
                        >
                        <BadgeCheckIcon v-else-if="v.verifiedOn && v.verified" :color="themeColor('secondary')">
                        </BadgeCheckIcon>
                        <DotsHorizontalIcon class="hero-icon" v-else>pending</DotsHorizontalIcon>
                        <ChatIcon
                          class="hero-icon"
                          @click="displayvVerificationMessage(v.message)"
                          v-if="v.message"
                          ></ChatIcon
                        >
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider
                      v-if="index + 1 < verifications.length"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'"  class="mt-2">
          <template v-for="(h, index) in history">
            <v-list-item
              :key="index + h._id"
              three-line
              @click="doNoting()"
            >
              <v-list-item-avatar>
                <FireIcon 
                  v-if="h.historyType == 'competition'"
                  :color="themeColor('error')"
                  class="hero-button"/>
                <LibraryIcon 
                  v-if="h.historyType == 'new_academy'"
                  :color="themeColor('blue')"
                  class="hero-button"/>
                <ChevronDoubleUpIcon 
                  v-if="h.historyType == 'promotion'"
                  :color="themeColor('success')"
                  class="hero-button"/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{truncate(h.title, 30, true)}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div class="history_text" v-if="h.historyType == 'competition'">
                    {{ h.federation }}:
                    {{
                      h.competitionType
                        ? h.competitionType.toUpperCase() + ", "
                        : ""
                    }}
                    {{ h.age }}, {{ h.weight }}, {{ capitalizeText(h.belt) }}
                  </div>
                  <div
                    class="history_text"
                    v-if="h.historyType == 'new_academy' && h.academy"
                  >
                    {{ h.academy.name }}
                  </div>
                  <div
                    class="history_text"
                    v-if="h.historyType == 'promotion' && h.givenBy"
                  >
                    {{ $t("message.given_by") }}: {{ h.givenBy }}
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="supporting-text">
                  {{$d(toDateNotAdjusted(h.date), "month_day_year", $i18n.locale)}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="(h.historyType == 'competition' && h.place && h.place > 0) || (h.historyType == 'promotion')">
                <MedalDisplay
                  style="margin-left:2px"
                  v-if="h.historyType == 'competition' && h.place && h.place > 0"
                  :medal="h.place"
                ></MedalDisplay>
                <BeltDisplay
                  v-else-if="h.historyType == 'promotion'"
                  :belt="h.belt"
                  :show-text="false"
                ></BeltDisplay>
              </v-list-item-action>
              <v-list-item-action>
                <v-menu offset-y small>
                  <template v-slot:activator="{ on }">
                    <v-btn small style="margin-right:-10px" absolute right color="secondry" class="elevation-0" v-on="on" icon slot="activator">
                      <DotsVerticalIcon class="hero-button"></DotsVerticalIcon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item 
                      v-if="h.historyType == 'promotion' && !alreadyverified(h)"
                      @click.stop="displayVerificationDialog(h)">
                      <v-list-item-title><BadgeCheckIcon class="hero-icon"></BadgeCheckIcon></v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                      @click.stop="displayEditDialog(h)" >
                      <v-list-item-title><PencilIcon class="hero-button"></PencilIcon></v-list-item-title>
                    </v-list-item>
                    <v-list-item 
                      @click.stop="displayDeleteHistoryConfirm(h)" >
                      <v-list-item-title><XCircleIcon class="hero-icon"></XCircleIcon></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index + 1 < history.length"
              :key="index"
            ></v-divider>
          </template>
        </v-sheet>
      </div>
    </div>
    <v-dialog v-model="addHistoryDialog" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <div class="text-subtitle-1">{{ $t("message.add_history") }}</div>
        </v-card-title>
        <v-card-title class="error" v-if="addHistoryError">{{
          $t("message.fill_all_params")
        }}</v-card-title>
        <v-card-text>
          <v-form v-if="currentToEdit != null">
            <v-text-field
              outlined
              dense
              v-if="currentToEdit.historyType == 'competition'"
              v-model="currentToEdit.title"
              :label="$t('message.competition')"
              required
            ></v-text-field>
            <academies-select
              v-if="currentToEdit.historyType == 'new_academy'"
              from="history"
              v-model="selectedAcademy"
            />
            <br />
            <v-select
              v-if="currentToEdit.historyType == 'competition'"
              item-text="name"
              item-value="value"
              v-model="currentToEdit.competitionType"
              :items="competitionType"
              :label="$t('message.gi_no_gi')"
              outlined
              dense
            ></v-select>
            <federations-combo
              v-if="currentToEdit.historyType == 'competition'"
              v-model="currentToEdit.federation"
              required
            />
            <weights-combo
              v-if="currentToEdit.historyType == 'competition'"
              v-model="currentToEdit.weight"
              required
            />
            <ages-combo
              v-if="currentToEdit.historyType == 'competition'"
              v-model="currentToEdit.age"
              required
            />
            <belts-select-stripes
              v-if="currentToEdit.historyType == 'promotion'"
              showIcon="false"
              v-model="selectedBelt"
            ></belts-select-stripes>
            <v-text-field
              outlined
              dense
              v-if="currentToEdit.historyType == 'promotion'"
              v-model="currentToEdit.givenBy"
              :label="$t('message.given_by')"
            ></v-text-field>
            <belts-select
              v-if="currentToEdit.historyType == 'competition'"
              showIcon="false"
              v-model="selectedBelt"
            ></belts-select>
            <v-select
              v-if="currentToEdit.historyType == 'competition'"
              :items="competitionPlaces"
              v-model="currentToEdit.place"
              :label="$t('message.placed') + '?'"
              item-text="title"
              item-value="value"
              outlined
              dense
            ></v-select>
            <div class="text-subtitle-1 text-left"
              v-if="currentToEdit.historyType == 'new_academy'"
            >
              {{ $t("message.when_academy") }}
            </div>
            <div class="text-subtitle-1 text-left"
              v-else-if="currentToEdit.historyType == 'competition'"
            >
              {{ $t("message.when_competition") }}
            </div>
            <div class="text-subtitle-1 txt-left"
              v-else-if="currentToEdit.historyType == 'promotion'"
            >
              {{ $t("message.when_belt") }}
            </div>
            <date-picker v-if="selectedDate" v-model="selectedDate" :past-event="true"></date-picker>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="disableSubmit"
            color="primary"
            @click.stop="addHistoryEntry()"
            >{{ $t("message.add") }}</v-btn
          >
          <v-btn color="secondary" @click.stop="addHistoryDialog = false">{{
            $t("message.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteHistoryDialog" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <div class="text-subtitle-1">{{ $t("message.remove_history") }}</div>
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" @click.stop="deleteHistoryEntry()">{{
            $t("message.delete")
          }}</v-btn>
          <v-btn color="secondary" @click.stop="deleteHistoryDialog = false">{{
            $t("message.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="verificationDialog" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <div class="text-subtitle-1">{{ $t("message.verification") }}</div>
        </v-card-title>
        <v-card-text class="text-left">
          <input
            style="display: none"
            type="file"
            accept="*"
            @change="onFileSelect"
            ref="fileUploadInput"
          />
          <div>
            <v-btn
              style="margin-left: 5px"
              @click="filePicker()"
              color="terciary"
              small
              dark
              slot="activator"
            >
              <PaperClipIcon class="hero-icon"></PaperClipIcon
              >{{ $t("message.upload_document") }} </v-btn
            ><span v-if="uploading"
              ><v-progress-circular
                indeterminate
                color="terciary"
              ></v-progress-circular></span
            ><span v-else-if="verificationFilename"
              ><CheckCircleIcon class="hero-button" :color="themeColor('success')"></CheckCircleIcon
              >{{ verificationFilename }}</span
            >
          </div>
          <div class="supporting-text">
            {{ $t("message.upload_document_belt_explained") }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="uploading || !uploadedFile"
            color="primary"
            elevation="0"
            @click="verifyEvent()"
            >{{ $t("message.verify") }}</v-btn
          >
          <v-btn 
              elevation="0" color="secondary" @click="closeVerificationDialog()">{{
            $t("message.cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="verificationMessageDialog" max-width="90%" v-if="verificationMessage">
      <v-card outlined class="pa-2">
        <v-card-text class="text-body-1" v-html="verificationMessage"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="secondary" @click="verificationMessageDialog=false">{{ $t("message.close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editHistoryDialog" max-width="500px">
      <v-card outlined v-if="currentToEdit">
        <v-card-title>
          <div class="text-subtitle-1">{{ $t("message.edit_history") }}</div>
        </v-card-title>
        <v-card-text>
          <v-text-field
            dense
            outlined
            v-model="currentToEdit.title"
            :label="$t('message.title')"
          ></v-text-field>
          <date-picker class="mb-2" v-if="selectedDate" v-model="selectedDate" :past-event="true"></date-picker>
          <belts-select
            v-if="
              currentToEdit.belt && currentToEdit.historyType != 'promotion'
            "
            showIcon="false"
            v-model="currentToEdit.belt"
            :clearable="false"
          ></belts-select>
          <v-select
            outlined
            dense
            v-if="currentToEdit.place"
            :items="competitionPlaces"
            v-model="currentToEdit.place"
            :label="$t('message.placed') + '?'"
            item-text="title"
            item-value="value"
          ></v-select>
          <v-select
            outlined
            dense
            v-if="currentToEdit.competitionType"
            item-text="name"
            item-value="value"
            v-model="currentToEdit.competitionType"
            :items="competitionType"
            :label="$t('message.gi_no_gi')"
          ></v-select>
          <federations-combo
            v-if="currentToEdit.federation"
            v-model="currentToEdit.federation"
          />
          <weights-combo
            v-if="currentToEdit.weight"
            v-model="currentToEdit.weight"
          />
          <ages-combo v-if="currentToEdit.age" v-model="currentToEdit.age" />
          <v-text-field
            v-model="currentToEdit.givenBy"
            outlined dense
            :label="$t('message.given_by')"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn elevation="0" color="primary" @click.stop="updateHistoryEntry()">{{
            $t("message.update")
          }}</v-btn>
          <v-btn elevation="0" color="secondary" @click.stop="editHistoryDialog = false; selectedDate=null">{{
            $t("message.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div
      v-if="user"
      style="
        display: none;
        font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
      "
      ref="print_div"
    >
      <img style="width: 100px; float: right" :src="assets('logo.png')" />
      <h2 style="margin-top: 50px">
        {{ $store.state.user.fName }} {{ $store.state.user.lName }}
        {{ getAge() }}
      </h2>
      <div class="text-subtitle-1" style="margin-bottom: 20px">
        {{ $store.state.user.academyName }} -
        {{ $store.state.user.cityDisplay }}
        <country-display
          :countryCode="$store.state.user.nationality"
        ></country-display>
      </div>
      <br />
      <div class="text-subtitle-2">{{ $t("message.resume") }}:</div>
      <div class="text-subtitle-1">
        {{ $t("message.total_competitions") }} {{ user.overview.competitions }}
        <br />
      </div>
      {{ user.overview.goldMedals + " gold, " }}
      {{ user.overview.silverMedals + " silver, " }}
      {{ user.overview.bronceMedals + " bronze" }}
      <br />
      <br />
      <template v-for="h in history">
        <div
          v-if="h.historyType == 'promotion'"
          :key="'print_item_' + h._id"
          style="
            text-align: left;
            border-bottom: 1px solid gray;
            margin-bottom: 5px;
          "
        >
          <i>
            <b>{{ toNiceDateFormat(h.date) }}</b>
          </i>
          - {{ $t("message.belt_promotion") }}: {{ capitalizeText(h.belt) }}
        </div>
        <div
          v-else-if="h.historyType == 'competition'"
          :key="'print_item_' + h._id"
          style="
            text-align: left;
            border-bottom: 1px solid gray;
            margin-bottom: 5px;
          "
        >
          <i>
            <b>{{ toNiceDateFormat(h.date) }}</b>
          </i>
          - {{ $t("message.competition") }}: {{ h.federation }}:
          {{ h.competitionType ? h.competitionType + ", " : "" }} {{ h.age }},
          {{ h.weight }}, {{ capitalizeText(h.belt) }}
          {{ h.place && h.place > 0 ? "(" + h.place + " place)" : "" }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import FileService2 from "@/services/FileService2";
import UserService from "@/services/UserService";
import BeltsSelect from "@/components/inputs/BeltsSelect.vue";
import BeltsSelectStripes from "@/components/inputs/BeltsSelectStripes.vue";
import BeltDisplay from "@/components/displays/BeltDisplay.vue";
import MedalDisplay from "@/components/displays/MedalDisplay.vue";
import FederationsCombo from "@/components/inputs/FederationsCombo.vue";
import AgesCombo from "@/components/inputs/AgeGroupsCombo.vue";
import WeightsCombo from "@/components/inputs/WeightClassesCombo.vue";
import AcademiesSelect from "@/components/inputs/AcademiesSelect.vue";
import DatePicker from "@/components/inputs/DateTimePicker2.vue";
import CountryDisplay from "@/components/displays/CountryDisplay.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

var PHE = require("print-html-element");
var moment = require("moment");

//Icons
import { 
  BadgeCheckIcon,
  PrinterIcon,
  DotsVerticalIcon,
  ChevronDoubleUpIcon,
  LibraryIcon,
  FireIcon,
  PencilIcon,
  XCircleIcon,
  PaperClipIcon,
  CheckCircleIcon,
  DotsHorizontalIcon,
  ChatIcon,
} from '@vue-hero-icons/outline'

export default {
  components: {
    BeltsSelect,
    BackButton,
    BeltsSelectStripes,
    BeltDisplay,
    MedalDisplay,
    AcademiesSelect,
    FederationsCombo,
    WeightsCombo,
    AgesCombo,
    CountryDisplay,
    DatePicker,
    BadgeCheckIcon,
    PrinterIcon,
    DotsVerticalIcon,
    ChevronDoubleUpIcon,
    LibraryIcon,
    FireIcon,
    PencilIcon,
    XCircleIcon,
    PaperClipIcon,
    CheckCircleIcon,
    DotsHorizontalIcon,
    ChatIcon
  },
  data() {
    return {
      academyCreated: this.$route.query.academyCreated,
      geocoder: null,
      user: null,
      selectedBelt: null,
      selectedAcademy: null,
      verifications: null,
      selectedAcademyName: null,
      showEditByIndex: null,
      disableSubmit: false,
      loading: true,
      uploading: false,
      addHistoryDialog: false,
      editHistoryDialog: false,
      deleteHistoryDialog: false,
      verificationDialog: false,
      verificationFilename: null,
      verificationMessageDialog: false,
      verificationMessage: null,
      historyReady: false,
      historyEmpty: false,
      currentToEdit: null,
      history: [],
      newHistoryErrors: null,
      addHistoryError: false,
      selectedDate: null,
      dateMenu: null,
      dateMenu2: null,
      uploadedFile: null,
      selectedFile: null,
      historyTypes: [
        { title: "Promotion", value: "promotion" },
        { title: "New Academy", value: "new_academy" },
        { title: "Competition", value: "competition" },
      ],
      competitionPlaces: [
        { title: "None", value: 0 },
        { title: "First place", value: 1 },
        { title: "Second Place", value: 2 },
        { title: "Third Place", value: 3 },
      ],
      competitionType: [
        { name: "Gi", value: "gi" },
        { name: "No Gi", value: "no-gi" },
      ],
      printCss: ".box {color:blue}",
    };
  },
  async created() {
    if (this.academyCreated)
      this.showAlert(
        "info",
        this.$t("message.academy_created"),
        this.$t("message.academy_created_more"),
        15000
      );
    this.checkUser();
    const u = await UserService.user(this.$store.state.user._id);
    this.user = u;
    if (this.user != null) {
      this.getVerifications();
      if (u.id.toString() == this.$store.state.user._id.toString())
        UserService.userWithUpdate(this.$store.state.user._id);
      this.loading = false;
      this.$store.dispatch(
        "setUserImage",
        u.userImageSmall ? u.userImageSmall : u.userImage
      );
      this.$store.dispatch("setHidden", u.hidden ? u.hidden : false);
    } else {
      this.navigateTo({ name: "error" });
    }
    this.getHistory(0);
    const vm = this;
    this.getMessagesCount(function (count) {
      vm.$store.dispatch("setMessagesCount", count);
    });
    this.getNotificationsCount(function (count) {
      vm.$store.dispatch("setNotifications", count);
    });
  },
  methods: {
    displayvVerificationMessage(message){
      this.verificationMessage = message;
      this.verificationMessageDialog = true;
    },
    filePicker() {
      this.uploading = false;
      this.uploadedFile = null;
      this.verificationFilename = null;
      this.$refs.fileUploadInput.click();
    },
    async getVerifications(reloadHistory) {
      const data = await UserService.verifications(this.$store.state.user._id);
      if (data && data.verifications && data.verifications.length > 0)
        this.verifications = data.verifications;
      if (reloadHistory) this.getHistory(0);
    },
    onFileSelect(event) {
      this.uploading = true;
      if (event.target.files[0]) {
        this.uploadedFile = null;
        this.selectedFile = event.target.files[0];
        const size = event.target.files[0].size;
        const sizeMb = Math.floor(size / 1000000);
        if (sizeMb <= 15) {
          this.uploadFile();
        } else {
          this.uploading = false;
          this.showAlert("error", this.$t("message.file_too_big"));
        }
      } else {
        this.uploading = false;
      }
    },
    uploadFile() {
      const formData = new FormData();
      formData.append("file", this.selectedFile, this.selectedFile.name);
      this.verificationFilename = this.selectedFile.name;
      FileService2.uploadFile(formData, function () {})
        .then((res) => {
          this.uploadedFile = res.url;
          this.uploading = false;
        })
        .catch(() => {
          this.uploading = false;
          this.showAlert("error", this.$t("message.error"));
        });
    },
    verifyEvent() {
      //Clear files and selected history
      const formData = {
        eventDate: this.currentToEdit.date,
        type: this.currentToEdit.historyType == "promotion" ? "belt" : "trophy",
        value: this.currentToEdit.belt
          ? this.beltFromStripes(this.currentToEdit.belt)
          : this.currentToEdit.title,
        fileName: this.verificationFilename,
        fileUrl: this.uploadedFile,
      };
      this.uploading = true;
      UserService.initVerification(formData)
        .then(() => {
          this.showAlert("success", this.$t("message.submitted"));
          this.closeVerificationDialog();
          this.getVerifications(true);
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.error_generic"));
        });
    },
    displayVerificationDialog(h) {
      this.verificationDialog = true;
      this.currentToEdit = h;
    },
    closeVerificationDialog() {
      this.verificationDialog = false;
      this.currentToEdit = null;
      this.uploading = false;
      this.uploadedFile = null;
      this.verificationFilename = null;
      this.$refs.fileUploadInput.value = null;
    },
    alreadyverified(h) {
      const verifiedBelts = this.user.verifiedBelts;
      let belt = this.beltFromStripes(h.belt);
      if (
        belt == "white" ||
        belt == "orange" ||
        belt == "gray" ||
        belt == "green"
      ) {
        return true;
      } else if (verifiedBelts && verifiedBelts[belt]) {
        return true;
      } else if (this.verifications && this.verifications.length > 0) {
        for (const v of this.verifications) {
          //TODO: Add for competitiions
          if (v.type == "belt") {
            if (belt == v.value && ((v.verifiedOn && v.verified) || !v.verifiedOn)) return true;
          }
        }
      }
      return false;
    },
    getAge() {
      const age = this.$store.state.user.dob
        ? "(" + moment().diff(this.$store.state.user.dob, "years") + ")"
        : "";
      return age;
    },
    printResume() {
      var p = this.$refs.print_div;
      console.log(p)
      PHE.printHtml(p.innerHTML);
    },
    displayAddDialog(type) {
      this.addHistoryError = false;
      this.currentToEdit = {};
      this.currentToEdit.historyType = type;
      this.addHistoryDialog = !this.addHistoryDialog;
      this.selectedDate = new Date();
    },
    displayEditDialog(h) {
      this.selectedDate = null;
      this.editHistoryDialog = !this.editHistoryDialog;
      this.currentToEdit = h;
      this.selectedDate = new Date(h.date);
    },
    displayDeleteHistoryConfirm(h) {
      this.currentToEdit = h;
      this.deleteHistoryDialog = !this.deleteHistoryDialog;
    },
    async deleteHistoryEntry() {
      this.deleteHistoryDialog = false;
      const data = await UserService.deleteHistory(this.currentToEdit._id);
      if (data && data.message == "OK") {
        this.getHistory(0);
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    async updateHistoryEntry() {
      this.currentToEdit.date = this.selectedDate;
      this.editHistoryDialog = false;
      if (this.selectedAcademy)
        this.currentToEdit.academy = this.selectedAcademy;
      const data = await UserService.updateHistory(
        this.$store.state.user._id,
        this.currentToEdit._id,
        this.currentToEdit
      );
      if (data && data.message == "OK") {
        // Reload
        this.getHistory(0);
        this.selectedDate = null;
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    validateAddHistory() {
      const h = this.currentToEdit;
      if (!h.date) return false;
      if (h.historyType == "promotion" && !h.belt) return false;
      if (h.historyType == "new_academy" && !h.academy) return false;
      if (h.historyType == "competition" && !h.belt) return false;
      if (h.historyType == "competition" && !h.age) return false;
      if (h.historyType == "competition" && !h.weight) return false;
      return true;
    },
    async addHistoryEntry() {
      this.disableSubmit = true;
      this.currentToEdit.date = this.selectedDate;
      if (this.selectedBelt) this.currentToEdit.belt = this.selectedBelt;
      if (this.selectedAcademy)
        this.currentToEdit.academy = this.selectedAcademy;
      if (this.currentToEdit.historyType == "promotion")
        this.currentToEdit.title = "Belt Promotion";
      else if (this.currentToEdit.historyType == "new_academy")
        this.currentToEdit.title = "New Academy";
      //Fill all parameters?
      if (!this.validateAddHistory()) {
        this.addHistoryError = true;
        return false;
      } else {
        const data = await UserService.addHistory(
          this.$store.state.user._id,
          this.currentToEdit
        );
        if (data && data.message == "OK") {
          this.disableSubmit = false;
          this.selectedDate = null;
          this.currentToEdit = null;
          //New academy? Let user know
          // Reload
          this.getHistory(0);
          if (this.selectedAcademy) {
            if (!this.$store.state.user.academyId) {
              const updatedUser = this.$store.state.user;
              updatedUser.academyId = this.selectedAcademy;
              this.$store.dispatch("setUser", updatedUser);
            }
            this.showAlert(
              "info",
              this.$t("message.new_academy_explain"),
              null,
              8000
            );
          }
          this.selectedAcademy = null;
          this.selectedBelt = null;

          UserService.user(this.$store.state.user._id)
            .then((data) => {
              this.$store.dispatch("setBelt", data.belt);
            });
        } else {
          this.showAlert("error", this.$t("message.oops_not_working"));
        }
        this.addHistoryDialog = false;
      }
    },
    async getHistory() {
      /** TODO: why did I add this? */ 
      this.history = [];
      this.historyEmpty = false;
      this.historyReady = false;
      //Now lets start getting the history
      const data = await UserService.history(this.$store.state.user._id);
      if (data) {
        this.history = data;
        if (data && data.length == 0) this.historyEmpty = true;
        this.history.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        this.historyReady = true;
      }
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style scoped>

</style>
