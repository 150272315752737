import Api from "@/services/Api";

export default {
  async acceptAgreement(aid) {
    const response = await Api().patch("agreements/accept/" + aid);
    return response.data;
  },
  async checkAgreements() {
    const response = await Api().patch("agreements/validate");
    return response.data;
  },
  async latest() {
    const response = await Api().get("agreements/view/latest");
    return response.data;
  },
};
