<template>
  <v-autocomplete
        @input="
          (event) => {
            emitChange(event);
          }
        "
        :items="beltList"
        v-model="selectedBelt"
        :label="$t('message.select_belt')"
        item-text="text"
        item-value="value"
        clearable
        outlined
        dense
      >
        <template slot="item" slot-scope="data">
          <template v-if="!data.item.text">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-avatar>
              <img :src="data.item.avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.text"></v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
  </v-autocomplete>
</template>

<script>
export default {
  data() {
    return {
      selectedBelt: "",
      beltList: [
        {
          text: this.$t("message.gray_white"),
          value: "gray_white",
          avatar: this.assets()+"/belts/gray_white_belt.png",
        },
        {
          text: this.$t("message.gray_white"),
          value: "gray_white1",
          avatar: this.assets()+"/belts/gray_white_belt1.png",
        },
        {
          text: this.$t("message.gray_white"),
          value: "gray_white2",
          avatar: this.assets()+"/belts/gray_white_belt2.png",
        },
        {
          text: this.$t("message.gray_white"),
          value: "gray_white3",
          avatar: this.assets()+"/belts/gray_white_belt3.png",
        },
        {
          text: this.$t("message.gray_white"),
          value: "gray_white4",
          avatar: this.assets()+"/belts/gray_white_belt4.png",
        },
        {
          text: this.$t("message.gray"),
          value: "gray",
          avatar: this.assets()+ "/belts/gray_belt.png",
        },
        {
          text: this.$t("message.gray"),
          value: "gray1",
          avatar: this.assets()+ "/belts/gray_belt1.png",
        },
        {
          text: this.$t("message.gray"),
          value: "gray2",
          avatar: this.assets()+ "/belts/gray_belt2.png",
        },
        {
          text: this.$t("message.gray"),
          value: "gray3",
          avatar: this.assets()+ "/belts/gray_belt3.png",
        },
        {
          text: this.$t("message.gray"),
          value: "gray4",
          avatar: this.assets()+ "/belts/gray_belt4.png",
        },
        {
          text: this.$t("message.gray_black"),
          value: "gray_black",
          avatar: this.assets()+ "/belts/gray_black_belt.png",
        },
        {
          text: this.$t("message.gray_black"),
          value: "gray_black1",
          avatar: this.assets()+ "/belts/gray_black_belt1.png",
        },
        {
          text: this.$t("message.gray_black"),
          value: "gray_black2",
          avatar: this.assets()+ "/belts/gray_black_belt2.png",
        },
        {
          text: this.$t("message.gray_black"),
          value: "gray_black3",
          avatar: this.assets()+ "/belts/gray_black_belt3.png",
        },
        {
          text: this.$t("message.gray_black"),
          value: "gray_black4",
          avatar: this.assets()+ "/belts/gray_black_belt4.png",
        },
        {
          text: this.$t("message.yellow_white"),
          value: "yellow_white",
          avatar: this.assets()+ "/belts/yellow_white_belt.png",
        },
        {
          text: this.$t("message.yellow_white"),
          value: "yellow_white1",
          avatar: this.assets()+ "/belts/yellow_white_belt1.png",
        },
        {
          text: this.$t("message.yellow_white"),
          value: "yellow_white2",
          avatar: this.assets()+ "/belts/yellow_white_belt2.png",
        },
        {
          text: this.$t("message.yellow_white"),
          value: "yellow_white3",
          avatar: this.assets()+ "/belts/yellow_white_belt3.png",
        },
        {
          text: this.$t("message.yellow_white"),
          value: "yellow_white4",
          avatar: this.assets()+ "/belts/yellow_white_belt4.png",
        },
        {
          text: this.$t("message.yellow"),
          value: "yellow",
          avatar: this.assets()+ "/belts/yellow_belt.png",
        },
        {
          text: this.$t("message.yellow"),
          value: "yellow1",
          avatar: this.assets()+ "/belts/yellow_belt1.png",
        },
        {
          text: this.$t("message.yellow"),
          value: "yellow2",
          avatar: this.assets()+ "/belts/yellow_belt2.png",
        },
        {
          text: this.$t("message.yellow"),
          value: "yellow3",
          avatar: this.assets()+ "/belts/yellow_belt3.png",
        },
        {
          text: this.$t("message.yellow"),
          value: "yellow4",
          avatar: this.assets()+ "/belts/yellow_belt4.png",
        },
        {
          text: this.$t("message.yellow_black"),
          value: "yellow_black",
          avatar: this.assets()+ "/belts/yellow_black_belt.png",
        },
        {
          text: this.$t("message.yellow_black"),
          value: "yellow_black1",
          avatar: this.assets()+ "/belts/yellow_black_belt1.png",
        },
        {
          text: this.$t("message.yellow_black"),
          value: "yellow_black2",
          avatar: this.assets()+ "/belts/yellow_black_belt2.png",
        },
        {
          text: this.$t("message.yellow_black"),
          value: "yellow_black3",
          avatar: this.assets()+ "/belts/yellow_black_belt3.png",
        },
        {
          text: this.$t("message.yellow_black"),
          value: "yellow_black4",
          avatar: this.assets()+ "/belts/yellow_black_belt4.png",
        },
        {
          text: this.$t("message.orange_white"),
          value: "orange_white",
          avatar: this.assets()+ "/belts/orange_white_belt.png",
        },
        {
          text: this.$t("message.orange_white"),
          value: "orange_white1",
          avatar: this.assets()+ "/belts/orange_white_belt1.png",
        },
        {
          text: this.$t("message.orange_white"),
          value: "orange_white2",
          avatar: this.assets()+ "/belts/orange_white_belt2.png",
        },
        {
          text: this.$t("message.orange_white"),
          value: "orange_white3",
          avatar: this.assets()+ "/belts/orange_white_belt3.png",
        },
        {
          text: this.$t("message.orange_white"),
          value: "orange_white4",
          avatar: this.assets()+ "/belts/orange_white_belt4.png",
        },
        {
          text: this.$t("message.orange"),
          value: "orange",
          avatar: this.assets()+ "/belts/orange_belt.png",
        },
        {
          text: this.$t("message.orange"),
          value: "orange1",
          avatar: this.assets()+ "/belts/orange_belt1.png",
        },
        {
          text: this.$t("message.orange"),
          value: "orange2",
          avatar: this.assets()+ "/belts/orange_belt2.png",
        },
        {
          text: this.$t("message.orange"),
          value: "orange3",
          avatar: this.assets()+ "/belts/orange_belt3.png",
        },
        {
          text: this.$t("message.orange"),
          value: "orange4",
          avatar: this.assets()+ "/belts/orange_belt4.png",
        },
        {
          text: this.$t("message.orange_black"),
          value: "orange_black",
          avatar: this.assets()+ "/belts/orange_black_belt.png",
        },
        {
          text: this.$t("message.orange_black"),
          value: "orange_black1",
          avatar: this.assets()+ "/belts/orange_black_belt1.png",
        },
        {
          text: this.$t("message.orange_black"),
          value: "orange_black2",
          avatar: this.assets()+ "/belts/orange_black_belt2.png",
        },
        {
          text: this.$t("message.orange_black"),
          value: "orange_black3",
          avatar: this.assets()+ "/belts/orange_black_belt3.png",
        },
        {
          text: this.$t("message.orange_black"),
          value: "orange_black4",
          avatar: this.assets()+ "/belts/orange_black_belt4.png",
        },
        {
          text: this.$t("message.green_white"),
          value: "green_white",
          avatar: this.assets()+ "/belts/green_white_belt.png",
        },
        {
          text: this.$t("message.green_white"),
          value: "green_white1",
          avatar: this.assets()+ "/belts/green_white_belt1.png",
        },
        {
          text: this.$t("message.green_white"),
          value: "green_white2",
          avatar: this.assets()+ "/belts/green_white_belt2.png",
        },
        {
          text: this.$t("message.green_white"),
          value: "green_white3",
          avatar: this.assets()+ "/belts/green_white_belt3.png",
        },
        {
          text: this.$t("message.green_white"),
          value: "green_white4",
          avatar: this.assets()+ "/belts/green_white_belt4.png",
        },
        {
          text: this.$t("message.green"),
          value: "green",
          avatar: this.assets()+ "/belts/green_belt.png",
        },
        {
          text: this.$t("message.green"),
          value: "green1",
          avatar: this.assets()+ "/belts/green_belt1.png",
        },
        {
          text: this.$t("message.green"),
          value: "green2",
          avatar: this.assets()+ "/belts/green_belt2.png",
        },
        {
          text: this.$t("message.green"),
          value: "green3",
          avatar: this.assets()+ "/belts/green_belt3.png",
        },
        {
          text: this.$t("message.green"),
          value: "green4",
          avatar: this.assets()+ "/belts/green_belt4.png",
        },
        {
          text: this.$t("message.green_black"),
          value: "green_black",
          avatar: this.assets()+ "/belts/green_black_belt.png",
        },
        {
          text: this.$t("message.green_black"),
          value: "green_black1",
          avatar: this.assets()+ "/belts/green_black_belt1.png",
        },
        {
          text: this.$t("message.green_black"),
          value: "green_black2",
          avatar: this.assets()+ "/belts/green_black_belt2.png",
        },
        {
          text: this.$t("message.green_black"),
          value: "green_black3",
          avatar: this.assets()+ "/belts/green_black_belt3.png",
        },
        {
          text: this.$t("message.green_black"),
          value: "green_black4",
          avatar: this.assets()+ "/belts/green_black_belt4.png",
        },
        {
          text: this.$t("message.white"),
          value: "white",
          avatar: this.assets("belts/white_belt.png"),
        },
        {
          text: this.$t("message.white1"),
          value: "white1",
          avatar: this.assets("belts/white_belt1.png"),
        },
        {
          text: this.$t("message.white2"),
          value: "white2",
          avatar: this.assets("belts/white_belt2.png"),
        },
        {
          text: this.$t("message.white3"),
          value: "white3",
          avatar: this.assets("belts/white_belt3.png"),
        },
        {
          text: this.$t("message.white4"),
          value: "white4",
          avatar: this.assets("belts/white_belt4.png"),
        },
        {
          text: this.$t("message.blue"),
          value: "blue",
          avatar: this.assets("belts/blue_belt.png"),
        },
        {
          text: this.$t("message.blue1"),
          value: "blue1",
          avatar: this.assets("belts/blue_belt1.png"),
        },
        {
          text: this.$t("message.blue2"),
          value: "blue2",
          avatar: this.assets("belts/blue_belt2.png"),
        },
        {
          text: this.$t("message.blue3"),
          value: "blue3",
          avatar: this.assets("belts/blue_belt3.png"),
        },
        {
          text: this.$t("message.blue4"),
          value: "blue4",
          avatar: this.assets("belts/blue_belt4.png"),
        },
        {
          text: this.$t("message.purple"),
          value: "purple",
          avatar: this.assets("belts/purple_belt.png"),
        },
        {
          text: this.$t("message.purple1"),
          value: "purple1",
          avatar: this.assets("belts/purple_belt1.png"),
        },
        {
          text: this.$t("message.purple2"),
          value: "purple2",
          avatar: this.assets("belts/purple_belt2.png"),
        },
        {
          text: this.$t("message.purple3"),
          value: "purple3",
          avatar: this.assets("belts/purple_belt3.png"),
        },
        {
          text: this.$t("message.purple4"),
          value: "purple4",
          avatar: this.assets("belts/purple_belt4.png"),
        },
        {
          text: this.$t("message.brown"),
          value: "brown",
          avatar: this.assets("belts/brown_belt.png"),
        },
        {
          text: this.$t("message.brown1"),
          value: "brown1",
          avatar: this.assets("belts/brown_belt1.png"),
        },
        {
          text: this.$t("message.brown2"),
          value: "brown2",
          avatar: this.assets("belts/brown_belt2.png"),
        },
        {
          text: this.$t("message.brown3"),
          value: "brown3",
          avatar: this.assets("belts/brown_belt3.png"),
        },
        {
          text: this.$t("message.brown4"),
          value: "brown4",
          avatar: this.assets("belts/brown_belt4.png"),
        },
        {
          text: this.$t("message.black"),
          value: "black",
          avatar: this.assets("belts/black_belt.png"),
        },
        {
          text: this.$t("message.black1"),
          value: "black1",
          avatar: this.assets("belts/black_belt1.png"),
        },
        {
          text: this.$t("message.black2"),
          value: "black2",
          avatar: this.assets("belts/black_belt2.png"),
        },
        {
          text: this.$t("message.black3"),
          value: "black3",
          avatar: this.assets("belts/black_belt3.png"),
        },
        {
          text: this.$t("message.black4"),
          value: "black4",
          avatar: this.assets("belts/black_belt4.png"),
        },
        {
          text: this.$t("message.black5"),
          value: "black5",
          avatar: this.assets("belts/black_belt5.png"),
        },
        {
          text: this.$t("message.black6"),
          value: "black6",
          avatar: this.assets("belts/black_belt6.png"),
        },
        {
          text: this.$t("message.coral"),
          value: "red_black",
          avatar: this.assets("belts/red_black_belt.png"),
        },
        {
          text: this.$t("message.red_white"),
          value: "red_white",
          avatar: this.assets("belts/red_white_belt.png"),
        },
        {
          text: this.$t("message.red"),
          value: "red",
          avatar: this.assets("belts/red_belt.png"),
        },
      ],
    };
  },
  props: {
    value: null,
    showIcon: null,
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        this.selectedBelt = newValue;
      },
    },
  },
  methods: {
    emitChange(event) {
      this.$emit("input", event);
    },
  },
};
</script>

<style scoped>
</style>
