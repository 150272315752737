<template>
  <div>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <v-card-title>Waivers</v-card-title>
      <v-list v-if="waivers && waivers.length > 0">
        <template v-for="(i, index) in waivers">
          <v-list-item :key="'document' + index"
            @click="navigateTo({ name: 'viewacademydocument', params: { academyDocumentId: i._id } })">
            <v-list-item-content>
              <v-list-item-title>
                {{ i.name }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="i.time">{{ $d(new Date(i.time), 'month_day_year', $i18n.locale) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="i.time">{{ i.academy }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index + 1 < waivers.length" :key="index"></v-divider>
        </template>
      </v-list>
    </v-sheet>
    <v-skeleton-loader v-if="loading" type="list-item, list-item, list-item"></v-skeleton-loader>
  </div>
</template>
<script>
import ConnectService from "@/services/ConnectService";

export default {
  data() {
    return {
      waivers: [],
      loading: true,
    };
  },
  components: {
  },
  created() {
    this.getWaivers();
  },
  methods: {
    getWaivers() {
      ConnectService.waivers()
        .then((data) => {
          if (data) {
            this.waivers = data.waivers;
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err)
          if (err)
            this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
