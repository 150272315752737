<template>
  <v-sheet v-if="$store.state.isUserLoggedIn" :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
    <v-card>
      <v-card-text class="pa-1">
        <iframe class="agreement_iframe" id="scroll-target" :src="url"></iframe>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="goBack()">{{$t('message.back')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>
<script>

export default {
  data() {
    return {
      url: "https://bjjlink.com/info/en/terms.html",
    };
  },
  components: {
  },
  async created() {
  },
  methods: {
  },
};
</script>

<style scoped>
.agreement_iframe{
  width: 100%;
  height:400px;
}
</style>
