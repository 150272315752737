<template>
  <div class="about">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-text class="pb-0">
        <v-autocomplete
          v-if="sources"
          v-model="sourceFilter"
          :items="sources"
          :label="$t('message.news_source')"
          item-text="name"
          item-value="id"
          chips
          clearable
          dense
          outlined
          :loading="loadingSources"
          @change="filterNews(false)"
        ></v-autocomplete>
        <v-combobox
          @change="filterNews(false)"
          :items="categories"
          v-model="categoryFilter"
          chips
          clearable
          dense
          outlined
          :loading="loadingCategories"
          :label="$t('message.category')"
        >
        </v-combobox>
      </v-card-text>
      <v-card-actions v-if="topFeeds"  class="pa-0 pr-3 pb-2" style="margin-top:-10px">
        <v-spacer></v-spacer>
        <v-btn style="margin-top:-5px" color="primary" right icon x-small @click="topSourcesDialog=true">
          <TrendingUpIcon class="hero-button" size="20"></TrendingUpIcon>
        </v-btn>
      </v-card-actions>
    </v-sheet>
    <v-sheet v-if="false" :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
      <v-card-title class="supporting-text" style="padding-left:10px;">{{$t('message.featured_magazine')}}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="primary" small>
          Genzai Magazine
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-sheet>
    <NewsDisplay
      v-for="(i, index) in news" :key="index+'_'+i.sourceId"
      :entry="i" 
      v-on:open_share_dialog="openPostDialog"
    ></NewsDisplay>
    <div v-if="loadingNews" class="mt-2">
      <v-skeleton-loader
        type="article, article"
      ></v-skeleton-loader>
    </div>
    <v-dialog v-model="postDialog" max-width="90%" v-if="selectedItem">
      <v-card outlined>
        <v-card-title><b style="margin-right:3px">{{$t('message.posting')}}:</b> {{selectedItem.title}}</v-card-title>
        <v-card-text>
          <v-textarea :label="$t('message.contents')" v-model="selectedItem.contents"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small @click="postNews()" class="elevation-0">{{$t('message.share')}}</v-btn>
          <v-btn color="secondary" small @click="closePostDialog()" class="elevation-0">{{$t('message.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="topSourcesDialog" max-width="90%" v-if="topFeeds">
      <v-card outlined>
        <v-card-title>{{$t('message.top_feeds')}}</v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-action>
              <TrendingUpIcon size="30" :color="themeColor('gold')" class="hero-icon"></TrendingUpIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{topFeeds[0].name}}
              </v-list-item-title>
              <v-list-item-subtitle class="text-subtitle-1">
                {{topFeeds[0].url}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <TrendingUpIcon size="30" :color="themeColor('silver')" class="hero-icon"></TrendingUpIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{topFeeds[1].name}}
              </v-list-item-title>
              <v-list-item-subtitle class="text-subtitle-1">
                {{topFeeds[1].url}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <TrendingUpIcon size="30" :color="themeColor('bronze')" class="hero-icon"></TrendingUpIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{topFeeds[2].name}}
              </v-list-item-title>
              <v-list-item-subtitle class="text-subtitle-1">
                {{topFeeds[2].url}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="topSourcesDialog=false" class="elevation-0">{{$t('message.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import NewsService from "@/services/NewsService";
import NewsDisplay from "@/components/displays/NewsDisplay.vue";

import { 
  TrendingUpIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      news: [],
      topSourcesDialog: false,
      loading: null,
      sourceFilter: null,
      sources: [],
      loadingSources: true,
      loadingNews: true,
      loadingCategories: true,
      categories: [],
      categoryFilter: null,
      selectedItem: null,
      postDialog: false,
      topFeeds: null
    };
  },
  components: {
    NewsDisplay,
    TrendingUpIcon,
  },
  async created() {
    this.filterNews(false, true);
    NewsService.sources()
      .then((data) => {
        this.sources = data.sources;
        this.loadingSources = false;
      });
  },
  methods: {
    filterFromPodium(p){
      this.sourceFilter = p.id;
    },
    filterByCategory(cat){
      this.categoryFilter = cat;
    },
    postNews(){
      NewsService.share({
        contents: this.selectedItem.contents,
        link: this.selectedItem.link,
        sourceId: this.selectedItem.sourceId,
        entryId: this.selectedItem._id,
      })
      .then(() => {
        this.closePostDialog();
        this.showAlert("success", this.$t("message.success"));
      })
      .catch(() => {
        this.showAlert("error", this.$t("message.oops_not_working"));
      });
    },
    openPostDialog(item){
      this.selectedItem = item;
      this.postDialog = true;
    },
    closePostDialog(){
      this.postDialog = false;
      this.selectedItem = null;
    },
    filterNews(append, loadCategories){
      this.loadingNews = true;
      this.news = [];
      if(!this.sourceFilter && !this.categoryFilter)
        loadCategories = true;
      NewsService.filter({source: this.sourceFilter, category: this.categoryFilter})
        .then((data) => {
          this.news = data.news;
          this.loadingNews = false;
          this.topFeeds = this.getPodiumData(data.topFeeds);
          if(loadCategories)
            this.loadCategoriesArray();
        });
    },
    getPodiumData(data){
      if(data && data.length == 3){
        let podiumData = [];
        podiumData.push({
          name: data[1].name,
          url: data[1].url,
          id: data[1]._id,
          icon: 'stars',
          color:'#e0e0e0',
          place: 2
        });
        podiumData.push({
          name: data[0].name,
          url: data[0].url,
          id: data[0]._id,
          icon: 'stars',
          color:'#e0e0e0',
          place: 1
        });
        podiumData.push({
          name: data[2].name,
          url: data[2].url,
          icon: 'stars',
          id: data[2]._id,
          color:'#e0e0e0',
          place: 3
        });
        return podiumData;
      }
    },
    loadCategoriesArray(){
      const catSet = new Set()
      for(const n of this.news){
        if(n.categories){
          for(const cat of n.categories)
            catSet.add(cat)  
        }
      }
      this.categories = Array.from(catSet);
      this.loadingCategories = false;
    },
  },
};
</script>

<style scoped>
</style>