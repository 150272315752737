<template>
  <v-combobox
      @input="event => { emitChange(event) }"
      :items="loadedItems"
      v-model="selected"
      :search-input.sync="search"
      small-chips
      clearable
      outlined
      dense
      :label="labelText"
    >
      <template slot="no-data" v-if="allowNew">
        <v-list-item>
          <span class="subheading">{{ $t('message.click_enter_add_new') }}:</span>
          <v-chip label small>{{ search }}</v-chip>
        </v-list-item>
      </template>
  </v-combobox>
</template>

<script>
import VideoService from "@/services/VideoService";

export default {
  data() {
    return {
      selected: null,
      loadedItems: null,
      labelText: null,
      search: null,
      preloadedItems: ['tournament','armbar','choke',
        'guard','pass','side-control','drills','omoplata',
        'back','americana','attack','defense','seminar']
    }
  },//SAve video category lower cased
  created() {
    if(this.label)
      this.labelText = this.label;
    this.loadedItems = this.preloadedItems;
    //Get preloaded ones
    VideoService.categories(this.academyOnly)
      .then(data => {
        if (data) {
          var tmp = data;
          for(const i of this.preloadedItems){
            if(tmp.indexOf(i.toLowerCase()) < 0)
              tmp.push(i.toLowerCase());
          }
          this.loadedItems = tmp.sort();
        }
      });
  },
  props: {
    value: null,
    label: null,
    allowNew: null,
    academyOnly: {type: Boolean, default: false}
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        this.selected = newValue;
      }
    }
  },
  methods: {
    emitChange (event) {
      this.$emit('input', event)
    }
  }
};
</script>

<style scoped>
</style>
