<template>
  <v-combobox
    v-model="selectedValue"
    :items="federations"
    small-chips
    dense
    outlined
    :label="$t('message.pick_add_federation')"
    @input="event => { $emit('input', event) }"
  ></v-combobox>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  data() {
    return {
      selectedValue: null,
      federations: [],
      loading: false,
      items: [],
      search: null
    };
  },
  created() {
    UserService.federations()
      .then(data => {
        if (data) {
          this.federations = data.sort();
        }
      });
  },
  mounted(){
    this.selectedValue = this.value
  },
  props: {
    value: null,
    showIcon: {
      type: Boolean, default:false
    }
  },
  watch: {
    search(val) {
      val && this.querySelections(val);
    },
    value: {
      handler: function(newValue) {
        this.selectedValue = newValue;
      }
    }
  },
  methods: {
    async querySelections(v) {
      this.loading = true;
      if (this.federations.length == 0) {
        var data = await UserService.federations();
        this.federations = data.sort();
      }
      this.items = this.federations.filter(e => {
        return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
      });
      this.loading = false;
    }
  }
};
</script>

<style scoped>
</style>
