<template>
  <svg :width="width" :height="height" viewBox="0 0 30 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Comp Medal</title>
    <g id="App-Design-2022" stroke="none" stroke-width="1" :fill="iconColor" fill-rule="evenodd">
        <g id="BJJLINK-Social---New-Design---DesktopDark-2/22" transform="translate(-235.000000, -370.000000)" :stroke="iconColor" fill-rule="nonzero">
            <g id="profile-card" transform="translate(114.000000, 81.000000)">
                <g id="Group-17" transform="translate(59.000000, 278.000000)">
                    <g id="Comp-Medal" transform="translate(62.886451, 11.000000)">
                        <polygon id="Shape" points="13.0115494 3.2785 10.9875494 0 2.4220494 0 8.7290494 10.2155"></polygon>
                        <path d="M18.3963244,17.441225 L18.3963244,13.85013 L9.8307744,13.85013 L9.8307744,17.441225 C3.20160008,19.5526102 -0.899737533,26.1802897 0.168626669,33.0550623 C1.23699087,39.9298348 7.15625828,45.0002765 14.1135494,45.0002765 C21.0708405,45.0002765 26.9901079,39.9298348 28.0584721,33.0550623 C29.1268363,26.1802897 25.0254987,19.5526102 18.3963244,17.441225 L18.3963244,17.441225 Z M14.1135494,42.97107 C9.22616935,42.97107 4.81997749,40.0271576 2.94959133,35.5118348 C1.07920516,30.9965121 2.11296902,25.7991029 5.56883642,22.3431711 C9.02470382,18.8872394 14.2220938,17.8533787 18.7374514,19.7236808 C23.2528089,21.5939829 26.1968944,26.0001199 26.1968944,30.8875 C26.1892897,37.5578311 20.7838804,42.9633411 14.1135494,42.97107 L14.1135494,42.97107 Z" id="Shape"></path>
                        <path d="M14.1135494,20.59555 C9.95082342,20.59555 6.19791626,23.102893 4.60482171,26.9487137 C3.01172717,30.7945345 3.89218217,35.2213289 6.83562746,38.1648671 C9.77907276,41.1084054 14.2058393,41.9890002 18.0517104,40.3960271 C21.8975815,38.803054 24.4051744,35.050226 24.4051744,30.8875 C24.3988288,25.2061704 19.7948788,20.602075 14.1135494,20.59555 L14.1135494,20.59555 Z M19.1097644,37.76416 L14.1135494,34.134215 L9.1173344,37.76416 L11.0257794,31.89081 L6.0295494,28.260865 L12.2051494,28.260865 L14.1135494,22.3875 L16.0219344,28.260885 L22.1975494,28.260885 L17.2013344,31.89083 L19.1097644,37.76416 Z" id="Shape"></path>
                        <polygon id="Shape" points="18.3965494 12 25.8050494 0 17.2395494 0 9.8305494 12"></polygon>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
//:fill="iconColor"
export default {
  data() {
    return {
      iconColor: "gray",
      width: '25px',
      height: '40px'
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
    if(this.size){
      if(this.size == 'large'){
        this.width = '75px';
        this.height = '120px';
      }
    }
  },
  props: ['color', 'size'],
  watch: {
    color: {
      handler: function (newValue) {
        this.iconColor = this.getColor(newValue);
      },
    },
  },
};
</script>
<style>
</style>