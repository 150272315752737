<template>
  <div v-if="$store.state.isUserLoggedIn">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <BackButton></BackButton>
      <v-card-text v-if="academyName" @click="goToAcademy()" class="text-center text-h5">
        {{academyName}}
      </v-card-text>
      <v-card-text class="text-center pt-0" v-if="rating && rating >= 0">
        <AcademyStars :rating="rating" />
      </v-card-text>
    </v-sheet>
    <v-sheet v-if="review" class="mt-2 pa-2" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-title class="pa-1">
        <user-name :user="review.user" :addHover="false"></user-name>
        <v-spacer></v-spacer>
        <span class="text-caption">{{ $d(new Date(review.time), 'month_day_year', $i18n.locale) }}</span>
      </v-card-title>
      <v-card-text class="pl-1">
        <AcademyStars :rating="review.rating" :show-reviews="false" />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text v-html="review.contents"></v-card-text>
    </v-sheet>
    <v-sheet v-if="this.academyId" class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-actions class="mt-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" small @click="viewAll()">{{ $t('message.view_all') }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-sheet>
  </div>
</template>
<script>
import AcademyStars from "@/components/displays/AcademyStarsDisplay.vue";
import ReviewService from "@/services/ReviewService";
import AcademyService from "@/services/AcademyService";
import UserName from "@/components/displays/UserNameDisplay.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

export default {
  data() {
    return {
      reviewId: this.$route.params.reviewId,
      academyId: null,
      academyName: null,
      starColor1: null,
      starColor2: null,
      starColor3: null,
      starColor4: null,
      starColor5: null,
      reviewsCount: 0,
      reviewsText: "",
      rating: null,
      review: null,
    };
  },
  components: {
    AcademyStars,
    UserName,
    BackButton,
  },
  async created() {
    ReviewService.getReview(this.reviewId)
      .then((data) => {
        if (data) {
          this.review = data;
          this.academyId = data.academy;
          this.reviewsCount = data.count;
          this.calculateRating(this.academyId);
          AcademyService.academy(this.academyId)
            .then((data) => {
              if (data) this.academyName = data.academy ? data.academy.name : null;
            });
        }
      });
  },
  methods: {
    async calculateRating(academyId) {
      const data = await ReviewService.getRating(academyId);
      var rating = data.rating;
      this.rating = data.rating;
      this.reviewsCount = data.totalReviews;
      //Get rating from Back end

      if (this.reviewsCount != 1) this.reviewsText = this.$t("message.reviews");
      else this.reviewsText = this.$t("message.review");
      for (var i = 1; i <= 5; i++) {
        if (i <= rating) this["starColor" + i] = "#FFBE33";
        else this["starColor" + i] = "";
      }
    },
    async viewAll() {
      if (this.academyId)
        this.navigateTo({
          name: "academyreviews",
          params: { academyId: this.academyId },
        });
    },
  },
};
</script>

<style scoped>
</style>