<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 410 120" style="enable-background:new 0 0 410 120;" xml:space="preserve" width="70">
  
        <g id="Layer_2">
          <g>
            <!-- Belt Outline -->
            <rect x="0" y="0" width="410" height="120" fill="black" />
          </g>
        </g>
        <g id="Layer_1">
          <g>
                        <!--Black, White or Red-->
            <rect x="5" y="5.5" width="400" height="109" :fill="filledColors[0]" />
            <!--Black or Red -->
            <rect x="5" y="5.5" width="100" height="109" :fill="filledColors[1]" />

  
            <!-- Red bar -->
            <rect x="206" y="5.5" width="199.6" height="109" fill="Red" />
            <!-- Dan bars -->
            <g>
              <rect x="218.9" y="5.5" :fill="filledColors[2]" width="10" height="109" />
            </g>
            <g>
              <rect x="239.4" y="5.5" :fill="filledColors[3]" width="10" height="109" />
            </g>
            <g>
              <rect x="259.9" y="5.5" :fill="filledColors[4]" width="10" height="109" />
            </g>
            <g>
              <rect x="280.4" y="5.5" :fill="filledColors[5]" width="10" height="109" />
            </g>
            <g>
              <rect x="300.9" y="5.5" :fill="filledColors[6]" width="10" height="109" />
            </g>
            <g>
              <rect x="321.4" y="5.5" :fill="filledColors[7]" width="10" height="109" />
            </g>
            <g>
              <rect x="341.9" y="5.5" :fill="filledColors[8]" width="10" height="109" />
            </g>
            <g>
              <rect x="362.4" y="5.5" :fill="filledColors[9]" width="10" height="109" />
            </g>
            <g>
              <rect x="382.9" y="5.5" :fill="filledColors[10]" width="10" height="109" />
            </g>
  
          </g>
        </g>
      </svg>
  </template>
  <script>
  export default {
    props: {
      colors: {
        type: Array,
        default: () => [],
      },
      belt: {
        type: String,
        default: "",
      },
    },
    computed: {
      isBlackBelt() {
        return this.belt.startsWith('black');
      },
      filledColors() {
        let defaultColors = ["#000000", "#000000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000", "#FF0000"];
        return this.colors.concat(defaultColors).slice(0, 11);
      },
    },
  };
  </script>
  