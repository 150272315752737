import Api from "@/services/Api";

export default {
  async academyClass(id) {
    const response = await Api().get("calendar/member/classes/" + id);
    return response.data;
  },
  async leaveAcademyClass(id) {
    const response = await Api().patch(
      "calendar/member/classes/" + id + "/leave"
    );
    return response.data;
  },
  async cancelRequest(id) {
    const response = await Api().patch(
      "calendar/member/classes/" + id + "/request/cancel"
    );
    return response.data;
  },
  async joinAcademyClass(id, member) {
    const response = await Api().patch(
      "calendar/member/classes/" +
        id +
        "/join" +
        (member ? "?member=" + member : "")
    );
    return response.data;
  },
  async joinAcademyClassFromHome(id, data) {
    const response = await Api().patch(
      "calendar/member/classes/" + id + "/join",
      data
    );
    return response.data;
  },
  async acceptRequest(managedAcademyid, classid, memberid) {
    const response = await Api().patch(
      "calendar/admin/academy/" +
        managedAcademyid +
        "/classes/" +
        classid +
        "/accept/" +
        memberid
    );
    return response.data;
  },
  async rejectRequest(managedAcademyid, classid, memberid) {
    const response = await Api().patch(
      "calendar/admin/academy/" +
        managedAcademyid +
        "/classes/" +
        classid +
        "/reject/" +
        memberid
    );
    return response.data;
  },
  async upcomingEvents() {
    const today = new Date();
    const response = await Api().get("calendar/member/upcoming?today=" + today);
    return response.data;
  },
};
