import axios from "axios";
import store from "../store/index.js";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_MEDIA_HOST + "/media/",
  headers: {
    authorization: "Bearer " + localStorage.token,
  },
  params: {},
  //withCredentials: true
});

/* Safe this if you want to use this for later
instance.interceptors.request.use((config) => {
    console.log('Hola, request!')
    return config;
}, (error) => {
    console.log('Hola, response error!!!!', error)
    return Promise.reject(error);
});
*/

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch("setToken", null);
      store.dispatch("setUser", null);
      localStorage.removeItem("token");
      window.location = "/login";
      console.log("Token expired, user logged out");
    }
    return Promise.reject(error);
  }
);

export default () => {
  return instance;
};
