<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="isAppAdmin(this.$store.state.user._id)">
    <v-card>
      <v-card-text>
        <v-text-field dense outlined v-model="userId" label="UserId/Email"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn small color="primary" @click="getUser()">Get User</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-2">
      <v-skeleton-loader v-if="loading" type="card"></v-skeleton-loader>
      <v-card-text v-if="user">
        <v-btn elevation="0" color="primary" block small @click="claimAcademyDialog=true">Claim cademy Management</v-btn>
        <v-list>
          <v-list-item @click="goToUserNewTab(user._id)">
            <v-list-item-avatar>
              <img v-if="user.userImageSmall" :src="userImg(user.userImageSmall)" />
              <img v-else :src="assets('blank-profile-picture.png')" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <span class="clickable">{{user.displayName}}</span>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <LinkIcon v-if="isLink" :color="themeColor('primary')" class="hero-icon"></LinkIcon>
              <LinkIcon v-else color="grey" class="hero-icon"></LinkIcon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <span>{{user._id}}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                User ID
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <span>{{user.email}}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                Email
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <span>{{ $d(new Date(user.createdOn), "long", $i18n.locale) }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                Created On
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="user.academyId" @click="goToAcademyNewTab(user.academyId)">
            <v-list-item-content>
              <v-list-item-title>
                <span>{{ user.academyName }}</span>
              </v-list-item-title>
              <v-list-item-subtitle>
                Academy
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-switch @change="toggleIsMinor()" v-model="user.isMinor" label="Is Minor"></v-switch>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-switch :disabled="true" v-model="user.hidden" label="Hidden User"></v-switch>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <div>
          <v-card-title class="text-subtitle-1 mb-0 pb-0">Apps Data</v-card-title>
          <v-list>
            <v-list-item v-for="(a, index) in user.apps" :key="'user_app_'+index">
              <v-list-item-content>
                <v-list-item-title>
                  <span class="mr-1">{{index+1}}</span>
                  <span>{{ a.os }} - {{a.version ? a.version : 'Unknown'}}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
        <v-divider></v-divider>
        <v-expansion-panels>
          <v-expansion-panel v-if="activities">
            <v-expansion-panel-header>
              Latest Activity
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item v-for="(i) in activities" :key="i._id">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{i.activity}} <span class="supporting-text" v-if="i.objectId">{{i.objectId}}</span>
                    </v-list-item-title>
                    <v-list-item-title class="supporting-text">
                      {{$d(new Date(i.time), "long", $i18n.locale)}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Raw Data
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card>
                <v-card-text class="page-description">
                  {{ user | pretty }}
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-text v-else-if="!loading" class="mt-2">
        <h3>Nada</h3>
      </v-card-text>
    </v-card>
    <v-dialog v-if="user" v-model="claimAcademyDialog" max-width="60%">
        <v-card outlined>
          <v-card-title>Claim Academy management for: {{user.displayName}}</v-card-title>
          <v-card-text>
            <v-text-field dense outlined v-model="academyId" label="Academy Id"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn small color="primary" @click.stop="claimAcademyManagement()">Claim Management</v-btn>
            <v-btn small color="secondary" @click.stop="claimAcademyDialog=false">{{ $t('message.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-sheet>
</template>

<script>
import BjjlinkAdminService from "@/services/BjjlinkAdminService";

import {
  LinkIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      aid: this.$route.query.aid,
      uid: this.$route.query.uid,
      user: null,
      email: null,
      userId: null,
      activities: null,
      loading: false,
      isLink: false,
      academyId: null,
      claimAcademyDialog: false,
    };
  },
  created() {
    if (this.aid)
      this.academyId = this.aid;
    if (this.uid) {
      this.userId = this.uid;
      this.getUser();
    }
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(value, null, 2);
    }
  },
  components: {
    LinkIcon
  },
  methods: {
    toggleIsMinor() {
      const isMinor = this.user.isMinor;
      const data = {
        isMinor: isMinor,
        userId: this.user._id,
      };
      BjjlinkAdminService.toggleIsMinor(data)
        .then(() => {
          this.showAlert("success", "Is Minor: "+ isMinor);
        })
        .catch(() => {
          this.showAlert("error", "Error");
        });
    },
    claimAcademyManagement() {
      const data = {
        userId: this.user._id,
        academyId: this.academyId
      };
      BjjlinkAdminService.claimAcademy(data)
        .then(() => {
            this.academyId = null
            this.showAlert("success", "Claimed");
        })
        .catch((e) => {
          console.log(e);
          this.showAlert("error", "Error");
        });
    },
    addPartner() {},
    goToUserNewTab(id){
      window.open("/secure/profile/"+id);
    },
    goToAcademyNewTab(id){
      window.open("/secure/academy/"+id);
    },
    getUser(){
      if(this.email || this.userId){
        this.user = null;
        this.loading = true;
        const data = {
          userId: this.userId,
          email: this.email ? this.email.trim() : null
        }
        BjjlinkAdminService.getUserByEmailOrId(data)
          .then((data) => {
            this.user = data.user;
            if(data.activities)
              this.activities = data.activities;
            //santiagoamaral@gmail.com
            this.isLink = data.isLink;
            if(this.user.linking)
              this.user.linking = this.user.linking.length;
            if(this.user.linkedBy)
              this.user.linkedBy = this.user.linkedBy.length;
            if(this.user.history)
              this.user.history = this.user.history.length;
            if(this.user.userImages)
              this.user.userImages = this.user.userImages.length;
            if(this.user.userImages)
              this.user.password = "****";
            this.loading = false;
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
          });
      }else{
        this.showAlert("error", "Email required!");
      }
    }
  },
};
</script>

<style scoped></style>
