<template>
  <v-container grid-list-xs pa-0>
    <v-layout v-if="!hideDate && lang == 'en'">
      <v-flex xs3>
        <v-autocomplete
          @change="updateMonth"
          v-if="ready"
          :items="months"
          v-model="selectedMonth"
          :label="$t('message.month')"
          autocomplete="off"
        >
        </v-autocomplete>
      </v-flex>
      <v-flex xs1 class="date-divider">/</v-flex>
      <v-flex xs3>
        <v-autocomplete
          @change="updateDay"
          v-if="ready"
          :items="days"
          v-model="selectedDay"
          :label="$t('message.day')"
          autocomplete="off"
        >
        </v-autocomplete>
      </v-flex>
      <v-flex xs1 class="date-divider">/</v-flex>
      <v-flex xs4>
        <v-autocomplete
          @change="updateYear"
          v-if="ready"
          :items="years"
          v-model="selectedYear"
          :label="$t('message.year')"
          autocomplete="off"
        >
        </v-autocomplete>
      </v-flex>
    </v-layout>
    <v-layout v-else-if="!hideDate">
      <v-flex xs3>
        <v-autocomplete
          @change="updateDay"
          v-if="ready"
          :items="days"
          v-model="selectedDay"
          :label="$t('message.day')"
          autocomplete="off"
        >
      </v-autocomplete>
      </v-flex>
      <v-flex xs1 class="date-divider">/</v-flex>
      <v-flex xs3>
        <v-autocomplete
          @change="updateMonth"
          v-if="ready"
          :items="months"
          v-model="selectedMonth"
          :label="$t('message.month')"
          autocomplete="off"
        >
        </v-autocomplete>
      </v-flex>
      <v-flex xs1 class="date-divider">/</v-flex>
      <v-flex xs4>
        <v-autocomplete
          @change="updateYear"
          v-if="ready"
          :items="years"
          v-model="selectedYear"
          :label="$t('message.year')"
          autocomplete="off"
        >
        </v-autocomplete>
      </v-flex>
    </v-layout>
    <v-layout v-if="showTime">
      <v-flex xs3>
        <v-autocomplete
          @change="updateHours"
          v-if="ready"
          :items="hours"
          v-model="selectedHour"
          :label="$t('message.hour')"
          autocomplete="off"
        >
        </v-autocomplete>
      </v-flex>
      <v-flex xs1 class="date-divider">:</v-flex>
      <v-flex xs3>
        <v-autocomplete
          @change="updateMinutes"
          v-if="ready"
          :items="minutes"
          v-model="selectedMinute"
          :label="$t('message.minute')"
          autocomplete="off"
        >
        </v-autocomplete>
      </v-flex>
      <v-flex xs1 class="date-divider">&nbsp;</v-flex>
      <v-flex xs3>
        <v-autocomplete
          @change="updateMeridiem"
          v-if="ready"
          :items="meridiems"
          v-model="selectedMeridiem"
          autocomplete="off"
        >
        </v-autocomplete>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      ready: false,
      lang: this.$store.state.language,
      timeValue: null,
      dateValue: null,
      selectedDay: null,
      selectedMonth: null,
      selectedYear: null,
      selectedMinute: null,
      selectedHour: null,
      selectedMeridiem: null,
      days: [],
      months: [],
      years: [],
      minutes: [],
      hours:[],
      meridiems: ['AM', 'PM']
    }
  },
  created() {
    if(!this.lang)
      this.lang = 'en';
    this.loadAvailableYears();
  },
  props: {
    showTime: {type: Boolean, default:false},
    hideDate: {type: Boolean, default:false},
    value: null,
    pastEvent: {type: Boolean, default:false},
    label: null
  },
  methods: {
    initValues(){
      try{
        if(this.value){
          if(this.value instanceof Date){
            this.dateValue = this.value;
          }else{
            this.dateValue = new Date(this.value); 
          }
          if(!this.dateValue)
            this.dateValue = new Date();
        }else{
          this.dateValue = new Date();
        }
        this.updateAvailableDays(this.dateValue.getMonth()+1);
        this.updateAvailableMonths(this.dateValue.getFullYear());
        this.selectedYear = this.dateValue.getFullYear();
        this.selectedMonth = this.dateValue.getMonth() + 1;
        this.selectedDay = this.dateValue.getDate();
        if(!this.showTime){
          this.dateValue.setMinutes(0);
          this.dateValue.setHours(0);
          this.dateValue.setSeconds(0);
        }else{
          //Keep minutes and hours from whatever they passed
          this.dateValue.setSeconds(0);
          let hour = this.dateValue.getHours();
          if(hour > 13){
            this.selectedHour = hour-12;
          }else{
            this.selectedHour = hour;
          }
          if(!this.value){
            let minutesValue = this.dateValue.getMinutes()-(this.dateValue.getMinutes() % 15);
            if(minutesValue == 0)
              minutesValue = 15;
            this.dateValue.setMinutes(minutesValue);
          }
          this.selectedMinute = "00";
          this.selectedMeridiem = this.getMeridiem(this.dateValue);
          for(let i = 1; i< 13; i++)
            this.hours.push(i);
          for(let i = 0; i< 60; i+=15)
            this.minutes.push(i == 0 ? '00' : i+'');
          this.updateMinutes(this.dateValue.getMinutes());
        }
      }catch(err){
        if(err)
          console.log('Cant init values... ', err)
      }
    },
    hasNumber(array, num){
      if(array){
        if(array.indexOf(num) >= 0)
          return true;
      }
      return false;
    },
    updateDay(val){
      this.dateValue.setDate(val);
      this.emitChange(this.dateValue);
    },
    updateMonth(val){
      this.dateValue.setMonth(val-1);
      this.updateAvailableDays(val);
      if(!this.hasNumber(this.days, this.selectedDay)){
        this.dateValue.setDate(this.days[0]);
        this.selectedDay = this.days[0];
      }
      this.emitChange(this.dateValue);
    },
    updateYear(val){
      if(this.dateValue){
        this.dateValue.setFullYear(val);
        this.updateAvailableMonths(val);
        if(!this.hasNumber(this.months, this.selectedMonth)){
          this.dateValue.setMonth(this.months[0]-1);
          this.selectedMonth = this.months[0];
        }
        this.emitChange(this.dateValue);
      }
    },
    updateMinutes(val){
      if(this.dateValue){
        this.dateValue.setMinutes(val);
        this.emitChange(this.dateValue);
      }
    },
    updateHours(val){
      if(this.dateValue){
        if(this.selectedMeridiem == 'PM'){
          this.dateValue.setHours(val+12);
        }else if(this.selectedMeridiem == 'AM'){
          this.dateValue.setHours(val);
        }
        this.emitChange(this.dateValue);
      }
    },
    updateMeridiem(val){
      if(this.dateValue){
        const hour = this.dateValue.getHours();
        if(val == 'AM' && hour > 13){
          this.dateValue.setHours(hour-12);
        }else if(val == 'PM' && hour < 12){
          this.dateValue.setHours(hour+12);
        }
        this.emitChange(this.dateValue);
      }
    },
    loadAvailableYears(){
      let today = new Date();
      let maxYears = today.getFullYear()+5;
      let minYears = today.getFullYear();
      if(this.pastEvent){
        maxYears = today.getFullYear();
        minYears = today.getFullYear()-100;
      }
      this.years = [];
      for (let i = minYears; i <= maxYears; i++)
        this.years.push(i);
      this.initValues();
      this.ready = true;
    },
    updateAvailableDays(month){
      //Day 0 is the last day in the previous month
      let daysInMonth = new Date(this.dateValue.getFullYear(), month, 0).getDate();
      let from  = 1;
      if(!this.pastEvent){
        //Only allow dates in the future depending or which day is today
        const today = new Date();
        if(today.getMonth() == this.dateValue.getMonth())
          from = new Date().getDate();
      }
      let tmp = [];
      for (let i = from; i <= daysInMonth; i++)
        tmp.push(i);
      this.days = tmp;
    },
    updateAvailableMonths(year){
      //const date = new Date();
      console.log(year);
      //const thisYear = date.getFullYear();
      //const selectedYear = year;
      let from = 1;
      let to = 12;
      /*
      if(thisYear == selectedYear){
        if(!this.pastEvent){
          //Future event and same year, only show available months
          from = date.getMonth()+1;
        }else{
          //Past event and same year, only show available months
          to = date.getMonth()+1;
        }
      }
      */
      const tmp = [];
      for (let i = from; i <= to; i++)
        tmp.push(i);
      this.months = tmp;
    },
    emitChange (event) {
      //console.log('Emitting change: ',event);
      this.$emit('input', event)
    }
  }
};
</script>

<style scoped>
.date-divider{
  margin-left:5px;
  margin-right:5px;
  font-size: xx-large;
  margin-top:10px
}
</style>