<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="$store.state.isUserLoggedIn" >
    <BackButton></BackButton>
    <div v-if="loading">
      <circles-loader></circles-loader>
    </div>
    <div v-if="!loading && academy">
      <v-card elevation="0">
        <v-card-title>
          <v-avatar>
            <img v-if="academy.image" :src="userImg(academy.image)">
            <img v-else :src="assets('default-empty-academy.png')">
          </v-avatar>
          <span class="text-h5">{{academy.name}}</span>
        </v-card-title>
        <v-card-text v-if="template"><h3>{{template.name}}</h3></v-card-text>
        <v-card-text v-if="originalRecipient" class="text-caption">
          {{$t('message.on_behalf_of')}}: 
          <span class="ml-1">{{originalRecipient.displayName}}</span>
        </v-card-text>
        <v-card-text v-html="template.contents"></v-card-text>
        <div class="text-left" v-if="template.questions">
          <div style="margin-left:15px"><span class="required">*</span> <span class="supporting-text">{{$t('message.required')}}</span></div>
          <template v-for="(i, index) in template.questions">
            <v-card :key="'question_'+index" class="elevation-0 question-card">
              <v-card-title>{{index+1}} - {{i.question}} <span v-if="i.required" class="required">*</span></v-card-title>
              <v-card-text class="answer-box">
                <span v-if="i.answerType == 'boolean'">
                  <v-radio-group v-model="i.answerBoolean">
                    <v-radio
                      :label="$t('message.true')"
                      :value="true"
                    ></v-radio>
                    <v-radio
                      :label="$t('message.false')"
                      :value="false"
                    ></v-radio>
                  </v-radio-group>
                </span>
                <span v-else-if="i.answerType == 'multiple_choice'">
                  <v-radio-group v-model="i.answer">
                    <template 
                      v-for="(ao, index2) in i.answerOptions">
                      <v-radio
                        :key="i._id+'answer_option_'+index2"
                        :label="ao"
                        :value="ao"
                        v-if="ao != ''"
                      ></v-radio>
                    </template>
                  </v-radio-group>
                </span>
                <span v-else>
                  <v-text-field v-model="i.answer" :label="$t('message.your_answer')"></v-text-field>
                </span>
              </v-card-text>
            </v-card>
          </template>
        </div>
        <v-card-actions>
          <v-btn
            color="primary"
            small
            class="elevation-0"
            @click="submitDocument()"
          >
            {{$t('message.submit')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-hide-embed="$route.query.embed"
            color="secondary"
            small
            class="elevation-0"
            @click="goBack()"
          >
            {{$t('message.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-sheet>
</template>
<script>
import NotificationService from "@/services/NotificationService";
import AcademyDocumentService from "@/services/AcademyDocumentService";
import CirclesLoader from "@/components/displays/CirclesLoader.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

export default {
  data() {
    return {
      embed: this.$route.query.embed,
      loading: false,
      academyDocumentId: this.$route.params.academyDocumentId,
      notificationId: this.$route.params.notificationId,
      template: null,
      date: null,
      academy: null,
      messages: null,
      originalRecipient: null,
      participantName: null,
    };
  },
  components: {
    CirclesLoader,
    BackButton
  },
  created() {
    const d = new Date();
    this.date = this.toNiceDateCustomFormat(d, "YYYY-MM-")
    this.loadDocument();
    if (this.notificationId && this.notificationId != 'alert') {
      NotificationService.read(this.notificationId);
    }
  },
  methods: {
    loadDocument(){
      AcademyDocumentService.document(this.academyDocumentId, this.notificationId)
        .then(data => {
          if(data.documentOwner){
            this.originalRecipient = data.documentOwner;
          }
          this.academy = data.academy;
          this.template = data.template;
          if(data.template){
            //this.switchLang(data.template.lang);
          }
          this.loading = false;
        })
        .catch(err => {
          if(err)
            this.showAlert(
              "error",
              this.$t('message.invalid_document')
            );
        });
    },
    submitDocument(){
      this.messages = null;
      if(this.template && this.template.questions && this.template.questions.length > 0){
        for(const q of this.template.questions){
          if(q.required){
            if(q.answerType == 'text' && !q.answer){
              this.showAlert(
                "info",
                this.$t('message.complete_required_parameters')
              );
              return;
            }else if(q.answerType == 'boolean' && typeof q.answerBoolean == 'undefined'){
              this.showAlert(
                "info",
                this.$t('message.complete_required_parameters')
              );
              return ;
            }
          }
        }
      }
      const postData = {
        userName: this.participantName,
        userDate: new Date(),
        originalRecipient: this.originalRecipient ? this.originalRecipient._id : null,
        contents: this.template.contents,
        questions: this.template.questions,
      };
      AcademyDocumentService.submitDocument(this.academyDocumentId, postData)
        .then(data => {
          if (data) {
              if(this.embed == 'true')
                this.navigateTo({ name: 'success' });
              else
                this.navigateTo({ name: 'notifications' });
            }
        })
        .catch(err => {
          if(err)
            this.showAlert(
              "error",
              this.$t('message.error_generic')
            );
        });
    },
  },
  mounted() {
  },
  computed: {
  },
  watch: {}
};
</script>

<style scoped>
.agreement_iframe{
  width: 100%;
  max-height: 500px;
}
.question-card{
  border-bottom: 2px solid #f5f5f5;
}
.answer-box{
  margin-top:-25px
}
.required{
  font-weight: bold;
  color: #EC7063
}
</style>