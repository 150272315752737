<template>
  <div class="landing" flui fill-height>
    <img 
      :src="assets('landing_screenshot_nologo.jpg')"/>
    <v-flex class="centered" style="min-width:95%;opacity:.8">
      <div class="elevation-0" >
        <v-tabs
          dark
          slider-color="error"
        >
          <v-tab :key="'login'" class="clickable">
            {{$t("message.forgot_password")}}
          </v-tab>
          <v-tab-item :key="'login'">
            <v-card elevation="0" outlined>
              <v-card-text>
                <div class="pl-4 pr-4 pt-2 pb-2">
                  <v-form ref="form">
                    <v-text-field
                      label="Email"
                      v-model="email"
                      required
                      dense
                      outlined
                    ></v-text-field>
                    <v-btn 
                      v-show="showButton"
                      color="primary"
                      @click="submitReset()">
                      {{$t("message.submit")}}
                    </v-btn>
                    <span style="color:green;font-size:20px">{{successMessage}}</span>
                  </v-form>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-flex>
  </div>
</template>

<script>
import AuthenticationService from '@/services/AuthenticationService'

export default {
  data () {
    return {
      email: null,
      successMessage: null,
      showButton: true
    }
  },
  components:{
  },
  mounted(){
  },
  methods: {
    goToLogin(){
      this.navigateTo({name: 'login'});
    },
    async submitReset() {
      if(this.email){
        AuthenticationService.forgotPassword({ email: this.email })
          .then(data => {
            if (data) {
              this.showAlert("success", "Email sent to: " + this.email);
              this.successMessage = 'You can close this window now.';
              this.showButton = false;
            }
          })
          .catch(err => {
            this.showAlert("error", err.response.data.message);
          });
      }
    },
    async reset () {
      try {
        if(!this.password || this.password == '' || 
          !this.password2 || this.password2 == ''){
            this.error = 'Please fill all the parameters';
            return;
        }else if(this.password != this.password2){
          this.error = 'Passwords don\'t match -_-';
          return;
        }else{
          const data = {
            token: this.token,
            password: this.password,
            password2: this.password2
          };
          AuthenticationService.resetPassword(data)
            .then(data => {
              if (data) {
                //GO t Login
                this.goToLogin();
              }
            })
            .catch(err => {
              console.log(err.response.data)
              this.error = err.response.data.message
            });
        }
      } catch (err) {
        console.log(err.response);
        this.error = 'Something went wrong :/'
      }
    }
  }
}
</script>

<style scoped>
  .centered{
    font-family: Helvetica, Arial, sans-serif;
    color: black;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .landing{
    padding: 0px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden
  }
  .landing img{
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%
  }
  .registration_error{
    color: red
  }
</style>
