<template>
    <v-avatar
      :size="calculateSize">
      <img alt="BJJLink Avatar" v-if="!src && !country && altImg" :src="altImg">
      <img alt="BJJLink Avatar" v-else-if="!src && !country" :src="assets('logo_circle_white.png')">
      <img alt="BJJLink Avatar" v-else-if="src" :src="userImg(src)">
      <img :alt="alt ? alt : ''" v-else :src="assets('flags/'+country+'.png')">
    </v-avatar>
</template>

<script>
export default {
  props: [
    "dynamic",
    "size", //small|medium|large
    "src",
    "alt",
    "country",
    "altImg"
  ],
  data() {
    return {
      mode: null
    };
  },
  created() {
    if (this.size == "small" || this.size == "medium" || this.size == "large")
      this.mode = this.size;
    if(!this.size)
      this.mode = 'large';
    else
      this.mode = this.size;
  },
  methods: {},
  computed: {
    calculateSize() {
      if (this.dynamic) {
        //Based on the size you started, we reduce the size
        if (
          this.$vuetify.breakpoint.name == "xs" ||
          this.$vuetify.breakpoint.name == "sm"
        ) {
          //changed it based on this.mode
          //if this.mode =
          return "56px";
        } else if (this.$vuetify.breakpoint.name == "md") {
          return "156px";
        } else {
          return "256px";
        }
      } else {
        if (this.mode == "large") return "300px";
        else if (this.mode == "medium") return "200px";
        else if (this.mode == "small") return "100px";
        else return this.mode
      }
    }
  }
};
</script>

<style scoped>

</style>
