<template>
  <div class="obj-likes">
    <v-btn 
      small 
      elevation="0"
      @click="likeFunction()" 
      :icon="tile ? false : true" 
      :color="tile ? iconColor : ''"
      v-if="alreadyLiked">
      <IconLike v-if="tile" class="hero-button" :size="iconSize" :color="tile ? 'white' : 'gray'" />
      <IconLike v-else class="hero-button" :size="iconSize" :color="iconColor" />
    </v-btn>
    <v-btn 
      elevation="0"
      :outlined="tile ? true : false"
      text
      small 
      @click="likeFunction()" 
      :icon="tile ? false : true" 
      v-else>
      <IconLike class="hero-button" :size="iconSize" :color="iconColor" />
    </v-btn>
    <span 
      v-if="!hideCount"
      class="ml-1 text-caption text-lowercase" @click="togglePopup()">{{badgeNumber}} {{$t("message.likes")}}</span>
    <v-dialog v-model="likesDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="secondary">
          <v-spacer></v-spacer>
          <v-btn icon @click="likesDialog = false">
            <XCircleIcon class="hero-button"></XCircleIcon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <div v-if="loadingUsers">
            <circles-loader></circles-loader>
          </div>
          <div v-else :style="'overflow-y: auto;max-height:'+this.height+'px'">
            <v-list>
              <template v-for="(u, index) in likeUsers">
                <v-list-item @click="goToUser(u._id)" v-if="!u.hidden" :key="'liked_'+obj._id+u._id">
                  <v-list-item-content>
                    <span class="text-body-2">{{u.fName}} {{u.lName}}</span>
                  </v-list-item-content>
                  <v-list-item-avatar>
                    <img style="width:32px;height:32px" :src="userImg(u.userImage)" />
                  </v-list-item-avatar>
                </v-list-item>
                <v-divider v-if="index + 1 < likeUsers.length" :key="index"></v-divider>
              </template>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import IconLike from "@/components/displays/icons/IconLike.vue";
import CirclesLoader from "@/components/displays/CirclesLoader.vue";

import { 
  XCircleIcon,
} from '@vue-hero-icons/outline'

export default {
  props: ["obj", "like", "unlike", "view","size","hideCount","tile"],
  data() {
    return {
      alreadyLiked: false,
      badgeNumber: 0,
      likesDialog: false,
      likeFunction: null,
      iconColor: "grey",
      timer: null,
      isInInfo: false,
      loadingUsers: true,
      iconSize: "40px",
      likeUsers: [],
      height: window.innerHeight - 100
    };
  },
  created() {
    if (this.obj) {
      var liked = false;
      if(this.size)
        this.iconSize = this.size;
      const likes = this.obj.likes;
      this.likeFunction = this.likeObj;
      if (likes && likes.length > 0)
        liked = likes.indexOf(this.$store.state.user._id) >= 0 ? true : false;
      this.alreadyLiked = liked;
      if (liked) {
        this.iconColor = "blue";
        this.likeFunction = this.unlikeObj;
      }
      this.badgeNumber = this.obj.likes ? this.obj.likes.length : 0;
    }
  },
  components: {
    IconLike,
    CirclesLoader,
    XCircleIcon,
  },
  methods: {
    likeObj() {
      const vm = this;
      this.like(this.obj._id, function() {
        vm.alreadyLiked = true;
        vm.iconColor = "blue";
        vm.badgeNumber++;
        vm.likeFunction = vm.unlikeObj;
      });
    },
    unlikeObj() {
      const vm = this;
      this.unlike(this.obj._id, function() {
        vm.alreadyLiked = true;
        vm.iconColor = "grey";
        vm.badgeNumber--;
        vm.likeFunction = vm.likeObj;
      });
    },
    togglePopup() {
      if(this.view){
        if (this.badgeNumber > 0) {
          let vm = this;
          if (this.likesDialog) {
            vm.likesDialog = false;
          } else {
            vm.likesDialog = true;
            vm.viewLikes();
          }
        }
      }
    },
    async viewLikes() {
      if(this.view){
        const vm = this;
        this.view(this.obj._id, function(data) {
          vm.likeUsers = data;
          vm.badgeNumber = data.length;
        });
        this.loadingUsers = false;
      }
    }
  },
  beforeMount() {},
  computed: {}
};
</script>

<style scoped>
.obj-likes{
  overflow-x: hidden;
  min-width: 45px;
}
.obj-likes img {
  vertical-align: middle;
  position: relative;
}
</style>
