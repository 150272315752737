<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'"  v-if="$store.state.isUserLoggedIn">
    <BackButton></BackButton>
    <div v-if="loading">
      <v-skeleton-loader
        v-if="loading"
        type="article"
      ></v-skeleton-loader>
    </div>
    <div v-if="!loading && message">
      <v-card height="100%" class="text-left" elevation="0">
        <v-card-title>
          <v-avatar size="30">
            <img :src="academyImage" />
          </v-avatar>
          <span class="text-subtitle-1 ml-2">{{message.title}}</span>
        </v-card-title>
        <v-card-text class="pl-3 text-body-2" style="padding-left:20px">{{message.contents}}</v-card-text>
      </v-card>
    </div>
  </v-sheet>
</template>
<script>
import NotificationService from "@/services/NotificationService";
import AcademyService from "@/services/AcademyService";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

export default {
  data() {
    return {
      messageId: this.$route.params.messageId,
      message: null,
      academyName: null,
      loading: true,
      academyImage: this.assets('default-empty-academy.png'),
    };
  },
  components: {
    BackButton
  },
  created() {
    NotificationService.notification(this.messageId)
      .then((result) => {
        if (result && result._id) {
          this.message = result;
          if (this.message._id) NotificationService.read(this.messageId);
          AcademyService.academy(this.message.objectId)
            .then((data) => {
              if (data) {
                if (data.academy.image)
                  this.academyImage = this.userImg(data.academy.image);
                this.academyName = data.academy.name;
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      })
      .catch(() => {
        this.loading = false;
        this.showAlert("error", this.$t("message.oops_not_working"));
      });
  },
  methods: {
    agree() {},
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
</style>