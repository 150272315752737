<template>
  <v-card 
    :rounded="isSmallerDisplay() ? '' : 'lg'"
    elevation="0" class="mt-2 pa-2" v-if="entry">
      <div v-if="header" class="text-caption gray--text pa-1">
        {{ $t("message.news") }}
      </div>
      <v-card-actions class="pa-1">
        <country-flag size="25" :country-code="entry.langFlag" style="margin-right:5px"/>
        <TrendingUpIcon class="hero-icon" v-if="entry.topFeed" :color="getPodiumIconColor(entry.topFeed)" size="20"></TrendingUpIcon>
        <v-spacer></v-spacer>
        <span class="text-overline">{{entry.sourceName}}</span>
      </v-card-actions>
      <v-card-text class="pa-0" @click="openRssLink(entry)">
        <FetchedUrl :item="entry" :url="entry.link" :alt="entry" :hide-link="true"/>
        <div class="ml-2">
          {{ $d(new Date(entry.date), 'month_day_year', $i18n.locale) }}
        </div>
      </v-card-text>
      <v-card-text v-if="!hideCategories && entry.categories" class="text-left pa-1">
        <v-chip 
          v-for="(cat, index) in entry.categories" 
          class="clickable mr-1"
          @click="filterByCategory(cat)" 
          x-small 
          :key="'cat_'+entry._id+'_'+index">
          {{cat}}
        </v-chip>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-1">
        <span v-if="entry.website">{{truncate(entry.website, 50)}}</span>
        <v-spacer></v-spacer>
        <v-btn v-if="!hideShare" @click="openPostDialog(entry)" class="elevation-0" icon>
          <ShareIcon class="hero-button">share</ShareIcon>
        </v-btn>
        <v-btn @click="openRssLink(entry)" class="elevation-0" icon><ExternalLinkIcon class="hero-button"></ExternalLinkIcon></v-btn>
      </v-card-actions>
    </v-card>
</template>
<script>
import NewsService from "@/services/NewsService";
import FetchedUrl from "@/components/displays/FetchedUrlDisplay.vue";
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";

import { 
  ExternalLinkIcon,
  ShareIcon,
  TrendingUpIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
    };
  },
  components: {
    FetchedUrl,
    CountryFlag,
    ExternalLinkIcon,
    ShareIcon,
    TrendingUpIcon,
  },
  props: ["entry","hideShare","header", "hideCategories"],
  created(){
  },
  methods:{
    openPostDialog(entry){
      this.$emit("open_share_dialog", entry);
    },
    openRssLink(item){
      if(item){
        const data = {
          entryId: item._id
        };
        NewsService.recordClick(item.sourceId, data);
        this.goToLink(item.link)
      }
    }
  }
};
</script>
<style scoped>
.news-entry-title{
  font-size:small;
  font-weight: bold;
  height: 30px
}
</style>