<template>
  <v-card-actions v-if="post" class="clickable pa-0">
    <div class="pl-2">
      {{totalCount}} <span>{{totalCount == 1 ? $t('message.reaction') : $t('message.reactions')}}</span>
    </div>
  </v-card-actions>
</template>

<script>

export default {
  props: ["post"],
  data() {
    return {
      users: [],
      height: window.innerHeight - 100,
      ossCount: 0,
      ossColor: 'grey',
      totalCount: 0,
    };
  },
  created() {
    if(this.post){
      const emotions = this.post.emotions;
      if(emotions){
        //oss
        this.ossCount = emotions['oss'] ? emotions['oss'].length : 0;
        this.updateTotalCount();
      }
    }
  },
  methods: {
    updateTotalCount(){
      this.totalCount = this.ossCount;
    },
  },
};
</script>

<style scoped>
</style>
