<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="!settings">
    <v-skeleton-loader type="list-item-avatar, divider, list-item-three-line, card-heading, actions">
    </v-skeleton-loader>
  </v-sheet>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-else row wrap>
    <BackButton></BackButton>
    <v-list v-hide-embed="$route.query.embed" subheader three-line>
      <v-subheader>{{ $t("message.preferred_lang") }}</v-subheader>
      <v-list-item>
        <v-list-item-action>
          <GlobeAltIcon class="hero-icon"></GlobeAltIcon>
        </v-list-item-action>
        <v-list-item-content>
          <lang-select v-model="userLang"></lang-select>
        </v-list-item-content>
      </v-list-item>
      <!--
      <v-list-item>
        <v-list-item-action>
        </v-list-item-action>
        <v-list-item-content>
          follow
        </v-list-item-content>
      </v-list-item>
      -->
    </v-list>
    <!--
    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>{{ $t("message.social") }}</v-subheader>
      <v-list-item v-if="!isSmallerDisplay()">
        <v-list-item-action>
          <IconMeta class="hero-icon"></IconMeta>
        </v-list-item-action>
        <v-list-item-content>
          <div v-if="accountFBConnected">
            {{ $t("message.facebook_connected") }}
          </div>
          <div v-else>
            {{ $t("message.facebook_connect_now") }}
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
        </v-list-item-action>
        <v-list-item-content>
          follow
        </v-list-item-content>
      </v-list-item>
    </v-list>
      -->
    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>
        <QuestionMarkCircleIcon size="20" class="hero-icon"></QuestionMarkCircleIcon>{{ $t("message.help") }}
      </v-subheader>
      <v-list-item>
        <v-list-item-action>
          <v-btn @click="goToHelp()" small icon color="primary">
            <QuestionMarkCircleIcon class="hero-icon"></QuestionMarkCircleIcon>
          </v-btn>
        </v-list-item-action>
        <v-list-item-content @click="goToHelp()" class="clickable">
          {{$t('message.help_title')}}
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list subheader three-line>
      <v-subheader>
        <UserIcon size="20" class="hero-icon"></UserIcon>{{ $t("message.profile") }}
      </v-subheader>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.displayCheckinAggregates"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.hide_checkin_aggregates_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.hide_checkin_aggregates_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list subheader three-line>
      <v-subheader>
        <BellIcon size="20"></BellIcon>{{ $t("message.social_notifications") }}
      </v-subheader>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenAcceptEventInvite"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_event_invite_accepted_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_event_invite_accepted_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenEventJoined"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_event_participant_joined_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_event_participant_joined_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenEventDateChanged"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_date_changed_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_date_changed_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenJoinAcademy"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_academy_new_member_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_academy_new_member_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenAcademyLiked"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_academy_new_like_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_academy_new_like_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-show="false">
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenFavoriteCompetitor"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_favorite_competitor_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
            $t("message.noti_favorite_competitor_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenAcademyReviewed"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_academy_new_review_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_academy_new_review_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenTagged"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_new_tag_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_new_tag_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenPostLiked"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_post_liked_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_post_liked_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenPostCommented"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_post_commented_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_post_commented_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenVideoLiked"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_video_new_like_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_video_new_like_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenVideoCommented"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.noti_video_new_comment_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.noti_video_new_comment_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list subheader three-line>
      <v-subheader>
        <LibraryIcon size="20"></LibraryIcon>{{ $t("message.academy_notifications") }}
      </v-subheader>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenAcademyPosts"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("message.noti_new_academy_post_title")
            }}</v-list-item-title>
          <v-list-item-subtitle>{{
            $t("message.noti_new_academy_post_desc")
            }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.notifyWhenAcademyVideoUploaded"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
            $t("message.noti_video_new_academy_video_title")
            }}</v-list-item-title>
          <v-list-item-subtitle>{{
            $t("message.noti_video_new_academy_video_desc")
            }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list subheader three-line>
      <v-subheader>
        <TemplateIcon size="20"></TemplateIcon>{{ $t("message.news_feed_posts") }}
      </v-subheader>
      <!--
      <v-list-item>
        <v-list-item-action>
          <v-checkbox
            @change="updateSettings"
            v-model="settings.postOnLink"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('message.post_new_link_title') }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('message.post_new_link_title') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      -->
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.postOnNewAcademy"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.post_new_academy_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.post_new_academy_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.postOnNewCompetition"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.post_new_competition_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.post_new_competition_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.postOnProfileImage"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.post_new_profile_image_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.post_new_profile_image_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.postOnCheckin"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.post_new_checkin_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.post_new_checkin_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.postOnNewProfessor"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.post_new_professor_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.post_new_professor_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.postOnBeltVerification"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.post_belt_verification_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.post_belt_verification_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox @change="updateSettings" v-model="settings.postOnJoiningEvent"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
          $t("message.post_joining_event_title")
          }}</v-list-item-title>
          <v-list-item-subtitle>{{
          $t("message.post_joining_event_desc")
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!--
      <v-list-item>
        <v-list-item-action>
          <v-checkbox
            @change="updateSettings"
            v-model="settings.postOnNewBelt"
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t('message.post_new_belt_title') }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('message.post_new_belt_description') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      -->
    </v-list>
    <v-divider></v-divider>
    <v-list subheader three-line v-if="embed != 'true'">
      <v-subheader>
        <DocumentTextIcon size="20"></DocumentTextIcon>{{ $t("message.terms") }}
      </v-subheader>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small class="elevation-0" @click="navigateTo({ name: 'viewourterms' })">
          {{ $t("message.view_terms") }}
        </v-btn>
        <v-btn color="primary" small class="elevation-0" @click="navigateTo({ name: 'viewprivacy' })">
          {{ $t("message.privacy_notice") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-list>
    <div id="fb-root"></div>
  </v-sheet>
</template>
<script>
import UserService from "@/services/UserService";
import AuthenticationService from "@/services/AuthenticationService";
import LangSelect from "@/components/inputs/LanguageSelect.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";
//Icons
import { 
  GlobeAltIcon,
  QuestionMarkCircleIcon,
  BellIcon,
  UserIcon,
  TemplateIcon,
  DocumentTextIcon,
  LibraryIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      embed: this.$route.query.embed,
      settings: null,
      FB: undefined,
      femail: "",
      fname: "",
      fimage: "",
      isConnected: false,
      connecting: true,
      accountFBConnected: false,
      userLang: this.$store.state.language,
      fbToken: null,
    };
  },
  created() {
    UserService.initializeSettings({ lang: this.browserLang })
      .then((data) => {
        if (data) {
          this.settings = data.settings;
          //lang?
          if (!this.$store.state.language) {
            this.$store.dispatch("setLanguage", data.lang);
            this.$i18n.locale = data.lang;
            this.userLang = data.lang;
          }
        }
      });
    AuthenticationService.isFBConnected()
      .then((response) => {
        if (response.data && response.data.fbId) {
          this.accountFBConnected = true;
        }
      });
  },
  components: {
    LangSelect,
    GlobeAltIcon,
    QuestionMarkCircleIcon,
    BellIcon,
    UserIcon,
    TemplateIcon,
    DocumentTextIcon,
    LibraryIcon,
    BackButton
  },
  methods: {
    updateSettings() {
      UserService.updateUser([{ propName: "settings", value: this.settings }]);
    },
    async updateLang(newValue) {
      const updateValues = [];
      updateValues.push({ propName: "lang", value: newValue });
      UserService.updateUser(updateValues);
      this.$store.dispatch("setLanguage", newValue);
      this.$i18n.locale = newValue;
      //Reload page
      //this.$router.go();
    },
    connectFacebook() {
      /*
      AuthenticationService.fbConnect(this.fbToken)
      .then(response => {
        const data = response.data;
        if (data) {
          
        }
      })
      .catch(err => {
      });*/
    },
  },
  mounted() {},
  computed: {},
  watch: {
    userLang: {
      handler: function (newValue) {
        this.updateLang(newValue);
      },
    },
  },
};
</script>

<style scoped>
</style>