<template>
  <div class="about">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <v-card-actions class="pl-0 pr-0 pt-0">
        <v-btn @click="navigateTo({
        name: 'createevent'})" block color="primary" small elevation="0">
          <v-icon>mdi-calendar-plus</v-icon>
          {{ $t("message.create_event") }}
        </v-btn>
      </v-card-actions>
      <v-card-actions class="pl-0 pr-0 pt-0">
        <v-btn @click="navigateTo({
        name: 'myevents'})" block color="primary" small elevation="0">
          <v-icon>mdi-calendar-account</v-icon>
          {{ $t("message.my_events") }}
        </v-btn>
      </v-card-actions>
      <v-card-title class="text-subtitle-2 pa-0 mb-0">
        {{ $t("message.filter_events") }}
      </v-card-title>
      <v-container class="pt-0">
        <v-row class="mt-0 pt-0 pb-0 mb-0">
          <v-col>
            <v-text-field :hide-details="true" dense outlined clearable v-model="titleFilter" :label="$t('message.name')">
            </v-text-field>
          </v-col>
          <v-col>
            <countries-select v-model="countryFilter">
            </countries-select>
          </v-col>
        </v-row>
        <v-expansion-panels flat>
          <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
            <v-expansion-panel-header outlined>
              <div>
                <FilterIcon size="20" class="hero-icon"></FilterIcon>
                {{ $t("message.more_filters") }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <event-types-select v-model="typeFilter">
                  </event-types-select>
                </v-col>
                <v-col>
                  <known-cities-select :country="countryFilter" v-model="cityFilter">
                  </known-cities-select>
                </v-col>
              </v-row>
              <v-row class="mt-0 pt-0 pb-0 mb-0">
                <v-col class="mt-0 pt-0 pb-0 mb-0">
                  <v-switch :hide-details="true" v-model="freeEventFilter" :label="$t('message.filter_free_events')"></v-switch>
                </v-col>
                <v-col class="mt-0 pt-0 pb-0 mb-0">
                  <v-switch :hide-details="true" v-model="recurrentFilter" :label="$t('message.recurrent')"></v-switch>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row class="mt-0 pt-0 pb-0 mb-0" v-if="$store.state.userManagedAcademy">
          <v-col class="pt-0 mt-0">
            <v-switch @change="filterEvents()" :hide-details="true" v-model="academyOnlyFilter" :label="$t('message.only_my_academy_events')"></v-switch>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet v-if="ibjjfTournamentsCount > 0" :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2 pa-0" elevation="0">
      <v-card-text class="primary--text" @click="navigateTo({ name: 'ibjjftournaments' })">
        <span v-html="$t('message.ongoing_ibjjf_tournaments', [ibjjfTournamentsCount])"></span>
      </v-card-text>
    </v-sheet>
    <div>
      <div v-if="!loading && (!events || events.length == 0)" class="text-center">
        <div class="text-h6">{{$t('message.no_events_match')}}</div>
      </div>
      <template v-else v-for="(e, index) in events">
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2 pa-0" elevation="0" :key="e._id"
          :position="index">
          <v-card-actions class="pa-1 text-overline">
            <country-flag v-if="e.country" size="15" :countryCode="e.country"></country-flag>
            <EventType class="ml-1" :type="e.type"></EventType>
            <v-spacer></v-spacer>
            <CheckCircleIcon v-if="e.isParticipant" class="hero-icon" :color="themeColor('success')" size="15">
            </CheckCircleIcon>
          </v-card-actions>
          <div v-if="e.altImage" class="text-center" @click="goToEvent(e._id)">
            <v-responsive class="event-cover" :style="eventCoverGradient+',url(\''+userFile(e.altImage)+'\');'">
              <PageAvatar :src="e.image" class="clickable" style="border:2px solid white" size="150px" :alt="e.title" />
            </v-responsive>
          </div>
          <v-card-text v-else class="clickable text-center" @click="goToEvent(e._id)">
            <page-avatar :src="e.image" class="clickable" size="150px" :alt="e.title" />
          </v-card-text>
          <v-card-text class="text-center text-truncate clickable" @click="goToEvent(e._id)">
            <div class="text-h6">{{e.title}}</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-list>
            <v-list-item two-line v-if="e.academyCreator">
              <v-list-item-action>
                <v-avatar size="25px" v-if="e.academyCreator.image"><img :src="userFile(e.academyCreator.image)" />
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{e.cityDisplay}}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{$t('message.event_behalf_academy')}}<span
                    class="ml-1 font-weight-bold">{{e.academyCreator.name}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="e.academyCreator"></v-divider>
            <v-list-item>
              <v-list-item-action>
                <LocationMarkerIcon class="hero-icon"></LocationMarkerIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{e.location}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-action>
                <FlagIcon class="hero-icon"></FlagIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{e.cityDisplay}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-action>
                <RefreshIcon v-if="e.recurrence" class="hero-icon"></RefreshIcon>
                <ClockIcon v-else class="hero-icon"></ClockIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="e.recurrence">
                  {{$t('message.'+e.recurrence+'s')}} {{ $d(new Date(e.date), 'time', $i18n.locale) }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  {{ $d(new Date(e.date), 'long', $i18n.locale) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="e.free"></v-divider>
            <v-list-item v-if="e.free">
              <v-list-item-action>
                <CashIcon class="hero-icon"></CashIcon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{$t('message.free_event')}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
      </template>
      <div v-if="loading">
        <v-skeleton-loader type="card, article"></v-skeleton-loader>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="viewMore" small color="primary" class="elevation-0" @click="filterEvents(true)">
          {{$t('message.view_more')}}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import IBJJFService from "@/services/IBJJFService";
import KnownCitiesSelect from "@/components/inputs/KnownCitiesSelect.vue";
import CountriesSelect from "@/components/inputs/CountriesSelect.vue";
import EventTypesSelect from "@/components/inputs/EventTypesSelect.vue";
import PageAvatar from "@/components/displays/PageAvatarDisplay.vue";
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";
import EventType from "@/components/displays/EventTypeDisplay.vue";

import { 
  FilterIcon,
  CheckCircleIcon,
  LocationMarkerIcon, 
  ClockIcon,
  RefreshIcon,
  CashIcon,
  FlagIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      events: [],
      loading: false,
      viewMore: false,
      cityFilter: null,
      countryFilter: null,
      pointer: 0,
      titleFilter: null,
      typeFilter: null,
      myEvents: [],
      freeEvent: false,
      freeEventFilter: false,
      recurrentFilter: false,
      pastEventsFilter: false,
      academyOnlyFilter: false,
      event: null,
      ibjjfTournamentsCount: 0,
      eventCoverGradient: this.isDarkTheme() ? 'background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(30, 30, 30, 100))' : 
        'background-image:linear-gradient(to bottom, rgba(30, 38, 48, 0), rgba(30, 38, 48, 0), rgba(30, 38, 48, 0), rgba(255, 255, 255, 100))',
    };
  },
  created() {
    if(this.$store.state.user.city && this.$store.state.user.city.country){
      this.countryFilter = this.$store.state.user.city.country;
    }
    if (this.$store.state.userManagedAcademy)
      this.academyOnlyFilter = true;
    this.filterEvents();
    this.getActiveIBJJFTournaments();
  },
  components: {
    KnownCitiesSelect,
    CountriesSelect,
    EventTypesSelect,
    EventType,
    PageAvatar,
    CountryFlag,
    FilterIcon,
    CheckCircleIcon,
    LocationMarkerIcon, 
    ClockIcon,
    RefreshIcon,
    CashIcon,
    FlagIcon,
  },
  watch: {
    typeFilter: {
      handler: function() {
        this.filterEvents();
      }
    },
    countryFilter: {
      handler: function() {
        this.filterEvents();
      }
    },
    cityFilter: {
      handler: function() {
        this.filterEvents();
      }
    },
    titleFilter: {
      handler: function() {
        this.filterEvents();
      }
    },
    freeEventFilter: {
      handler: function() {
        this.filterEvents();
      }
    },
    recurrentFilter: {
      handler: function() {
        this.filterEvents();
      }
    },
  },
  methods: {
    filterEvents(append) {
      if (!this.loading) {
        this.loading = true;
        this.viewMore = false;
        if (!append) {
          this.events = [];
          this.pointer = 0;
        }
        let params = {
          pointer: this.pointer
        };
        if (this.cityFilter && this.cityFilter != "")
          params["city"] = this.cityFilter;
        if (this.academyOnlyFilter)
          params["academyonly"] = this.academyOnlyFilter;
        if (this.typeFilter && this.typeFilter != "")
          params["type"] = this.typeFilter;
        if (this.titleFilter && this.titleFilter.length > 0)
          params["title"] = this.titleFilter;
        if (this.freeEventFilter)
          params["free"] = true;
        if (this.recurrentFilter)
          params["recurrent"] = true;
        if(this.countryFilter)
          params["country"] = this.countryFilter;
        params["from"] = new Date();
        EventService.events(params)
          .then(data => {
            if (data) {
              const sortedEvents = this.getSortedEvents(data.events);
              this.events = this.events.concat(sortedEvents);
              this.loading = false;
              this.pointer = this.pointer + data.count;
              if (data.count >= this.searchesLimit) this.viewMore = true;
            }
          })
          .catch(err => {
            console.log(err)
          });
      }
    },
    getSortedEvents(events){
      for(let e of events){
        e.date = this.adjustTimeToOffset(e.date, e.timeZone);
      }
      return events;
    },
    getActiveIBJJFTournaments() {
      IBJJFService.getActive()
        .then(data => {
          if (data && data.tournaments) {
            this.ibjjfTournamentsCount = data.tournaments.length;
            //this.ibjjfTournamentsCount = data.tournaments.count;
          }
        });
    },
  }
};
</script>

<style scoped>
.event-cover{
  padding-top:15px;
  height:200px;
  background-position: center;
  background-size: cover;
}
</style>