<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" justify-center>
    <div v-if="isAppAdmin(this.$store.state.user._id)">
      <v-card v-if="verifications">
        <v-card-title>
          <BadgeCheckIcon class="hero-icon"></BadgeCheckIcon>
          Pending Verifications ({{verifications.length}})
        </v-card-title>
        <v-list two-line>
          <template v-for="(v, index) in verifications">
            <v-list-item :key="v._id + index">
              <v-list-item-avatar>
                <belt-display
                  v-if="v.type == 'belt'"
                  :belt="v.value"
                  :show-text="false"
                ></belt-display
              ></v-list-item-avatar>
              <v-list-item-content>
              <v-list-item-title
                ><span v-if="v.type == 'belt'">{{
                  $t("message.verification_belt")
                }}</span>:
                <span @click="goToUserNewTab(v.user._id)" class="clickable"
                  ><b>{{ v.user.displayName }}</b></span
              ></v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="v.type == 'belt'">Promotion Date:{{ toSimpleDateFormat(v.eventDate) }}</span>
              </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content
                ><span class="clickable" @click="viewImage(v.fileUrl, v.fileName)"
                  ><PaperClipIcon class="hero-icon"></PaperClipIcon></span
                ></v-list-item-content
              >
              <v-list-item-action>
                <v-btn icon @click="completeVerificationRequest(v._id)">
                  <CheckCircleIcon class="hero-button" color="green"></CheckCircleIcon></v-btn
                >
                <v-btn icon @click="displayRejectDialog(v._id)">
                  <XCircleIcon class="hero-button" color="error"></XCircleIcon></v-btn
                >
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index + 1 < verifications.length"
              :key="index"
            ></v-divider>
          </template>
        </v-list>
      </v-card>
      <v-card style="margin-top: 10px">
        <v-card-text>
          <v-autocomplete
            @input="
              (event) => {
                emitChange(event);
              }
            "
            label="Search User"
            :loading="loading"
            :items="users"
            item-text="displayName"
            item-value="_id"
            :search-input.sync="search"
            v-model="selectedUser"
            clearable
            outlined
            dense
          >
            <template slot="no-data" v-show="false"> No match </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-text v-if="selectedUser && verifiedBelts" class="text-left">
          <h3>
            Highest Verified:
            <verified-belt :belts="selectedUser.verifiedBelts" />
          </h3>
          <v-list two-line>
            <template v-for="(b, index) in verifiedBelts">
              <v-list-item light :key="b.belt">
                <v-list-item-content>
                  <v-list-item-title two-line>
                    <belt-display :belt="b.belt"></belt-display>
                  </v-list-item-title>
                  <v-list-item-title two-line>
                    <b>Verified On:</b>
                    {{
                      $d(new Date(b.verified), "month_day_year", $i18n.locale)
                    }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <XCircleIcon class="hero-icon" @click="removeVerification(b.belt)"
                    ></XCircleIcon
                  >
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index + 1 < verifiedBelts.length"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card v-if="selectedUser" style="margin-top: 10px">
        <v-card-title> Verify New/Existing Belt </v-card-title>
        <v-card-text v-if="selectedUser">
          <v-select
            :items="belts"
            v-model="selectedBelt"
            label="Select Belt"
            :clearable="true"
          >
          </v-select>
        </v-card-text>
        <v-card-text v-if="selectedUser">
          <v-menu
            ref="dateMenu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            max-width="290px"
            min-width="290px"
          >
            <v-text-field
              slot="activator"
              v-model="selectedDate"
              prepend-icon="event"
              hint="YYYY/MM/DD format"
            ></v-text-field>
            <v-date-picker
              v-model="selectedDate"
              no-title
              scrollable
              :max="getNowDateString()"
            >
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dateMenu = false">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" v-if="selectedUser" @click="verifyBelt"
            >Verify</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
    <div v-else>
      <h2>You are not welcome here.</h2>
      <div>
        <icon-master color="error" :size="200" />
      </div>
    </div>
    <v-dialog v-model="imageDialog" max-width="70%">
      <v-card outlined>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon small @click="closeImageDialog()"><XCircleIcon class="hero-button"></XCircleIcon></v-btn>
        </v-card-actions>
        <v-card-title>{{verificationImageFilename}}</v-card-title>
        <v-card-actions>
          <v-btn icon small color="secondary" @click="closeImageDialog()"><XCircleIcon class="hero-button"></XCircleIcon></v-btn>
        </v-card-actions>
        <v-card-text class="text-center">
          <img style="max-width:80%" :src="verificationImage"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="selectedVerification" v-model="rejectDialog" max-width="70%">
      <v-card outlined>
        <v-card-text class="mt-2">
          <v-textarea outlined label="Message to User" v-model="rejectMessage"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="rejectBelt()">Reject</v-btn>
          <v-btn color="secondary" @click="rejectDialog=false;selectedVerification=null">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import BjjlinkAdminService from "@/services/BjjlinkAdminService";
import IconMaster from "@/components/displays/icons/IconMaster.vue";
import BeltDisplay from "@/components/displays/BeltDisplay.vue";
import VerifiedBelt from "@/components/displays/VerifiedBeltDisplay.vue";

//Icons
import { 
  XCircleIcon,
  CheckCircleIcon,
  BadgeCheckIcon,
  PaperClipIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      loading: false,
      selectedUser: null,
      selectedBelt: null,
      users: [],
      rejectDialog: false,
      rejectMessage: null,
      selectedVerification: null,
      dateMenu: false,
      imageDialog:null,
      search: null,
      verifiedBelts: null,
      verifications: null,
      selectedDate: null,
      verificationImage: null,
      verificationImageFilename: null,
      belts: ["blue", "purple", "brown", "black", "coral", "red"],
    };
  },
  components: {
    IconMaster,
    BeltDisplay,
    VerifiedBelt,
    XCircleIcon,
    CheckCircleIcon,
    BadgeCheckIcon,
    PaperClipIcon,
  },
  async created() {
    this.getPendingVerifications();
  },
  methods: {
    rejectBelt(){
      if(!this.rejectMessage){
        this.showAlert("error", "Add a message", "Don't be like Instagram, be nice!");
        return;
      }
      BjjlinkAdminService.rejectVerificationRequest(this.selectedVerification, this.rejectMessage)
        .then(() => {
          this.showAlert("success", "Rejected!", "That's mean :)");
          this.getPendingVerifications();
          this.selectedVerification = null;
          this.rejectDialog = false;
        })
        .catch(() => {
          this.showAlert("error", "Yoo! ", "What da??? Talk to Santi :/");
        });
    },
    displayRejectDialog(id){
      this.selectedVerification = id;
      this.rejectDialog = true;
    },
    closeImageDialog(){
      this.imageDialog = null;
      this.verificationImage = null;
      this.verificationImageFilename = null;
    },
    viewImage(url, filename){
      this.imageDialog = true;
      this.verificationImage = "https://bjjlink.com/" + url;
      this.verificationImageFilename = filename;
    },
    goToUserNewTab(id){
      window.open("/secure/profile/"+id);
    },
    async getPendingVerifications() {
      this.verifications = null;
      const data = await BjjlinkAdminService.pendingVerifications();
      if (data && data.verifications && data.verifications.length > 0)
        this.verifications = data.verifications;
    },
    completeVerificationRequest(id) {
      BjjlinkAdminService.completeVerificationRequest(id)
        .then(() => {
          this.showAlert("success", "Verified!");
          this.getPendingVerifications();
        })
        .catch(() => {
          this.showAlert("error", "Yoo! ", "What da??? Talk to Santi :/");
        });
    },
    getFile(url) {
      window.open("https://bjjlink.com/" + url);
    },
    emitChange(event) {
      this.verifiedBelts = null;
      for (const u of this.users) {
        if (u._id == event) {
          this.selectedUser = u;
          if (u.verifiedBelts) {
            var tmp = [];
            for (const prop in u.verifiedBelts) {
              tmp.push({
                belt: prop,
                verified: u.verifiedBelts[prop],
              });
            }
            tmp.sort(function (a, b) {
              if (a.verified < b.verified) return 1;
              if (a.verified > b.verified) return -1;
              return 0;
            });
            this.verifiedBelts = tmp;
            return;
          }
        }
      }
    },
    verifyBelt() {
      BjjlinkAdminService.verifyBelt(this.selectedUser._id, this.selectedBelt, {
        date: this.selectedDate,
      })
        .then(() => {
          this.showAlert("success", "Verified!");
          this.selectedUser = null;
          this.selectedBelt = null;
          this.imageDialog = null;
          this.verificationImage = null;
          this.verificationImageFilename = null;
        })
        .catch((err) => {
          if(err)
            this.showAlert("error", "Yoo! ", "What da??? Talk to Santi :/");
        });
    },
    removeVerification(belt) {
      console.log(belt);
    },
    async querySelections(v) {
      this.loading = true;
      var data = await BjjlinkAdminService.searchUsers({ term: v });
      if (data) this.users = data.users;
      this.loading = false;
    },
  },
  watch: {
    search(val) {
      if (
        val &&
        (!this.selectedUser ||
          (this.selectedUser && val !== this.selectedUser.displayName))
      )
        this.querySelections(val);
    },
  },
};
</script>

<style scoped></style>
