import Api from '@/services/Api'

export default {
    async getUserReview (academyId) {
        const response = await Api().get('reviews/user/'+academyId)
        return response.data
    },
    async getReview (reviewId) {
        const response = await Api().get('reviews/'+reviewId)
        return response.data
    },
    async reviews (index, data) {
        const response = await Api().post('reviews/get?index='+index, data)
        return response.data
    },
    async getRating (academyId) {
        const response = await Api().get('reviews/rating/'+academyId)
        return response.data
    },
    async addReview (data) {
        const response = await Api().post('reviews/add', data)
        return response.data
    },
    async updateReview (values) {
        const response = await Api().patch('reviews', values)
        return response.data
    }
}
