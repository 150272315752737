<template>
    <v-row no-gutters>
        <v-col cols="7" :style="'border-radius:5px 0 0 0;height:'+(height ? height : '100')+'px;background-color:'+beltColor">
        </v-col>
        <v-col :style="'background-color:'+coverColor">
        </v-col>
        <v-col :style="'background-color:'+stripe4Color">
        </v-col>
        <v-col :style="'background-color:'+coverColor4">
        </v-col>
        <v-col :style="'background-color:'+stripe3Color">
        </v-col>
        <v-col :style="'background-color:'+coverColor3">
        </v-col>
        <v-col :style="'background-color:'+stripe2Color">
        </v-col>
        <v-col :style="'background-color:'+coverColor2">
        </v-col>
        <v-col :style="'background-color:'+stripe1Color">
        </v-col>
        <v-col :style="'border-radius:0 5px 0 0;background-color:'+coverColor1">
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: ["belt","height"],
    data () {
        return {
            beltColor: null,
            coverColor: 'black',
            coverColor4: 'black',
            coverColor3: 'black',
            coverColor2: 'black',
            coverColor1: 'black',
            stripe1Color: null,
            stripe2Color: null,
            stripe3Color: null,
            stripe4Color: null,
        }
    },
    created(){
        if(!this.belt || this.belt == 'bjjlover'){
            this.beltColor = 'white';
            this.stripe1Color = 'black';
            this.stripe2Color = 'black';
            this.stripe3Color = 'black';
            this.stripe4Color = 'black';
        }else if(this.belt == 'coral'){
            this.beltColor = 'black';
            const redColor = this.themeColor('error');
            this.stripe1Color = redColor;
            this.stripe2Color = redColor;
            this.stripe3Color = redColor;
            this.stripe4Color = redColor;
        }else if(this.belt == 'red_white'){
            this.beltColor = 'black';
            const redColor = this.themeColor('error');
            this.stripe1Color = redColor;
            this.stripe2Color = redColor;
            this.stripe3Color = redColor;
            this.stripe4Color = redColor;
            this.coverColor4 = 'white';
            this.coverColor3 = 'white';
            this.coverColor2 = 'white';
            this.coverColor1 = 'white';
        }else{
            let belt = this.belt;
            if(belt.indexOf('black') >= 0){
                const redColor = this.themeColor('error');
                this.stripe1Color = redColor;
                this.stripe2Color = redColor;
                this.stripe3Color = redColor;
                this.stripe4Color = redColor;
            }else{
                this.stripe1Color = 'black';
                this.stripe2Color = 'black';
                this.stripe3Color = 'black';
                this.stripe4Color = 'black';
            }
            if(belt.indexOf('black') >= 0){
                const redColor = this.themeColor('error');
                this.coverColor = redColor;
                this.coverColor4 = redColor;
                this.coverColor3 = redColor;
                this.coverColor2 = redColor;
                this.coverColor1 = redColor;
            }
            const len = belt.length;
            const lastChar = belt.substring(len-1, len);
            if(isNaN(lastChar)){
                //No stripes
                if(belt.indexOf('gray') >= 0){
                    belt = 'gray';
                }else if(belt.indexOf('orange') >= 0){
                    belt = 'orange';
                }else if(belt.indexOf('green') >= 0){
                    belt = 'green';
                }else if(belt.indexOf('yellow') >= 0){
                    belt = 'yellow';
                }
                this.beltColor = this.themeColor(belt);
            }else{
                const stripes = new Number(lastChar).valueOf();
                belt = belt.substring(0, len-1);
                if(belt.indexOf('gray') >= 0){
                    belt = 'gray';
                }else if(belt.indexOf('orange') >= 0){
                    belt = 'orange';
                }else if(belt.indexOf('green') >= 0){
                    belt = 'green';
                }else if(belt.indexOf('yellow') >= 0){
                    belt = 'yellow';
                }
                this.beltColor = this.themeColor(belt);
                if(stripes == 1){
                    this.stripe1Color = 'white';
                }else if(stripes == 2){
                    this.stripe1Color = 'white';
                    this.stripe2Color = 'white';
                }else if(stripes == 3){
                    this.stripe1Color = 'white';
                    this.stripe2Color = 'white';
                    this.stripe3Color = 'white';
                }else if(stripes == 4){
                    this.stripe1Color = 'white';
                    this.stripe2Color = 'white';
                    this.stripe3Color = 'white';
                    this.stripe4Color = 'white';
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
