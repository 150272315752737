<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" justify-center>
    <v-flex v-if="isAppAdmin($store.state.user._id)">
      <v-card>
        <v-card-text>
          <v-autocomplete
            v-model="selectedHelp"
            :items="helpItems"
            label="Select Existing To Edit" 
            clearable
          ></v-autocomplete>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <image-upload 
          :objId="'new_partner_image'"
          :vm="this" 
          :operation="'return_image'"
          :success-function="successFunction" >
        </image-upload>
        <v-spacer></v-spacer>
      </v-card-actions>
      <img :src="selectedImage" style="max-width:300px;max-height:300px;">
      <v-card>
        <v-card-text>
          <v-text-field dense outlined label="Icon" v-model="newHelp.icon"></v-text-field>
          <span>Select icons from <a target="_blank" href="https://material.io/resources/icons/">here</a></span>
        </v-card-text>
      </v-card>
      <v-card style="margin-top:10px">
        <v-card-title>English (en)</v-card-title>
        <v-card-text>
          <v-text-field dense outlined label="Category" v-model="newHelp.category.en"></v-text-field>
          <v-text-field dense outlined label="Question" v-model="newHelp.question.en"></v-text-field>
          <v-textarea outlined label="Contents" v-model="newHelp.contents.en"></v-textarea>
        </v-card-text>
      </v-card>
      <v-card style="margin-top:10px">
        <v-card-title>Spanish (es)</v-card-title>
        <v-card-text>
          <v-text-field dense outlined label="Category" v-model="newHelp.category.es"></v-text-field>
          <v-text-field dense outlined label="Question" v-model="newHelp.question.es"></v-text-field>
          <v-textarea outlined label="Contents" v-model="newHelp.contents.es"></v-textarea>
        </v-card-text>
      </v-card>
      <v-btn color="primary" v-if="!update" @click="addHelp">
        Add
      </v-btn>
      <v-btn color="terciary" v-if="update" @click="updateHelp">
        Update
      </v-btn>
    </v-flex>
    <v-flex v-else>
      You should not be here
    </v-flex>
  </v-sheet>
</template>

<script>
import ImageUpload from "@/components/inputs/ImageUploadCroppie.vue";
import UtilService from "@/services/UtilService";

export default {
  data() {
    return {
      newHelp: {},
      update: false,
      helpItems: [],
      selectedHelp: null,
      selectedImage: null,
      successFunction: function(uploadedFile, vm) {
        if (vm && uploadedFile) {
          vm.selectedImage = this.userImg(uploadedFile);
          vm.newHelp.image = uploadedFile;
        }
      }
    };
  },
  components: {
    ImageUpload
  },
  created(){
    this.initHelp();
    UtilService.getAllHelp()
      .then(help => {
        this.buildHelp(help);
      });
  },
  methods: {
    buildHelp(help){
      if(help){
        for(const h of help){
          this.helpItems.push({text: h.question.en, value: h._id});
        }
      }
    },
    initHelp(obj){
      this.newHelp = {
        icon: obj && obj.icon ? obj.icon : null,
        image: obj && obj.image ? obj.image : null,
        category: {
          en: obj && obj.category.en ? obj.category.en : null,
          es: obj && obj.category.es ? obj.category.es : null
        },
        question: {
          en: obj && obj.question.en ? obj.question.en : null,
          es: obj && obj.question.es ? obj.question.es : null
        },
        contents: {
          en: obj && obj.contents.en ? obj.contents.en : null,
          es: obj && obj.contents.es ? obj.contents.es : null
        },
      };
      if(!obj){
        this.update = false;
        this.selectedImage = null;
      }else{
        this.newHelp._id = obj._id;
      }
    },
    selectHelp(val){
      UtilService.getHelp(val)
        .then(help => {
          this.update = true;
          this.initHelp(help);
          this.selectedImage = this.userImg(help.image);
        });
    },
    updateHelp(){
      if(this.newHelp.contents.en && this.newHelp.question.en && this.newHelp.category.en){
        const data = {
          icon: this.newHelp.icon,
          image: this.newHelp.image,
          category: this.newHelp.category,
          question: this.newHelp.question,
          contents: this.newHelp.contents
        };
        UtilService.updateHelp(this.newHelp._id, data).then(data => {
          if (data) {
            this.initHelp();
            this.selectedHelp = null;
            this.showAlert(
              "info",
              'Updated!'
            );
          }
        });
      } else {
        this.showAlert(
          "error",
          'Yoo! ',
          'At least fill english, ya know? Don\'t be lazy!'
        );
      }
    },
    addHelp(){
      if(this.newHelp.contents.en && this.newHelp.question.en && this.newHelp.category.en){
        UtilService.addHelp(this.newHelp).then(data => {
          if (data) {
            this.initHelp();
            this.showAlert(
              "info",
              'Added'
            );
          }
        });
      } else {
        this.showAlert(
          "error",
          'Yoo! ',
          'At least fill english, ya know? Don\'t be lazy!'
        );
      }
    }
  },
  mounted() {},
  computed: {},
  watch: {
    selectedHelp: {
      handler: function(newValue) {
        if(newValue)
          this.selectHelp(newValue);
        else
          this.initHelp();
      }
    }
  }
};
</script>

<style scoped>
.charity_class {
  height: 100%;
}
.charity_image {
  overflow-y: hidden;
  height: 350px;
}
.charity_image img {
  width: 90%;
  margin-top: -50px;
}
.charity_text{
  overflow-y: hidden;
  height:100px;
}
</style>
