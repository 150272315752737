<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="$store.state.isUserLoggedIn" class="pa-0">
    <v-card elevation="0">
      <v-card-title>
        <h3>{{notification.title}}</h3>
      </v-card-title>
      <v-card-text class="text-left">
        {{notification.contentsTranslated}}
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="customMessage">
      {{customMessage}}
    </v-card>
  </v-sheet>
</template>

<script>
import NotificationService from "@/services/NotificationService";
import VideoService from "@/services/VideoService";

export default {
  data() {
    return {
      notificationId: this.$route.params.notificationId,
      notification: null,
      notificationMessage: null,
      customMessage: null
    };
  },
  created() {
    NotificationService.notification(this.notificationId)
      .then((data) => {
        if (data) {
          this.notification = data;
          if(data.type == "video_deleted"){
            this.loadVideoContent(data.objectId);
          }
        }
      });
  },
  methods: {
    doNothing() {},
    loadVideoContent(videoId){
      VideoService.video(videoId)
        .then((data) => {
          if (data) {
            this.notification = data;
            if(data.type == "video_deleted"){
              this.loadVideoContent();
            }
          }
        });
    }
  },
};
</script>

<style scoped></style>
