<template>
  <LikeIcon v-if="isType('like')" :color="color ? color : 'white'" size="15" style="margin-bottom:-1px"></LikeIcon>
  <RncIcon v-else-if="isType('rnc')" :color="color ? color : 'white'" size="20" style="margin-bottom:-1px"></RncIcon>
  <OssIson v-else-if="isType('oss')" :color="color ? color : 'white'" size="15" style="margin-bottom:-1px"></OssIson>
  <FireIcon v-else-if="isType('fire')" :color="color ? color : 'white'" size="15" style="margin-bottom:-1px"></FireIcon>
  <CalendarIcon v-else-if="isType('class')" :color="color ? color : 'white'" size="15" style="margin-bottom:-1px">
  </CalendarIcon>
  <CalendarIcon v-else-if="isType('event')" :color="color ? color : 'white'" size="15" style="margin-bottom:-1px">
  </CalendarIcon>
  <LibraryIcon v-else-if="isType('new_academy_member')" :color="color ? color : 'white'" size="15"
    style="margin-bottom:-1px"></LibraryIcon>
  <ChatAltIcon v-else-if="isType('comment')" :color="color ? color : 'white'" size="15" style="margin-bottom:-1px">
  </ChatAltIcon>
  <ChatIcon v-else-if="isType('academy_message')" :color="color ? color : 'white'" size="15" style="margin-bottom:-1px">
  </ChatIcon>
  <CashIcon v-else-if="isType('payment')" :color="color ? color : 'white'" size="15" style="margin-bottom:-1px">
  </CashIcon>
  <BadgeCheckIcon v-else-if="isType('belt_verified')" :color="isDarkTheme() ? 'white' : 'black'" size="15"
    style="margin-bottom:-1px"></BadgeCheckIcon>
  <VideoCameraIcon v-else-if="isType('video')" :color="color ? color : 'white'" size="15" style="margin-bottom:-1px">
  </VideoCameraIcon>
  <LinkIcon v-else-if="isType('new_link')" :color="color ? color : 'white'" size="15" style="margin-bottom:-1px">
  </LinkIcon>
  <DocumentTextIcon v-else-if="isType('document_signature_request')" :color="color ? color : 'white'" size="15"
    style="margin-bottom:-1px"></DocumentTextIcon>
  <DocumentTextIcon v-else-if="isType('document_survey_request')" :color="color ? color : 'white'" size="15"
      style="margin-bottom:-1px"></DocumentTextIcon>
  <StarIcon v-else-if="isType('academy_reviewed')" :color="themeColor('gold')" size="15" style="margin-bottom:-1px">
  </StarIcon>
  <TagIcon v-else-if="isType('post_tag')" :color="themeColor('success')" size="15" style="margin-bottom:-1px"></TagIcon>
  <MatchIcon v-else-if="isType('saved_competitor_alert')" 
    :color="color ? color : 'white'" size="20"
    style="margin-bottom:-1px">
  </MatchIcon>
  <v-icon :color="color ? color : 'white'" size="15" v-else-if="isType('program_assigned')">mdi-school-outline</v-icon>
  <v-icon :color="color ? color : 'white'" size="15" v-else-if="isType('academy_post')">mdi-pin-outline</v-icon>
  <BellIcon v-else :color="color ? color : 'white'" size="15" style="margin-bottom:-1px"></BellIcon>
</template>

<script>
import LikeIcon from "@/components/displays/icons/IconLike.vue";
import OssIson from "@/components/displays/icons/IconOss.vue";
import FireIcon from "@/components/displays/icons/IconFire.vue";
import RncIcon from "@/components/displays/icons/IconRnc.vue";
import MatchIcon from "@/components/displays/icons/IconMatch.vue";

import { 
  ChatAltIcon,
  BellIcon,
  CalendarIcon,
  CashIcon,
  VideoCameraIcon,
  DocumentTextIcon,
  LinkIcon,
  LibraryIcon,
  ChatIcon,
  BadgeCheckIcon,
  StarIcon,
  TagIcon,
} from '@vue-hero-icons/outline'

export default {
  components: {
    LikeIcon,
    ChatAltIcon,
    BellIcon,
    CalendarIcon,
    CashIcon,
    VideoCameraIcon,
    DocumentTextIcon,
    LinkIcon,
    RncIcon,
    FireIcon,
    OssIson,
    LibraryIcon,
    ChatIcon,
    BadgeCheckIcon,
    StarIcon,
    TagIcon,
    MatchIcon
  },
  props: ["type"],
  data() {
    return {
      color: 'white',
    };
  },
  created(){
    this.colorMe();
  },
  methods:{
    isType(t){
      if(this.type && (this.type == t || this.type.indexOf(t) >= 0))
        return true;
      else
        return false;
    },
    colorMe(){
      const t = this.type;
      if(t){
        if(t.indexOf('like') >= 0){
          this.color = this.themeColor('blue');
        } else if(t == this.notificationTypes.POSTRNC){
          this.color = this.themeColor('purple');
        } else if(t == this.notificationTypes.POSTOSS){
          this.color = this.themeColor('error');
        } else if(t == this.notificationTypes.POSTFIRE){
          this.color = this.themeColor('orange');
        } else if(t.indexOf('comment') >= 0 || t == this.notificationTypes.ACADEMYMESSAGE){
          this.color = this.themeColor('success');
        } else if(t == this.notificationTypes.VIDEOREADY){
          this.color = this.themeColor('primary');
        } else if(t.indexOf('event') >= 0){
          this.color = this.themeColor('error');
        } else if(t == this.notificationTypes.NEWLINK){
          this.color = this.themeColor('primary');
        } else if(t == this.notificationTypes.ACADEMYMESSAGE){
          this.color = this.themeColor('success');
        } else if(t == this.notificationTypes.BELT_VERIFIED){
          this.color = this.themeColor('black');
        } else if(t == this.notificationTypes.ACADEMYREVIEWED){
          this.color = this.themeColor('gold');
        } else if (t == this.notificationTypes.NOTI_SAVECOMPETITORALERT) {
          this.color = this.themeColor('orange');
        } else if(t == this.notificationTypes.PAYMENTREQUEST){
          this.color = '#85BB65';
        } else if(t.indexOf('class') >= 0 || t == this.notificationTypes.DOCUMENTREQUEST || t == this.notificationTypes.DOCUMENTREQUESTSURVEY || t == this.notificationTypes.NEWACADEMYVIDEO) {
          this.color = "gray"
        } else if (t == "academy_post") {
          this.color = "gray" 
        } else if (t == "new_academy_member") {
          this.color = "gray"
        }
      }
    },
  }
};
</script>

<style scoped>
</style>
