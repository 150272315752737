<template>
    <div class="user_avatar" v-if="user">
        <v-badge
            v-if="user.verifiedBelts"
            overlap
            :offset-x="offsetX ? offsetX : '12'"
            avatar
            color="none"
        >
            <template v-slot:badge>
                <span :style="smallBadge ? bgSmall : bgBig">
                </span>
                <VerifiedBelt 
                    :icon="icon" 
                    :color="color"
                    :size="smallBadge ? '20' : '30'"/>
            </template>
            <v-avatar
                color="white"
                :style="hasProgress ? '' : 'border: 1px solid gray'"
                :size="size ? size+'px' : '32px'">
                <img v-if="!user.userImage && !user.userImageSmall" :src="assets('blank-profile-picture.png')" alt="avatar">
                <img v-else-if="storeImage" :src="userFile($store.state.userImage)" alt="avatar">
                <img v-else-if="user.userImageSmall" :src="userImg(user.userImageSmall)" alt="avatar">
                <img v-else :src="userFile(user.userImage)" alt="avatar">
            </v-avatar>
        </v-badge>
        <v-avatar
            v-else
            color="white"
            :style="hasProgress ? '' : 'border: 1px solid gray'"
            :size="size ? size+'px' : '32px'">
            <img v-if="!user.userImage && !user.userImageSmall" :src="assets('blank-profile-picture.png')" alt="avatar">
            <img v-else-if="storeImage" :src="userFile($store.state.userImage)" alt="avatar">
            <img v-else-if="user.userImageSmall" :src="userImg(user.userImageSmall)" alt="avatar">
            <img v-else :src="userImg(user.userImage)" alt="avatar">
        </v-avatar>
    </div>
</template>

<script>
import VerifiedBelt from "@/components/displays/VerifiedBeltDisplay.vue";

export default {
    props: [
        'user',
        'size',
        'smallBadge',
        'hasProgress',
        'offsetX',
        'storeImage'
    ],
    data () {
        return {
            badgeColor: 'primary',
            icon: 'verified',
            color: 'blue',
            bgSmall: 'background-color:white;border-radius:10px;width:7px;height:7px;margin-top:7px;margin-left:7px;position:absolute;z-index:1',
            bgBig: 'background-color:white;border-radius:10px;width:10px;margin-top:8px;margin-left:10px;position:absolute;z-index:1'
        }
    },
    created(){
        if(this.user && this.user.verifiedBelts){
            const highestVerified = this.getHighestVerifiedBelt(this.user.verifiedBelts);
            if(highestVerified && highestVerified == 'hero'){
                this.icon = 'hero';
                this.color = "gold";
            }else if (highestVerified){ 
                this.color = this.themeColor(highestVerified);
            }
        }
    },
    methods: {
    },
    components: {
        VerifiedBelt
    }
}
</script>

<style scoped>
</style>
