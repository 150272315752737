<template>
  <v-layout>
    <v-flex>
      <v-autocomplete
        @input="event => { emitChange(event) }"
        :items="countries"
        v-model="selectedCountry"
        :label="inputLabel"
        item-text="country"
        item-value="countryCode"
        :clearable="true"
        outlined
        dense
        :hide-details="true"
      >
        <template slot="item" slot-scope="data">
          <template v-if="!data.item.countryCode">
            <v-list-item-content  v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-avatar>
              <img :src="flagImg(data.item.countryCode)" @error="defaultFlagImg(data.item.countryCode)" :ref="data.item.countryCode">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.country"></v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </v-flex>
  </v-layout>
</template>

<script>
import countriesJson from '@/assets/data/countries.json'
// var imageExists = require('image-exists');

export default {
  data () {
    return {
      selectedCountry: '',
      countries: null,
      inputLabel: this.$t('message.select_country')
    }
  },
  created() {
    if(this.label)
      this.inputLabel = this.label;
    if(this.list && this.list.length > 0){
      var temp = this.list;
      temp.sort();
      this.countries = [];
      for(const c of temp){
        for(const c2 of countriesJson){
          if(c2.countryCode == c){
            this.countries.push(c2);
            break;
          }
        }
      }
    }else{
      this.countries = countriesJson;
    }
  },
  props: {
    value: null,
    label: null,
    list: null,
    showIcon: null
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        this.selectedCountry = newValue
      }
    }
  },
  methods: {
    emitChange (event) {
      this.$emit('input', event)
    },
    defaultFlagImg (country) {
      if(this.$refs[country])
        this.$refs[country].src =  this.assets('logo-circular-watermarked.png');
    },
    flagImg (country) {
      return this.assets('flags/' + country + '.png')
    }
  }
}
</script>

<style scoped>
</style>
