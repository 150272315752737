import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {
    token: null,
    user: null,
    name: null,
    isUserLoggedIn: false,
    previousPage: "",
    notificationsCount: 0,
    messagesCount: 0,
    userImage: "",
    hasAgreements: false,
    language: "",
    belt: "",
    hidden: false,
    inApp: false,
    managedAcademyAdmin: false,
    userManagedAcademy: null,
    appVersion: "",
    sessionTimer: null,
    theme: "dark",
    lat: null,
    lon: null,
  },
  plugins: [createPersistedState()],
  mutations: {
    setToken(state, token) {
      state.token = token;
      if (token) {
        state.isUserLoggedIn = true;
      } else {
        state.isUserLoggedIn = false;
      }
    },
    setUser(state, user) {
      state.user = user;
      if (user) state.notificationsCount = user.notifications;
    },
    setName(state, name) {
      state.name = name;
    },
    setPreviousPage(state, name) {
      state.previousPage = name;
    },
    setNotifications(state, number) {
      state.notificationsCount = number;
    },
    setMessagesCount(state, number) {
      state.messagesCount = number;
    },
    setUserImage(state, img) {
      state.userImage = img;
    },
    setHasAgreements(state, value) {
      state.hasAgreements = value;
    },
    setLanguage(state, value) {
      state.language = value;
    },
    setBelt(state, value) {
      state.belt = value;
    },
    setHidden(state, value) {
      state.hidden = value;
    },
    setInApp(state, value) {
      state.inApp = value;
    },
    setManagedAcademyAdmin(state, value) {
      state.managedAcademyAdmin = value;
    },
    setAppVersion(state, value) {
      state.appVersion = value;
    },
    setSessionTimer(state, value) {
      state.sessionTimer = value;
    },
    setUserManagedAcademy(state, value) {
      state.userManagedAcademy = value;
    },
    setTheme(state, value) {
      state.theme = value;
    },
    setLat(state, value) {
      state.lat = value;
    },
    setLon(state, value) {
      state.lon = value;
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setName({ commit }, name) {
      commit("setName", name);
    },
    setUserImage({ commit }, image) {
      commit("setUserImage", image);
    },
    setPreviousPage({ commit }, name) {
      commit("setPreviousPage", name);
    },
    setNotifications({ commit }, number) {
      commit("setNotifications", number);
    },
    setMessagesCount({ commit }, number) {
      commit("setMessagesCount", number);
    },
    setHasAgreements({ commit }, value) {
      commit("setHasAgreements", value);
    },
    setLanguage({ commit }, value) {
      commit("setLanguage", value);
    },
    setBelt({ commit }, value) {
      commit("setBelt", value);
    },
    setHidden({ commit }, value) {
      commit("setHidden", value);
    },
    setInApp({ commit }, value) {
      commit("setInApp", value);
    },
    setManagedAcademyAdmin({ commit }, value) {
      commit("setManagedAcademyAdmin", value);
    },
    setAppVersion({ commit }, value) {
      commit("setAppVersion", value);
    },
    setSessionTimer({ commit }, value) {
      commit("setSessionTimer", value);
    },
    setUserManagedAcademy({ commit }, value) {
      commit("setUserManagedAcademy", value);
    },
    setTheme({ commit }, value) {
      commit("setTheme", value);
    },
    setLat({ commit }, value) {
      commit("setLat", value);
    },
    setLon({ commit }, value) {
      commit("setLon", value);
    },
  },
});
