<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" row wrap>
    <div v-if="loading" class="text-center pa-3">
      <v-icon size="80" class="mb-2" color="primary">mdi-credit-card-clock-outline</v-icon>
      <v-progress-linear
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="12"
      ></v-progress-linear>
    </div>
    <v-card v-if="transaction" elevation="0" class="text-center">
      <div class="text-h5 error--text text-center" v-if="transaction.cancelled">
        {{$t('message.already_cancelled')}}</div>
      <div class="text-h6 success--text text-center" v-else-if="transaction.completed">
        {{$t('message.already_completed')}}</div>
      <div class="text-h6 error--text text-center" v-if="transaction.expired" style="color:red">{{
        $t("message.transaction_expired") }}</div>
      <div class="text-caption error--text text-center" v-if="tooCloseToStartDate">{{
        $t("message.transaction_too_close_start") }}
      </div>
      <div class="mt-2" v-if="transaction.cancelled || transaction.completed || error">
        <h2 class="error--text">{{error}}</h2>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" small @click="navigateTo({ name: 'transaction', transactionId: transactionId})"
            color="primary">{{$t('message.to_transaction')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </div>
    </v-card>
    <v-card v-else-if="error" elevation="0" class="text-center mt-2">
      <h2 class="error--text">{{error}}</h2>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="navigateTo({ name: 'transaction', transactionId: transactionId})" color="secondary">
          {{$t('message.to_transaction')}}</v-btn>
        <v-btn @click="navigateTo({ name: 'payments'})" color="terciary">{{$t('message.to_payments')}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import PaymentsService from "@/services/PaymentsService";
import NotificationService from "@/services/NotificationService";

export default {
  data() {
    return {
      transactionId: this.$route.params.transactionId,
      notificationId: this.$route.params.notificationId,
      transaction: null,
      loading: true,
      stripePublishableKey: null,
      error: null,
      tooCloseToStartDate: false,
      stripeAccountId: null,
    };
  },
  created() {
    //Get transaction, if not cancelled, proceed
    if (this.transactionId) {
      PaymentsService.transaction(this.transactionId)
        .then((response) => {
          this.transaction = response.transaction;
          this.stripeAccountId = response.stripeAccountId;
          const session = response.session;
          if (session && session.status == 'active') {
            //Send them back, already completed
            this.processError();
            return;
          }
          if (!this.transaction.cancelled && !this.transaction.completed) {
            //if regular expired session just generate again the new way
            if (this.transaction.sessionId && response.expiresAtUnix && this.transaction.category == 'retail') {
              const expirationTime = new Date(response.expiresAtUnix * 1000);
              expirationTime.setHours(expirationTime.getHours() - 1);
              const today = new Date();
              if (
                today > expirationTime &&
                !this.transaction.expired
              ) {
                //Geta  new session
                this.initNewSession();
              } else {
                //use existing session
                this.checkout(this.transaction.sessionId);
              }
            } else {
              const expired = this.isExpiredTRansaction();
              //Expiration will happen only for transaciton with start date or that have been open for more than x days for retail and y days for subcription
              if (!expired) {
                this.initNewSession();
              } else {
                this.transaction.cancelled = new Date();
                this.transaction.expired = true;
                this.loading = false;
                PaymentsService.markExpired(this.transactionId);
              }
            }
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.processError();
        });
    }
    if(this.notificationId){
      NotificationService.read(this.notificationId);
    }
  },
  methods: {
    initNewSession() {
      PaymentsService.initTransactionCheckout(this.transactionId)
        .then((data) => {
          if (data.session && data.session.id) {
            this.checkout(data.session.id, data.stripeAccountId);
          } else {
            console.log('Came back from session init but there is no session.');
            this.processError();
          }
        })
        .catch(() => {
          console.log('Could not init session checkout...');
          this.processError();
        });
    },
    isExpiredTRansaction() {
      let expired = false;
      //retail transactions neveer expire
      if (this.transaction && this.transaction.category == 'membership') {
        if (this.transaction.subscriptionStartDate) {
          const today = new Date();
          today.setHours(1);
          today.setMinutes(1);
          const startDate = new Date(this.transaction.subscriptionStartDate);
          startDate.setMinutes(59);
          startDate.setHours(23);
          if (today > startDate) {
            expired = true;
          } else {
            const daysBetween = Math.abs(this.daysBetween(today, startDate));
            if (daysBetween <= 2) {
              expired = true;
              this.tooCloseToStartDate = true;
            }
          }
        }
      }
      return expired;
    },
    processError(){
      this.loading = false;
      this.error = this.$t("message.error_generic");
      this.showAlert("error", "Oops!", this.$t("message.error_generic"));
    },
    checkout(sessionId, stripeAccountId) {
      this.stripePublishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
      if (this.stripeAccountId) {
        // eslint-disable-next-line
        var stripe = Stripe(this.stripePublishableKey, {
          stripeAccount: stripeAccountId ? stripeAccountId : this.stripeAccountId,
        });
        stripe
          .redirectToCheckout({
            sessionId: sessionId,
          })
          .then(function () {
            this.error = this.$t("message.error_generic");
            this.showAlert("error", "Oops!", this.$t("message.error_generic"));
          });
      }
    },
  },
};
</script>

<style scoped>
</style>