<template>
    <div class="likes">
        <v-badge right overlap offset-x="10" offset-y="18">
            <span slot="badge">{{badgeNumber}}</span>
            <v-btn 
                v-if="alreadyLiked" 
                @click="likeFunction()" 
                icon
            >
                <IconLike  size="25px" color="blue"/>
            </v-btn>
            <v-btn 
                v-else 
                @click="likeFunction()" 
                icon
            >
                <IconLike size="25px" color="light"/>
            </v-btn>
        </v-badge>
    </div>
</template>

<script>
import PartnersService from '@/services/PartnersService';
import IconLike from '@/components/displays/icons/IconLike.vue';

export default {
    props: [
        'partner',
        'icon'
    ],
    data () {
        return {
            alreadyLiked: false,
            badgeNumber: 0,
            likeFunction: null,
            showPopup: false,
            timer: null,
            isInInfo: false,
            loadingUsers: true,
            likeUsers: []
        }
    },
    created () {
        var liked = false
        const likes = this.partner.likes
        this.likeFunction = this.likePartner
        if(likes && likes.length > 0)
            liked = likes.indexOf(this.$store.state.user._id) >= 0 ? true : false
        this.alreadyLiked = liked
        if(liked){
            this.likeFunction = this.unlikePartner
        }
        this.badgeNumber = this.partner.likes ? this.partner.likes.length : 0;
    },
    components: {
        IconLike
    },
    methods: {
        async likePartner(){
            const data = await PartnersService.like(this.partner._id)
            if(data){
                this.alreadyLiked = true;
                this.badgeNumber++;
                this.likeFunction = this.unlikePartner;
            }else{
                this.showAlert('error','Opps... Something went wrong in the system, give us a bit.')
            }
        },
        async unlikePartner(){
            const data = await PartnersService.unlike(this.partner._id)
            if(data){
                this.alreadyLiked = false;
                this.badgeNumber--;
                this.likeFunction = this.likePartner;
            }else{
                this.showAlert('error','Opps... Something went wrong in the system, give us a bit.')
            }
        }
    },
    beforeMount () {
    },
    computed: {
    }
}
</script>

<style scoped>
  .likes img{
    width: 16px;
    vertical-align: middle;
    position: relative
  }
</style>
