<template>
  <svg :width="iconSize" :height="iconSize" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>LIKE</title>
    <g id="App-Design-2022" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g transform="translate(-46.000000, -601.000000)" :stroke="iconColor" stroke-width="2">
            <path d="M58.1445548,609 L62.9710588,609 C64.4773974,609 65.457105,610.5646 64.7834674,611.8944 L61.2374727,618.8944 C60.8942204,619.572 60.19262,620 59.4250641,620 L55.3545661,620 C55.1889175,620 55.0238768,619.9799 54.8630913,619.9403 L51.0525654,619 M58.1445548,609 L58.1445548,604 C58.1445548,602.89543 57.237388,602 56.1182721,602 L56.0215171,602 C55.515453,602 55.1051307,602.40497 55.1051307,602.90453 C55.1051307,603.61883 54.8909527,604.31715 54.4894447,604.91149 L51.0525654,610 L51.0525654,619 M54.59856,605.5 L57.637984,605.5 M58.1445548,609 L56.1182721,609 M51.0525654,619 L49.0262827,619 C47.9071972,619 47,618.1046 47,617 L47,611 C47,609.8954 47.9071972,609 49.0262827,609 L51.559136,609" id="BJJLINK-Like-outline"></path>
        </g>
    </g>
</svg>
</template>
<script>
//:fill="iconColor"
export default {
  data() {
    return {
      iconColor: "gray",
      iconSize: "20px"
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
    if(this.size)
      this.iconSize = this.size;
  },
  props: ['color','size'],
  watch: {
    color: {
      handler: function (newValue) {
        this.iconColor = this.getColor(newValue);
      },
    },
  },
};
</script>
<style>
</style>