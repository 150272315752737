<template>
  <div v-if="$store.state.isUserLoggedIn">
    <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>
    <v-sheet v-if="!loading" :rounded="isSmallerDisplay() ? '' : 'lg'" class="pt-2">
      <v-tabs v-model="activeTab" show-arrows>
        <v-tab v-hide-embed="$route.query.embed" :key="'insights'" @click="clickTab(0)">
          <ChartSquareBarIcon class="hero-icon mr-1" size="25"></ChartSquareBarIcon>
          {{$t("message.stats")}}
        </v-tab>
        <v-tab v-hide-embed="$route.query.embed" :key="'journal'" @click="clickTab(1)">
          <ClipboardListIcon class="hero-icon mr-1" size="25"></ClipboardListIcon>
          {{ $t("message.journal") }}
        </v-tab>
        <v-tab v-hide-embed="$route.query.embed" v-show="timers" :key="'timers'" @click="clickTab(2)">
          <ClockIcon class="hero-icon mr-1" size="25"></ClockIcon>
          {{ $t("message.timers") }}
        </v-tab>
        <v-tab v-hide-embed="$route.query.embed" :key="'checkins'" @click="clickTab(3)">
          <LocationMarkerIcon class="hero-icon mr-1" size="25"></LocationMarkerIcon>
          {{$t("message.checkins")}}
        </v-tab>
        <v-tab v-hide-embed="$route.query.embed" :key="'workouts'" @click="clickTab(4)">
          <ClipboardCheckIcon class="hero-icon mr-1" size="25"></ClipboardCheckIcon>
          {{$t("message.workouts")}}
        </v-tab>
        <v-tab-item :key="'insights'">
          <v-sheet outlined rounded="lg" v-if="checkinDaysChartData" class="ma-2">
            <v-card-text class="text-center">
              {{ $t("message.last_2_weeks_training") }}
            </v-card-text>
            <v-card-text class="pa-0">
              <ApexChart v-if="checkinDaysChartData && checkinDaysChartData[0]" type="area" height="350"
                :options="checkinDaysChartOptions" :series="checkinDaysChartData"></ApexChart>
            </v-card-text>
          </v-sheet>
          <v-sheet outlined rounded="lg" class="text-center ma-2">
            <v-card-text>
              {{ $t("message.techniques") }}
            </v-card-text>
            <v-card-text class="pa-0">
              <ApexChart v-if="techniquesChartData" type="pie" :options="techniquesChartOptions" height="350"
                :series="techniquesChartData"></ApexChart>
            </v-card-text>
          </v-sheet>
          <div v-if="aggregates">
            <v-row class="pa-2 pt-0">
              <v-col>
                <v-sheet outlined rounded="lg" class="">
                  <v-card-text>
                    <ClockIcon class="hero-icon"></ClockIcon>
                    {{ capitalizeFirstLetter($t("message.minutes")) }}
                  </v-card-text>
                  <v-card-text text-xs-center class="aggregate-value text-center">
                    <span class="text-h4">
                      {{ aggregates && aggregates.tracking ? aggregates.tracking.minutes : 0 }}
                    </span>
                  </v-card-text>
                </v-sheet>
              </v-col>
              <v-col>
                <v-sheet outlined rounded="lg" class="">
                  <v-card-text>
                    <FireIcon class="hero-icon"></FireIcon>
                    {{ $t("message.rounds") }}
                  </v-card-text>
                  <v-card-text text-xs-center class="aggregate-value text-center">
                    <span class="text-h4">
                      {{ aggregates && aggregates.tracking ? aggregates.tracking.rounds : 0 }}
                    </span>
                  </v-card-text>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row class="pa-2 pt-0">
              <v-col>
                <v-sheet outlined rounded="lg" class="">
                  <v-card-text>
                    <LocationMarkerIcon class="hero-icon"></LocationMarkerIcon>
                    {{ $t("message.total_checkins") }}
                  </v-card-text>
                  <v-card-text text-xs-center class="aggregate-value text-center">
                    <span class="text-h4">
                      {{ aggregates && aggregates.total_checkins > 0 ? aggregates.total_checkins : 0 }}
                    </span>
                  </v-card-text>
                </v-sheet>
              </v-col>
              <v-col>
                <v-sheet outlined rounded="lg" class="">
                  <v-card-text>
                    <LocationMarkerIcon class="hero-icon"></LocationMarkerIcon>
                    {{ $t("message.last30days") }}
                  </v-card-text>
                  <v-card-text class="aggregate-value text-center">
                    <span class="aggregate-value text-h4 mr-2">{{aggregates.last_30.value}}</span>
                    <ArrowCircleDownIcon size="30" class="hero-icon" color="error" v-if="aggregates.last_30.delta < 0">
                    </ArrowCircleDownIcon>
                    <ArrowCircleUpIcon size="30" :color="themeColor('success')"
                      v-else-if="aggregates.last_30.delta > 0">
                    </ArrowCircleUpIcon>
                    <span v-if="aggregates.last_30.delta != 0" class="text-caption">
                      {{ Math.abs(aggregates.last_30.delta) }}%
                    </span>
                  </v-card-text>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row class="pa-2 pt-0">
              <v-col>
                <v-sheet outlined rounded="lg" class="">
                  <v-card-text>
                    <GlobeIcon class="hero-icon"></GlobeIcon>
                    {{ $t("message.total_countries") }}
                  </v-card-text>
                  <v-card-text class="aggregate-value text-center">
                    <span class="text-h4">
                      {{ aggregates.by_country ? aggregates.by_country : 0 }}
                    </span>
                  </v-card-text>
                </v-sheet>
              </v-col>
              <v-col>
                <v-sheet outlined rounded="lg" class="">
                  <v-card-text>
                    <LibraryIcon class="hero-icon"></LibraryIcon>
                    {{ $t("message.total_academies") }}
                  </v-card-text>
                  <v-card-text class="aggregate-value text-center">
                    <span class="text-h4">
                      {{ aggregates.by_academy }}
                    </span>
                  </v-card-text>
                </v-sheet>
              </v-col>
            </v-row>
          </div>
        </v-tab-item>
        <v-tab-item v-hide-embed="$route.query.embed" :key="'journal'">
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="displayAddDialog()" small elevation="0" color="primary">
                + {{ $t("message.add_entry") }}
              </v-btn>
            </v-card-actions>
            <v-card-text class="pb-0 mb-0">
              <journal-tags v-model="tagFilter" :label="$t('message.filter_by_tag')"></journal-tags>
            </v-card-text>
          </v-card>
          <div class="text-h4 text-center" v-if="journal.length == 0">
            {{ $t("message.empty_journal") }}
          </div>
          <v-expansion-panels>
            <v-expansion-panel v-for="(j, index) in journal" :key="index + j._id">
              <v-expansion-panel-header class="text-left">
                <div>
                  {{ truncate(j.title, 50) }}
                  <PhotographIcon size="20" v-if="j.image" :color="themeColor('primary')" class="hero-icon">
                  </PhotographIcon>
                  <span class="text-caption ml-2 grey--text">
                    {{ $d(new Date(j.date), 'month_day_year', $i18n.locale) }}
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-title v-if="j.tags && j.tags.length > 0">
                  <template v-for="(t, index2) in j.tags">
                    <v-chip class="mr-1" small :key="j._id + 'tag' + index2">{{ t }}</v-chip>
                  </template>
                </v-card-title>
                <v-card-text v-html="j.notes" rounded="lg" class="page-description">
                </v-card-text>
                <v-responsive v-if="j.image">
                  <img style="width: 100%; max-width: 550px" :src="userImg(j.image)" />
                </v-responsive>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn elevation="0" fab small v-bind="attrs" v-on="on" left>
                        <DotsVerticalIcon class="hero-button"></DotsVerticalIcon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click.stop="displayUpdateDialog(j)">
                        <v-list-item-title>{{ $t("message.edit") }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click.stop="displayDeleteDialog(j._id)">
                        <v-list-item-title>{{ $t("message.delete") }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-actions>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-if="loading" class="text-center">
            <v-skeleton-loader type="article"></v-skeleton-loader>
          </div>
          <v-card-actions v-if="viewMore">
            <v-spacer></v-spacer>
            <v-btn small color="primary" @click="getJournal(true)">{{
              $t("message.view_more")
              }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-tab-item>
        <v-tab-item v-hide-embed="$route.query.embed" :key="'timers'">
          <v-card class="mt-2" elevation="0" style="margin-bottom: 15px;">
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small class="elevation-0" @click="goToTimer()" color="primary">
                <v-icon class="mr-1">mdi-clock-plus-outline</v-icon>
                {{ $t("message.add_timer") }}
              </v-btn>
            </v-card-actions>
            <v-list two-line class="pa-0">
              <template v-for="(i, index) in timers">
                <v-list-item :key="'timer' + index" @click="goToTimer(i)">
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1 primary--text">
                      {{ i.name }}
                    </v-list-item-title>
                    <v-list-item-title class="supporting-text" v-if="i.advanced">
                      <AdjustmentsIcon class="hero-icon" size="20"></AdjustmentsIcon>{{
                      $t("message.intervals_with_rounds", [i.intervals.length]) }}
                    </v-list-item-title>
                    <v-list-item-title class="supporting-text" v-else-if="i.intervals[0]">
                      {{ i.intervals[0].rounds }} {{ $t("message.rounds") }}
                    </v-list-item-title>
                    <v-list-item-title class="supporting-text">
                      {{ $t("message.total_time") }}: {{ seconds2Minutes(i.totalTime) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="index + 1 < timers.length" :key="index"></v-divider>
              </template>
            </v-list>
          </v-card>
        </v-tab-item>
        <v-tab-item v-hide-embed="$route.query.embed" :key="'checkins'">
          <div>
            <v-card style="margin-top: 10px;">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="elevation-0" small @click="showNewCheckinDialog()">
                  + {{$t("message.add_checkin")}}
                </v-btn>
              </v-card-actions>
              <v-card-text v-if="!loadingCheckins && (!checkins || checkins.length == 0)">
                <span>{{ $t("message.no_checkins") }}</span>
              </v-card-text>
              <v-expansion-panels flat>
                <template v-for="(i) in checkins">
                  <v-expansion-panel :key="i._id" style="margin-bottom: 5px;" class="elevation-0">
                    <v-expansion-panel-header>
                      <v-list-item :key="i._id" v-if="i.academy">
                        <v-list-item-avatar>
                          <img v-if="i.academy.image" :src="userImg(i.academy.image)" />
                          <img v-else :src="assets('default-empty-academy.png')" />
                        </v-list-item-avatar>
                        <v-list-item-content three-line>
                          <v-list-item-title>
                            {{ i.academy.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span>{{$d(new Date(i.date), "long", $i18n.locale)}}</span>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle v-if="i.name">
                            {{ truncate(i.name, 30) }}
                            <span v-if="i.duration">({{ i.duration }}{{ $t("message.minutes") }})</span>
                          </v-list-item-subtitle>
                          <v-list-item-title v-else-if="i.contents" v-html="truncate(i.contents, 30)">
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card class="elevation-0 pa-2">
                        <v-card-text v-if="i.name">{{i.name}}</v-card-text>
                        <v-card-text v-else-if="i.contents">{{i.contents}}</v-card-text>
                        <v-card-text>
                          <b>{{$t("message.notes")}}:</b>
                          <span v-if="i.notes">{{i.notes}}</span>
                          <span v-else class="supporting-text">{{$t("message.none")}}</span>
                        </v-card-text>
                        <v-card-text>
                          <table class="pa-0" v-if="i.tracking">
                            <tr>
                              <td class="mr-2">{{ capitalizeFirstLetter($t("message.minutes")) }}</td>
                              <td>{{i.tracking.minutes}}</td>
                            </tr>
                            <tr>
                              <td class="mr-2">{{ $t("message.rounds") }}</td>
                              <td>{{i.tracking.rounds}}</td>
                            </tr>
                            <tr>
                              <td class="mr-2">{{ $t("message.sweeps") }}</td>
                              <td>{{i.tracking.sweeps}}</td>
                            </tr>
                            <tr>
                              <td class="mr-2">{{ $t("message.passes") }}</td>
                              <td>{{i.tracking.passes}}</td>
                            </tr>
                            <tr>
                              <td class="mr-2">{{ $t("message.back_takes") }}</td>
                              <td>{{i.tracking.backTakes}}</td>
                            </tr>
                            <tr>
                              <td class="mr-2">{{ $t("message.mounts") }}</td>
                              <td>{{i.tracking.mounts}}</td>
                            </tr>
                            <tr>
                              <td class="mr-2">{{ $t("message.takedowns") }}</td>
                              <td>{{i.tracking.takedowns}}</td>
                            </tr>
                            <tr>
                              <td class="mr-2">{{ $t("message.submissions") }}</td>
                              <td>{{i.tracking.submissions}}</td>
                            </tr>
                            <tr>
                              <td class="mr-2">{{ $t("message.tap_outs") }}</td>
                              <td>{{i.tracking.tapOuts}}</td>
                            </tr>
                          </table>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn icon @click.stop="displayEditCheckinDialog(i)">
                            <PencilIcon class="hero-button">=</PencilIcon>
                          </v-btn>
                          <v-btn icon v-if="i.source == 'manual_social'" @click.stop="displayConfirmDeleteDialog(i)">
                            <TrashIcon class="hero-button"></TrashIcon>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-expansion-panel-content>
                    <v-divider></v-divider>
                  </v-expansion-panel>
                </template>
              </v-expansion-panels>
              <div v-if="loadingCheckins">
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
              </div>
              <v-card-actions v-if="viewMoreCheckins">
                <v-spacer></v-spacer>
                <v-btn class="elevation-0" small color="primary" @click="getCheckins(true)">{{
                  $t("message.view_more")
                  }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </div>
        </v-tab-item>
        <v-tab-item v-hide-embed="$route.query.embed" :key="'workouts'">
          <div>
            <v-card style="margin-top: 10px;">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="elevation-0" small @click="showNewWorkoutDialog()">
                  + {{$t("message.add_workout")}}
                </v-btn>
              </v-card-actions>
              <v-card-text v-if="!loadingWorkouts && (!workouts || workouts.length == 0)">
                <span>{{ $t("message.add_workout_explained") }}</span>
              </v-card-text>
              <v-expansion-panels>
                <v-expansion-panel :key="i._id" v-for="(i, index) in workouts">
                  <v-expansion-panel-header>
                    <v-list-item :key="i._id">
                      <v-list-item-action>
                        <workout-icon size="30" :type="i.type"></workout-icon>
                      </v-list-item-action>
                      <v-list-item-content three-line>
                        <v-list-item-title>
                          <span>{{i.name}}</span> <span class="">({{$t("message."+i.type).toLowerCase()}})</span>
                        </v-list-item-title>
                        <v-list-item-title>
                          <span class="text-caption">{{$d(new Date(i.date), "long", $i18n.locale)}}</span>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card class="elevation-0 pa-2">
                      <table class="pa-0" v-if="i.tracking">
                        <tr>
                          <td class="mr-2">{{ capitalizeFirstLetter($t("message.minutes")) }}</td>
                          <td>{{i.tracking.minutes}}</td>
                        </tr>
                        <tr>
                          <td class="mr-2">{{ capitalizeFirstLetter($t("message.calories")) }}</td>
                          <td>{{i.tracking.calories}}</td>
                        </tr>
                      </table>
                      <v-textarea v-if="i.notes" v-html="i.notes" class="notes"></v-textarea>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn icon @click.stop="displayEditWorkoutDialog(i)">
                          <PencilIcon class="hero-button"></PencilIcon>
                        </v-btn>
                        <v-btn icon @click.stop="displayConfirmDeleteWorkoutDialog(i)">
                          <TrashIcon class="hero-button"></TrashIcon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                  <v-divider v-if="index + 1 < workouts.length" :key="index"></v-divider>
                </v-expansion-panel>
              </v-expansion-panels>
              <div v-if="loadingWorkouts">
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
              </div>
              <v-card-actions v-if="viewMoreWorkouts">
                <v-spacer></v-spacer>
                <v-btn class="elevation-0" small color="primary" @click="getWorkouts(true)">{{
                  $t("message.view_more")
                  }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs>
    </v-sheet>
    <v-dialog v-model="newCheckinDialog" max-width="90%">
      <v-card outlined v-if="newCheckin">
        <v-card-text class="text-left pa-2 mb-2">
          <span class="text-subtitle-1">{{$t('message.add_checkin')}}</span>
          <div class="text-caption">{{$t('message.add_checkin_explained')}}</div>
        </v-card-text>
        <v-card-text>
          <v-container grid-list-xs pa-0>
            <v-layout row wrap>
              <v-flex xs12>
                <academies-select from="history" :label="$t('message.academy')" :hide-message="true"
                  v-model="newCheckin.academy" />
              </v-flex>
              <v-flex xs12 class="pa-2 date-box">
                <DatePicker v-if="newCheckin" v-model="newCheckin.date" :past-event="true" :show-time="true">
                </DatePicker>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.class_name')" v-model="newCheckin.className">
                </v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.duration')+' ('+$t('message.minutes')+')'"
                  v-model="newCheckin.tracking.minutes">
                </v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.rounds')" v-model="newCheckin.tracking.rounds"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.sweeps')" v-model="newCheckin.tracking.sweeps"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.passes')" v-model="newCheckin.tracking.passes"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.back_takes')" v-model="newCheckin.tracking.backTakes"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.mounts')" v-model="newCheckin.tracking.mounts"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.takedowns')" v-model="newCheckin.tracking.takedowns"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.submissions')" v-model="newCheckin.tracking.submissions">
                </v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.tap_outs')" v-model="newCheckin.tracking.tapOuts"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" :disabled="!newCheckin.academy" @click="addNewCheckin()">{{$t('message.add')}}</v-btn>
          <v-btn color="secondary" @click="clearNewCheckinDialog()">{{$t('message.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newWorkoutDialog" max-width="90%">
      <v-card outlined v-if="newWorkout" class="pa-2">
        <v-card-text class="text-left">
          <span class="text-h5">{{$t('message.add_workout')}}</span>
          <div class="text-subtitle-2">{{$t('message.add_workout_explained')}}</div>
        </v-card-text>
        <v-card-text class="">
          <div class="pb-2">
            <DatePicker v-if="newWorkout" v-model="newWorkout.date" :past-event="true" :show-time="true"></DatePicker>
          </div>
          <div>
            <v-select :items="workoutTypes" v-model="newWorkout.type" :label="$t('message.type')" item-text="name"
              item-value="value" dense outlined :clearable="false">
            </v-select>
          </div>
          <div>
            <v-text-field dense outlined :label="$t('message.name')" v-model="newWorkout.name">
            </v-text-field>
          </div>
          <v-row>
            <v-col>
              <v-text-field dense outlined :label="$t('message.duration')+' ('+$t('message.minutes')+')'"
                v-model="newWorkout.tracking.minutes">
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field dense outlined :label="$t('message.calories')"
                v-model="newWorkout.tracking.calories"></v-text-field>
            </v-col>
          </v-row>
          <div>
            <v-textarea rows="3" outlined v-model="newWorkout.notes" :label="$t('message.notes')"></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" small :disabled="!newWorkout.date || !newWorkout.name || !newWorkout.tracking.minutes"
            @click="addNewWorkout()">{{$t('message.add')}}
          </v-btn>
          <v-btn small color="secondary" @click="clearNewWorkoutDialog()">{{$t('message.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editWorkoutDialog" max-width="90%">
      <v-card outlined v-if="workoutToEdit">
        <v-card-text>
          <v-container grid-list-xs pa-0>
            <v-layout row wrap>
              <v-flex xs12>
                <v-select :items="workoutTypes" v-model="workoutToEdit.type" :label="$t('message.type')"
                  item-text="name" item-value="value" :clearable="false">
                </v-select>
              </v-flex>
              <v-flex xs12>
                <v-text-field :label="capitalizeFirstLetter($t('message.name'))" v-model="workoutToEdit.name">
                </v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="capitalizeFirstLetter($t('message.minutes'))"
                  v-model="workoutToEdit.tracking.minutes">
                </v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="capitalizeFirstLetter($t('message.calories'))"
                  v-model="workoutToEdit.tracking.calories">
                </v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-textarea rows="2" outline v-model="workoutToEdit.notes" :label="$t('message.notes')">
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="editWorkout()">{{
            $t("message.update")
            }}</v-btn>
          <v-btn color="secondary" @click.stop="closeEditWorkoutDialog()">{{
            $t("message.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editCheckinDialog" max-width="90%">
      <v-card outlined v-if="checkinToEdit">
        <v-card-text>
          <v-textarea rows="2" outline v-model="checkinToEdit.notes" :label="$t('message.private_notes')">
          </v-textarea>
        </v-card-text>
        <v-card-text style="margin-top:-50px">
          <v-container grid-list-xs pa-0>
            <v-layout row wrap>
              <v-flex xs6>
                <v-text-field :label="capitalizeFirstLetter($t('message.minutes'))"
                  v-model="checkinToEdit.tracking.minutes">
                </v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.rounds')" v-model="checkinToEdit.tracking.rounds"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.sweeps')" v-model="checkinToEdit.tracking.sweeps"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.passes')" v-model="checkinToEdit.tracking.passes"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.back_takes')"
                  v-model="checkinToEdit.tracking.backTakes"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.mounts')" v-model="checkinToEdit.tracking.mounts"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.takedowns')"
                  v-model="checkinToEdit.tracking.takedowns"></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.submissions')" v-model="checkinToEdit.tracking.submissions">
                </v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field :label="$t('message.tap_outs')" v-model="checkinToEdit.tracking.tapOuts"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="editCheckin()">{{
            $t("message.update")
            }}</v-btn>
          <v-btn color="secondary" @click.stop="closeEditCheckinDialog()">{{
            $t("message.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addJournalDialog" max-width="90%">
      <v-card outlined v-if="currentToEdit">
        <v-card-title class="tect-h6">
          {{ $t("message.add_journal_title") }}
        </v-card-title>
        <v-card-title class="error--text" v-if="addJournalError">{{
          $t("message.fill_all_params")
          }}</v-card-title>
        <v-card-text>
          <v-text-field dense outlined v-model="currentToEdit.title" :label="$t('message.title')"
            required></v-text-field>
          <v-combobox :items="loadedTags" v-model="currentToEdit.tags" :search-input.sync="search"
            :menu-props="{value: autoselectMenu}" @click:append="toggle" @focus="toggle" @blur="autoselectMenu=false"
            chips dense outlined :label="$t('message.tags')" multiple>
            <template slot="no-data">
              <v-list-item>
                <span class="subheading">{{ $t("message.click_enter_add_new") }}:</span>
                <v-chip label small>{{ search }}</v-chip>
              </v-list-item>
            </template>
          </v-combobox>
          <DatePicker v-model="currentToEdit.date" :past-event="true" :show-time="false"></DatePicker>
          <v-textarea outlined dense :label="$t('message.notes')" v-model="currentToEdit.notes"></v-textarea>
        </v-card-text>
        <v-card-actions class="mt-0 pt-0">
          <input style="display: none" type="file" accept="video/*" name="video" @change="onVideoFileSelect"
            ref="videoFileUploadInput" />
          <input style="display: none" type="file" accept="image/*" @change="onFileSelect" ref="fileUploadInput" />
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="elevation-0" style="margin-left: 5px" @click="filePicker()" small v-bind="attrs" v-on="on"
                slot="activator">
                <PhotographIcon class="hero-button"></PhotographIcon>
              </v-btn>
            </template>
            <span>{{ $t("message.add_image") }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="elevation-0 ml-2" small v-bind="attrs" v-on="on">
                <VideoCameraIcon class="hero-button"></VideoCameraIcon>
              </v-btn>
            </template>
            <span>{{ $t("message.coming_soon") }}</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <div style="margin-left:10px" class="supporting-text" v-if="uploadingMessage">
            {{uploadingMessage}}
          </div>
          <v-btn small color="error" class="elevation-0" icon v-if="currentToEdit.image && uploadingMessage">
            <TrashIcon class="hero-button" @click="removeUploadedFile()">delete</TrashIcon>
          </v-btn>
          <v-btn @click="removeUploadedFile()" small color="error" class="elevation-0" v-else-if="currentToEdit.image">
            <TrashIcon class="hero-button"></TrashIcon>
            {{$t("message.remove_image")}}
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn class="elevation-0" color="primary" @click.stop="addJournal()">{{
            $t("message.save")
            }}</v-btn>
          <v-btn class="elevation-0" color="secondary" @click.stop="closeAddJournalDialog()">{{
            $t("message.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteJournalDialog" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <span>{{ $t("message.remove_journal") }}</span>
        </v-card-title>
        <v-card-actions>
          <v-btn class="elevation-0" color="error" @click.stop="deleteJournal()">{{
            $t("message.delete")
            }}</v-btn>
          <v-btn class="elevation-0" color="secondary" @click.stop="closeDeleteJournal()">{{
            $t("message.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteCheckinDialog" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <span>{{ $t("message.are_you_sure") }}</span>
        </v-card-title>
        <v-card-actions>
          <v-btn class="elevation-0" color="error" @click.stop="deleteCheckin()">{{
            $t("message.delete")
            }}</v-btn>
          <v-btn class="elevation-0" color="secondary" @click.stop="closeDeleteCheckinDialog()">{{
            $t("message.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteWorkoutDialog" max-width="500px">
      <v-card outlined>
        <v-card-title>
          <span>{{ $t("message.are_you_sure") }}</span>
        </v-card-title>
        <v-card-actions>
          <v-btn class="elevation-0" color="error" @click.stop="deleteWorkout()">{{
            $t("message.delete")
            }}</v-btn>
          <v-btn class="elevation-0" color="secondary" @click.stop="closeDeleteWorkoutDialog()">{{
            $t("message.close")
            }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileService2 from "@/services/FileService2";
import JournalTags from "@/components/inputs/JournalTagsSelect.vue";
import JournalService from "@/services/JournalService";
import TimerService from "@/services/TimerService";
import UserService from "@/services/UserService";
import WorkoutIcon from "@/components/displays/WorkoutIconDisplay.vue";
import AcademiesSelect from "@/components/inputs/AcademiesSelect.vue";
import DatePicker from "@/components/inputs/DateTimePicker2.vue";

import ApexChart from 'vue-apexcharts';

//Icons
import { 
  ClipboardListIcon,
  ClockIcon,
  ChartSquareBarIcon,
  LocationMarkerIcon,
  ArrowCircleUpIcon,
  ArrowCircleDownIcon,
  FireIcon,
  GlobeIcon,
  LibraryIcon,
  PencilIcon,
  TrashIcon,
  ClipboardCheckIcon,
  DotsVerticalIcon,
  PhotographIcon,
  VideoCameraIcon,
  AdjustmentsIcon,
} from '@vue-hero-icons/outline'

export default {
  components: {
    JournalTags,
    AcademiesSelect,
    DatePicker,
    WorkoutIcon,
    LibraryIcon,
    ClipboardListIcon,
    ClockIcon,
    ChartSquareBarIcon,
    LocationMarkerIcon,
    ArrowCircleUpIcon,
    ArrowCircleDownIcon,
    FireIcon,
    GlobeIcon,
    PencilIcon,
    TrashIcon,
    ClipboardCheckIcon,
    DotsVerticalIcon,
    PhotographIcon,
    VideoCameraIcon,
    AdjustmentsIcon,
    ApexChart,
  },
  data() {
    return {
      loadedTags: [],
      urlTab: this.$route.params.tabId,
      tagFilter: null,
      search: null,
      journalReady: false,
      journal: null,
      timers: [],
      selectedFile: null,
      uploadingMessage: null,
      uploadedVideoFile: null,
      uploading: false,
      uploadingVideo: false,
      videoUploaded: false,
      classNotes: null,
      journalEmpty: false,
      loading: false,
      viewMore: false,
      viewMoreCheckins: false,
      viewMoreWorkouts: false,
      activeTab: null,
      checkinsCompared: null,
      checkins: null,
      loadingCheckins: true,
      workouts: null,
      loadingWorkouts: true,
      index: 0,
      addJournalDialog: false,
      deleteJournalDialog: false,
      addJournalError: false,
      defaultDuration: 30,
      currentToEdit: null,
      newCheckin: null,
      newWorkout: null,
      dateMenu: null,
      toDelete: null,
      autoselectMenu: false,
      searching: false,
      searchingClassNotes: false,
      aggregates: null,
      editCheckinDialog: false,
      editWorkoutDialog: false,
      deleteCheckinDialog:false,
      deleteWorkoutDialog:false,
      checkinToEdit: null,
      workoutToEdit: null,
      checkinDaysChartData: null,
      newCheckinDialog: false,
      newWorkoutDialog: false,
      checkinDaysChartOptions: {
        legend: {position: 'none'},
        colors: [
          this.getColor('blue'), 
          this.getBeltColor('green'),
          this.getBeltColor('purple'),
          this.getBeltColor('red'),
          this.getBeltColor('brown'),
          this.getBeltColor('orange')],
        vAxis: {
          title: this.$t("message.hour_of_day"),
          maxValue: 24,
          viewWindow: {
            max: 24
          }
        },
        sizeAxis: {
          maxSize: 15
        }
      },
      aggregatesInterval: 14,
      techniquesChartData: null,
      techniquesChartOptions: {},
      onUploadProgress: function () {},
      workoutTypes: [
        { name: this.$t('message.cardio'), value: "cardio" },
        { name: this.$t('message.cross_straining'), value: "cross_straining" },
        { name: this.$t('message.strength_and_conditioning'), value: "strength_and_conditioning" },
        { name: this.$t('message.stretching'), value: 'stretching' },
        { name: this.$t('message.weights'), value: "weights" }
      ]
    };
  },
  mounted(){
    try{
      if(this.urlTab){
        const n = new Number(this.urlTab);
        this.activeTab = n.valueOf();
        this.clickTab(n);
      }
    }catch(err){
      if(err)
        console.log('Invalid tab: ', this.urlTab)
    }
  },
  async created() {
    if(this.urlTab){
      this.activeTab = this.urlTab;
    }
    this.getJournal(false);
    this.getCheckins(false);
    this.getWorkouts(false);
    this.getCheckinAggregates();
    this.getCheckinsChart();
    this.getTimers();
    //Tags
    JournalService.tags()
      .then((data) => {
        if (data) this.loadedTags = data;
      });
  },
  methods: {
    goToUpcomingEvent(e) {
      if(e.socialEvent){
        this.navigateTo({
          name: "viewevent",
          params: { eventId: e._id },
        });
      }else{
        this.navigateTo({
          name: "viewacademyclass",
          params: { academyClassId: e._id },
        });
      }
    },
    videoFilePicker() {
      this.$refs.videoFileUploadInput.click();
    },
    uploadVideo(){

    },
    onVideoFileSelect(event) {
      try {
        if (event.target.files[0]) {
          const size = event.target.files[0].size;
          const sizeMb = (size / 1000000).toFixed(1);
          if (sizeMb > 300.0) {
            this.showAlert(
              "error",
              this.$t("message.video_too_big") + " (< 300 MB)"
            );
            return;
          } else {
            this.uploading = true;
            this.videoUploaded = false;
            this.uploadingVideo = true;
            this.selectedFile = event.target.files[0];
            this.uploadedVideoFile = null;
            this.uploadedFile = null;
            this.uploadVideo();
          }
        } else {
          this.uploading = false;
        }
      } catch (e) {
        console.log(e.toString());
      }
    },
    uploadPhoto() {
      const formData = new FormData();
      this.uploadingMessage = this.$t("message.image_upload_progress");
      formData.append("image", this.selectedFile, this.selectedFile.name);
      FileService2.uploadImage(formData, this.onUploadProgress)
        .then((res) => {
          this.uploadedFile = res.file;
          this.uploading = false;
          this.uploadingMessage = this.selectedFile.name;
          this.currentToEdit.image = res.file;
        })
        .catch(() => {
          this.clearParameters();
        });
    },
    onFileSelect(event) {
      this.uploading = true;
      if (event.target.files[0]) {
        this.uploadedVideoFile = null;
        this.uploadedFile = null;
        this.selectedFile = event.target.files[0];
        const size = event.target.files[0].size;
        const sizeMb = Math.floor(size / 1000000);
        if (sizeMb <= 15) {
          this.uploadPhoto();
        } else {
          this.uploading = false;
          this.showAlert(
            "error",
            "Sorry! Your image is too big (" +
              sizeMb +
              "mb), try a smaller one."
          );
        }
      } else {
        this.uploading = false;
      }
    },
    filePicker() {
      this.uploading = false;
      this.uploadedVideoFile = null;
      this.uploadingVideo = false;
      this.$refs.fileUploadInput.click();
    },
    removeUploadedFile(){
      this.currentToEdit.image = null;
      this.uploadingMessage = null;
    },
    clickTab(tab){
      if(tab != this.activeTab){
        this.navigateTo({
          name: "viewmytrainingtab",
          params: { tabId: tab },
        });
      }
    },
    goToTimer(t){
      if(t)
        this.navigateTo({ name: 'managetimer', params: {timerId: t._id}});
      else
        this.navigateTo({ name: 'managetimer', params: {timerId: 'new'}});
    },
    getTimers(){
      TimerService.getTimers()
        .then((data) => {
          if(data){
            this.timers = data.timers;
          }
        });
    },
    toggle() {
      this.autoselectMenu = !this.autoselectMenu
    },
    addNewWorkout(){
      this.newWorkout.startTime = this.newWorkout.date.getHours();
      this.newWorkout.day = this.getWeekDay(this.newWorkout.date.getDay());
      this.newWorkout.dayOfMonth = this.newWorkout.date.getDate();
      this.newWorkout.year = this.newWorkout.date.getFullYear();
      this.newWorkout.month = this.newWorkout.date.getMonth()+1;
      for(const prop in this.newWorkout.tracking){
        let num = 0;
        try{
          if(this.newWorkout.tracking[prop]){
            num = new Number(this.newWorkout.tracking[prop]).valueOf();
            if(isNaN(num))
              num = 0;
          }
        }catch(err){
          console.log(err.toString())
        }
        this.newWorkout.tracking[prop] = num;
      }
      if(!this.newWorkout.name){
        this.newWorkout.name = this.$t('message.' + this.newWorkout.type);
      }
      JournalService.addWorkout(this.newWorkout)
        .then((data) => {
          if(data){
            this.clearNewWorkoutDialog();
            this.getWorkouts();
            this.getCheckinAggregates();
            this.getCheckinsChart();
          }
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    clearNewWorkoutDialog(){
      let checkinDate = new Date();
      this.newCheckin = {
        tracking: {
          minutes: this.defaultDuration
        },
        duration: this.defaultDuration,
        date: checkinDate
      };
      this.newWorkout = {
        tracking: {
          minutes: this.defaultDuration
        },
        type:'weights',
        duration: this.defaultDuration,
        date: checkinDate
      };
      //Add academy by default
      this.newWorkoutDialog = false;
    },
    showNewWorkoutDialog(){
      let checkinDate = new Date();
      checkinDate.setMinutes(0);
      checkinDate.setSeconds(0);
      this.newWorkout = {
        tracking: {
          minutes: this.defaultDuration
        },
        type:'weights',
        duration: this.defaultDuration,
        date: checkinDate
      };//Add academy by default
      this.newWorkoutDialog = true;
    },
    addNewCheckin(){
      this.newCheckin.startTime = this.newCheckin.date.getHours();
      this.newCheckin.day = this.getWeekDay(this.newCheckin.date.getDay());
      this.newCheckin.dayOfMonth = this.newCheckin.date.getDate();
      this.newCheckin.year = this.newCheckin.date.getFullYear();
      this.newCheckin.month = this.newCheckin.date.getMonth()+1;
      for(const prop in this.newCheckin.tracking){
        let num = 0;
        try{
          if(this.newCheckin.tracking[prop]){
            num = new Number(this.newCheckin.tracking[prop]).valueOf();
            if(isNaN(num))
              num = 0;
          }
        }catch(err){
          console.log(err.toString());
        }
        this.newCheckin.tracking[prop] = num;
      }
      JournalService.addCheckin(this.newCheckin)
        .then((data) => {
          if(data){
            this.clearNewCheckinDialog();
            this.getCheckinAggregates();
            this.getCheckinsChart();
            const vm = this;
            setTimeout(function(){
              vm.getCheckins(false);
            }, 1000);
          }
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    clearNewCheckinDialog(){
      this.newCheckin = {
        academy: this.$store.state.user.academyId,
        tracking: {},
        date: new Date()
      };//Add academy by default
      this.newCheckinDialog = false;
    },
    showNewCheckinDialog(){
      let checkinDate = new Date();
      checkinDate.setMinutes(0);
      checkinDate.setSeconds(0);
      this.newCheckin = {
        academy: this.$store.state.user.academyId,
        tracking: {},
        date: checkinDate
      };//Add academy by default
      this.newCheckinDialog = true;
    },
    getCheckinsChart(){
      JournalService.getCheckinChart(this.aggregatesInterval)
        .then((data) => {
          if(data){
            if(data && data.hasData){
              //We add this date to control the boundries of the chart
              const startDate = new Date();
              startDate.setDate(startDate.getDate() - 14);
              startDate.setHours(0);
              startDate.setMinutes(0);
              startDate.setSeconds(0);
              startDate.setMilliseconds(0);
              
              let days = {};
              const categories = [];
              for (let i = 1; i < 14 + 1; i++) {
                const d = new Date(startDate);
                const dateString = this.$d(new Date(d), "month_day", this.$i18n.locale)
                days[dateString] = {
                  text: dateString, 
                  date: d, 
                  checkinMinutes:0,
                  workoutMinutes:0,
                };
                categories.push(dateString);
                startDate.setDate(startDate.getDate() + 1);
              }
              for(const d of data.days){
                const date = new Date(d.date);
                const dateString = this.$d(new Date(date), "month_day", this.$i18n.locale);
                const dataDay = days[dateString];
                if(dataDay){
                  const duration = d.duration && d.duration > 0 ? d.duration : this.defaultDuration;
                  if(d.type == 'checkin'){
                    dataDay.checkinMinutes += duration;
                  }else{
                    dataDay.workoutMinutes += duration;
                  }
                }
              }
              let dataCheckins = [];
              let dataWorkouts = [];
              for(const day in days){
                const d = days[day];
                dataCheckins.push(d.checkinMinutes);
                dataWorkouts.push(d.workoutMinutes);
              }
              const series = [
                {
                  name: this.$t("message.checkins"),
                  data: dataCheckins
                },
                {
                  name: this.$t("message.workouts"),
                  data: dataWorkouts
                },
              ];

              this.checkinDaysChartOptions = {
                colors: [this.themeColor('primary'), this.themeColor('orange')],
                labels: [this.$t("message.without_checkin"), this.$t("message.with_checkin")],
                xaxis: {
                  categories: categories,
                  labels: {
                    show: true,
                    style: {
                      colors: this.themeColor('title')
                    },
                  }
                },
                yaxis: {
                  title: {
                    text: this.$t("message.minutes")
                  },
                  labels: {
                    show: true,
                    style: {
                      colors: this.themeColor('title')
                    },
                  }
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: 'smooth'
                },
                theme: {
                  mode: this.isDarkTheme() ? 'dark' : 'light',
                },
                chart: {
                  toolbar: {
                    show: false
                  },
                  background: 'none'
                },
                legend:{
                  position: 'bottom'
                },
                grid: {
                  borderColor: this.themeColor('gray'),
                },
              };
              this.checkinDaysChartData = series;
            }
          }
        });
    },
    displayEditCheckinDialog(checkin){
      this.checkinToEdit = checkin;
      this.editCheckinDialog = true;
    },
    displayConfirmDeleteDialog(checkin){
      this.checkinToEdit = checkin;
      this.deleteCheckinDialog = true;
    },
    closeDeleteCheckinDialog(){
      this.checkinToEdit = null;
      this.deleteCheckinDialog = false;
    },
    closeEditCheckinDialog(){
      this.checkinToEdit = null;
      this.editCheckinDialog = false;
    },
    deleteCheckin(){
      JournalService.deleteCheckin(this.checkinToEdit._id)
        .then((data) => {
          if(data){
            this.closeDeleteCheckinDialog();
            this.getCheckins();
          }
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    editCheckin(){
      JournalService.updateCheckin(this.checkinToEdit)
        .then((data) => {
          if(data){
            this.closeEditCheckinDialog();
            this.getCheckinAggregates();
            this.getCheckinsChart();
          }
        });
    },
    //Workouts
    displayEditWorkoutDialog(workout){
      this.workoutToEdit = workout;
      this.editWorkoutDialog = true;
    },
    displayConfirmDeleteWorkoutDialog(workout){
      this.workoutToEdit = workout;
      this.deleteWorkoutDialog = true;
    },
    closeDeleteWorkoutDialog(){
      this.workoutToEdit = null;
      this.deleteWorkoutDialog = false;
    },
    closeEditWorkoutDialog(){
      this.workoutToEdit = null;
      this.editWorkoutDialog = false;
    },
    deleteWorkout(){
      JournalService.deleteWorkout(this.workoutToEdit._id)
        .then((data) => {
          if(data){
            this.closeDeleteWorkoutDialog();
            this.getWorkouts();
          }
        })
        .catch(() => {
          this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    editWorkout(){
      JournalService.updateWorkout(this.workoutToEdit)
        .then((data) => {
          if(data){
            this.closeEditWorkoutDialog();
            this.getCheckinAggregates();
            this.getCheckinsChart();
          }
        });
    },
    getYouTubeId(url){
      if(url){
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
          ? match[2]
          : null;
      }
      return null;
    },
    clearFilter() {
      this.tagFilter = null;
      this.getJournal();
    },
    emitChange(event) {
      this.$emit("input", event);
    },
    closeAddJournalDialog() {
      this.addJournalDialog = false;
      this.currentToEdit = {};
    },
    closeDeleteJournal() {
      this.deleteJournalDialog = false;
      this.toDelete = null;
    },
    displayUpdateDialog(j) {
      this.currentToEdit = j;
      this.addJournalDialog = true;
    },
    displayDeleteDialog(id) {
      this.toDelete = id;
      this.deleteJournalDialog = true;
    },
    displayAddDialog() {
      this.addJournalDialog = true;
      this.currentToEdit = {
        date: new Date()
      };
    },
    async deleteJournal() {
      const data = await JournalService.deleteJournal(this.toDelete);
      if (data) {
        this.getJournal(false);
        this.closeDeleteJournal();
      } else {
        this.showAlert("error", this.$t("message.oops_not_working"));
      }
    },
    async updateJournal() {
      if (!this.validateAddJournal()) {
        this.addJournalError = true;
        return false;
      } else {
        try {
          const data = await JournalService.updateJournal(this.currentToEdit);
          if (data) {
            this.getJournal(false);
            this.uploadingMessage = null;
          }
        } catch (err) {
          if(err)
            this.showAlert("error", this.$t("message.oops_not_working"));
        }
        this.addJournalDialog = false;
      }
    },
    async addJournal() {
      this.index = 0;
      if (this.currentToEdit._id) {
        this.updateJournal();
      } else {
        if (!this.validateAddJournal()) {
          this.addJournalError = true;
          return false;
        } else {
          const data = await JournalService.addJournal(this.currentToEdit);
          if (data) {
            this.getJournal(false);
          } else {
            this.showAlert("error", this.$t("message.oops_not_working"));
          }
          this.addJournalDialog = false;
        }
      }
    },
    validateAddJournal() {
      const h = this.currentToEdit;
      if (!h.date) return false;
      if (!h.title) return false;
      if (!h.notes) return false;
      return true;
    },
    async getJournal(append) {
      try {
        if (!this.searching) {
          this.searching = true;
          this.viewMore = false;
          if (!append) {
            this.index = 0;
            this.journal = [];
          }
          var data = {};
          if (this.tagFilter) data.tags = [this.tagFilter];
          JournalService.journal(this.index, data)
            .then((data) => {
              if (data) {
                this.journal = this.journal.concat(data.journal);
                this.loading = false;
                this.index = this.index + data.count;
                if (data.count >= this.searchesLimit) this.viewMore = true;
                this.searching = false;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      } catch (err) {
        console.log(err.toString())
      }
    },
    getCheckinAggregates() {
      UserService.checkinAggregates()
        .then((data) => {
          if (data) {
            this.aggregates = data;
            if(this.aggregates.tracking && 
              (this.aggregates.tracking.sweeps > 0 || 
              this.aggregates.tracking.passes > 0 || 
              this.aggregates.tracking.backTakes > 0 || 
              this.aggregates.tracking.mounts > 0 || 
              this.aggregates.tracking.takedowns > 0 || 
              this.aggregates.tracking.tapOuts > 0 || 
              this.aggregates.tracking.submissions > 0)){
              this.techniquesChartData = [
                this.aggregates.tracking.sweeps,
                this.aggregates.tracking.passes,
                this.aggregates.tracking.backTakes,
                this.aggregates.tracking.mounts,
                this.aggregates.tracking.takedowns,
                this.aggregates.tracking.tapOuts,
                this.aggregates.tracking.submissions
              ];
            }
            //Options
            this.techniquesChartOptions = {
              legend:{
                position: 'bottom'
              },
              theme: {
                mode: this.isDarkTheme() ? 'dark' : 'light',
              },
              xaxis: {
                labels: {
                  show: true,
                  style: {
                    colors: this.themeColor('title')
                  },
                }
              },
              chart: {background: 'none'},
              colors: [
                this.themeColor('blue'),
                this.themeColor('purple'),
                this.themeColor('brown'),
                this.themeColor('gray'),
                this.themeColor('orange'),
                this.themeColor('error'),
                this.themeColor('success')],
              labels: [
                this.$t("message.sweeps"),
                this.$t("message.passes"),
                this.$t("message.back_takes"),
                this.$t("message.mounts"),
                this.$t("message.takedowns"),
                this.$t("message.tap_outs"),
                this.$t("message.submissions")
              ]
            };
          }
        })
        .catch(() => {
          this.loadingCheckins = false;
        });
    },
    getCheckins(append) {
      try {
        if (!this.searching || !append) {
          this.searching = true;
          this.loadingCheckins = true;
          this.viewMoreCheckins = false;
          if (!append) {
            this.index = 0;
            this.checkins = [];
          }
          UserService.checkins(this.index)
            .then((data) => {
              if (data) {
                this.checkins = this.checkins.concat(data.checkins);
                this.loadingCheckins = false;
                this.index = this.index + data.count;
                if (data.count >= this.searchesLimit)
                  this.viewMoreCheckins = true;
                this.searching = false;
              }
            })
            .catch(() => {
              this.loadingCheckins = false;
            });
        }
      } catch (err) {
        console.log(err.toString())
      }
    },
    getWorkouts(append) {
      try {
        if (!this.loadingWorkouts || !append) {
          this.loadingWorkouts = true;
          this.viewMoreWorkouts = false;
          if (!append) {
            this.index = 0;
            this.workouts = [];
          }
          JournalService.workouts(this.index)
            .then((data) => {
              if (data) {
                this.workouts = this.workouts.concat(data.workouts);
                this.loadingWorkouts = false;
                this.index = this.index + data.count;
                if (data.count >= this.searchesLimit)
                  this.viewMoreWorkouts = true;
              }
            })
            .catch(() => {
              this.loadingWorkouts = false;
            });
        }
      } catch (err) {
        console.log(err.toString())
      }
    },
  },
  computed: {},
  watch: {
    tagFilter: {
      handler: function (newValue) {
        if (newValue) {
          this.getJournal();
        }else{
          console.log('Clearing...')
        }
      },
    },
  },
};
</script>

<style scoped>
</style>
