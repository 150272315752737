<template>
  <div>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="partner">
      <v-card-text class="text-center">
        <v-avatar
          size="200">
          <img :src="userFile(partner.image)">
        </v-avatar>
      </v-card-text>
      <v-card-text class="text-h5 text-center">{{partner.name}}</v-card-text>
      <v-card-text>
        <template v-for="(c, index) in partner.categories">
          <v-chip class="mr-2" small :key="partner._id+'category'+index">{{c}}</v-chip>
        </template>
      </v-card-text>
      <v-card-title class="text-body-2" v-html="partner.description"></v-card-title>
      <v-card-title v-if="partner.contact">
        <ChatIcon class="hero-icon"></ChatIcon>{{partner.contact}}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <PartnerLikes v-if="partner.likes" :partner="partner"/>
        <v-spacer></v-spacer>
        {{getHostName(partner.url)}}
        <v-btn small @click="goToSite(partner)" icon>
          <ExternalLinkIcon class="hero-button"></ExternalLinkIcon>
        </v-btn>
      </v-card-actions>
    </v-sheet>
    <v-sheet class="mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-actions>
        <v-btn @click="goBack()" color="secondary">
          {{$t('message.back')}}
        </v-btn>
        <v-btn v-if="isAdmin" color="terciary" @click="editPartner">
          {{$t('message.edit')}}
        </v-btn>
      </v-card-actions>
    </v-sheet>
    <div v-if="isAdmin" class="mt-2">
      <NewPost :clear="clearPost" :submit="submitPost"/>
    </div>
    <div v-if="posts && posts.length > 0"  class="mt-2">
      <PostsDisplay :posts="posts" :addHovers="false" :hide-user="true"></PostsDisplay>
      <div v-if="loadingPosts">
        <v-skeleton-loader
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
      </div>
      <div v-if="viewMore">
        <v-btn small color="primary" @click="getPosts(true)">View More</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import PartnersService from "@/services/PartnersService";
import PostsDisplay from "@/components/displays/PostsDisplay.vue";
import NewPost from "@/components/inputs/NewPost.vue";
import PostService from "@/services/PostService";
import PartnerLikes from "@/components/displays/PartnerLikesDisplay.vue";

import { 
  ChatIcon,
  ExternalLinkIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      partnerId: this.$route.params.partnerId,
      isAdmin: false,
      posts: null,
      partner: {},
      loading: true,
      loadingPosts: false,
      viewMore: false,
      clearPost: true
    };
  },
  components: {
    NewPost,
    PostsDisplay,
    PartnerLikes,
    ChatIcon,
    ExternalLinkIcon
  },
  beforeRouteUpdate(to, from, next) {
    this.partnerId = to.params.partnerId;
    this.getPartner();
    next();
  },
  created(){
    this.getPartner();
  },
  methods: {
    writePartnerPost(){
      
    },
    async getPosts(append) {
      this.loadingPosts = true;
      this.viewMore = false;
      if (!append) this.posts = [];
      PostService.partnerPosts(this.partnerId, this.pointer)
        .then(data => {
          if (data) {
            this.posts = this.posts.concat(data.posts);
            this.loadingPosts = false;
            this.pointer = this.pointer + data.count;
            if (data.count >= this.searchesLimit) this.viewMore = true;
          }
        })
        .catch(err => {
          if(err)
            this.loadingPosts = false;
        });
    },
    async submitPost(postData, postFunction) {
      if (postData.contents && postData.contents != "") {
        postData.partnerId = this.partnerId;
        const data = await PostService.submitPost(postData);
        //Post added
        if (data) {
          data.post.user = this.$store.state.user;
          this.posts.unshift(data.post);
          this.clearPost = true;
        } else {
          this.showAlert(
            "error",
            this.$t('message.oops_not_working')
          );
        }
        if(postFunction)
            postFunction();
      } else {
        this.showAlert(
          "warning",
          this.$t("message.need_type_text")
        );
      }
    },
    goToSite(p) {
      PartnersService.click(p._id);
      if (p.url) 
        this.goToLink(p.url);
    },
    editPartner(){
      this.navigateTo({ name: "managepartner", params: { partnerId: this.partnerId } });
    },
    getPartner(){
      PartnersService.getPartner(this.partnerId)
        .then(data => {
          if (data) {
            this.getPosts();
            this.partner = data.partner;
            if(data.isAdmin){
              this.isAdmin = true;
            }
          }
        });
    }
  },
};
</script>

<style scoped>
</style>
