import Api from "@/services/Api";

export default {
  async uploadVideo(formData, progress) {
    const response = await Api().post("content/video/social", formData, {
      onUploadProgress: (uploadEvent) => {
        progress(uploadEvent);
      },
    });
    return response.data;
  },
  async uploadImage(formData, progress) {
    const response = await Api().post("content/image/social", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (uploadEvent) => {
        progress(uploadEvent);
      },
    });
    return response.data;
  },
};
