<template>
  <div v-if="!$store.state.hidden">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <v-card-title class="text-subtitle-2 pa-0">
        {{ $t("message.affiliate_programs") }}
      </v-card-title>
      <v-skeleton-loader v-if="loading" type="list-item, list-item, list-item"></v-skeleton-loader>
      <div v-else>
        <div v-if="!affiliates.length" class="text-h6">
          {{ $t("message.no_data_to_display") }}
        </div>
        <v-list dense three-line v-else>
          <template v-for="(i, index) in affiliates">
            <v-list-item :key="'affiliate_' + index"
              @click.stop="navigateTo({ name: 'viewaffiliate', params: { id: i._id } })">
              <v-list-item-avatar>
                <img v-if="i.image" :src="userImg(i.image)" />
                <img v-else :src="assets('default-profile-avatar.png')" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ i.academy }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="i.campaign">
                  {{ i.campaign }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ $t("message.partner_since") }}: {{ $d(new Date(i.since), 'month_day_year', $i18n.locale) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-badge
                  color="success"
                  small
                  :content="i.interactions+''"
                >
                  <v-icon>mdi-cursor-default-click-outline</v-icon>
                </v-badge>
              </v-list-item-action>
              <v-list-item-action>
                <v-badge
                  color="success"
                  small
                  :content="i.referrals+''"
                >
                  <v-icon>mdi-account-star-outline</v-icon>
                </v-badge>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index + 1 < affiliates.length" :key="index"></v-divider>
          </template>
        </v-list>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import AffiliateService from "@/services/AffiliateService";

export default {
  data() {
    return {
      affiliates: [],
      loading: true
    };
  },
  components: {},
  created() {
    this.getAffiliates()
  },
  methods: {
    getAffiliates() {
      AffiliateService.getAssociatedAffiliates()
        .then((data) => {
          if (data) {
            this.affiliates = data.data;
            this.loading = false;
          }
      }).catch(() => {
        this.loading = false;
      });
    }
  },
};
</script>

<style scoped>
</style>
