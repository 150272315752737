import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#8866ff",
        secondary: "#f3f3f3",
        tertiary: "#8D70B2",
        accent: "#f36284",
        error: colors.red.lighten2,
        success: colors.green.lighten3,
        title: "#1E1E1E",
        gray: colors.grey.lighten1,
        alt1: "#f39b36",
        opposite: "#000000",
        icon: colors.white,
        green: "#6ca754",
        yellow: colors.yellow.lighten1,
        white: "#FFFFFF",
        blue: "#0075F2",
        purple: "#450E75",
        brown: "#50341C",
        black: "#000000",
        gold: "#fbc42e",
        silver: "#ababab",
        bronze: "#ce8740",
        orange: "#fd9927",
      },
      dark: {
        primary: "#8866ff",
        secondary: "#424242",
        tertiary: "#747272",
        accent: "#f36284",
        error: "#f4493f",
        success: colors.green.lighten1,
        title: "#FFFFFF",
        gray: colors.grey.lighten1,
        alt1: "#f39b36",
        opposite: "#ffffff",
        green: "#6ca754",
        yellow: colors.yellow.lighten1,
        white: "#FFFFFF",
        blue: "#0075F2",
        purple: "#450E75",
        brown: "#50341C",
        black: "#000000",
        gold: "#fbc42e",
        icon: "red",
        silver: "#ababab",
        bronze: "#ce8740",
        orange: "#fd9927",
      },
    },
  },
});
