import { render, staticRenderFns } from "./ViewAgreement.vue?vue&type=template&id=65d2c5f6&scoped=true&"
import script from "./ViewAgreement.vue?vue&type=script&lang=js&"
export * from "./ViewAgreement.vue?vue&type=script&lang=js&"
import style0 from "./ViewAgreement.vue?vue&type=style&index=0&id=65d2c5f6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d2c5f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VSheet,VSwitch})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
