<template>
  <v-container>
    <v-card-actions>
      <v-btn small @click="showAddClassParams()">{{ $t('message.add_class') }}</v-btn>
    </v-card-actions>
    <v-container fluid grid-list-xl v-if="addingClass || editingClass">
      <v-layout wrap align-center>
        <v-flex xs12 d-flex v-if="error" style="color: #ED1C24">
          {{ $t('message.fill_all_parameters') }}
        </v-flex>
        <v-flex xs12 d-flex>
          <v-text-field
            :label="$t('message.class_title')"
            v-model="newClass.name"
            outlined
            dense
          ></v-text-field>
        </v-flex>
        <v-flex xs12 d-flex>
          <v-select
            v-if="addingClass"
            :items="daysSelector"
            item-text="text"
            item-value="value"
            :label="$t('message.class_day')"
            v-model="newClass.day"
            outlined
            denseo
            multiple
          ></v-select>
          <v-select
            v-if="editingClass"
            :items="daysSelector"
            item-text="text"
            item-value="value"
            :label="$t('message.class_day')"
            v-model="newClass.day"
            outlined
            dense
          ></v-select>
        </v-flex>
        <v-flex xs12 d-flex>
          <v-select
            :items="hours"
            v-model="newClass.start"
            item-text="display"
            item-value="value"
            :label="$t('message.class_start')"
            outlined
            dense
          ></v-select>
        </v-flex>
        <v-flex xs12 d-flex>
          <v-select
            :items="hours"
            v-model="newClass.end"
            item-text="display"
            item-value="value"
            :label="$t('message.class_end')"
            outlined
            dense
          ></v-select>
        </v-flex>
        <v-flex xs12 d-flex style="margin-top: -20px">
          <v-btn color="primary" small @click="addToSchedule()"><PlusCircleIcon class="hero-button"></PlusCircleIcon></v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <v-flex v-if="hasClasses">
      <v-list
          subheader
      >
        <template v-for="(d, dayIndex) in days">
          <v-subheader :key="'day'+dayIndex" v-if="schedule[d].length>0"><h3 style="color:#1B75BC">{{d | capitalize}}</h3></v-subheader>
          <template v-for="(c, hourIndex) in schedule[d]">
            <v-list-item v-if="schedule[d].length>0" :key="d+hourIndex" @click.stop="doNothing()">
              <v-list-item-content>
                <v-list-item-title>{{c.name}}</v-list-item-title>
                <v-list-item-subtitle>{{numberToString(c.start)}} - {{numberToString(c.end)}}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small icon @click="editFromSchedule(c.day, c.start, c.end, c.name)">
                  <PencilIcon class="hero-button"></PencilIcon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn small icon @click="removeFromSchedule(c.day, c.start, c.end, c.name)">
                  <TrashIcon class="hero-button"></TrashIcon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-flex>
    <v-flex v-else>
      <h3>{{ $t('message.schedule_explain1') }}</h3>
      {{ $t('message.schedule_explain2') }} <a @click="navigateTo({ name: $t('message.menu_academies') })">Academies</a> {{ $t('message.schedule_explain3') }}
    </v-flex>
  </v-container>
</template>
<script>
import { 
  PencilIcon,
  TrashIcon,
  PlusCircleIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      newClass: {},
      schedule: {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: []
      },
      hasClasses: false,
      error: false,
      addingClass: false,
      editingClass: false,
      days: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday"
      ],
      daysSelector: [
        {text: this.$t('message.monday'), value:"monday"},
        {text: this.$t('message.tuesday'), value:"tuesday"},
        {text: this.$t('message.wednesday'), value:"wednesday"},
        {text: this.$t('message.thursday'), value:"thursday"},
        {text: this.$t('message.friday'), value:"friday"},
        {text: this.$t('message.saturday'), value:"saturday"},
        {text: this.$t('message.sunday'), value:"sunday"}
      ],
      hours: this.initializeHours()
    };
  },
  components: {
    PencilIcon,
    TrashIcon,
    PlusCircleIcon
  },
  props: {
    value: null
  },
  created() {
    this.initializeHours();
    this.scheduleTemplate = [];
    if (this.value && 
      (this.value.monday || 
        this.value.tuesday ||
        this.value.wednesday ||
        this.value.thursday || 
        this.value.friday ||
        this.value.saturday || 
        this.value.sunday)) {
      //We have a schedule, pre load it
      this.schedule = this.value;
      this.hasClasses = true;
    }
  },
  mounted() {},
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
    value: {
      handler: function(newValue) {
        this.schedule = newValue;
      }
    },
  },
  methods: {
    initializeHours(){
      var hours = [];
      for(var i = 5; i < 24; i+= 0.25){
        var display = "";
        const r = i % 1;
        if(r == 0.25)
          display = Math.round(i-r)+':15';
        else if(r ==  0.5)
          display = Math.round(i-r)+':30';
        else if(r ==  0.75)
          display = Math.round(i-r)+':45';
        else
          display = Math.round(i)+':00';
        hours.push({display: display, value: i});
      }
      return hours;
    },
    doNothing(){},
    numberToString(time) {
      const split = time.toString().split(".");
      if (split.length > 1){
        if(split[1] == 25)
          return split[0] + ":15";
        else if(split[1] == 5)
          return split[0] + ":30";
        else if(split[1] == 75)
          return split[0] + ":45";
        else
          return split[0] + ":00";
      }
      else return time + ":00";
    },
    showAddClassParams() {
      this.editingClass = false;
      this.addingClass = !this.addingClass;
      if (!this.addingClass) {
        //Clear params
        this.newClass.name = null;
        this.newClass.day = null;
        this.newClass.start = null;
        this.newClass.end = null;
      }
    },
    addToSchedule() {
      this.error = false;
      if (
        !this.newClass.name ||
        !this.newClass.day ||
        !this.newClass.start ||
        !this.newClass.end
      ) {
        this.error = true;
        return;
      }
      if(Array.isArray(this.newClass.day)){
        //Loop and add all
        for(const day of this.newClass.day){
          var dayClasses = this.schedule[day];
          if(!dayClasses)
            dayClasses = [];
          dayClasses.push({
            name: this.newClass.name,
            day: day,
            start: this.newClass.start,
            end: this.newClass.end
          });
          this.schedule[day] = this.sortedArray(dayClasses);
        }
      }else{
        //normal
        let dayClasses = this.schedule[this.newClass.day];
        if(!dayClasses)
          dayClasses = [];
        dayClasses.push(this.newClass);
        this.schedule[this.newClass.day] = this.sortedArray(dayClasses);
      }
      this.$emit("input", this.schedule);
      this.newClass = {};
      this.addingClass = false;
      this.hasClasses = true;
    },
    editFromSchedule(day, start, end, name) {
      this.addingClass = false;
      const dayClasses = this.schedule[day];
      var index = 0;
      for (const c of dayClasses) {
        if (c.name == name && c.start == start && c.end == end) {
          dayClasses.splice(index, 1);
        }
        index++;
      }
      //Fill the add
      this.newClass.name = name;
      this.newClass.day = day;
      this.newClass.start = start;
      this.newClass.end = end;
      this.editingClass = true;
      //this.$emit("input", this.schedule);
    },
    removeFromSchedule(day, start, end, name) {
      const dayClasses = this.schedule[day];
      var index = 0;
      for (const c of dayClasses) {
        if (c.name == name && c.start == start && c.end == end) {
          dayClasses.splice(index, 1);
        }
        index++;
      }
      this.$emit("input", this.schedule);
    },
    sortedArray(array) {
      if (array) {
        return array.sort(function compare(a, b) {
          if (a.start < b.start) return -1;
          if (a.start > b.start) return 1;
          return 0; //Improve the base case if they start at the same time
        });
      }
      return array;
    }
  },
  computed: {}
};
</script>

<style scoped>
</style>