<template>
    <v-container wrap class="text-center">
        <div :class="!isSmallerDisplay() ? 'centered' : ''">
            <v-card flat max-width="650px" light>
                <v-progress-circular v-if="working" :size="70" :width="7" color="primary"
                    indeterminate></v-progress-circular>
                <v-card-text v-if="!working && updateCOF">
                    <div class="text-subtitle-1">{{ $t("message.update_card_on_file") }}</div>
                </v-card-text>
                <v-card-text>
                    <div v-if="!updateCOF">
                        {{ oneClickAvailable }} Button:
                        <div v-if="oneClickAvailable == 'Mac OS'" id="apple-pay-button"></div>
                        <div v-else-if="oneClickAvailable == 'Android'" id="google-pay-button"></div>
                    </div>
                    <iframe light v-show="!working" id="token-iframe" frameborder="0" :width="iframeWidth"
                        :height="iframeHeight"></iframe>
                    <input type="hidden" id="token" />
                    <v-list v-if="items && items.length > 0" class="text-left" outlined rounded>
                        <v-list-item v-for="(i, index) in items" :key="'item_' + index" link dense>
                            <v-list-item-content>
                                <v-list-item-title class="text-left">
                                    {{ i.name }}
                                    <v-icon size="20" class="ml-1" v-if="i.recurrent">mdi-sync</v-icon>
                                    <v-icon size="20" class="ml-1" v-else-if="i.discount">mdi-tag-outline</v-icon>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="i.startDate" class="primary--text">
                                    {{ $t("message.starts") }} {{ $d(new Date(i.startDate), "month_day_year",
                                    $i18n.locale) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content>
                                <v-list-item-title v-if="i.discount" class="text-right">
                                    <span>-</span>
                                    <span v-if="i.percent > 0">%{{ i.percent }}</span>
                                    <span v-else>{{ currency }}{{ i.amount }}</span>
                                </v-list-item-title>
                                <v-list-item-title v-else class="text-right">
                                    <span v-if="!i.recurrent">{{ i.quantity }}</span>
                                    <span v-if="!i.recurrent" class="mr-1 ml-1">x</span>
                                    {{ currency }}{{ i.amount }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-text class="text-center text-h2" v-if="!working && !updateCOF">
                    {{ currency }}{{ total }}
                </v-card-text>
                <v-card-actions v-if="!working">
                    <v-btn :disabled="paying || (total <= 0 && !updateCOF && !startsInFuture) " elevation="0" block
                        color="primary pl-0 pr-0" @click="checkPaymentMethod()">
                        <div v-if="!paying">
                            <span v-if="updateCOF">UPDATE</span>
                            <span v-else-if="startsInFuture && !willProcessNow">SAVE PAYMENT METHOD</span>
                            <span v-else-if="startsInFuture && willProcessNow">SAVE PAYMENT METHOD & PAY NOW</span>
                            <span v-else>PAY NOW</span>
                        </div>
                        <v-progress-linear v-else-if="!paymentComplete" indeterminate color="white"
                            size="50"></v-progress-linear>
                        <v-icon v-if="paymentComplete" color="success">mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-card-actions>
                    <v-btn elevation="0" small block @click="cancelPayment()">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </v-container>
</template>

<script>
import PaymentsService from "@/services/PaymentsService";

export default {
    data() {
        return {
            transaction: {},
            working: true,
            tokenKey: null,
            oneClickAvailable: null,
            postData: { routeFrom: 'bulk' },
            total: 0,
            currency: '$',
            discount: null,
            paying: false,
            inBackend: false,
            paymentComplete: false,
            iframeWidth: '400px',
            iframeHeight: '250px',
            updateCOF: false,
            items: [],
            startsInFuture: false,
            willProcessNow: true,
        };
    },
    props: ["transactionId","transactionData"],
    components: {
    },
    created() {
        if (this.isSmallerDisplay()) {
            this.iframeWidth = '300px';
            this.iframeHeight = '400px';
        }
        if (this.transactionData && this.transactionData.action == 'update-card') {
            this.updateCOF = true;
        }
    },
    beforeDestroy() {
        /*eslint-disable no-undef*/
        DatacapHostedWebToken.removeMessageEventListener();  
    },
    mounted() {
        this.initPayment();
        this.oneClickAvailable = this.getOS();
        /*
        let oneClickPayScript = document.createElement('script')
        oneClickPayScript.setAttribute('src', 'https://wallet-cert.dcap.com/v1/client/applepa')
        document.head.appendChild(oneClickPayScript)
        */
    },
    methods: {
        checkPaymentDates() {
            let willProcessNow = true;
            if (this.startsInFuture) {
                const len = this.items.length;
                if (this.items && len > 0) {
                    if (len == 1)
                        willProcessNow = false;
                    else if (len == 2 && (this.items[0].discount || this.items[1].discount))
                        willProcessNow = false;
                    //if there are more than 2 means there is prorated account + discount so willProcessNow  = true
                }
            }
            console.log('Will process in now: ', willProcessNow)
            return willProcessNow;
        },
        goToTransaction() {
            this.navigateTo({ name: 'transaction', params: { transactionId: this.transactionId } })
        },
        cancelPayment() {
            if (this.transactionId)
                this.navigateTo({ name: 'transaction', params: { transactionId: this.transactionId } });
            else
                this.navigateTo({ name: 'myacademytab', params: { tabId: 1 } })
        },
        async initPayment() {
            this.working = true;
            try {
                if (!this.transactionData || this.transactionData.action != 'update-card') {
                    //this.transactionId = '65ec7b9c4cd0235d436815e6';
                    if (!this.transactionId) {
                        this.showAlert("error", this.$t("message.error_generic"));
                        return;
                    }
                    try {
                        this.working = false;
                        const response = await PaymentsService.getTransactionV3(this.transactionId)
                        if (response.transaction) {
                            this.transaction = response.transaction;
                            this.total = this.transaction.amount;

                            //Items
                            let items = [];
                            if (this.transaction.product) {
                                const recurrent = this.transaction.product.type == 'service' ? true : false
                                items.push({
                                    name: this.transaction.product.name,
                                    amount: this.transaction.product.amountDecimal,
                                    quantity: 1,
                                    recurrent: recurrent,
                                    startDate: recurrent ? this.transaction.subscriptionStartDate : null
                                });
                            }else if(this.transaction.items){
                                for (const i of this.transaction.items) {
                                    items.push({
                                        name: i.name,
                                        amount: i.amountDecimal,
                                        quantity: i.quantity
                                    });
                                }
                            }
                            if (this.transaction.coupon && this.transaction.coupon.duration) {
                                if (this.transaction.coupon.discountAmount > 0) {
                                    items.push({
                                        name: this.$t("message.discount"),
                                        discount: true,
                                        amount: this.transaction.coupon.discountAmount,
                                    });
                                } else if (this.transaction.coupon.discountPercent > 0) {
                                    items.push({
                                        name: this.$t("message.discount"),
                                        discount: true,
                                        percent: this.transaction.coupon.discountPercent,
                                    });
                                }
                            }

                            if (this.transaction.prorated && this.transaction.prorated.amount) {
                                items.push({
                                    name: this.$t("message.prorated_amount"),
                                    amount: this.transaction.prorated.amount,
                                    quantity: 1,
                                });
                            }
                            this.items = items;

                            if (this.transaction.subscriptionStartDate && this.transaction.recurrence) {
                                if (this.transaction.prorated && this.transaction.prorated.amount > 0)
                                    this.total = this.transaction.prorated.amount;
                                else
                                    this.total = 0;
                                this.startsInFuture = true;
                                this.willProcessNow = this.checkPaymentDates();
                            } else if (this.transaction.recurrence) {
                                this.total = this.transaction.recurrence.totalAmount;
                            } else {
                                this.total = this.transaction.amount;
                            }

                            this.tokenKey = response.transaction.token;
                            //const customCSS = ".container { color: white; font: 75% verdana,arial,sans-serif; }";
                            /*eslint-disable no-undef*/
                            DatacapHostedWebToken.init(this.tokenKey, 'token-iframe', this.completePayment);
                        } else {
                            this.showAlert("error", this.$t("message.invalid_transaction"));
                        }
                    } catch (e) {
                        console.log(e)
                        if (e)
                            this.showAlert("error", this.$t("message.error_generic"));
                    }
                } else if (this.transactionData.routeFrom == 'viewmyacademy') {
                    this.working = false;
                    PaymentsService.paymentsModuleData(this.transactionData.managedAcademyId, 'infinicept')
                        .then(response => {
                            if (response.data) {
                                this.tokenKey = response.data.tokenKey;
                                //const customCSS = ".container { color: white; font: 75% verdana,arial,sans-serif; }";
                                /*eslint-disable no-undef*/
                                DatacapHostedWebToken.init(this.tokenKey, 'token-iframe', this.completePayment);
                            }
                        })
                        .catch(e => {
                            console.log(e)
                            if (e)
                                this.showAlert("error", this.$t("message.error_generic"));
                            this.working = false;
                        });  
                }
            } catch (err) {
                console.log(err)
                this.showAlert("error", this.$t("message.error_generic"));
            }
        },
        checkPaymentMethod() {
            this.paying = true;
            if (this.paymentComplete)
                return;
            /*eslint-disable no-undef*/
            DatacapHostedWebToken.requestToken();
        },
        async completePayment(response) {
            if (this.inBackend) {
                console.log('Abort..already paying')
                return;
            }
            if (response.Error) {
                // Handle tokenization errors
                let error = response.Error;
                this.paying = false;
                this.showAlert("error", error);
            } else {
                const vm = this;
                const token = response.Token;
                let data = {
                    token: token,
                    source: 'social'
                };
                this.inBackend = true;
                if (this.updateCOF) {
                    if (!this.transactionData.memberId || !this.transactionData.managedAcademyId) {
                        this.paying = false;
                        this.showAlert("error", "Oops!", this.$t("message.error_generic"));
                        return;
                    }
                    //Change this post
                    PaymentsService.updateCreditCardV3(this.transactionData.managedAcademyId, data)
                        .then((response) => {
                            if (response.data) {
                                const data = response.data;
                                if (data.status == "Approved" && data.gatewayId) {
                                    /*eslint-disable no-undef*/
                                    DatacapHostedWebToken.removeMessageEventListener();
                                    //back to transaction page
                                    const vm = this;
                                    setTimeout(function () {
                                        vm.navigateTo({ name: 'myacademytab', params: { tabId: 1 } })
                                    }, 1000);
                                } else {
                                    this.paying = false;
                                    this.showAlert("error", this.$t("message.error_generic"));
                                    this.inBackend = false;
                                    DatacapHostedWebToken.removeMessageEventListener();
                                    DatacapHostedWebToken.init(this.tokenKey, 'token-iframe', this.completePayment);
                                }
                            }
                        })
                        .catch((err) => {
                            this.inBackend = false;
                            this.paying = false;
                            this.showAlert("error", err.response.data.message);
                            /*eslint-disable no-undef*/
                            DatacapHostedWebToken.removeMessageEventListener();
                            DatacapHostedWebToken.init(this.tokenKey, 'token-iframe', this.completePayment);
                        });
                } else {
                    if (!this.transaction._id) {
                        this.paying = false;
                        this.showAlert("error", "Oops!", this.$t("message.cant_initialize_transaction"));
                        return;
                    }
                    PaymentsService.submitPaymentV3(this.transactionId, data)
                        .then(response => {
                            if (response.data) {
                                this.inBackend = false;
                                const data = response.data;
                                if (data.status == "Approved" && data.gatewayId) {
                                    vm.paymentComplete = true;
                                    /*eslint-disable no-undef*/
                                    DatacapHostedWebToken.removeMessageEventListener();
                                    setTimeout(function () {
                                        vm.paying = false;
                                        vm.goToTransaction();
                                        //console.log('Back to transaction')
                                    }, 1000);
                                } else {
                                    this.paying = false;
                                    this.showAlert("error", this.$t("message.error_generic"));
                                }
                            }
                            this.paying = false;
                            DatacapHostedWebToken.removeMessageEventListener();
                        })
                        .catch(e => {
                            if (e)
                                this.showAlert("error", this.$t("message.error_generic"));
                            this.working = false;
                            this.paying = false;
                            this.inBackend = false;
                            DatacapHostedWebToken.removeMessageEventListener();
                        });
                }
            }
        }
    }
};
</script>

<style scoped>
.centered div {
    margin-left: auto;
    margin-right: auto;
}
</style>