<template>
  <v-container v-if="$store.state.isUserLoggedIn" row wrap>
    <BackButton></BackButton>
    <div v-if="loading">
      <circles-loader></circles-loader>
    </div>
    <div v-if="!loading && academy">
      <v-card v-if="completed">
        <v-card-title>
          <v-avatar>
            <img v-if="academy.image" :src="userImg(academy.image)">
            <img v-else :src="assets('default-empty-academy.png')">
          </v-avatar>
          <h2>{{academy.name}}</h2>
        </v-card-title>
        <v-card-text><h3>{{$t('message.name', selectedLang)}}</h3></v-card-text>
        <div light>
          <v-card-text class="template-contents" v-html="documentContents"></v-card-text>
        </div>
        <v-card-title>
          <v-text-field
            style="margin-right:20px" 
            disabled
            v-model="participantName"
          ></v-text-field>
        </v-card-title>
        <v-card-title>
          <v-text-field 
            disabled
            style="margin-right:20px"
            v-model="signedDate"
          ></v-text-field>
        </v-card-title>
        <div class="text-center" v-if="signature" style="margin-bottom:15px;">
          <img :src="signature" style="height:150px;border-bottom: 1px solid black"/>
          <div style="font-family: 'Avenir', Arial, sans-serif;width:350px;margin-right:auto; margin-left:auto">{{$t('message.signature')}}</div>
        </div>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <v-avatar>
            <img v-if="academy.image" :src="userImg(academy.image)">
            <img v-else :src="assets('default-empty-academy.png')">
          </v-avatar>
          <div class="text-h6 text-center">{{academy.name}}</div>
        </v-card-title>
        <v-card-actions>
          <template v-for="l in availableLanguages">
            <v-btn 
              @click="switchLang(l)"
              :color="l == selectedLang ? 'pink' : 'primary'"
              fab small dark :key="l">
              {{l}}
            </v-btn>
          </template>
        </v-card-actions>
        <v-card-text><h3>{{academyDocumentTemplate.name}}</h3></v-card-text>
        <div light>
          <v-card-text class="template-contents" v-html="documentContents"></v-card-text>
        </div>
        <v-card-text class="text-center" v-if="messages" v-html="messages"></v-card-text>
        <v-card-text v-if="extraFieldValues">
          <template v-for="(field, index) in extraFieldValues">
            <v-text-field 
              :key="'extra_field_'+index"
              :label="field.fieldName + (field.required ? ' ('+$t('message.required').toLowerCase()+')' : '')"
              v-model="field.value"></v-text-field>
          </template>
        </v-card-text>
        <v-card-title>
          <v-text-field
            style="margin-right:20px"
            :label="$t('message.name', selectedLang)"
            v-model="participantName"
          ></v-text-field>
        </v-card-title>
        <v-card-title>
          <v-text-field
            :label="$t('message.complete_todays_date')"
            v-model="date"
          ></v-text-field>
        </v-card-title>
        <v-card-text class="text-center">
          <Vue-Signature 
            style="border: 1px solid black; margin-left:auto; margin-right:auto;" 
            :w="'360px'" :h="'150px'"
            ref="waiverSignature" 
            :sigOption="signatureOptions"></Vue-Signature>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="!participantName || !date"
            color="primary"
            @click="submitDocument()"
          >
            {{$t('message.submit', [selectedLang])}}
          </v-btn>
          <v-btn
            color="secondary"
            @click="clearSignature()"
          >
            {{$t('message.clear_signature')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="goBack()"
          >
            {{$t('message.cancel', selectedLang)}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>
<script>
import AcademyDocumentService from "@/services/AcademyDocumentService";
import CirclesLoader from "@/components/displays/CirclesLoader.vue";
import VueSignature from "vue-signature"
import BackButton from "@/components/displays/BackButtonDisplay.vue";

var moment = require("moment");

export default {
  data() {
    return {
      loading: false,
      academyId: this.$route.params.academyId,
      eventId: this.$route.query.e,
      academyDocumentTemplate: null,
      documentContents: null,
      completed: false,
      date: null,
      signedDate: null,
      availableLanguages: [],
      extraFieldValues: null,
      selectedLang: null,
      academy: null,
      messages: null,
      participantName: null,
      signatureOptions: {
        penColor:"rgb(0, 0, 0)",
        backgroundColor:"rgb(255,255,255)"
      },
      signature: null
    };
  },
  components: {
    CirclesLoader,
    VueSignature,
    BackButton
  },
  created() {
    this.participantName = this.$store.state.user.fName +' '+ this.$store.state.user.lName;
    const d = new Date();
    this.date = this.toNiceDateCustomFormat(d, "YYYY-MM-")
    this.loadDocument();
  },
  methods: {
    loadDocument(){
      AcademyDocumentService.visitorWaiver(this.academyId)
        .then(data => {
          this.academy = data.academy;
          this.academyDocumentTemplate = data.template;
          if(data.template && !data.completed){
            this.availableLanguages.push(data.template.lang);
            for(const t in data.template.contentTransaltions){
              this.availableLanguages.push(t);
            }
            this.extraFieldValues = data.template.extraFields;
            this.switchLang(data.template.lang);
          }else{
            var completedDocument = data.completed;
            this.documentContents = completedDocument.contents;
            this.participantName = completedDocument.userName;
            this.signedDate = completedDocument.userDate;
            this.extraFieldValues = completedDocument.extraFieldValues;
            this.signature = completedDocument.signature;
            this.completed = true;
            this.showAlert(
              "warning",
              this.$t('message.already_signed')
            );
          }
          this.loading = false;
        })
        .catch(err => {
          if(err){
            this.showAlert(
              "error",
              this.$t('message.invalid_document')
            );
          }
        });
    },
    submitDocument(){
      this.messages = null;
      var _this = this;
      var pngImage = _this.$refs.waiverSignature.save();
      if(_this.$refs.waiverSignature.isEmpty()){
        this.messages = '<span style="color:red;font-size:1.5em">'+this.$t('message.please_sign')+'</span>';
        return;
      }
      if(this.isToday()){
        const postData = {
          userName: this.participantName,
          event: this.eventId,
          userDate: this.date,
          lang: this.selectedLang,
          extraFieldValues: this.extraFieldValues,
          contents: this.documentContents,
          signature: pngImage
        };
        AcademyDocumentService.submitVisitorWaiver(this.academyId, postData)
          .then(data => {
            if(data.completed){
              var completedDocument = data.completed;
              this.documentContents = completedDocument.contents;
              this.participantName = completedDocument.userName;
              this.signedDate = postData.userDate;
              this.signature = completedDocument.signature;
              this.completed = true;
              this.showAlert(
                "success", this.$t('message.success')
              );
              const vm = this;
              setTimeout(function(){vm.goBack()}, 1000);
            }
            this.loading = false;
          })
          .catch(err => {
            if(err)
              this.showAlert(
                "error",
                this.$t('message.error_generic')
              );
          });
      }else{
        this.messages = '<span style="color:red;font-size:1.5em">'+this.$t('message.not_today')+'</span>';
      }
    },
    clearSignature(){
      var _this = this;
      _this.$refs.waiverSignature.clear();
    },
    switchLang(lang){
      this.selectedLang = lang;
      if(lang == this.academyDocumentTemplate.lang){
        this.documentContents = this.academyDocumentTemplate.contents;
      }else{
        this.documentContents = this.academyDocumentTemplate.contentTransaltions[lang];
      }
    },
    isToday(){
      try{
        const day = moment(this.date, "YYYY-MM-DD");
        const today = moment();
        if(day.isSame(today, 'day') && day.isSame(today, 'date'))
          return true;
        else
          return false;
      }catch(e){
        return false;
      }      
    }
  },
  mounted() {
  },
  computed: {},
  watch: {}
};
</script>

<style scoped>
.template-contents{
  background-color: white;
  color: black;
}
</style>