<template>
  <div v-if="$store.state.isUserLoggedIn && user != null" wrap>
    <BackButton></BackButton>
    <v-skeleton-loader
      v-if="loading"
      type="card"
    ></v-skeleton-loader>
    <div v-if="!loading && user">
      <v-sheet
        :rounded="isSmallerDisplay() ? '' : 'lg'"  
        class="get-app-alert text-center"
        v-if="
          this.$store.state.user._id == user.id &&
          (!user.apps || user.apps.length == 0)
        "
      >
        <div>
          <ExclamationIcon class="hero-icon"></ExclamationIcon>
          {{ $t("message.download_app_reminder") }}
        </div>
        <img
          v-if="os == 'Mac OS' || os == 'iOS'"
          @click="
            goToLink('https://apps.apple.com/us/app/bjjlink/id1458988543')
          "
          style="width: 150px; cursor: pointer"
          :src="assets('appstore_badge.png')"
        />
        <img
          v-else-if="os == 'Android'"
          @click="
            goToLink(
              'https://play.google.com/store/apps/details?id=com.bjjlink.android'
            )
          "
          style="width: 150px; cursor: pointer"
          :src="assets('googleplay_badge.png')"
        />
      </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="text-center mt-2 pa-2">
        <img
          class="userImage"
          v-if="!user.userImage"
          :src="assets('blank-profile-picture.png')"
          ref="profileImg"
        />
        <img
          class="userImage"
          v-else
          :src="userImg(user.userImage)"
          ref="profileImg"
        />
        <ImageUpload
          :objId="user.id"
          :vm="vm"
          :add-small="true"
          :operation="'user_profile_image'"
          :success-function="successFunction"
        ></ImageUpload>
      </v-sheet>
      <div>
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
          <v-list-item two-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditFieldDialog('fName', $t('message.first_name'))"
                class="hero-icon"
                ></PencilIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.fName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.first_name")}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditFieldDialog('lName', $t('message.last_name'))"
                class="hero-icon"
                ></PencilIcon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.lName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.last_name")}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditFieldDialog('nickname', $t('message.nickname'))"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.nickname }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.nickname")}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditFieldDialog('identifier', $t('message.identifier'))"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.identifier }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.identifier")}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditFieldDialog('email', 'Email')"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.email }} 
                <v-tooltip v-if="user.tempEmail" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <ExclamationIcon 
                      v-bind="attrs"
                      v-on="on"
                      class="hero-icon" size="20" :color="themeColor('accent')">
                    </ExclamationIcon>
                  </template>
                  <span>Approval pending for {{ user.tempEmail }}</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                Email
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditFieldDialog('nationality', $t('message.nationality'))"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <CountryDisplay
                  :countryCode="user.nationality"
                ></CountryDisplay>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.nationality")}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditFieldDialog('gender', $t('message.gender'))"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ displayUserGender(user.gender) }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.gender")}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item three-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditDateDialog('dob', 'Date Of Birth')"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <DobDisplay
                  :dob="user.dob"
                  :hide-age="user.hideAge"
                ></DobDisplay>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.dob")}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-switch dense v-model="hideAge" :label="$t('message.hide_age')"></v-switch>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditHistoryDialog('promotion')"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <BeltDisplay :belt="user.belt"></BeltDisplay>
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.belt")}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditHistoryDialog('new_academy')"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.academyName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.academy")}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-action>
                  <PencilIcon
                    @click="showEditFieldDialog('specialty', $t('message.specialty'))"
                    class="hero-icon"
                    ></PencilIcon
                  >
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.specialty }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{$t("message.specialty")}}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span class="hidden-sm-and-down">{{
              $t("message.specialty_explain")
            }}</span>
          </v-tooltip>
          <v-list-item two-line>
            <v-list-item-action>
              <PencilIcon
                @click="showEditFieldDialog('masterName', $t('message.professor'))"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ user.masterName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{$t("message.professor")}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
          <v-card>
            <v-card-title class="text-subtitle-1">
                {{ $t("message.interests") }}:
            </v-card-title>
            <v-card-text class="text-xs-left">
              <interests-combo
                v-model="interests"
              />
            </v-card-text>
          </v-card>
        </v-sheet>
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
          <v-card>
            <v-card-title class="text-subtitle-1">
              {{ $t("message.jjtags") }}:
            </v-card-title>
            <v-card-text class="text-left">
              <v-combobox
                :items="loadedJJTags"
                v-model="userJJTags"
                :search-input.sync="search"
                chips
                multiple
                outlined
              >
                <template slot="no-data">
                  <v-list-item>
                    <span class="subheading"
                      >{{ $t("message.click_enter_add_new") }}:</span
                    >
                    <v-chip label small>{{ search }}</v-chip>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-card-text>
          </v-card>
        </v-sheet>
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2" v-if="user.masterName">
          <v-card dark style="background-color: black; color: white">
            <v-card-title class="text-subtitle-1">
              {{ $t("message.your_lineage") }}:
            </v-card-title>
            <v-card-text class="text-left">
              <lineage-display
                :master="user.masterName"
                :user-id="user.id"
                :master-id="user.masterId"
                :user="user.fName"
              />
            </v-card-text>
          </v-card>
        </v-sheet>
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
          <v-list-item>
            <v-list-item-action>
              <PencilIcon
                @click="editPasswordDialog=true"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-container grid-list-md text-xs-center>
                <v-layout row wrap>
                  <v-flex xs4 class="text-xs-left hidden-sm-and-down">{{
                    $t("message.password")
                  }}</v-flex>
                  <v-flex xs8 class="text-xs-left">
                    *********
                  </v-flex>
                </v-layout>
              </v-container>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
        <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
          <v-list-item>
            <v-list-item-action>
              <PencilIcon
                @click="showEditDateDialog('bjjSince', $t('message.bjj_since'))"
                class="hero-icon"
                ></PencilIcon
              >
            </v-list-item-action>
            <v-list-item-content>
              <v-container grid-list-md text-xs-center>
                <v-layout row wrap>
                  <v-flex xs4 class="text-xs-left hidden-sm-and-down">{{
                    $t("message.bjj_since")
                  }}</v-flex>
                  <v-flex xs8 class="text-xs-left">
                    <dob-display
                      :dob="user.overview.bjjSince"
                      :hide-age="false"
                    ></dob-display></v-flex>
                </v-layout>
              </v-container>
            </v-list-item-content>
          </v-list-item>
        </v-sheet>
        <div style="margin-top: 10px" v-if="user && user.social">
           <v-card>
            <v-card-text class="text-sm-left">
              <v-text-field outlined dense
                label="Facebook"
                v-model="user.social.facebook"
              ></v-text-field>
              <v-text-field outlined dense
                label="Instagram"
                v-model="user.social.instagram"
              ></v-text-field>
              <v-text-field outlined dense 
                label="Twitter"
                v-model="user.social.twitter"
              ></v-text-field>
              <v-text-field outlined dense
                label="TikTok"
                v-model="user.social.tiktok"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="updateSocial()">{{
                $t("message.update")
              }}</v-btn>
            </v-card-actions>
           </v-card>
        </div>
        <div xs12 style="margin-top: 10px" v-if="user && user.memberData">
          <v-card>
            <v-card-title class="text-left text-subtitle-1">
              {{ $t("message.academy_private_info") }}
            </v-card-title>
            <v-card-text class="text-sm-left">
              <v-text-field outlined dense
                v-model="user.memberData.address"
                :label="$t('message.address')"
              ></v-text-field>
            </v-card-text>
            <v-card-text class="text-sm-left">
              <v-text-field outlined dense
                v-model="user.memberData.phone"
                :label="$t('message.phone')"
              ></v-text-field>
            </v-card-text>
            <v-card-text class="text-sm-left">
              <v-text-field outlined dense
                v-model="user.memberData.emergencyContact"
                :label="$t('message.emergency_contact')"
              ></v-text-field>
            </v-card-text>
            <v-card-text class="text-sm-left">
              <v-text-field outlined dense
                v-model="user.memberData.guardian"
                :label="$t('message.guardian_name')"
              ></v-text-field>
            </v-card-text>
            <v-card-text class="text-sm-left">
              <v-text-field outlined dense
                v-model="user.memberData.guardianEmail"
                :label="$t('message.guardian_email')"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="updateMemberData()">{{
                $t("message.update")
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div xs12 style="margin-top: 10px" v-if="user">
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="notBjjUser"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                  {{$t("message.not_bjj_user")}}
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-text class="text-left text-body-1">{{
              $t("message.not_bjj_user_explained")
            }}</v-card-text>
          </v-card>
        </div>
        <div xs12 style="margin-top: 10px">
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="hiddenUser"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                  {{
                    $t("message.hidden_user")
                  }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-text class="text-left text-body-1">{{
              $t("message.hidden_user_explain")
            }}</v-card-text>
          </v-card>
        </div>
        <div xs12 style="margin-top: 10px" v-if="embed != 'true'">
          <v-card elevation="0">
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="deleteUserToggle"></v-switch>
                </v-list-item-action>
                <v-list-item-content>
                  {{
                    $t("message.delete_user")
                  }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-title class="text-left text-body-1">
              <v-btn
                color="error"
                @click="deleteUserDialog = true"
                :disabled="!deleteUserToggle"
                >{{ $t("message.delete_user") }}</v-btn
              >
              {{ $t("message.delete_user_message") }}
            </v-card-title>
          </v-card>
        </div>
      </div>
      <v-dialog v-model="editDateDialog" max-width="500px">
        <v-card outlined class="pa-2">
          <v-card-title>
            <div class="text-subtitle-1">{{ $t("message.update") }}: {{ selectedUpdateFieldLabel }}</div>
          </v-card-title>
          <v-card-text>
            <date-picker :past-event="true" v-model="dobDate" ></date-picker>
          </v-card-text>
          <v-card-actions>
            <v-btn small class="elevation-0" color="primary" @click.stop="update(selectedUpdateField)">{{
              $t("message.update")
            }}</v-btn>
            <v-btn small class="elevation-0" color="secondary" @click.stop="closeEditDateDialog()">{{
              $t("message.close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editPasswordDialog" max-width="500px">
        <v-card outlined class="pa-2">
          <v-card-text outlined dense class="text-left">
            <div class="text-subtitle-1">{{ $t("message.update") }} {{$t('message.password')}}</div>
          </v-card-text>
          <v-card-text>
            <v-text-field outlined dense
              :label="$t('message.current_password')"
              v-model="password1"
              autocomplete="false"
            ></v-text-field>
            <v-text-field outlined dense
              :label="$t('message.new_password')"
              v-model="updateValue"
              autocomplete="false"
            ></v-text-field>
            <v-text-field outlined dense
              :label="$t('message.retype_password')"
              v-model="password2"
              autocomplete="false"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn small color="primary" @click.stop="updatePassword()">{{
              $t("message.update")
            }}</v-btn>
            <v-btn small color="secondary" @click.stop="editPasswordDialog=false;updateValue=null;">{{
              $t("message.close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editFieldDialog" max-width="500px">
        <v-card outlined class="pa-2">
          <v-card-text class="text-left">
            <div class="text-subtitle-1">{{ $t("message.update") }}: {{ selectedUpdateFieldLabel }}</div>
            <div class="supporting-text" v-if="selectedUpdateField == 'identifier'">
              {{ $t("message.identifier_explained") }}
            </div>
          </v-card-text>
          <v-card-text>
            <v-text-field outlined dense
              v-if="
                selectedUpdateField == 'identifier' ||
                selectedUpdateField == 'fName' ||
                selectedUpdateField == 'lName' ||
                selectedUpdateField == 'email'||
                selectedUpdateField == 'nickname'
              "
              v-model="updateValue"
              autocomplete="false"
            ></v-text-field>
            <countries-select
              v-else-if="selectedUpdateField == 'nationality'"
              v-model="updateValue"
            />
            <date-picker 
              v-else-if="
                selectedUpdateField == 'dob' ||
                selectedUpdateField == 'bjjSince'
              "
              :past-event="true"
              v-model="dobDate"></date-picker>
            <master-combo
              v-else-if="selectedUpdateField == 'masterName'"
              v-model="updateValue"
            ></master-combo>
            <gender-select
              v-else-if="selectedUpdateField == 'gender'"
              v-model="updateValue"
            />
            <specialties-combo
              v-else-if="selectedUpdateField == 'specialty'"
              v-model="updateValue"
              label="Specialty (pick or add a new one)"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn small color="primary" @click.stop="update(selectedUpdateField)">{{
              $t("message.update")
            }}</v-btn>
            <v-btn small color="secondary" @click.stop="closeEditFieldDialog()">{{
              $t("message.close")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteUserDialog" max-width="500px">
        <v-card outlined>
          <v-card-title>
            <div class="text-subtitle-1">{{ $t("message.delete_user") }} :(</div>
          </v-card-title>
          <v-card-text>{{ $t("message.delete_user_why") }}</v-card-text>
          <v-card-text>
            <v-text-field outlined dense v-model="deleteUserMessage"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn small color="error" @click.stop="deleteUser()">{{
              $t("message.delete")
            }}</v-btn>
            <v-btn
              small
              color="secondary"
              @click.stop="
                deleteUserDialog = false;
                deleteUserToggle = false;
              "
              >{{ $t("message.close") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editHistoryDialog" v-if="historyEvent" max-width="80%">
        <v-card outlined>
          <v-card-title>
            <div class="text-subtitle-1" v-if="historyEvent.historyType == 'promotion'">{{ $t("message.update_belt") }}</div>
            <div class="text-subtitle-1" v-else>{{ $t("message.update_academy") }}</div>
          </v-card-title>
          <v-card-text class="text-left">
            <belts-select-stripes v-if="historyEvent.historyType == 'promotion'" showIcon="false" v-model="historyEvent.belt"></belts-select-stripes>
            <academies-select v-else v-model="historyEvent.academy"></academies-select>
            <div class="text-subtitle-2">{{$t("message.event_date")}}</div>
            <date-picker 
              :past-event="true"
              v-model="historyEvent.date">
            </date-picker>
          </v-card-text>
          <v-card-actions v-if="!isSmallerDisplay()">
            <v-btn 
              small
              class="elevation-0"
              :disabled="disableAddHistory"
              color="primary" @click.stop="updateHistoryEvent()">{{
              $t("message.update")
            }}</v-btn>
            <v-btn
              small
              class="elevation-0"
              color="secondary"
              @click.stop="editHistoryDialog = false;"
              >{{ $t("message.close") }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn 
              small
              class="elevation-0"
              color="error"
              @click="clearUserAcademy()"
              v-if="historyEvent.historyType == 'new_academy'"
            >
              <TrashIcon class="hero-button"></TrashIcon>
              {{$t("message.remove_academy")}}
            </v-btn>
          </v-card-actions>
          <div v-else class="pa-2">
            <v-btn 
              small
              block
              class="elevation-0"
              :disabled="disableAddHistory"
              color="primary" @click.stop="updateHistoryEvent()">{{
              $t("message.update")
            }}</v-btn>
            <v-btn
              small
              block
              class="elevation-0 mt-1"
              color="secondary"
              @click.stop="editHistoryDialog = false;"
              >{{ $t("message.close") }}</v-btn
            >
            <v-btn 
              small
              class="elevation-0 mt-1"
              block
              color="error"
              @click="clearUserAcademy()"
              v-if="historyEvent.historyType == 'new_academy'"
            >
              <TrashIcon class="hero-button"></TrashIcon>
              {{$t("message.remove_academy")}}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/UserService";
import LineageService from "@/services/LineageService";
import CountriesSelect from "@/components/inputs/CountriesSelect.vue";
import BeltsSelectStripes from "@/components/inputs/BeltsSelectStripes.vue";
import SpecialtiesCombo from "@/components/inputs/SpecialtiesCombo.vue";
import BeltDisplay from "@/components/displays/BeltDisplay.vue";
import CountryDisplay from "@/components/displays/CountryDisplay.vue";
import DobDisplay from "@/components/displays/DobDisplay.vue";
import LineageDisplay from "@/components/displays/LineageDisplay.vue";
import ImageUpload from "@/components/inputs/ImageUploadCroppie.vue";
import MasterCombo from "@/components/inputs/MasterCombo.vue";
import GenderSelect from "@/components/inputs/GenderSelect.vue";
import InterestsCombo from "@/components/inputs/InterestsCombo.vue";
import DatePicker from "@/components/inputs/DateTimePicker2.vue";
import AcademiesSelect from "@/components/inputs/AcademiesSelect.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

var moment = require("moment");

//Icons
import { 
  PencilIcon,
  TrashIcon,
  ExclamationIcon,
} from '@vue-hero-icons/outline'

export default {
  components: {
    BeltsSelectStripes,
    BeltDisplay,
    CountriesSelect,
    CountryDisplay,
    DobDisplay,
    GenderSelect,
    ImageUpload,
    LineageDisplay,
    MasterCombo,
    SpecialtiesCombo,
    InterestsCombo,
    DatePicker,
    AcademiesSelect,
    PencilIcon,
    TrashIcon,
    ExclamationIcon,
    BackButton,
  },
  data() {
    return {
      vm: this,
      embed: this.$route.query.embed,
      hiddenUser: false,
      notBjjUser: false,
      profilePic: null,
      showEditByIndex: null,
      user: null,
      os: null,
      dobCheck: true,
      hideAge: false,
      search: null,
      deleteUserMessage: null,
      deleteUserToggle: false,
      deleteUserDialog: false,
      masterId: null,
      userJJTags: [],
      loadedJJTags: [],
      interests:[],
      loading: true,
      autoselectMenu: false,
      editFieldDialog: false,
      editPasswordDialog: false,
      editDateDialog: false,
      selectedUpdateField: null,
      selectedUpdateFieldLabel: null,
      password1: null,
      password2: null,
      updateValue: "",
      dobDate: null,
      dateMenu: null,
      editHistoryDialog: false,
      historyEvent: null,
      disableAddHistory: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      successFunction: function (uploadedFile, vm) {
        if (uploadedFile) {
          this.$store.dispatch("setUserImage", uploadedFile);
          vm.profilePic = this.userImg(uploadedFile);
          vm.user.userImage = uploadedFile;
          //Update user image value!
          vm.updateUserImages(uploadedFile);
        }
      },
    };
  },
  async created() {
    const u = await UserService.user(this.$store.state.user._id);
    this.os = this.getOS();
    this.user = u;
    if (this.user != null) {
      if(!this.user.social)
        this.user.social = {};
      if (u.id.toString() == this.$store.state.user._id.toString())
        UserService.userWithUpdate(this.$store.state.user._id);
      this.hiddenUser = this.user.hidden ? this.user.hidden : false;
      this.notBjjUser = this.user.notBjjUser ? this.user.notBjjUser : false;
      this.hideAge = this.user.hideAge ? this.user.hideAge : false;
      this.$store.dispatch("setHidden", this.hiddenUser);
      this.masterId = this.user.masterId;
      if (this.user.jjTags) {
        this.userJJTags = this.user.jjTags;
      }
      this.loadedJJTags = await UserService.jiujitsuTags();
      this.loading = false;
      this.profilePic = this.userImg(this.user.userImage);
      this.interests = this.getInterestsValues(this.user.interests);
    } else {
      this.navigateTo({ name: "error" });
    }
  },
  methods: {
    clearUserAcademy(){
      UserService.removeAcademy()
        .then((data) => {
            if (data) {
              this.user.academyName = null;
              this.user.academyId = null;
              this.editHistoryDialog = false;
              this.showAlert(
                "success",
                this.$t("message.success")
              );
            }
        }).catch((e) => {
          if (e.response.data.message_translation)
              this.showAlert(
                "error",
                this.$t("message." + e.response.data.message_translation),
                null,
                8000
              );
            else
              this.showAlert(
                "error",
                "Oops!",
                this.$t("message.error_generic")
              );
        });
    },
    updatePassword(){
      if(this.updateValue != this.password2){
        this.showAlert("error",this.$t("message.passwords_dont_match"));
      }else if(this.updateValue == this.password1){
        this.showAlert("error",this.$t("message.cant_use_same_password"));
      }else{
        const data = {
          currentPassword: this.password1,
          password: this.password2
        };
        UserService.updatePassword(data)
          .then((data) => {
            if (data) {
              this.editPasswordDialog = false;
              this.password1 = null;
              this.password2 = null;
              this.updateValue = null;
              this.showAlert(
                "success",
                this.$t("message.success")
              );
            }
          }).catch((err) => {
            if (err.response.status == 400)
              this.showAlert(
                "error",
                this.$t("message.invalid_password")
              );
            else this.showAlert(
                "error",
                this.$t("message.error_generic")
              );
          });
      }
    },
    updateHistoryEvent(){
      this.disableAddHistory = true;
      UserService.addHistory(
        this.$store.state.user._id,
        this.historyEvent
      ).then((data) => {
        if (data) {
          const vm = this;
          setTimeout(function(){
            UserService.user(vm.$store.state.user._id)
              .then((data) => {
                if (data) {
                  vm.user.belt = data.belt;
                  vm.user.academyName = data.academyName;
                }
              });
            vm.showAlert(
              "success",
              vm.$t("message.updated")
            );
            vm.editHistoryDialog = false;
          }, 1000);
        }
      }).catch(() => {
        this.showAlert(
          "error",
          this.$t("message.oops_not_working")
        );
      });
    },
    showEditHistoryDialog(historyType){
      this.disableAddHistory = false;
      let title = "Belt Promotion";
      if (historyType == "new_academy")
        title = "New Academy";
      this.historyEvent = {
        historyType: historyType,
        date: new Date(),
        title: title
      };
      this.editHistoryDialog = true;
    },
    updateMemberData() {
      const postData = this.user.memberData;
      UserService.updateMemberData(postData).then(() => {
        this.showAlert("success", this.$t("message.success"));
      });
    },
    updateSocial(){
      this.user.social.facebook = this.getLastPartOfUrl(this.user.social.facebook);
      this.user.social.instagram = this.getLastPartOfUrl(this.user.social.instagram);
      this.user.social.tiktok = this.getLastPartOfUrl(this.user.social.tiktok);
      this.user.social.twitter = this.getLastPartOfUrl(this.user.social.twitter);
      const updateValues = [{ propName: "social", value: this.user.social }];
      UserService.updateUser(updateValues);
      this.showAlert("success", this.$t("message.success"));
    },
    showApprovalpending() {
      if (this.user.tempEmail) {
        this.showAlert("info", "Approval pending for " + this.user.tempEmail);
      }
    },
    displayUserGender(gender) {
      if (!gender) return this.$t("message.prefer_not_to_say");
      else if (gender == "female") return this.$t("message.female");
      else return this.$t("message.male");
    },
    async deleteUser() {
      //Send call to backend, wait and then logut
      try {
        UserService.deleteUser({ message: this.deleteUserMessage })
          .then((data) => {
            if (data) {
              this.$store.dispatch("setToken", null);
              this.$store.dispatch("setUser", null);
              localStorage.removeItem("token");
              window.location.replace("/login");
            }
          })
          .catch((err) => {
            this.showAlert(
              "error",
              this.$t("message." + err.response.data.message_translation),
              null,
              8000
            );
          });
      } catch (e) {
        if(e)
          this.showAlert("error", e.response.data.message);
      }
    },
    async processNotBjjUser(val) {
      const updateValues = [{ propName: "notBjjUser", value: val }];
      try {
        UserService.updateUser(updateValues);
      } catch (err) {
        this.showAlert("error", err.response.data.message);
      }
    },
    async processHiddenUser(val) {
      const updateValues = [{ propName: "hidden", value: val }];
      try {
        UserService.updateUser(updateValues);
        this.$store.dispatch("setHidden", val);
      } catch (err) {
        this.showAlert("error", err.response.data.message);
      }
    },
    async processHideAge(val) {
      const updateValues = [{ propName: "hideAge", value: val }];
      try {
        UserService.updateUser(updateValues);
        this.user.hideAge = val;
      } catch (err) {
        this.showAlert("error", err.response.data.message);
      }
    },
    showEditDateDialog(field, label) {
      this.selectedUpdateField = field;
      this.selectedUpdateFieldLabel = label;
      if(this.selectedUpdateField == 'dob')
        this.dobDate = new Date(this.user.dob);
      else if(this.selectedUpdateField == 'bjjSince')
        this.dobDate = new Date(this.user.overview.bjjSince);
      this.editDateDialog = true;
    },
    closeEditDateDialog() {
      this.selectedUpdateField = null;
      this.editDateDialog = false;
      this.updateValue = "";
      this.selectedUpdateFieldLabel = null;
    },
    closeEditFieldDialog() {
      this.selectedUpdateField = null;
      this.editFieldDialog = false;
      this.updateValue = "";
      this.selectedUpdateFieldLabel = null;
    },
    showEditFieldDialog(field, label) {
      this.selectedUpdateField = field;
      this.selectedUpdateFieldLabel = label;
      this.editFieldDialog = true;
    },
    goToAcademy(id) {
      this.navigateTo({ name: "viewacademy", params: { academyId: id } });
    },
    showImagePicker() {
      this.$refs.pickFileInput.click();
    },
    async updateMaster(value) {
      if(value && value._id){
        LineageService.updateUserMasterId(value._id)
          .then((data) => {
            if (data) {
              this.user.masterId = value._id;
              this.user.masterName = value.name;
              this.updateValue = "";
              this.closeEditFieldDialog();
            }
          })
          .catch(() => {
            this.updateValue = "";
            this.closeEditFieldDialog();
          });
      }else{
        //New master by name
        LineageService.changeUserMaster(value)
          .then((data) => {
            if (data) {
              this.user.masterId = data._id;
              this.user.masterName = this.updateValue;
              this.updateValue = "";
              this.closeEditFieldDialog();
            }
          })
          .catch(() => {
            this.updateValue = "";
            this.closeEditFieldDialog();
          });
      }
    },
    async update(prop) {
      if (prop == "masterName") {
        this.updateMaster(this.updateValue);
        return;
      }
      const updateValues = [];
      if (prop == "email") {
        try {
          this.updateValue.trim();
          UserService.updateEmail({
            email: this.updateValue,
          });
          this.showAlert(
            "success",
            "We have sent you an email to [" +
              this.updateValue +
              "] with some instructions!"
          );
          this[prop] = false;
        } catch (err) {
          this.showAlert("error", err.response.data.message);
        }
      } else {
        if (prop == "dob") {
          if (!this.dobDate) {
            this.showAlert("error", this.$t("message.incorrect_date"));
            return;
          }
          this.updateValue = this.dobDate;
        } else if (prop == "nationality") {
          //leave it
        } else if (prop == "gender") {
          //leave it
        } else if (prop == "identifier") {
          this.updateValue.trim();
          this.updateValue = this.updateValue
            .toLowerCase()
            .replace(/ /g, "_")
            .replace(/[^\w\s]/gi, "");
        } else if (prop == "bjjSince") {
          prop = "overview";
          if (!this.dobDate) {
            this.showAlert("error", this.$t("message.incorrect_date"));
            return;
          }
          this.user.overview.bjjSince = this.dobDate;
          this.updateValue = this.user.overview;
        } else {
          this.updateValue.trim();
          this.updateValue = this.capitalizeText(this.updateValue);
        }
        updateValues.push({ propName: prop, value: this.updateValue });
        try {
          UserService.updateUser(updateValues);
          this.user[prop] = this.updateValue;
          this[prop] = false;
          if (prop == "belt") {
            this.user.belt = this.updateValue;
          }
          if (prop == "nationality") {
            this.user.nationality = this.updateValue;
          }
          if (prop == "masterName") {
            this.user.masterName = this.updateValue;
          }
          if (prop == "dob") {
            this.user.dob = this.updateValue;
            this.dobDate = null;
          }
          if (prop == "bjjSince") {
            this.dobDate = null;
            this.user.overview = this.updateValue;
          }
          if (prop == "fName") {
            this.$store.dispatch("setName", this.updateValue);
          }
        } catch (err) {
          if (err.response.status === 403) {
            this.showAlert(
              "error",
              this.$t("message.error_duplicate_identifier")
            );
          }
        }
      }
      // Clear value
      this.updateValue = "";
      this.closeEditFieldDialog();
      this.closeEditDateDialog();
    },
    updateUserImages(filePath) {
      const updateValues = [];
      var split = filePath.split("/");
      const filePathSmall = "media/img/" + split[2] + "/" + "small-" + split[3];
      updateValues.push({ propName: "userImage", value: filePath });
      updateValues.push({ propName: "userImageSmall", value: filePathSmall });
      UserService.updateUser(updateValues);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  mounted() {},
  computed: {
    age: function () {
      return this.user ? "(" + moment().diff(this.user.dob, "years") + ")" : "";
    },
  },
  watch: {
    hiddenUser(newVal) {
      this.processHiddenUser(newVal);
    },
    notBjjUser(newVal) {
      this.processNotBjjUser(newVal);
    },
    hideAge(newVal) {
      this.processHideAge(newVal);
    },
    userJJTags(val) {
      //Update and remove
      var lowerCased = [];
      for (const v of val) lowerCased.push(v.toLowerCase());
      const updateValues = [{ propName: "jjTags", value: lowerCased }];
      UserService.updateUser(updateValues);
    },
    interests(values) {
      //Update and remove
      let tmp = [];
      for(const v of values)
        tmp.push(v.value);
      const updateValues = [{ propName: "interests", value: tmp }];
      UserService.updateUser(updateValues);
    },
  },
};
</script>

<style scoped>
.userImage {
  width: 210px;
  height: 210px;
}
.left {
  text-align: left;
}
.user_overview {
  margin-top: 10px;
}
.user_lineage {
  margin-top: 10px;
}
.get-app-alert {
  background-color: #ec7063;
  border-radius: 5px;
  margin-bottom: 5px;
  color: white;
  font-weight: bold;
  padding: 2px;
}
</style>