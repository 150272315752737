<template>
  <img
    v-if="fileUrl"
    :src="fileUrl"
  />
</template>

<script>
import Api from "@/services/Api";
import AWSUrlApi from "@/services/AWSUrlApi";
export default {
  props: ["src"],
  data() {
    return {
      fileUrl: null,
    };
  },
  methods: {
    getFileUrl() {
      if (this.src) {
        if (this.src.indexOf('https:') == 0) {
          const encodedUrl = encodeURIComponent(this.src);
          Api()
            .get("content/url/signed/" + encodedUrl)
            .then(res => {
              this.fileUrl = res.data;
            });
        } else {
          AWSUrlApi()
            .get(this.src)
            .then((res) => {
              this.fileUrl = res.data;
            });
        }
      }
    },
  },
  created() {
    this.getFileUrl();
    //Viewed?
  },
};
</script>

<style scoped>
</style>
