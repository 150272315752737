<template>
  <span v-if="icon">
    <icon-hero 
      style="z-index:4;position:relative"
      v-if="icon == 'hero'" 
      :color="color ? color : 'white'"
      :size="size ? size : '35'">
    </icon-hero>
    <BadgeCheckIcon
      style="z-index:4;position:relative"
      v-else
      :color="color" 
      :size="size ? size : '35'">
    </BadgeCheckIcon>
  </span>
</template>

<script>
import IconHero from "@/components/displays/icons/IconHero.vue";

import { 
  BadgeCheckIcon,
} from '@vue-hero-icons/solid'

export default {
  props: ["icon", "size", "color"],
  components:{
    IconHero,
    BadgeCheckIcon
  },
  created() {
  },
  methods: {
  },
};
</script>

<style scoped></style>
