<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="$store.state.isUserLoggedIn">
    <BackButton></BackButton>
    <div v-if="loading" class="text-center">
      <circles-loader></circles-loader>
    </div>
    <div v-if="!loading && agreement">
      <v-card height="100%" elevation="0">
        <v-card-title>
          <span class="text-h6">{{$t('message.please_agree_terms_conditions')}}</span>
        </v-card-title>
        <v-card-text v-if="agreement.url" lass="pa-1">
          <iframe ref="agreementIframe" 
            :style="iframeHeight" 
            class="agreement_iframe" 
            id="scroll-target" 
            v-scroll:#scroll-target="onScroll" 
            :src="agreement.url"></iframe>
        </v-card-text>
        <v-card-text v-else>
          <h4>{{agreement.title}}</h4>
          <div v-html="agreement.contents"></div>
        </v-card-text>
        <v-card-text>
          <v-switch
            label="I have read and accept the terms and conditions"
            v-model="acceptSwitch"
          ></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="agree()" :disabled="!acceptSwitch">
            {{$t('message.agree')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-sheet>
</template>
<script>
import AgreementService from "@/services/AgreementService";
import CirclesLoader from "@/components/displays/CirclesLoader.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

export default {
  data() {
    return {
      agreement: null,
      loading: true,
      acceptSwitch: false,
      iframeHeight: 'height:500px'
    };
  },
  components: {
    CirclesLoader,
    BackButton
  },
  created() {
    AgreementService.latest()
      .then(data => {
        if (data) {
          this.loading = false;
          this.agreement = data;
        }
      });
    if (this.$vuetify.breakpoint.name == 'xs' || 
      this.$vuetify.breakpoint.name == 'sm')
      this.iframeHeight = 'height:180px';
  },
  methods: {
    onScroll (e) {
      console.log(e.target.scrollTop);
    },
    agree(){
      AgreementService.acceptAgreement(this.agreement._id)
        .then(data => {
          if (data) {
            localStorage.hasAgreements = true;
            if(!this.$store.state.user.academyId)
              this.navigateTo({name: 'edithistory'});
            else
              this.navigateTo({name: 'home'});
          }
        });
    }
  },
};
</script>

<style scoped>
.agreement_iframe{
  background-color: white;
  width: 100%;
  max-height: 400px;
}
</style>