<template>
    <IconCompMedal :size="iconSize" v-if="medal == 1" :color="themeColor('gold')"></IconCompMedal>
    <IconCompMedal :size="iconSize" v-else-if="medal == 2" :color="themeColor('silver')"></IconCompMedal>
    <IconCompMedal :size="iconSize" v-else-if="medal == 3" :color="themeColor('bronze')"></IconCompMedal>
</template>

<script>
import IconCompMedal from "@/components/displays/icons/IconCompMedal";

export default {
    props: [
        'medal',
        'count',
        'size'
    ],
    data () {
        return {
            iconSize: "32px"
        }
    },
    created(){
        if(this.size)
            this.iconSize = this.size;
    },
    components: {
        IconCompMedal
    }
}
</script>

<style scoped>
</style>