<template>
  <svg :width="size ? size : '29px'" height="22px" viewBox="0 0 29 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>RNC</title>
    <g id="App-Design-2022" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="BJJLINK---Menu-icons" transform="translate(-66.000000, -712.000000)">
            <g id="Choke" transform="translate(67.000000, 713.000000)">
                <path d="M5.27585513,17.25 L2.18227958,17.25 L2.18227958,15.75 C2.18227958,14.507325 3.01330678,13.5 4.03842491,13.5 C4.62967526,13.5 8.59236368,13.7897145 8.93227958,14.3117895" id="Path" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M20.4801264,19.3451187 C20.0903839,19.4046263 19.6967405,18.920039 19.2991962,17.8913569 C19.2991962,16.8972169 19.8532144,16.0913569 20.5366265,16.0913569 C20.9307934,16.0913569 23.705669,16.7662978 23.9322796,17.1839578" id="Path-Copy-10" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" transform="translate(21.615738, 17.720678) scale(-1, -1) translate(-21.615738, -17.720678) "></path>
                <path d="M12.2671875,18.107175 C12.820125,16.72575 14.171115,15.75 15.75,15.75 C17.3289,15.75 18.679875,16.72575 19.23285,18.107175" id="Path" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M8.87130004,8.87130004 C7.69972503,10.042875 5.80027502,10.042875 4.62867752,8.87130004 M13.5,6.75000003 C13.5,10.47795 10.47795,13.5 6.75000003,13.5 C3.02208001,13.5 0,10.47795 0,6.75000003 C0,3.02208001 3.02208001,8.52651283e-14 6.75000003,8.52651283e-14 C10.47795,8.52651283e-14 13.5,3.02208001 13.5,6.75000003 Z" id="Shape" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M18.1287225,12.8787 C19.300275,11.707125 21.1998,11.707125 22.371375,12.8787 M27,9.75000003 C27,13.47795 23.97795,16.5 20.25,16.5 C16.52208,16.5 13.5,13.47795 13.5,9.75000003 C13.5,6.02208001 16.52208,3 20.25,3 C23.97795,3 27,6.02208001 27,9.75000003 Z" id="Shape" :stroke="iconColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <ellipse id="Oval-Copy" :stroke="iconColor" stroke-width="2" transform="translate(12.000000, 14.625000) rotate(43.000000) translate(-12.000000, -14.625000) " cx="12" cy="14.625" rx="2.25" ry="2.625"></ellipse>
                <circle id="Oval" :fill="iconColor" cx="4.49999999" cy="5.24999999" r="1"></circle>
                <circle id="Oval-Copy-3" :fill="iconColor" cx="18" cy="8.24999999" r="1"></circle>
                <circle id="Oval-Copy-2" :fill="iconColor" cx="8.99999999" cy="5.24999999" r="1"></circle>
                <circle id="Oval-Copy-4" :fill="iconColor" cx="22.5" cy="8.24999999" r="1"></circle>
            </g>
        </g>
    </g>
  </svg>
</template>
<script>
//:fill="iconColor"
export default {
  data() {
    return {
      iconColor: "gray",
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
  },
  props: ['color','size'],
  watch: {
    color: {
      handler: function (newValue) {
        this.iconColor = this.getColor(newValue);
      },
    },
  },
};
</script>
<style>
</style>