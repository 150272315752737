<template>
  <div v-if="!$store.state.hidden">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2">
      <v-card-title class="text-subtitle-2 pa-0">
        {{ $t("message.filter_users") }}
      </v-card-title>
      <v-container>
        <v-row class="mt-0 pt-0 pb-0 mb-0">
          <v-col>
            <v-text-field
              dense
              outlined
              v-model="nameFilter"
              :label="$t('message.name')"
              :clearable="true"
            ></v-text-field>
          </v-col>
          <v-col>
            <known-cities-select
              v-model="cityFilter"
              :label="$t('message.training_city')"
            >
            </known-cities-select>
          </v-col>
        </v-row>
        <v-expansion-panels flat>
          <v-expansion-panel :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
            <v-expansion-panel-header outlined>
              <div>
                <FilterIcon size="20" class="hero-icon"></FilterIcon>
                {{ $t("message.more_filters") }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col>
                  <countries-select
                    v-model="nationalityFilter"
                    :label="$t('message.nationality')"
                  />
                </v-col>
                <v-col>
                  <jjtags-select
                    v-model="jjtagFilter"
                    :label="$t('message.jjtags')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <specialties-select
                    :label="$t('message.specialty')"
                    v-model="specialtyFilter"
                  />
                </v-col>
                <v-col>
                  <belts-select :show-icon="false" v-model="beltFilter">
                  </belts-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <interests-select :show-icon="false" v-model="interestFilter" :label="$t('message.interests')">
                  </interests-select>
                </v-col>
                <v-col class="text-center">
                  <v-btn small @click="clearFilters()" color="error">
                    {{$t("message.clear_filters")}}
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2 mt-2">
      <PodiumDisplay
        height="190px"
        :title="podiumTitle" 
        :secondary-title="$t('message.last_30_days')"
        v-if="topUsers" 
        :display-avatars="true"
        :podium-action="goToPodiumUser"
        :display-scores="true" 
        score-text="JJP"
        :data="topUsers">
      </PodiumDisplay>
    </v-sheet>
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2 mt-2" v-if="showSuggestions">
      <v-subheader>
        {{ $t("message.suggested_jitsers") }}
      </v-subheader>
      <template v-for="(u, index) in suggested">
            <v-list-item three-lines :key="u._id + index" @click="goToUser(u._id)">
              <v-list-item-avatar :tile="true">
                <UserAvatar 
                  :user="u" 
                  size="30" 
                  :small-badge="true"
                  :has-progress="false" >
                </UserAvatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ u.fName + " " + u.lName }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="!u.notBjjUser">
                  <belt-display size="15" :belt="u.belt" :show-text="true">
                  </belt-display>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="u.academyName">
                  <LibraryIcon class="hero-icon" size="20"></LibraryIcon>
                  {{ u.academyName }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <link-button
                  v-if="$store.state.user._id != u._id"
                  :linked="false"
                  :userId="u._id"
                  :small="true"
                >
                </link-button>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index + 1 < suggested.length"
              :key="index"
            ></v-divider>
      </template>
    </v-sheet>
    <v-sheet class="pa-2 mt-2" :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="jitsers && jitsers.length > 0">
      <template v-for="(u, index) in jitsers">
        <v-list-item three-line :key="u._id + index" @click="goToUser(u._id)">
          <v-list-item-avatar :tile="true">
            <UserAvatar 
              :user="u" 
              size="30" 
              :small-badge="true"
              :has-progress="false" >
            </UserAvatar>
          </v-list-item-avatar>   
          <v-list-item-content>
            <v-list-item-title>
              {{ u.fName + " " + u.lName }}
            </v-list-item-title>
            <v-list-item-subtitle>
                <belt-display
                  v-if="!u.notBjjUser"
                  size="15"
                  :belt="u.belt"
                  :show-text="true"
                >
                </belt-display>
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="u.academyName">
              <LibraryIcon class="hero-icon" size="20"></LibraryIcon>
              {{ u.academyName }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <link-button
              v-if="$store.state.user._id != u._id"
              :linked="u.alreadyLinked"
              :userId="u._id"
              :small="true"
            >
            </link-button>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="index + 1 < jitsers.length" :key="index"></v-divider>
      </template>
      <div class="text-center">
        <v-btn small @click="searchMoreUsers" color="primary" :disabled="!viewMore">{{
          $t("message.view_more")
        }}</v-btn>
      </div>
    </v-sheet>
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
    ></v-skeleton-loader>
  </div>
</template>
<script>
import UserService from "@/services/UserService";
import KnownCitiesSelect from "@/components/inputs/KnownCitiesSelect.vue";
import SpecialtiesSelect from "@/components/inputs/SpecialtiesSelect.vue";
import BeltsSelect from "@/components/inputs/BeltsSelect.vue";
import InterestsSelect from "@/components/inputs/InterestsSelect.vue";
import BeltDisplay from "@/components/displays/BeltDisplay.vue";
import CountriesSelect from "@/components/inputs/CountriesSelect.vue";
import JjtagsSelect from "@/components/inputs/JiuJitsuTagSelect.vue";
import LinkButton from "@/components/displays/LinkUserButtonDisplay.vue";
import UserAvatar from "@/components/displays/UserAvatarDisplay.vue";
import PodiumDisplay from "@/components/displays/PodiumDisplay.vue";

import { 
  FilterIcon,
  LibraryIcon
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      jitsers: [],
      loading: false,
      loadingMore: false,
      cityFilter: null,
      nameFilter: null,
      beltFilter: null,
      interestFilter: null,
      nationalityFilter: null,
      specialtyFilter: null,
      jjtagFilter: null,
      pointer: 0,
      podiumTitle: this.$t("message.top_users")+': BJJLINK™',
      viewMore: false,
      alreadyLinked: false,
      suggested: [],
      showSuggestions: false,
      onload: true,
      topUsers: null
    };
  },
  components: {
    KnownCitiesSelect,
    BeltDisplay,
    BeltsSelect,
    JjtagsSelect,
    SpecialtiesSelect,
    CountriesSelect,
    LinkButton,
    UserAvatar,
    InterestsSelect,
    PodiumDisplay,
    FilterIcon,
    LibraryIcon
  },
  created() {
    this.getPodium();
    UserService.suggested()
      .then((data) => {
        if (data) {
          if (data.length > 0) {
            this.showSuggestions = true;
            this.suggested = data;
          }
        }
      });
    //this.filterUsers();
  },
  methods: {
    goToPodiumUser(p){
      UserService.logActivity({
        activityType: "clicked_user_podium",
        value: p.id,
      });
      this.goToUser(p.id);
    },
    clearFilters() {
      this.cityFilter = null;
      this.nameFilter = null;
      this.beltFilter = null;
      this.interestFilter = null;
      this.nationalityFilter = null;
      this.specialtyFilter = null;
      this.showSuggestions = true;
      this.getPodium();
    },
    searchMoreUsers() {
      this.filterUsers(true);
    },
    getCountryName(code) {
      var c = this.getCountry(code);
      if (c) return c.country;
    },
    goToUser(id) {
      this.navigateTo({ name: "viewprofile", params: { userId: id } });
    },
    getPodium(group){
      let params = {};
      if(group == 'city'){
        params["city"] = this.cityFilter;
        this.podiumTitle = this.$t("message.top_users")+': '+this.$t("message.training_city");
      }else if(group == 'nationality'){
        params["nationality"] = this.nationalityFilter;
        this.podiumTitle = this.$t("message.top_users")+': '+this.$t("message.nationality");
      }else if(group == 'belt'){
        params["belt"] = this.beltFilter;
        this.podiumTitle = this.$t("message.top_users")+': '+this.$t("message.belt");
      }else if(group == 'speciality'){
        params["specialty"] = this.specialtyFilter;
        this.podiumTitle = this.$t("message.top_users")+': '+this.$t("message.speciality");
      }else if(group == 'interests'){
        params["interests"] = this.interestFilter;
        this.podiumTitle = this.$t("message.top_users")+': '+this.$t("message.interests");
      }else if(group == 'jjTags'){
        if (this.jjtagFilter && this.jjtagFilter.length > 0){
          params["jjTags"] = this.jjtagFilter;
          this.podiumTitle = this.$t("message.top_users")+': '+this.$t("message.jjtags");
        }
      }else{
        this.podiumTitle = this.$t("message.top_users")+': BJJLINK™';
      }
      this.topUsers = null;
      UserService.podium(params)
          .then((data) => {
            if (data) {
              this.topUsers = this.getPodiumData(data.topUsers);
            }
          })
          .catch(() => {
            this.loading = false;
          });
    },
    getPodiumData(data){
      if(data && data.length == 3){
        let podiumData = [];
        podiumData.push({
          name: data[1].displayName,
          id: data[1]._id,
          place: 2,
          avatar: data[1].userImageSmall,
          points: data[1].jjPoints,
          badges: data[1].verifiedBelts,
          belt: data[1].belt,
          verifiedBelts: data[1].verifiedBelts,
          userImageSmall: data[1].userImageSmall,
        });
        podiumData.push({
          name: data[0].displayName,
          id: data[0]._id,
          place: 1,
          avatar: data[0].userImageSmall,
          points: data[0].jjPoints,
          badges: data[0].verifiedBelts,
          belt: data[0].belt,
          verifiedBelts: data[0].verifiedBelts,
          userImageSmall: data[0].userImageSmall,
        });
        podiumData.push({
          name: data[2].displayName,
          id: data[2]._id,
          place: 3,
          avatar: data[2].userImageSmall,
          points: data[2].jjPoints,
          badges: data[2].verifiedBelts,
          belt: data[2].belt,
          verifiedBelts: data[2].verifiedBelts,
          userImageSmall: data[2].userImageSmall,
        });
        return podiumData;
      }
    },
    filterUsers(append) {
      this.onload = false;
      this.showSuggestions = false;
      if (!this.loading) {
        this.viewMore = false;
        if (!append) {
          this.jitsers = [];
          this.pointer = 0;
        }
        this.loading = true;
        var params = {};
        if (this.cityFilter != null && this.cityFilter != "")
          params["city"] = this.cityFilter;
        if (this.nameFilter && this.nameFilter != "")
          params["name"] = this.nameFilter;
        if (this.beltFilter && this.beltFilter != "")
          params["belt"] = this.beltFilter;
        if (this.interestFilter && this.interestFilter != "")
          params["interests"] = this.interestFilter;
        if (this.specialtyFilter && this.specialtyFilter != "")
          params["specialty"] = this.specialtyFilter;
        if (this.nationalityFilter && this.nationalityFilter != "")
          params["nationality"] = this.nationalityFilter;
        if (this.jjtagFilter && this.jjtagFilter.length > 0)
          params["jjtags"] = this.jjtagFilter;
        params["pointer"] = this.pointer;
        UserService.users(params)
          .then((data) => {
            if (data) {
              this.jitsers = this.jitsers.concat(data.users);
              this.loading = false;
              this.pointer = this.pointer + data.count;
              if (data.count >= this.searchesLimit) this.viewMore = true;
              //Loop and add already linked
              for (var u of this.jitsers) {
                if (u.linkedBy) {
                  var i = u.linkedBy.indexOf(this.$store.state.user._id);
                  if (i >= 0) u.alreadyLinked = true;
                  else u.alreadyLinked = false;
                }
              }
            }
          })
          .catch(() => {
            this.loading = false;
            this.jitsers = [];
          });
      }
    },
  },
  watch: {
    nameFilter: {
      handler: function () {
        this.pointer = 0;
        this.filterUsers();
      },
    },
    cityFilter: {
      handler: function (newValue) {
        this.pointer = 0;
        if(newValue)
          this.getPodium('city');
        else
          this.getPodium();
        this.filterUsers();
      },
    },
    nationalityFilter: {
      handler: function (newValue) {
        this.pointer = 0;
        if(newValue)
          this.getPodium('nationality');
        else
          this.getPodium();
        this.filterUsers();
      },
    },
    beltFilter: {
      handler: function (newValue) {
        this.pointer = 0;
        if(newValue)
          this.getPodium('belt');
        else
          this.getPodium();
        this.filterUsers();
      },
    },
    interestFilter: {
      handler: function (newValue) {
        this.pointer = 0;
        if(newValue)
          this.getPodium('interests');
        else
          this.getPodium();
        this.filterUsers();
      },
    },
    specialtyFilter: {
      handler: function (newValue) {
        this.pointer = 0;
        if(newValue)
          this.getPodium('speciality');
        else
          this.getPodium();
        this.filterUsers();
      },
    },
    jjtagFilter: {
      handler: function (newValue) {
        this.pointer = 0;
        if(newValue)
          this.getPodium('jjTags');
        else
          this.getPodium();
        this.filterUsers();
      },
    },
  },
};
</script>

<style scoped>
</style>
