<template>
  <v-card class="pa-1 elevation-0">
    <v-card-title class="text-subtitle-2">
      <v-icon class="mr-2" size="20">mdi-chat-question-outline</v-icon>{{poll.question}}
      <v-spacer></v-spacer>
      <v-btn
        small
        icon
        v-if="canViewDetails"
        @click="viewDetails()"
      >
        <v-icon>mdi-chart-box-outline</v-icon>
      </v-btn>
    </v-card-title>
    <v-list v-if="responses && responses.length > 0" style="width:100%">
      <v-list-item 
        class=""
        :key="'poll_answer_'+poll._id+'_'+index"
        v-for="(r, index) in responses">
        <v-list-item-action>
          <v-radio-group v-model="selectedAnswer">
            <v-radio
              :value="index"
              @click="selectAnswer(index)"
            ></v-radio>
          </v-radio-group>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{r.option}}
          </v-list-item-title>
          <v-list-item-title v-if="r.percent != null">
            <v-progress-linear
              v-if="poll.winning == index"
              :color="getColor('green')"
              height="25"
              :value="r.percent"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
            <v-progress-linear
              v-else
              :color="getColor('blue')"
              height="25"
              :value="r.percent"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog
      v-model="detailsDialog"
      fullscreen
      hide-overlay

    >
      <v-card>
        <v-toolbar color="primary" dense flat>
          <v-toolbar-title class="text-h6">
            <v-icon size="20" class="mr-2">mdi-chat-question-outline</v-icon>{{poll.question}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon class="elevation-0" 
            color="secondary" 
            @click.stop="detailsDialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="text-left" v-if="availableAnswers">
          <v-select
            class="mt-2"
            @change="getAnswerDetails()"
            :items="availableAnswers"
            v-model="selectedAnswerDetails"
            :label="$t('message.answer')"
            item-text="text"
            item-value="value"
            outlined
            dense
            :clearable="false">
          </v-select>
        </v-card-text>
        <v-card-text v-if="answerUsers" class="text-left">
          <v-list v-if="answerUsers && answerUsers.length > 0">
            <template v-for="(u, index) in answerUsers">
              <v-list-item 
                @click="goToUser(u._id)"
                :key="'poll_answer_'+poll._id+'_'+u._id"
                >
                <v-list-item-avatar>
                  <img v-if="u.userImageSmall" :src="userImg(u.userImageSmall)">
                  <img v-else :src="assets('blank-profile-picture.png')">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{u.displayName}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index + 1 < answerUsers.length" :key="'divider_'+index"></v-divider>
            </template>
          </v-list>
          <div v-else>
            <h3>{{$t('message.no_responses')}}</h3>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-skeleton-loader
            class="mx-auto"
            type="list-item-avatar, list-item-avatar"
          ></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import PostService from "@/services/PostService";

export default {
  props: ["poll", "moderator"],
  data() {
    return {
      selectedAnswer: null,
      responses: null,
      detailsDialog: false,
      answerUsers: null,
      canViewDetails: false,
      availableAnswers:  null,
      selectedAnswerDetails: 0
    };
  },
  created() {
    if (this.$store.state.user._id == this.poll.user) 
      this.canViewDetails = true;
    else if (this.moderator)
      this.canViewDetails = true;
    if(this.poll && this.poll.userAnswer != null)
      this.selectedAnswer = this.poll.userAnswer;
    this.responses = this.poll.responses;
    const tmp = [];
    let i = 0;
    if(this.poll.responses && this.poll.responses.length > 0){
      for(const r of this.poll.responses){
        tmp.push({text: r.option, value:i});
        i++;
      }
    }
    this.availableAnswers = tmp;
  },
  components:{
  },
  methods: {
    viewDetails(){
      this.detailsDialog = true;
      this.getAnswerDetails();
    },
    getAnswerDetails(){
      PostService.answerDetails(this.poll._id, this.selectedAnswerDetails)
        .then(data => {
          if (data && data.users){
            this.answerUsers = data.users;
          }
        });
    },
    selectAnswer(index){
      this.answerUsers = null;
      this.selectedAnswer = index;
      const data = {
        value: index
      }
      PostService.answerPoll(this.poll._id, data)
        .then(data => {
          if (data && data.poll){
            if(data.poll.userAnswer != null){
              this.selectedAnswer = data.poll.userAnswer;
            }
            this.responses = null;
            this.responses = data.poll.responses;
          }
        });
    }
  }
};
</script>

<style scoped>
</style>
