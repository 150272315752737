<template>
  <v-combobox
    v-model="selectedValue"
    :items="weights"
    :hide-no-data="!search"
    :search-input.sync="search"
    small-chips
    outlined
    dense
    :label="labelText" 
    @input="event => { $emit('input', event) }"
  >
    <template slot="no-data">
      <v-list-item>
        <span class="subheading">{{ $t('message.click_enter_add_new') }}: </span>
          <v-chip
            label
            small
          >
            {{ search }}
          </v-chip>
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  data() {
    return {
      selectedValue: null,
      weights: [],
      loading: false,
      items: [],
      search: null,
      labelText: null
    };
  },
  created() {
    if(this.label)
      this.labelText = this.label;
    else
      this.labelText = this.$t('message.weight');
    UserService.weightClasses()
      .then(data => {
        if (data) {
          this.weights = data.sort();
        }
      });
  },
  mounted(){
    this.selectedValue = this.value
  },
  props: {
    value: null,
    showIcon: {type:Boolean, default:false},
    label: null
  },
  watch: {
    search(val) {
      val && this.querySelections(val);
    },
    value: {
      handler: function(newValue) {
        if(newValue && newValue.length > 1)
          newValue = newValue.charAt(0).toUpperCase() + newValue.substr(1).toLowerCase();
        else if(newValue)
          newValue = newValue.charAt(0).toUpperCase();
        this.selectedValue = newValue;
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    async querySelections(v) {
      this.loading = true;
      if (this.weights.length == 0) {
        var data = await UserService.weightClasses();
        this.weights = data.sort();
      }
      this.items = this.weights.filter(e => {
        return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
      });
      this.loading = false;
    }
  }
};
</script>

<style scoped>
</style>
