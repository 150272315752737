<template>
  <svg :fill="iconColor" :width="iconSize" :height="iconSize" id="icon_oss" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
    <title>OSS</title>
    <g>
      <path d="M26.69,29.57c1.13,0.2,2.08,0.76,2.85,1.66c0.77,0.91,1.15,1.95,1.15,3.12v4.54v1.81v0.12v21.85v4.54
        c0,1.17-0.38,2.2-1.15,3.09c-0.77,0.89-1.71,1.43-2.85,1.63H15.5c-1.13-0.2-2.08-0.74-2.85-1.63c-0.77-0.89-1.15-1.92-1.15-3.09
        V34.35c0-1.17,0.38-2.21,1.15-3.12c0.77-0.91,1.71-1.46,2.85-1.66H26.69z M24.03,40.69v-1.81v-2.66h-5.87v29.05h5.87v-2.66V40.82
        V40.69z"/>
      <path d="M57.68,31.56c0.97,1.17,1.45,2.48,1.45,3.93v4.54v1.21h-1.21h-4.24h-1.21v-1.2v-3.83h-5.15v7.57v0.3h5.72
        c1.7,0,3.14,0.59,4.32,1.75c1.18,1.17,1.76,2.6,1.76,4.29v6.04v9.79c0,1.45-0.48,2.76-1.45,3.92c-0.97,1.09-2.18,1.75-3.63,1.99
        l-0.12,0.06h-0.06h-7.93h-0.06l-0.12-0.06c-1.45-0.24-2.66-0.91-3.63-2c-0.97-1.17-1.45-2.48-1.45-3.94V61.4v-1.21h1.2h4.26h1.2
        v1.21v3.87h5.15V50.75h-5.75c-1.69,0-3.13-0.59-4.3-1.76c-1.17-1.17-1.76-2.6-1.76-4.3v-0.42v-0.55V35.5
        c0-1.45,0.48-2.76,1.45-3.93c0.97-1.09,2.18-1.76,3.63-2h0.12h0.06h7.93h0.06h0.12C55.5,29.81,56.71,30.47,57.68,31.56z"/>
      <path d="M86.13,31.56c0.97,1.17,1.45,2.48,1.45,3.93v4.54v1.21h-1.21h-4.24h-1.21v-1.2v-3.83h-5.15v7.57v0.3h5.72
        c1.7,0,3.14,0.59,4.32,1.75c1.18,1.17,1.76,2.6,1.76,4.29v6.04v9.79c0,1.45-0.48,2.76-1.45,3.92c-0.97,1.09-2.18,1.75-3.63,1.99
        l-0.12,0.06h-0.06h-7.93h-0.06l-0.12-0.06c-1.45-0.24-2.66-0.91-3.63-2c-0.97-1.17-1.45-2.48-1.45-3.94V61.4v-1.21h1.2h4.26h1.2
        v1.21v3.87h5.15V50.75h-5.75c-1.69,0-3.13-0.59-4.3-1.76c-1.17-1.17-1.76-2.6-1.76-4.3v-0.42v-0.55V35.5
        c0-1.45,0.48-2.76,1.45-3.93c0.97-1.09,2.18-1.76,3.63-2h0.12h0.06h7.93h0.06h0.12C83.95,29.81,85.16,30.47,86.13,31.56z"/>
    </g>
  </svg>
</template>
<script>
export default {
  data() {
    return {
      iconColor: "gray",
      iconSize: "32px"
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
    if(this.size)
      this.iconSize = this.size;
  },
  props: ['color','size'],
  watch: {
    color: {
      handler: function(newValue) {
        if(newValue)
          this.iconColor = this.getColor(newValue);
      }
    }
  }
};
</script>
<style>
  svg {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -6px; 
  }
</style>