<template>
    <v-avatar
        size="36px"
        class="grey lighten-4">
        <img v-if="!$store.state.userImage" :src="assets('blank-profile-picture.png')" alt="avatar">
        <img v-else :src="userImg(this.$store.state.userImage)" alt="avatar">
    </v-avatar>
</template>

<script>
export default {
    props: [
    ],
    data () {
        return {
        }
    },
    methods: {
        
    },
    computed: {
    }
}
</script>

<style scoped>
    .current_user_avatar{
        vertical-align: top;
        margin-right: 5px;
    }
</style>
