import Api from "@/services/Api";

export default {
  async videos(index, data) {
    const response = await Api().post("videos/get?index=" + index, data);
    return response.data;
  },
  async managedAcademyVideos(index, data) {
    const response = await Api().post(
      "videos/managed-academy?index=" + index,
      data
    );
    return response.data;
  },
  async eleateVideos() {
    const response = await Api().get("videos/elevate");
    return response.data;
  },
  async academyVideos(id) {
    const response = await Api().get("videos/academy/" + id);
    return response.data;
  },
  async academyVideosCount(id) {
    const response = await Api().get("videos/academy/" + id + "/count");
    return response.data;
  },
  async video(id) {
    const response = await Api().get("videos/" + id);
    return response.data;
  },
  async initialize(data) {
    const response = await Api().post("videos/initialize", data);
    return response.data;
  },
  async addVideo(data) {
    const response = await Api().post("videos/", data);
    return response.data;
  },
  async updateVideo(values) {
    const response = await Api().patch("videos/", values);
    return response.data;
  },
  async deleteVideo(id) {
    const response = await Api().delete("videos/" + id);
    return response.data;
  },
  async tags(academyOnly) {
    const response = await Api().get(
      "videos/get/tags?" + (academyOnly ? "academy-only=true" : "")
    );
    return response.data;
  },
  async categories(academyOnly) {
    const response = await Api().get(
      "videos/get/categories?" + (academyOnly ? "academy-only=true" : "")
    );
    return response.data;
  },
  async likeVideo(id) {
    try {
      const response = await Api().patch("videos/like/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async unlikeVideo(id) {
    try {
      const response = await Api().patch("videos/unlike/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async viewLikes(id) {
    try {
      const response = await Api().get("videos/likes/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async getComments(id) {
    try {
      const response = await Api().get("videos/comments/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async addComment(id, formData) {
    try {
      const response = await Api().post("videos/comments/" + id, formData);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async updateComment(id, contents) {
    try {
      const response = await Api().patch("videos/comments/" + id, {
        contents: contents,
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async deleteComment(cid) {
    try {
      const response = await Api().delete("videos/comments/" + cid);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async reportAbuse(id) {
    const response = await Api().get("videos/report/" + id);
    return response.data;
  },
  async viewVideo(id) {
    const response = await Api().patch("videos/view/" + id);
    return response.data;
  },
};
