<template>
  <v-autocomplete
    @input="event => { emitChange(event) }"
    v-model="selectedSpecialty"
    :items="specialties"
    :label="labelText" 
    clearable
    dense
    outlined
  ></v-autocomplete>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  data() {
    return {
      selectedSpecialty: null,
      specialties: null,
      labelText: null
    }
  },
  created() {
    if(this.label)
      this.labelText = this.label;
    UserService.specialties()
      .then(data => {
        if (data) {
          this.specialties = data.sort();
        }
      });
  },
  props: {
    value: null,
    label: null
  },
  watch: {
      value: {
      immediate: true,
      handler: function (newValue) {
        this.selectedSpecialty = newValue
      }
    }
  },
  methods: {
    emitChange (event) {
      this.$emit('input', event)
    }
  }
};
</script>

<style scoped>
</style>