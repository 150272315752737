import Api from "@/services/Api";

export default {
  async initializeSettings(data) {
    const response = await Api().patch("users/init/settings", data);
    return response.data;
  },
  async initMe() {
    const response = await Api().get("users/init/me");
    return response.data;
  },
  async toggleTheme() {
    const response = await Api().patch("users/theme/toggle");
    return response.data;
  },
  async user(id, simple) {
    const response = await Api().get(
      "users/" + id + (simple ? "?simple=true" : "")
    );
    return response.data;
  },
  async suggested() {
    const response = await Api().get("users/get/suggested");
    return response.data;
  },
  async userWithUpdate(id) {
    const response = await Api().get("users/" + id + "?update=true");
    return response.data;
  },
  async users(data) {
    const response = await Api().post("users", data);
    return response.data;
  },
  async myAcademy() {
    const response = await Api().get("users/myacademy");
    return response.data;
  },
  async podium(data) {
    const response = await Api().post("users/tops", data);
    return response.data;
  },
  async academyPodium() {
    const response = await Api().get("users/academy/tops");
    return response.data;
  },
  async pointsHistory(index) {
    const response = await Api().get("users/points/history?index=" + index);
    return response.data;
  },
  async updateUser(values) {
    const response = await Api().patch("users/", values);
    return response.data;
  },
  async updatePassword(values) {
    const response = await Api().patch("users/password", values);
    return response.data;
  },
  async uploadProfileImage(id, formData) {
    const response = await Api().post("users/profileimg/" + id, formData);
    return response.data;
  },
  async history(id) {
    const response = await Api().get("users/history/" + id);
    return response.data;
  },
  async verifications(id) {
    const response = await Api().get("users/verifications/" + id);
    return response.data;
  },
  async initVerification(data) {
    const response = await Api().post("users/verifications/init", data);
    return response.data;
  },
  async updateHistory(uid, hid, data) {
    const response = await Api().patch(
      "users/history/" + uid + "/" + hid,
      data
    );
    return response.data;
  },
  async addHistory(uid, data) {
    const response = await Api().post("users/history/" + uid, data);
    return response.data;
  },
  async deleteHistory(hid) {
    const response = await Api().delete("users/history/" + hid);
    return response.data;
  },
  async linkWithUser(uid) {
    const response = await Api().post("users/link/" + uid);
    return response.data;
  },
  async unlinkWithUser(uid) {
    const response = await Api().post("users/unlink/" + uid);
    return response.data;
  },
  async getLinkedBy(uid) {
    const response = await Api().get("users/linkedby/" + uid);
    return response.data;
  },
  async filterLinks(filter) {
    const response = await Api().get(
      "users/filter/links?" +
        (filter ? "filter=" + encodeURIComponent(filter) : "")
    );
    return response.data;
  },
  async filterMutualLinks(filter) {
    const response = await Api().get(
      "users/filter/links/mutual?" +
        (filter ? "filter=" + encodeURIComponent(filter) : "")
    );
    return response.data;
  },
  async federations() {
    const response = await Api().get("users/distinct/federations");
    return response.data;
  },
  async specialties() {
    const response = await Api().get("users/distinct/specialties");
    return response.data;
  },
  async weightClasses() {
    const response = await Api().get("users/distinct/weightclasses");
    return response.data;
  },
  async ageGroups() {
    const response = await Api().get("users/distinct/agegroups");
    return response.data;
  },
  async updateEmail(data) {
    return Api().patch("users/email", data);
  },
  async isFBConnected() {
    const response = await Api().get("users/fb/isconnected");
    return response.data;
  },
  async fbConnect(data) {
    const response = await Api().post("users/fb/connect", data);
    return response.data;
  },
  async jiujitsuTags() {
    try {
      const response = await Api().get("users/distinct/jjtags");
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async deleteUser(message) {
    const response = await Api().patch("users/delete", message);
    return response.data;
  },
  async checkins(index) {
    const response = await Api().get("checkins/user?index=" + index);
    return response.data;
  },
  async removeAcademy() {
    const response = await Api().delete("academies/user/current");
    return response.data;
  },
  async checkinAggregates() {
    const response = await Api().get("checkins/user/aggregates");
    return response.data;
  },
  async checkinsByAcademy(userId) {
    const response = await Api().get(
      "checkins/user/" + userId + "/aggregates/by/academy"
    );
    return response.data;
  },
  async updateMemberData(data) {
    const response = await Api().patch("users/memberdata", data);
    return response.data;
  },
  async userImages(userId, index) {
    const response = await Api().get(
      "users/images/" + userId + "?index=" + index
    );
    return response.data;
  },
  async academyMembers(managedAcademyId) {
    const response = await Api().get(
      "users/managedacademy/" + managedAcademyId + "/members"
    );
    return response.data;
  },
  logActivity(data) {
    if (data && data.activityType) {
      data["source"] = "social";
      Api().post("users/activity/" + data.activityType, data);
    }
  },
};
