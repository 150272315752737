<template>
  <div>
    <v-btn elevation="0" color="primary" fab small dark @click="displayCropImageDialog">
      <PhotographIcon class="hero-button"></PhotographIcon>
    </v-btn>
    <v-dialog v-model="cropImageDialog" width="99%"  style="margin-top:20px;" dark>
      <v-card style="overflow-x:hidden" class="text-center pa-2">
        <v-card-text>
          <h3 v-if="imageLoaded">{{ $t('message.drag_pinch_to_move') }}</h3>
          <croppa 
            v-model="myCroppa"
            :width="300"
            :height="300"
            :disable-rotation="false"
            @file-choose="handleCroppaFileChoose"
            style="width:300px;height:300px;"
            :placeholder="$t('message.click_to_pick_image')">
          </croppa>
        </v-card-text> 
        <v-card-actions>
          <v-btn fab small @click="rotate(90)" color="#8D70B2" :disabled="!imageLoaded">
            <ReplyIcon class="hero-button"></ReplyIcon>
          </v-btn>
          <span v-if="uploading">uploading...</span>
          <v-spacer></v-spacer>
          <v-btn fab small @click="uploadCroppedImage()" color="success" :disabled="!imageLoaded">
            <CloudUploadIcon class="hero-button"></CloudUploadIcon>
          </v-btn>
          <v-btn fab small @click="closeDialog()" color="error">
            <TrashIcon class="hero-button"></TrashIcon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FileService2 from "@/services/FileService2";

//Icons
import { 
  PhotographIcon,
  CloudUploadIcon,
  ReplyIcon,
  TrashIcon
} from '@vue-hero-icons/outline'

export default {
  components:{
    PhotographIcon,
    CloudUploadIcon,
    ReplyIcon,
    TrashIcon
  },
  data() {
    return {
      image: null,

      imageLoaded: false,
      myCroppa: null,
      cropImageDialog: false,
      uploadingMessage: null,
      resize: false,
      callWhenDone: null,
      uploading: false,
      onUploadProgress: function() {}
    };
  },
  props: ["successFunction", "objId","operation","vm","enableResize","addSmall"],
  watch: {},
  created() {
    this.callWhenDone = this.successFunction;
    if(this.enableResize)
      this.resize = this.enableResize;
  },
  methods: {
    uploadCroppedImage() {
      this.myCroppa.generateBlob(
        blob => {
          this.uploading = true;
          this.uploadImage(blob);
        },
        'image/jpeg',
        0.8
      ); // 80% compressed jpeg file
    },
    handleCroppaFileChoose(){
      this.imageLoaded = true;
    },
    onImageChange(){

    },
    closeDialog() {
      this.cropImageDialog = false;
      this.myCroppa.remove();
      this.imageLoaded = false;
    },
    rotate() {
      this.myCroppa.rotate(-1);
    },


    getCroppieViewport(){
      if (this.$vuetify.breakpoint.name == 'xs' || 
        this.$vuetify.breakpoint.name == 'sm'){
        this.croppieViewport = { width: 200, height: 200, type:'square'};
        this.croppieViewport = { width: 250, height: 250};
      }
    },
    displayCropImageDialog() {
      this.cropImageDialog = !this.cropImageDialog;
    },
    async uploadImage(blob) {
      //PUSH
      try{
        const d = new Date();
        const formData = new FormData();
        this.uploadingMessage = this.$t('message.image_upload_progress');
        formData.append("image", blob, 'uploaded-image-'+d.toISOString());
        if(this.addSmall)
          formData.append("addSmall", true);
        FileService2.uploadImage(formData, this.onUploadProgress)
        .then(res => {
          this.callWhenDone(res.file, this.vm);
        })
        .catch(err => {
          console.log(err);
        });
      }catch(err){
        console.log('WTF?????', err);
        this.showAlert('error',this.$t('message.oops_not_working'));
      }
      this.closeDialog();
    }
  }
};
</script>

<style scoped>

</style>