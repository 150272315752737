<template>
  <div v-if="$store.state.isUserLoggedIn && !$store.state.hidden">
    <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="pa-2" v-show="false">
      <v-card-actions >
        <v-btn color="primary" elevation="0" block small @click="openElevate()">
          <v-icon size="20" class="mr-1">mdi-open-in-new</v-icon>{{ $t("message.open_elevate") }}
        </v-btn>
      </v-card-actions>
    </v-sheet>
    <div class="pa-2">
        <v-skeleton-loader
          v-if="loading"
          class="mx-auto"
          type="card"
         ></v-skeleton-loader>
         <template v-else v-for="(v, index) in videos">
          <v-sheet
            :rounded="isSmallerDisplay() ? '' : 'lg'"
            :key="index + v._id"
            :position="index"
            class="mt-2"
            v-if="v.fileUrl"
          >
            <v-list-item dense>
              <v-list-item-avatar>
                <img v-if="v.user && v.user.userImageSmall" :src="userImg(v.user.userImageSmall)"/>
                <img v-else :src="assets() + '/logos/logo_elevate.png'"/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ v.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ $d(new Date(v.uploaded), "month_day", $i18n.locale) }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="false">
                <v-btn small elevation="0" icon @click="goToElevateVideo(v._id)">
                   <v-icon size="20">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-responsive class="pa-0">
              <video-display :skip-autoplay="true" :video="v"></video-display>
            </v-responsive>
            <v-card-text
              class="text-left"
            >
              <span v-html="truncate(v.description, 500)"></span>
            </v-card-text>
            <div v-if="v.tags && v.tags.length > 0" class="pa-2">
              <template v-for="(t, index2) in r.tags">
                <v-chip small class="text-overline mr-1" :key="v._id + 'tag' + index2">{{ t }}</v-chip>
              </template>
            </div>
            <v-divider></v-divider>
          </v-sheet>
        </template>
      </div>
  </div>
</template>

<script>
  import UserService from "@/services/UserService";
  import VideoService from "@/services/VideoService";
  import VideoDisplay from "@/components/displays/VideoDisplay.vue";

  export default {
    name: 'Home',
    data() {
      return {
        loading: true,
        videos: []
      };
    },
    components: {
      VideoDisplay
    },
    created() {
      VideoService.eleateVideos()
        .then((data) => {
          if (data) {
            this.videos = data.data;
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
          this.result = [];
        });
    },
    methods: {
      goToElevateVideo(id) {
        window.open("https://bjjlink.com/elevate/watch/" + id);
      },
      openElevate(){  
        UserService.logActivity({
          activityType: "clicked_elevate_menu"
        });
        window.open("https://bjjlink.com/elevate");
      }
    }
  }
</script>
<style scoped>

</style>
