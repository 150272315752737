<template>
  <v-flex>
    <v-combobox
      @input="event => { emitChange(event) }"
      :items="items"
      v-model="selectedItems"
      small-chips
      outlined
      multiple
      :label="labelText"
    >
    </v-combobox>
  </v-flex>
</template>

<script>

export default {
  data() {
    return {
      selectedItem: null,
      items: [
        {value:"mentor", text: this.$t('message.interest_mentor')},
        {value:"mentoring", text: this.$t('message.interest_mentoring')},
        {value:"competing", text: this.$t('message.interest_competing')},
        {value:"privates", text: this.$t('message.interest_privates')},
        {value:"teaching", text: this.$t('message.interest_teaching')}
      ],
      labelText: null,
    }
  },
  created() {
    if(this.label)
      this.labelText = this.label;
  },
  props: {
    value: null,
    label: null
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newValue) {
        this.selectedItems = newValue;
      }
    }
  },
  methods: {
    emitChange (event) {
      this.$emit('input', event)
    }
  }
};
</script>

<style scoped>
</style>
