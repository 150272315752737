<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="article"
    ></v-skeleton-loader>
    <div row wrap v-else-if="!loading && (isManager || addingNew)">
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'">
        <v-card-text class="text-center">
          <img :src="eventImage" style="max-width:250px;max-height:250px;">
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <image-upload 
            v-if="isManager || addingNew"
            :objId="eventId"
            :vm="this" 
            :operation="'return_image'"
            :success-function="successFunction">
          </image-upload>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
        <v-card-text v-if="academy" class="text-left">
          <div class="text-caption gray--text">
            {{$t('message.event_behalf_academy')}}
          </div>
          <div>
            <v-avatar size="30px" v-if="academy.image"><img :src="userFile(academy.image)"/></v-avatar>
            <span>{{academy.name}}</span>
          </div>
        </v-card-text>
        <v-card-text>
          <v-text-field
            dense outlined
            v-model="event.title"
            :label="$t('message.title')"
            required
          ></v-text-field>
          <event-types v-model="event.type"></event-types>
          <locations-autocomplete v-model="location" :display="event.location"></locations-autocomplete> 
          <v-text-field
            dense outlined
            v-model="event.url"
            :label="$t('message.website')"
          ></v-text-field>
          <v-text-field
            dense outlined
            v-if="event.type == 'type_virtual'"
            v-model="event.videoConferenceUrl"
            :label="$t('message.video_conference_url')"
          ></v-text-field>
          <v-switch style="margin-top:0px" v-model="freeEvent" :label="$t('message.free_event')"></v-switch>
        </v-card-text>
      </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
        <v-card-title class="text-subtitle-1">
          {{$t('message.date_time')}}
        </v-card-title>
        <v-card-text v-if="event.type == 'type_openmat'">
          <v-select
            :items="days"
            item-text="display"
            item-value="value"
            :label="$t('message.repeat_day')"
            v-model="event.recurrence" 
            @click:clear="clearRecurrence()"
            clearable
            dense
            outlined
          ></v-select>
          <time-picker 
            :show-time="true"
            :hide-date="true"
            v-model="event.eventDate" 
            :past-event="false">
          </time-picker>
        </v-card-text>
        <v-card-text left v-if="event.type == 'type_openmat' && !event.recurrence">
          <span>{{$t('message.or_select_date')}}</span>
        </v-card-text>
        <v-card-text>
          <date-picker 
            v-if="!event.recurrence"
            :show-time="true"
            v-model="event.eventDate" 
            :past-event="false">
          </date-picker>
        </v-card-text>
      </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
        <v-card-text>
          <v-textarea
            outlined
            rows="3"
            name="input-7-4"
            :label="$t('message.description')"
            v-model="event.description"
          ></v-textarea>
        </v-card-text>
      </v-sheet>
      <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" class="mt-2">
        <v-card-actions>
          <v-btn elevation="0" small v-if="eventId == 'new_academy_event'" color="primary" @click="saveUpdateEvent" :disabled="!goodToProceed()">
            {{$t('message.create')}}
          </v-btn>
          <v-btn v-else elevation="0" small color="primary" @click="saveUpdateEvent" :disabled="!goodToProceed()">
            {{$t('message.update')}}
          </v-btn>
          <v-btn elevation="0" small color="secondary" @click="goBack">
            {{$t('message.cancel')}}
          </v-btn>
        </v-card-actions>
      </v-sheet>
    </div>
    <div v-else>
      <h1>You should NOT be here... sorry, brou</h1>
    </div>
  </div>
</template>

<script>
import AcademyService from "@/services/AcademyService";
import CityService from "@/services/CityService";
import EventService from "@/services/EventService";
import ImageUpload from "@/components/inputs/ImageUploadCroppie.vue";
import EventTypes from "@/components/inputs/EventTypesSelect.vue";
import LocationsAutocomplete from "@/components/inputs/LocationsAutocomplete.vue";
import DatePicker from "@/components/inputs/DateTimePicker2.vue";
import TimePicker from "@/components/inputs/DateTimePicker.vue";

export default {
  components: {
    ImageUpload,
    EventTypes,
    LocationsAutocomplete,
    DatePicker,
    TimePicker
  },
  data() {
    return {
      addingNew: false,
      eventId: this.$route.params.eventId,
      academyId: this.$route.params.academyId,
      academy: null,
      loading: true,
      location: null,
      event: null,
      dateFrom: null,
      timeZone: null,
      eventDate: null,
      freeEvent: false,
      eventImage: this.assets('logo-circle.png'),
      isManager: false,
      days: this.getWeekDaysTranslated(),
      successFunction: function(uploadedFile, vm) {
        if (vm && uploadedFile) {
          vm.eventImage = this.userImg(uploadedFile);
          vm.event.image = uploadedFile;
        }
      }
    };
  },
  created() {
    //get events
    this.loading = false;
    if (this.eventId == "new_user_event") {
      this.addingNew = true;
      this.event = {
        userCreator: this.$store.state.user._id
      };
    } else if (this.eventId == "new_academy_event") {
      AcademyService.academy(this.academyId, false)
        .then(data => {
          if(data){
            this.academy = {
              name: data.academy.name,
              image: data.academy.image
            };
          }
        });
      this.addingNew = true;
      this.event = {
        academyCreator: this.academyId
      };
    } else {
      this.getEvent();
    }
  },
  watch: {
    location: {
      handler: function(newValue) {
        if(newValue && !newValue.city){
          this.missingCityError = true;
        }else{
          this.event.geolocated = newValue;
          this.updateTimeZone(newValue);
          this.missingCityError = false;
        }
      }
    }
  },
  methods: {
    updateTimeZone(values){
      if(values && values.longitude && values.latitude){
      CityService.timeZone({
        longitude: values.longitude,
        latitude: values.latitude
      })
        .then(data => {
          if(data && data.timeZone)
            this.event.timeZone = data.timeZone;
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    goodToProceed() {
      if (
        this.event &&
        this.event.title &&
        this.event.type &&
        (this.event.eventDate || this.event.recurrence) &&
        this.event.description
      ) {
        return true;
      } else {
        return false;
      }
    },
    showRemoveManagerConfirm(m) {
      this.futureManager = m;
      this.removeManagerConfirm = true;
    },
    getEvent() {
      EventService.event(this.eventId)
        .then(data => {
          if (data && data.event) {
            this.eventDate = this.adjustTimeToOffset(data.event.date, data.timeZone);
            //this.eventDate = new Date(data.event.date);
            data.event.date = this.eventDate;
            data.event.eventDate = this.eventDate;
            //Same offset calculation
            
            this.event = data.event;
            this.timeZone = data.timeZone;
            this.event.timeZone = data.timeZone;
            if(this.event && this.event.free)
              this.freeEvent = true;
            if (
              this.contains(
                this.event.managers,
                this.$store.state.user._id,
                "_id"
              )
            ) {
              this.isManager = true;
              this.eventImage = this.userImg(this.event.image);
            }
          }
        });
    },
    emitChange(event) {
      this.$emit("input", event);
    },
    clearRecurrence(){
      const thisYear = new Date().getFullYear();
      const d = new Date(this.event.date);
      d.setFullYear(thisYear);
      this.event.date = d;
    },
    saveUpdateEvent() {
      if (this.goodToProceed()) {
        if(this.event.recurrence){
          let d = new Date(this.event.eventDate);
          d.setFullYear(d.getFullYear() + 200);
          this.event.eventDate = d;
        }
        this.event.academyCreator = this.academyId;
        if(this.freeEvent)
          this.event.free = true;
        this.event.date = this.adjustTimeToPost(this.event.eventDate, this.event.timeZone);
        this.event.eventDate = this.event.date;
        this.event.staticDate = this.event.eventDate.toString();
        EventService.saveUpdate(this.event)
          .then(data => {
            if (data) {
              this.navigateTo({
                name: "viewevent",
                params: { eventId: data.event._id }
              });
            }
          })
          .catch(err => {
            if(err)
              this.showAlert(
                "error",
                this.$t('message.oops_not_working')
              );
          });
      }
    }
  }
};
</script>

<style scoped>
</style>
