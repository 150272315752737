<template>
  <v-container justify-center>
    <div v-if="isAppAdmin(this.$store.state.user._id)">
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            @click="navigateTo({ name: 'internalaggregates' })"
            >Social Dashboard</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            @click="navigateTo({ name: 'managedacademiesstats' })"
            color="primary"
            >Admin Dashboard</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            @click="navigateTo({ name: 'adminlineagemanager' })"
            color="tertiary"
            >Lineage Manager</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            @click="navigateTo({ name: 'admin_add_academy' })"
            color="#A97942"
            >Add/Remove Academy</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            color="blue"
            @click="navigateTo({ name: 'managepartnersadmin' })"
            dark
            >Add Partner</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            @click="navigateTo({ name: 'addhelpadmin' })"
            color="error"
            >Add Help</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            @click="navigateTo({ name: 'managereleases' })"
            color="light-green"
            >Add Release</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            @click="navigateTo({ name: 'verifybelts' })"
            color="pink"
            >Verify Belts</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            @click="navigateTo({ name: 'adminusers' })"
            color="gold"
            >Users</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            @click="navigateTo({ name: 'addmanagedacademymembers' })"
            color="deep-purple"
            >Add Managed Academy User</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            block
            @click="navigateTo({ name: 'managebanners' })"
            color="orange"
            >Manage Banners</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions>
            <v-btn
              block
              @click="navigateTo({ name: 'elevateevents' })"
              color="lime"
              >Elevate Events</v-btn
            >
          </v-card-actions>
        <v-card-actions>
          <v-btn
            block
            @click="logout()"
            color="error"
            >Logout</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-dialog v-model="testDialog" max-width="60%">
        <v-card>
          <v-card-title>Test event ICS</v-card-title>
          <v-card-text>
            <v-text-field v-model="testValue" label="Value"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn :disabled="!testValue" color="primary" @click.stop="test()">Try It!</v-btn>
            <v-btn color="secondary" @click.stop="testDialog=false">{{ $t('message.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-else class="text-center">
      <h2>You can't come to these secret places</h2>
      <div>
        <icon-master color="error" :size="200" />
      </div>
    </div>
  </v-container>
</template>

<script>
import EventService from "@/services/EventService";
import IconMaster from "@/components/displays/icons/IconMaster.vue";
import AuthenticationService from "@/services/AuthenticationService";

export default {
  data() {
    return {
      newPartner: {},
      testValue: null,
      testDialog: null
    };
  },
  components: {
    IconMaster,
  },
  methods: {
    logout() {
      AuthenticationService.logout();
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setInApp", null);
      localStorage.removeItem("token");
      window.location.replace("/login");
    },
    addPartner() {},
    test(){
      EventService.getEventIcs(this.testValue)
        .then((response) => {
          let blob = new Blob([response.data], { type: "text/calendar" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = 
          "BJJLINK_Event.ics";
          link.click();
          console.log('Saved!!!')
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style scoped></style>
