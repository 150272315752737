<template>
  <v-avatar 
    :rounded="avatar ? false : true"
    :size="size ? size : defaultSize" 
    v-if="countryCode" 
    :style="borderStyle">
    <v-img :src="imagePath" @error="imageError()" />
  </v-avatar>
</template>

<script>
import countriesJson from "@/assets/data/countries.json";

export default {
  props: ["countryCode","size","avatar", "border"],
  data() {
    return {
      countries: countriesJson,
      showImage: true,
      defaultSize: '20',
    };
  },
  methods: {
    imageError() {
      this.showImage = false;
    },
  },
  computed: {
    imagePath() {
      const cc = this.countryCode.toUpperCase();
      return this.assets("flags/" + cc + ".png");
    },
    borderStyle() {
      if (this.border)
        return "border: 1px solid "+this.border+";";
      else
        return "border: 1px solid black;";
    }
  },
};
</script>

<style scoped>
</style>
