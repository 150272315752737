import Api from "@/services/Api";

export default {
  async count() {
    const response = await Api().get("messages/count");
    return response.data;
  },
  async updateCount() {
    const response = await Api().patch("messages/count");
    return response.data;
  },
  async threads(pointer) {
    const response = await Api().get("messages/threads?pointer=" + pointer);
    return response.data;
  },
  async thread(id) {
    const response = await Api().get("messages/threads/" + id);
    return response.data;
  },
  async filterThreads(data, pointer) {
    const response = await Api().post(
      "messages/threads?pointer=" + pointer,
      data
    );
    return response.data;
  },
  async threadMessages(tid, pointer) {
    const response = await Api().get(
      "messages/thread/" + tid + "?pointer=" + pointer
    );
    return response.data;
  },
  async startThread(data) {
    const response = await Api().post("messages/thread", data);
    return response.data;
  },
  async sendMessage(tid, data) {
    const response = await Api().post("messages/thread/" + tid, data);
    return response.data;
  },
  async readMessages(tid, data) {
    const response = await Api().patch("messages/read/" + tid, data);
    return response.data;
  },
  async deleteThread(id) {
    const response = await Api().delete("messages/thread/" + id);
    return response.data;
  },
  async deleteMessage(id) {
    const response = await Api().delete("messages/message/" + id);
    return response.data;
  },
  async availableRecipients() {
    const response = await Api().get("messages/recipients");
    return response.data;
  },
  async addParticipant(threadId, participantId) {
    const response = await Api().post(
      "messages/thread/" + threadId + "/participant/" + participantId
    );
    return response.data;
  },
  async removeParticipant(threadId, participantId) {
    const response = await Api().delete(
      "messages/thread/" + threadId + "/participant/" + participantId
    );
    return response.data;
  },
  async skipNotifications(threadId) {
    const response = await Api().patch(
      "messages/thread/" + threadId + "/skip/notifications"
    );
    return response.data;
  },
  async updateThreadTitle(threadId, data) {
    const response = await Api().patch(
      "messages/thread/" + threadId + "/title",
      data
    );
    return response.data;
  }
};
