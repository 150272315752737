import Api from "@/services/Api";

export default {
  async journal(index, data) {
    const response = await Api().post("journal/get?index=" + index, data);
    return response.data;
  },
  async classNotes(index, data) {
    const response = await Api().post(
      "journal/classnotes/get?index=" + index,
      data
    );
    return response.data;
  },
  async addJournal(data) {
    const response = await Api().post("journal/", data);
    return response.data;
  },
  async updateJournal(values) {
    const response = await Api().patch("journal/", values);
    return response.data;
  },
  async deleteJournal(id) {
    const response = await Api().delete("journal/" + id);
    return response.data;
  },
  async tags() {
    const response = await Api().get("journal/tags");
    return response.data;
  },
  async classNotesTags() {
    const response = await Api().get("journal/classnotes/tags");
    return response.data;
  },
  async updateCheckin(data) {
    const response = await Api().patch("checkins/user", data);
    return response.data;
  },
  async deleteCheckin(id) {
    const response = await Api().delete("checkins/user/" + id);
    return response.data;
  },
  async addCheckin(data) {
    const response = await Api().post("checkins/user", data);
    return response.data;
  },
  async getCheckinChart(interval) {
    const response = await Api().get("checkins/user/interval/" + interval);
    return response.data;
  },
  async addWorkout(data) {
    const response = await Api().post("checkins/user/workout", data);
    return response.data;
  },
  async workouts(index) {
    const response = await Api().get("checkins/user/workouts?index=" + index);
    return response.data;
  },
  async updateWorkout(data) {
    const response = await Api().patch("checkins/user/workout", data);
    return response.data;
  },
  async deleteWorkout(id) {
    const response = await Api().delete("checkins/user/workout/" + id);
    return response.data;
  },
  async getFlowchart(managedAcademyId, id) {
    const response = await Api().get(
      "flowcharts/users/managedacademy/" + managedAcademyId + "/flowchart/" + id
    );
    return response.data;
  },
};
