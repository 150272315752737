<template>
    <v-responsive v-if="longitude && latitude && imgUrl" class="map-image text-xs-center">
        <v-img 
            style="width: 100%;max-width: 550px"
            class="hidden-md-and-up" 
            :src="imgUrl"
        />
        <v-img 
            style="width: 100%;"
            class="hidden-sm-and-down" 
            :src="imgUrl"
        />
    </v-responsive>
</template>

<script>

export default {
    props: [
        'size',
        'longitude',
        'latitude',
        'showMarker',
        'zoom'
    ],
    data () {
        return {
            icon: this.assets('logo_map.png'),
            key: 'AIzaSyDS7qZboGXsPMwSUxk35eRX7pDu26mU-MI',
            url: 'https://maps.googleapis.com/maps/api/staticmap?',
            imgUrl: null,
            windowWidth: null,
            darkModeStyles: '&style=element%3Ageometry%7Ccolor%3A0x242f3e&style=element%3Alabels.text.stroke%7Ccolor%3A0x242f3e&style=element%3Alabels.text.fill%7Ccolor%3A0x746855&style=feature%3Aadministrative.locality%7Celement%3Alabels.text.fill%7Ccolor%3A0xd59563&style=feature%3Apoi%7Celement%3Alabels.text.fill%7Ccolor%3A0xd59563&style=feature%3Apoi.park%7Celement%3Ageometry%7Ccolor%3A0x263c3f&style=feature%3Apoi.park%7Celement%3Alabels.text.fill%7Ccolor%3A0x6b9a76&style=feature%3Aroad%7Celement%3Ageometry%7Ccolor%3A0x38414e&style=feature%3Aroad%7Celement%3Ageometry.stroke%7Ccolor%3A0x212a37&style=feature%3Aroad%7Celement%3Alabels.text.fill%7Ccolor%3A0x9ca5b3&style=feature%3Aroad.highway%7Celement%3Ageometry%7Ccolor%3A0x746855&style=feature%3Aroad.highway%7Celement%3Ageometry.stroke%7Ccolor%3A0x1f2835&style=feature%3Aroad.highway%7Celement%3Alabels.text.fill%7Ccolor%3A0xf3d19c&style=feature%3Atransit%7Celement%3Ageometry%7Ccolor%3A0x2f3948&style=feature%3Atransit.station%7Celement%3Alabels.text.fill%7Ccolor%3A0xd59563&style=feature%3Awater%7Celement%3Ageometry%7Ccolor%3A0x17263c&style=feature%3Awater%7Celement%3Alabels.text.fill%7Ccolor%3A0x515c6d&style=feature%3Awater%7Celement%3Alabels.text.stroke%7Ccolor%3A0x17263'
        }
    },
    created(){
        this.windowWidth = window.innerHeight;
        if(this.longitude && this.latitude)
            this.imgUrl = this.buildUrl();
    },
    methods: {
        buildUrl () {
            var params = '';
            var coordinate = this.latitude+','+this.longitude;
            if(this.zoom)
                params += 'zoom='+this.zoom;
            else
                params += 'zoom=11';
            if(this.size)
                params += '&size='+this.size;
            else
                params += '&size='+this.sizeForScreenType();
            if(this.showMarker){
                //Create a marker
                params += '&'+encodeURI('markers=anchor:center|icon:'+this.icon+'|'+coordinate);
            }else{
                //Just add center
                params += 'center='+coordinate;
            }
            if(this.$vuetify.theme.dark)
                params+=this.darkModeStyles;
            return this.url + params + '&key='+this.key;    
        },
        sizeForScreenType(){
            if (this.$vuetify.breakpoint.name == 'xs' || 
                    this.$vuetify.breakpoint.name == 'sm')
                return '500x200';
            else
                return this.windowWidth+'x200'
        }
    },
    computed: {
       
    }
}
</script>

<style scoped>
    .map-image{
        max-width:100%;
        height:auto;
        max-height: 310px;
    }
</style>
