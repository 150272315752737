<template>
  <div>
    <div class="pa-2">
        <div v-if="title" class="text-subtitle-2">
          {{title}}
          <span v-if="secondaryTitle" class="ml-3 text-caption">{{secondaryTitle}}</span>
        </div>
    </div>
    <div id="podium-box" class="row" :style="'height: '+boxHeight">
      <div 
        v-for="(d, index) in data" 
        :key="'step_'+index" 
        @click="clickPodium(d)"
        class="col-md-4 step-container ma-0 pa-0">
        <div v-if="displayAvatars">
          <UserAvatar 
            :user="d" 
            size="35" 
            :small-badge="true"
            :has-progress="false" >
          </UserAvatar>
        </div>
        <div>
          <v-icon v-if="d.icon" :size="d.iconSize ? d.iconSize : '20'" :color="getPodiumIconColor(d.place)">{{d.icon}}</v-icon>
          <b>{{truncate(d.name, 17, true)}}</b>
        </div>
        <div v-if="displayScores" class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span 
                v-bind="attrs"
                v-on="on" class="text-subtitle-2">{{d.points}} {{scoreText}}</span>
            </template>
            <span>{{ $t("message.jjp") }}</span>
          </v-tooltip>
        </div>
        <div :id="getStepId(index)" class="podium-bg step centerBoth podium-number" :style="d.color ? 'background-color:'+d.color : '' +';' +getStepRadious(index)">
          <div v-if="index == 0">
            2
          </div>
          <div v-else-if="index == 1">
            1
          </div>
          <div v-else>
            3
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import UserAvatar from "@/components/displays/UserAvatarDisplay";

export default {
    props: [
        'data',
        'icon',
        'title',
        'podiumAction',
        'height',
        'displayAvatars',
        'displayScores',
        'scoreText',
        'secondaryTitle'
    ],
    data () {
        return {
          boxHeight: '150px'
        }
    },
    created(){
      if(this.height)
        this.boxHeight = this.height;
    },
    methods:{
      clickPodium(p){
        if(this.podiumAction)
          this.podiumAction(p);
      },
      getStepId(index){
        if(index == 0)
          return 'second-step';
        else if(index == 1)
          return 'first-step';
        else if(index == 2)
          return 'third-step';
      },
      getStepRadious(step){
        if(step == 0)
          return 'border-radius: 0 0 0 5px;';
        else if(step == 2)
          return 'border-radius: 0 0 5px 0;';
      }
    },
    components: {
      UserAvatar
    }
}
</script>

<style scoped>
#podium-box {
  margin: 0 auto;
  display: flex;
}

.podium-number {
  font-weight: bold;
  font-size: 2.5em;
  color: white;
}

.podium-score {
  background-color: #f5f5f5;
  font-size: 0.8em;
  font-weight: normal;
  width:50%;
  margin-left:auto;
  margin-right: auto;
  border-radius: 6px;
  margin-bottom:2px;
  padding-left:5px;
  padding-right:5px;
  padding-top:2px;
  padding-bottom:2px;
}

.podium-bg{
    background-color: #1B75BC
}

.step-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.step-container>div:first-child {
  margin-top: auto;
  text-align: center;
}

.step {
  text-align: center;
}

#first-step {
  height: 50%;
}

#second-step {
  height: 35%;
}

#third-step {
  height: 30%;
}
</style>