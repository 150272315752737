<template>
    <v-dialog v-model="dialogToggle" max-width="90%" @input="v => v || closeDialog()">
        <v-card outlined class="pa-2">
            <v-card-actions class="pa-2 text-subtitle-1">
                {{$t('message.send_message')}}
                <v-spacer></v-spacer>
                <v-btn @click="closeDialog()" icon dark>
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-actions>
            <v-card-text>
                <links-chips 
                    v-if="!hideChips"
                    ref="recipientsCombo"
                    :user="$store.state.user._id" 
                    :label="$t('message.recipients')"
                    v-model="recipients">
                </links-chips>
                <div v-else class="text-xs-left">
                    <single-chip 
                        :user="recipient" />    
                </div>
            </v-card-text>
            <v-card-text>
                <v-textarea
                    outlined
                    v-model="contents"
                    :label="$t('message.contents')"
                ></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-btn 
                    elevation="0"
                    :disabled="!contents || contents == ''"
                    :color="themeColor('primary')" @click="startThread()">
                    <v-icon class="mr-1">mdi-send-outline</v-icon>
                    {{ $t('message.send') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import MessageService from "@/services/MessageService";
import LinksChips from "@/components/inputs/LinksChips.vue";
import SingleChip from "@/components/displays/SingleChipDisplay.vue";

export default {
    props: [
        'recipient',
        'value'
    ],
    data () {
        return {
            dialogToggle: this.value,
            contents: null,
            recipients: [],
            hideChips: false,
            disableSend: false
        }
    },
    created(){
        this.dialogToggle = this.value;
        if (this.recipient) {
            this.hideChips = true;
            this.recipients = [this.recipient.id];
        }
    },
    components: {
        LinksChips,
        SingleChip,
    },
    methods: {
        closeDialog(){
            this.$emit('input', false);
            this.dialogToggle = false;
            if(this.$refs.recipientsCombo)
                this.$refs.recipientsCombo.clearAll();
        },
        startThread(){
            this.disableSend = true;
            const data = {
                recipients: this.recipients,
                contents: this.contents
            };
            MessageService.startThread(data)
                .then(data => {
                    if (data) {
                        this.disableSend = false;
                        this.showAlert(
                            "success",
                            "Message sent!"
                        );
                        this.contents = null;
                        this.closeDialog();
                    }
                });
        }
    },
    watch: {
        value: {
            handler: function(newValue) {
                this.dialogToggle = newValue;
            }
        },
        recipient: {
            handler: function(newValue) {
                if(newValue){
                    this.hideChips = true;
                    this.recipients = [newValue.id];
                }
            }
        }
    }
}
</script>

<style scoped>
.send-icon{
  transform: rotate(90deg);
}
</style>