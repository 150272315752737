<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="$store.state.isUserLoggedIn" row wrap>
    <BackButton></BackButton>
    <div v-if="loading">
      <v-skeleton-loader
        type="article"
      ></v-skeleton-loader>
    </div>
    <div v-if="!loading && academyClass">
      <v-card elevation="0" class="pa-2">
        <div>
          <v-avatar size="30">
            <img v-if="academy.image" :src="userImg(academy.image)" />
            <img v-else :src="assets('default-empty-academy.png')" />
          </v-avatar>
          <span class="ml-2 text-h6 text-truncate">{{academy.name}}</span>
        </div>
        <div class="text-body-2 mt-2">
          <span class="data-label mr-2 text-subtitle-1">{{$t('message.class')}}:</span>
          {{academyClass.title}}
        </div>
        <div class="text-body-2">
          <span class="data-label mr-2 text-subtitle-1">{{$t('message.time')}}:</span>
          {{ $d(new Date(academyClass.date), 'long', $i18n.locale) }}
        </div>
        <div v-if="!academyClass.disabled">
          <div class="text-body-2" v-if="academyClass.professorUser">
            <span class="data-label mr-2 text-subtitle-1">{{ $t('message.professor') }}:</span>
            {{ academyClass.professorUser.displayName }}
          </div>
          <div class="text-body-2">
            <span class="data-label mr-2 text-subtitle-1">{{ $t('message.participants') }}:</span>
            {{ participantCount }} <span v-if="academyClass.maxCapacity > 0">/{{ academyClass.maxCapacity }}</span>
          </div>
          <div class="text-body-2" v-if="status">
            <span class="mr-2 text-subtitle-1" v-if="status">{{ $t('message.status') }}:</span>
            <div v-if="status == 'pending'">
              <v-icon :color="!inCapacity ? 'error' : ''">mdi-clock-time-five-outline</v-icon>
              {{ $t('message.pending') }}
              <span v-if="!inCapacity">{{ $t('message.in_waitlist') }}</span>
            </div>
            <div v-if="status == 'approved'">
              <v-icon color="success">mdi-checkbox-marked-circle-outline</v-icon>
              {{ $t('message.accepted') }}
            </div>
            <div v-if="status == 'rejected'">
              <v-icon class="error">mdi-close-circle-outline</v-icon>
              {{ $t('message.rejected') }}
            </div>
          </div>
          <div class="text-body-2" v-if="academyClass.notes" style="text-align:left">
            <span class="text-subtitle-1">{{ $t('message.notes') }}:</span>
            {{ academyClass.notes }}
          </div>
          <v-responsive v-if="academyClass.video && academyClass.video.ready" class="pa-0">
            <video-display :video="academyClass.video"></video-display>
          </v-responsive>
          <v-card-text v-if="adminActions">
            <span>
              <v-avatar>
                <img v-if="requestor.userImageSmall" :src="userImg(requestor.userImageSmall)" />
                <img v-else :src="assets('blank-profile-picture.png')" />
              </v-avatar>
              {{ $t('message.user_request_join', [requestor.displayName]) }}
            </span>
            <h1 v-if="!inCapacity">{{ $t('message.waitlist') }}</h1>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="status == 'approved'"
              @click="leaveClassDialog = true"
              small elevation="0"
              color="error"
            >{{ $t('message.leave_class') }}</v-btn>
            <v-btn
              small
              v-if="status == 'pending'"
              elevation="0"
              @click="cancelRequest()"
              color="error"
            >{{ $t('message.cancel_request') }}</v-btn>
            <v-btn
              small
              elevation="0" 
              @click.stop="joinAcademyClass()"
              color="primary"
              v-if="!status && !adminActions && academyClass.canJoin"
            >
              {{ $t('message.join') }}
            </v-btn>
            <v-btn
              elevation="0" small
              @click.stop="joinAcademyClass()"
              color="primary"
              v-else-if="!status && !adminActions && academyClass.canWaitlist"
            >
              {{ $t('message.join_waitlist') }}
            </v-btn>
            <v-btn
              elevation="0" small
              :disabled="alreadyProcessed"
              v-if="adminActions && inCapacity"
              @click="acceptRequest()"
              color="green"
            >{{ $t('message.approve') }}</v-btn>
            <v-btn
              elevation="0" small
              :disabled="alreadyProcessed"
              v-if="adminActions && inCapacity"
              @click="rejectRequest()"
              color="error"
            >{{ $t('message.reject') }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <span
              v-if="alreadyProcessed"
              class="supporting-text"
            >{{ $t('message.already_processed') }}</span>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-text v-if="familyMembers && academyClass.canJoin">
            <div class="text-subtitle-2">{{ $t('message.family_members') }}</div>
            <v-list>
              <template v-for="(user, index) in familyMembers">
                <v-list-item :key="'family_member_' + index + '_' + user.userId" @click="doNothing()">
                  <v-list-item-avatar>
                    <img v-if="user.image" :src="userFile(user.image)" alt="avatar"/>
                    <img v-else :src="assets('blank-profile-picture.png')" alt="avatar"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      small
                      v-if="user.status == 'approved'"
                      icon
                      color="success"
                      @click.stop="leaveClass(user)"
                    >
                      <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      icon
                      v-else-if="user.status == 'pending'"
                      @click.stop="cancelRequest(user)"
                    >
                      <v-icon>mdi-clock-time-five-outline</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      icon
                      v-else
                      @click.stop="joinAcademyClass(user)"
                    >
                      <v-icon>mdi-login</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
          <v-card-text v-if="participantList.length > 0">
            <div class="text-subtitle-2">{{ $t('message.participants') }}</div>
            <v-list>
              <template v-for="(user, index) in participantList">
                <v-list-item :key="'participant_member_' + index + '_' + user.userId" @click="doNothing()">
                  <v-list-item-avatar>
                    <img v-if="user.image" :src="userFile(user.image)" alt="avatar"/>
                    <img v-else :src="assets('blank-profile-picture.png')" alt="avatar"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
        </div>
        <div v-else>
          <v-card-text class="error--text text-center text-h6">
            {{ $t('message.cancelled') }}
          </v-card-text>
        </div>
        <v-card-actions>
            <v-spacer></v-spacer>
             <v-btn
              elevation="0" small
              @click="goBack()"
            >{{ $t('message.back') }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
      </v-card>
    </div>
    <v-dialog v-model="leaveClassDialog" max-width="90%">
      <v-card outlined>
        <v-card-title>
          <span>{{$t('message.leave_class_message')}}</span>
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="error"
            small elevation="0"
            v-if="status == 'approved'"
            @click.stop="leaveClass()"
          >{{ $t('message.leave_class') }}</v-btn>
          <v-btn small elevation="0" @click.stop="leaveClassDialog=false">{{ $t('message.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>
<script>
import NotificationService from "@/services/NotificationService";
import UserService from "@/services/UserService";
import AcademyCalendarService from "@/services/AcademyCalendarService";
import VideoDisplay from "@/components/displays/VideoDisplay.vue";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

export default {
  data() {
    return {
      loading: true,
      academyClassId: this.$route.params.academyClassId,
      notificationId: this.$route.params.notificationId,
      familyMembersDialog: null,
      academyClass: null,
      academy: null,
      status: null,
      adminActions: false,
      memberRequesting: null,
      alreadyProcessed: false,
      familyMembers: [],
      participantList: [],
      participantCount: 0,
      requestor: null,
      inCapacity: true,
      leaveClassDialog: false,
    };
  },
  components: {
    VideoDisplay,
    BackButton
  },
  created() {
    this.getAcademyClass();
    if (this.notificationId) {
      NotificationService.read(this.notificationId);
    }
  },
  methods: {
    getAcademyClass() {
      AcademyCalendarService.academyClass(this.academyClassId)
        .then((data) => {
          if (data) {
            this.academyClass = data.academyClass;
            this.familyMembers = data.familyMembers;
            this.participantList = data.participantList;
            if (data.academyClass && data.academyClass.approved && data.academyClass.approved.length > 0) {
              this.participantCount = data.academyClass.approved.length;
            }
            this.academy = data.academy;
            if (this.academyClass.maxCapacity) {
              try {
                const maxCapacity = this.academyClass.maxCapacity;
                const totalApproved = this.academyClass.approved
                  ? this.academyClass.approved.length
                  : 0;
                if (totalApproved < maxCapacity) this.inCapacity = true;
                else this.inCapacity = false;
                if (totalApproved < maxCapacity)
                  this.academyClass["canJoin"] = true;
                else if (data.academyClass.allowWaitlist) this.academyClass["canWaitlist"] = true;
              } catch (e) {
                console.log(e.toString());
              }
            }
            if (this.notificationId) {
              NotificationService.notification(this.notificationId)
                .then((notification) => {
                  const type = notification.type;
                  const sender = notification.sender;
                  this.memberRequesting = notification.sender;
                  if (
                    type == "class_request" &&
                    (this.$store.state.user._id == this.academyClass.user ||
                      this.$store.state.user._id ==
                      this.academyClass.professorUser._id)
                  ) {
                    UserService.user(sender, true)
                      .then((user) => {
                        if (
                          this.academyClass.pending &&
                          this.academyClass.pending.indexOf(sender) >= 0
                        ) {
                          this.requestor = user;
                          this.adminActions = true;
                          this.loading = false;
                        } else {
                          this.requestor = user;
                          this.adminActions = true;
                          this.alreadyProcessed = true;
                          this.loading = false;
                        }
                      });
                  } else if (type == "class_request_accepted") {
                    this.loading = false;
                    this.status = "approved";
                  } else if (type == "class_request_rejected") {
                    this.loading = false;
                    this.status = "rejected";
                  } else {
                    this.loading = false;
                  }
                });
            } else {
              this.loading = false;
              if (
                this.academyClass.approved &&
                this.academyClass.approved.indexOf(this.$store.state.user._id) >=
                0
              )
                this.status = "approved";
              else if (
                this.academyClass.pending &&
                this.academyClass.pending.indexOf(this.$store.state.user._id) >= 0
              )
                this.status = "pending";
            }
          }
        });
    },
    joinAcademyClass(familyMember) {
      AcademyCalendarService.joinAcademyClass(this.academyClassId, familyMember ? familyMember.userId : null)
        .then((data) => {
          if (data) {
            this.showAlert("success", this.$t("message.success"));
            if (familyMember) {
              familyMember.status = 'pending';
            } else {
              this.status = "pending";
            }
          }
        })
        .catch((err) => {
          if (err)
            this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    cancelRequest() {
      AcademyCalendarService.cancelRequest(this.academyClassId)
        .then(() => {
          this.showAlert("success", this.$t("message.success"));
          this.status = null;
        });
    },
    leaveClass() {
      AcademyCalendarService.leaveAcademyClass(this.academyClassId)
        .then(() => {
          this.showAlert("success", this.$t("message.success"));
          this.status = null;
          this.leaveClassDialog = false;
          this.getAcademyClass();
        });
    },
    acceptRequest() {
      AcademyCalendarService.acceptRequest(
        this.academyClass.managedAcademy,
        this.academyClassId,
        this.memberRequesting
      )
        .then(() => {
          this.showAlert("success", this.$t("message.success"));
          this.status = null;
          this.adminActions = false;
          this.academyClass.canJoin = false;
          this.academyClass.canWaitlist = false;
        });
    },
    rejectRequest() {
      AcademyCalendarService.rejectRequest(
        this.academyClass.managedAcademy,
        this.academyClassId,
        this.memberRequesting
      )
        .then(() => {
          this.showAlert("success", this.$t("message.success"));
          this.status = null;
          this.adminActions = false;
          this.academyClass.canJoin = false;
          this.academyClass.canWaitlist = false;
        });
    },
    agree() {},
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.agreement_iframe {
  width: 100%;
  max-height: 500px;
}
</style>