<template>
  <svg :fill="iconColor" :width="iconSize" :height="iconSize" id="icon_hero" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 16L3 5L8.5 10L12 4L15.5 10L21 5L19 16H5M19 19C19 19.6 18.6 20 18 20H6C5.4 20 5 19.6 5 19V18H19V19Z" /></svg>
</template>
<script>
export default {
  data() {
    return {
      iconColor: "white",
      iconSize: "32px"
    };
  },
  mounted(){
    if(this.color)
      this.iconColor = this.getColor(this.color);
    if(this.size)
      this.iconSize = this.size;
  },
  props: ['color','size'],
};
</script>
<style>
  svg {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -6px; 
  }
</style>