<template>
  <v-select
    dense
    outlined
    @input="event => { emitChange(event) }"
    :items="eventTypes"
    v-model="selectedValue"
    :label="$t('message.select_event_type')"
    item-text="name"
    item-value="value"
    :hide-details="true"
    :clearable="true">
  </v-select>
</template>

<script>

export default {
  data() {
    return {
      selectedValue: null,
      eventTypes: [
        { name: this.$t('message.open_mat'), value: "type_openmat" },
        { name: this.$t('message.tournament'), value: "type_tournament" },
        { name: this.$t('message.charity'), value: "type_charity" },
        { name: this.$t('message.camp'), value: "type_camp" },
        { name: this.$t('message.regular'), value: "type_regular" },
        { name: this.$t('message.private'), value: "type_private" },
        { name: this.$t('message.seminar'), value: "type_seminar" },
        { name: this.$t('message.virtual'), value: "type_virtual" }
      ]
    };
  },
  created() {
    this.eventTypes = this.eventTypes.sort();
  },
  props: {
    value: null
  },
  watch: {
    value: {
      immediate: true,
      handler: function(newValue) {
        this.selectedValue = newValue;
      }
    }
  },
  methods: {
    emitChange(event) {
      this.$emit("input", event);
    }
  }
};
</script>

<style scoped>
</style>
