<template>
  <v-combobox
        v-model="selectedValue"
        :items="masters"
        :hide-no-data="!search"
        :search-input.sync="search"
        item-text="text"
        item-value="_id"
        small-chips
        :disabled="loading"
        dense
        outlined
        :loading="loading"
        :label="$t('message.select_professor')" 
        @input="event => { $emit('input', event) }"
      >
        <template slot="no-data">
          <v-list-item>
            <span class="subheading">{{ $t('message.click_enter_add_new') }}: </span>
            <v-chip
              label
              small
            >
              {{ search }}
            </v-chip>
          </v-list-item>
        </template>
  </v-combobox>
</template>

<script>
import LineageService from "@/services/LineageService";

export default {
  data() {
    return {
      loading: false,
      selectedValue: null,
      masters: [],
      items: [],
      search: null
    };
  },
  created() {
    this.loading = true;
    LineageService.masters({})
      .then(data => {
        if (data) {
          this.masters = data.masters;
          this.loading = false;
        }
      });
  },
  mounted(){
    this.selectedValue = this.value
  },
  props: {
    value: null,
    showIcon: {
      type:Boolean,
      default:false
    }
  },
  watch: {
    search(val) {
      val && this.querySelections(val);
    },
    value: {
      handler: function(newValue) {
        this.selectedValue = newValue;
      }
    }
  },
  methods: {
    async querySelections(v) {
      this.loading = true;
      LineageService.masters({filter: v})
        .then((data) => {
          if (data) {
            const tmp = data.masters;
            this.loading = false;
            this.masters = tmp.filter((e) => {
              return (
                (e.text || "")
                  .toLowerCase()
                  .indexOf((v || "").toLowerCase()) > -1
              );
            });
          }
        });
    }
  }
};
</script>

<style scoped>
</style>
