<template>
  <v-autocomplete
    @input="event => { emitChange(event) }"
    v-model="selectedItem"
    :items="items"
    :label="labelText" 
    clearable
    outlined
    dense
  ></v-autocomplete>
</template>

<script>

export default {
  data() {
    return {
      selectedItem: null,
      items: [
        {value:"mentor", text: this.$t('message.interest_mentor')},
        {value:"mentoring", text: this.$t('message.interest_mentoring')},
        {value:"competing", text: this.$t('message.interest_competing')},
        {value:"privates", text: this.$t('message.interest_privates')},
        {value:"teaching", text: this.$t('message.interest_teaching')}
      ],
      labelText: null
    }
  },
  created() {
    if(this.label)
      this.labelText = this.label;
  },
  props: {
    value: null,
    label: null
  },
  methods: {
    emitChange (event) {
      this.$emit('input', event)
    }
  }
};
</script>

<style scoped>
</style>