<template>
  <div>
    <v-text-field
      :label="$t('message.pick_location')"
      v-model="selectedValue"
      :loading="loading"
      v-on:keyup="findLocation"
      clearable
    ></v-text-field>
    <v-container v-if="showPopup">
      <v-card class="locations_popover" transition="fade">
        <v-card-text v-if="!loading && items.length == 0"
          ><h2>{{ $t("message.no_data_matching") }}</h2></v-card-text
        >
        <v-card-text v-else style="padding: 0px">
          <v-list two-line pa-0>
            <template v-for="(item, index) in items">
              <v-list-item
                pa-0
                :key="index + '_postloc'"
                @click="selectLocation(item)"
              >
                <v-list-item-avatar>
                  <img v-if="item.image" :src="userImg(item.image)" />
                  <img v-else :src="assets('logo-circular-new.png')" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="item.text"></v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-if="item.type == 'event'">{{
                      $t("message.event")
                    }}</span>
                    <span v-if="item.type == 'academy'">{{
                      $t("message.academy")
                    }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import UtilService from "@/services/UtilService";

export default {
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      selectedValue: null,
      selectedLocation: null,
      showPopup: false,
    };
  },
  components: {},
  watch: {
    selectedValue: {
      handler: function (newValue) {
        if (!newValue) {
          this.showPopup = false;
          this.$emit("input", null);
        }
      },
    },
  },
  props: {
    value: null,
    display: null,
  },
  created() {
    if (this.display) this.selectedValue = this.display;
  },
  mounted() {},
  methods: {
    selectLocation(loc) {
      this.showPopup = false;
      if (loc.location) loc.location.source = "post";
      this.$emit("input", loc.location);
      this.selectedLocation = loc;
      this.selectedValue = loc.text;
    },
    findLocation() {
      if (this.selectedValue && this.selectedValue.length > 2) {
        this.fetchLocations(this.selectedValue);
      } else {
        this.showPopup = false;
      }
    },
    fetchLocations(v) {
      if (!this.loading) {
        this.loading = true;
        UtilService.searchLocations(v).then((data) => {
          this.showPopup = true;
          if (data) {
            this.items = data;
            this.loading = false;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.locations_popover {
  position: absolute;
  box-shadow: 0 6px 6px rgba(16, 16, 16, 0.04), 0 6px 6px rgba(0, 0, 0, 0.05);
  z-index: 999;
  margin-top: -50px;
  margin-left: 10px;
  min-width: 50%;
}
</style>