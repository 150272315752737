<template>
  <div>
    <span v-if="showImage">
      <img class="country-display" :src="imagePath" @error="imageError()" />
    </span>
    <span>
      {{ countryDisplay }}
    </span>
  </div>
</template>

<script>
import countriesJson from "@/assets/data/countries.json";

export default {
  props: ["countryCode","hideImage"],
  data() {
    return {
      countries: countriesJson,
      showImage: true,
    };
  },
  methods: {
    imageError() {
      this.showImage = false;
    },
  },
  created() {
    if(this.hideImage)
      this.showImage = false;
  },
  computed: {
    countryDisplay() {
      if (this.countryCode) {
        for (var i = 0; i < this.countries.length; i++) {
          const c = this.countries[i];
          const cc = this.countryCode.toUpperCase();
          if (c.countryCode == cc) {
            return c.country;
          }
        }
      }
      return "";
    },
    imagePath() {
      return this.assets("flags/" + this.countryCode + ".png");
    },
  },
};
</script>

<style scoped>
.country-display {
  height: 20px;
  vertical-align: middle;
}
</style>
