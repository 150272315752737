import Api from "@/services/Api";

export default {
  async progress() {
    const response = await Api().get("syllabus/user/progress");
    return response.data;
  },
  async details(instanceId) {
    const response = await Api().get(
      "syllabus/user/progress/details/" + instanceId
    );
    return response.data;
  },
  async getActiveInstances() {
    const response = await Api().get("syllabus/user/active/instances");
    return response.data;
  },
  async completeRule(instanceDataId, ruleId) {
    const response = await Api().patch(
      "syllabus/user/instances/data/" +
        instanceDataId +
        "/complete/rule?ruleId=" +
        ruleId,
      { rule: ruleId }
    );
    return response.data;
  },
};
