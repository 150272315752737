<template>
  <div class="fill-height">
    <BackButton></BackButton>
    <v-sheet class="fill-height" :rounded="isSmallerDisplay() ? '' : 'lg'">
      <v-card-actions class="pb-0 mb-0 pl-1">
        <v-autocomplete
          :items="treeNodes"
          :label="$t('message.search_tree')"
          v-model="selectedTreeName"
          item-text="name"
          item-value="_id"
          :clearable="true"
          :loading="loadingTreeNodes"
          outlined
          dense
          hide-no-data
          :search-input.sync="searchTree"
          :disabled="loading"
          class="pa-0 autocomplete"
          :disable-lookup="true"
          style="margin-bottom:-14px"
        >
          <template slot="no-data">
            <v-list-item>
              <span class="subheading">{{
                $t("message.no_data_matching")
              }}</span>
            </v-list-item>
          </template>
          <template slot="item" slot-scope="data">
            <v-list-item-avatar v-if="data.item && data.item.user">
              <img :src="userImg(data.item.user.userImageSmall)" />
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
              <v-icon>eco</v-icon>
            </v-list-item-avatar>
            <v-list-item>
              <v-list-item-content v-if="data.item">{{
                data.item.name
              }}</v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-spacer></v-spacer>
        <v-btn icon right small @click="sendInfoDialog = true">
          <QuestionMarkCircleIcon class="hero-button"></QuestionMarkCircleIcon>
        </v-btn>
      </v-card-actions>
      <div class="text-center" v-if="loading">
        <v-progress-circular
          indeterminate
          size="100"
          color="success"
        >
          <IconLineage size="30" :color="themeColor('success')"></IconLineage>
        </v-progress-circular>
        <div class="text-body-2">{{ $t("message.loading_lineage_tree") }}</div>
      </div>
      <div v-if="tree">
        <v-sheet class="treescroll" style="width:100%;overflow-x:auto">
          <ul style="padding-left:0px;width:600px;min-height: 100px">
            <tree-item
              style="list-style: none;"
              :edit-children="true"
              :item="tree"
            ></tree-item>
          </ul>
        </v-sheet>
      </div>
    </v-sheet>
    <v-dialog v-model="sendInfoDialog" max-width="60%">
      <v-card outlined>
        <v-card-title class="text-subtitle-1">
          <QuestionMarkCircleIcon class="hero-icon"></QuestionMarkCircleIcon>
          {{ $t("message.questions") }}?
        </v-card-title>
        <v-card-text class="text-left">
          <div>{{ $t("message.lineage_questions") }}</div>
          <div>{{ $t("message.lineage_questions2") }}</div>
        </v-card-text>
        <v-card-text>
          <v-textarea
            outlined
            dense
            v-model="lineageQuestions"
            rows="2"
            style="margin-left: 5px; margin-right: 5px; padding-top: 5px"
            :label="$t('message.enter_text_here')"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="sendLineageInfo()">{{
            $t("message.send_info")
          }}</v-btn>
          <v-btn color="secondary" @click="sendInfoDialog = false">{{
            $t("message.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LineageService from "@/services/LineageService";
import TreeItem from "@/components/displays/TreeItem.vue";
import IconLineage from "@/components/displays/icons/IconLineage";
import BackButton from "@/components/displays/BackButtonDisplay.vue";

//Icons
import { 
  QuestionMarkCircleIcon,
} from '@vue-hero-icons/outline'

export default {
  data() {
    return {
      tree: null,
      loading: true,
      treeNodes: [],
      searchTree: null,
      loadingTreeNodes: false,
      selectedTreeName: null,
      sendInfoDialog: false,
      lineageQuestions: null,
    };
  },
  components: {
    TreeItem,
    QuestionMarkCircleIcon,
    IconLineage,
    BackButton
  },
  created() {
    this.getTree("root", true);
    LineageService.treeNodes()
      .then((data) => {
        if (data) {
          this.treeNodes = data.nodes;
        }
      });
  },
  methods: {
    sendLineageInfo() {
      if (this.lineageQuestions && this.lineageQuestions != "") {
        LineageService.sendQuestions({ contents: this.lineageQuestions }).then(
          (data) => {
            if (data) {
              this.sendInfoDialog = false;
              this.showAlert("success", this.$t("message.success"));
            }
          }
        );
      }
    },
    getTree(nodeId, root) {
      this.loading = true;
      this.tree = null;
      LineageService.children(nodeId, root).then((data) => {
        if (data) {
          if (root) this.tree = data.root;
          this.loading = false;
        }
      });
    },
  },
  watch: {
    selectedTreeName(val) {
      if (!val) val = "root";
      this.tree = null;
      this.getTree(val, true);
    },
  },
};
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.treescroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.treescroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>